import {
    BarLine,
    Grid,
    StatusContainer,
    SubHeading,
    Card,
    Text,
    Header,
    UserWrap,
} from './style';
import { BackButton, TopText, UserImage, UserName } from '../users/style';
import { useNavigate, useParams } from 'react-router-dom';
import back_arrow from '../../common/images/back_arrow.svg';
import { Loader, ModalComp } from '../../common/component';
import { getDateString } from '../../common/utils/date';
import { useGetSingleWithdrawalRequestQuery } from '../../store/withdrawalRequestApi';
import { currencyFormater } from '../../common/utils';

export const ViewWithdrawalRequest = () => {
    const params = useParams();
    const navigate = useNavigate();

    const withdrawalRequestId = params?.id as string;
    const { data: withdrawalData, isLoading } =
        useGetSingleWithdrawalRequestQuery(withdrawalRequestId);

    return (
        <div>
            <div className="d-flex align-items-center my-2">
                <BackButton onClick={() => navigate(-1)}>
                    <img src={back_arrow} alt="" /> Back
                </BackButton>
                <TopText>
                    {' '}
                    <span>/ Withdrawal Request</span> / View Request
                </TopText>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="d-flex gap-3 mt-4">
                        <BarLine />
                        <div className="d-flex flex-column gap-4 w-100">
                            <Grid className="d-flex gap-4 gap-sm-5">
                                <Card>
                                    <div className="pb-1">
                                        <UserWrap>Applied by</UserWrap>
                                    </div>
                                    <div className="d-flex align-items-center gap-3 gap-lg-2">
                                        <UserImage>
                                            <img
                                                src={
                                                    withdrawalData?.data?.user
                                                        ?.passportPhoto
                                                }
                                                alt=""
                                            />
                                        </UserImage>
                                        <UserName>{`${withdrawalData?.data?.user?.firstName} ${withdrawalData?.data?.user?.lastName}`}</UserName>
                                    </div>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Applied on</SubHeading>
                                    <Text>
                                        {getDateString(
                                            withdrawalData?.data
                                                ?.createdAt as string,
                                        )}
                                    </Text>
                                </Card>

                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Status</SubHeading>
                                    <StatusContainer
                                        status={
                                            withdrawalData?.data
                                                ?.requestStatus as string
                                        }
                                    >
                                        {withdrawalData?.data?.requestStatus}
                                    </StatusContainer>
                                </Card>

                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Wallet Type</SubHeading>
                                    <Text>
                                        {
                                            withdrawalData?.data?.wallet
                                                ?.walletType
                                        }
                                    </Text>
                                </Card>

                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Wallet Balance</SubHeading>
                                    <Text>
                                        {`${withdrawalData?.data?.wallet?.walletCurrency.toUpperCase()}${currencyFormater(
                                            withdrawalData?.data?.wallet
                                                ?.walletBalance,
                                        )}`}
                                    </Text>
                                </Card>

                                {withdrawalData?.data?.approvedBy && (
                                    <Card className="d-flex flex-column gap-2">
                                        <SubHeading>Approved By</SubHeading>
                                        <Text>
                                            {withdrawalData?.data?.approvedBy
                                                ?.firstName +
                                                ' ' +
                                                withdrawalData?.data?.approvedBy
                                                    ?.lastName}
                                        </Text>
                                    </Card>
                                )}
                            </Grid>
                        </div>
                    </div>

                    <div className="my-4">
                        <Header>Bank Details</Header>
                        <div className="d-flex gap-3">
                            <BarLine />

                            <Card className="d-flex flex-column gap-2">
                                <SubHeading>Bank Name</SubHeading>
                                <Text>
                                    {withdrawalData?.data?.bank?.bankName}
                                </Text>
                            </Card>

                            <Card className="d-flex flex-column gap-2">
                                <SubHeading>Account Number</SubHeading>
                                <Text>
                                    {withdrawalData?.data?.bank?.accountNumber}
                                </Text>
                            </Card>
                            <Card className="d-flex flex-column gap-2">
                                <SubHeading>Account Name</SubHeading>
                                <Text>
                                    {withdrawalData?.data?.bank?.accountName}
                                </Text>
                            </Card>
                            <Card className="d-flex flex-column gap-2">
                                <SubHeading>Account Type</SubHeading>
                                <Text>
                                    {withdrawalData?.data?.bank?.accountType}
                                </Text>
                            </Card>
                            <Card className="d-flex flex-column gap-2">
                                <SubHeading>Request Amount</SubHeading>
                                <Text>
                                    {`${
                                        withdrawalData?.data?.bank?.accountType.toUpperCase() ??
                                        ''
                                    }${currencyFormater(
                                        withdrawalData?.data?.amount,
                                    )}`}
                                </Text>
                            </Card>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
