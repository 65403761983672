import {
	ActiveButton,
	BackButton,
	BorderDiv,
	TextGrid,
	TopText,
	UserContact,
	UserImage,
	UserName,
	UserType,
} from "./style";
import { ReactComponent as Message } from "../../common/images/message_icon.svg";
import calender from "../../common/images/calender.svg";
import { ReactComponent as CallIcon } from "../../common/images/call_icon.svg";
import back_arrow from "../../common/images/back_arrow.svg";
import { useState } from "react";
import { TradeHistory } from "./tradeHistory";
import { WalletBalance } from "./walletBalance";
import { BankAccount } from "./bankAccount";
import { useNavigate, useParams } from "react-router-dom";
import { Col } from "react-bootstrap";
import { useGetSingleUserQuery } from "../../store/userApi";
import { getDateString } from "../../common/utils/date";

export const ViewUser = () => {
	const param = useParams();
	const [active, setActive] = useState(1);
	const navigate = useNavigate();
	const userId = param?.id as string;
	const { data: userList, isLoading: isLoadingUser } =
		useGetSingleUserQuery(userId);

	return (
		<div>
			<div className="d-flex align-items-center my-2">
				<BackButton onClick={() => navigate(-1)}>
					<img src={back_arrow} alt="" /> Back
				</BackButton>
				<TopText>
					{" "}
					<span>/ Users</span> / View User
				</TopText>
			</div>
			<div
				className="p-3"
				style={{ background: "rgba(250, 202, 21, 0.1)", borderRadius: "8" }}
			>
				{!isLoadingUser ? (
					<div className="d-sm-flex align-items-center justify-content-between ">
						<div className="w-100">
							<Col className="d-flex align-items-center  mb-2 mb-sm-0">
								<UserType>{userList?.data?.userType.toUpperCase()}</UserType>
							</Col>
							<div className="d-flex align-items-center gap-3">
								<UserImage>
									<img src={userList?.data?.passportPhoto} alt="" />
								</UserImage>
								<div className="w-100">
									<UserName>{`${userList?.data?.firstName} ${userList?.data?.lastName}`}</UserName>
									<div className="d-flex align-items-center justify-content-between mt-1">
										<div className="d-lg-flex align-items-center ">
											{userList?.data?.email ? (
												<UserContact>
													<Message />
													{userList?.data?.email}
												</UserContact>
											) : null}
											<div className="px-3" />
											{userList?.data?.phone ? (
												<UserContact>
													<CallIcon />
													{userList?.data?.phone}
												</UserContact>
											) : null}
										</div>
										<UserContact className="d-none d-sm-flex">
											Date created <img src={calender} alt="" />{" "}
											{getDateString(userList?.data?.createdAt as string)}
										</UserContact>
									</div>
								</div>
							</div>
						</div>
						<div className="d-flex d-sm-none flex-column  align-items-lg-end my-3">
							<UserContact>
								Date created <img src={calender} alt="" />{" "}
								{getDateString(userList?.data?.createdAt as string)}
							</UserContact>
						</div>
					</div>
				) : null}
			</div>
			<BorderDiv className=" my-4 ">
				<Col sm={8}>
					<TextGrid>
						<ActiveButton active={active === 1} onClick={() => setActive(1)}>
							Trade history
						</ActiveButton>
						<ActiveButton active={active === 2} onClick={() => setActive(2)}>
							Wallet balance
						</ActiveButton>
						<ActiveButton active={active === 3} onClick={() => setActive(3)}>
							Bank accounts
						</ActiveButton>
					</TextGrid>
				</Col>
			</BorderDiv>
			{active === 1 ? (
				<TradeHistory />
			) : active === 2 ? (
				<WalletBalance />
			) : (
				<BankAccount />
			)}
		</div>
	);
};
