import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
    colors,
    CustomButton,
    ModalComp,
    SelectComp,
    TextEditor,
    TextInputComp,
} from '../../common/component';
import { showSuccessToast, showErrorToast } from '../../common/utils/toast';
import {
    useGetAllTopicsQuery,
    useGetSingleSubTopicQuery,
    useUpdateSubTopicMutation,
} from '../../store/topicApi';
import CustomSearchDropDown from '../../common/component/Ui/customSearch';
const parse = require('html-react-parser');

export const SubTopicDetails = () => {
    const param = useParams();
    const subTopicId = param?.id as string;
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState<any>();
    const [desc, setDesc] = useState<any>();
    const [priority, setPriority] = useState<any>();

    const [status, setStatus] = useState<any>();
    const [subTopicApiId, setSubTopicApiId] = useState<any>('');
    const { data: subTopicData } = useGetSingleSubTopicQuery(subTopicId);
    const [updateSubTopic, { isLoading: isUpdating }] =
        useUpdateSubTopicMutation();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };

    const { data: allTopics } = useGetAllTopicsQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });

    const { handleSubmit, control } = useForm({
        defaultValues: {
            topic: '',
            title: '',
            status: '',
            priority: 0,
            description: '',
            subTopicId: '',
        },
    });

    const onHandleEdit = () => {
        const payload = {
            topic: subTopicApiId?.value,
            title: title,
            description: desc,
            subTopicId: subTopicId,
            priority: Number(priority),
            status: status === 'Active' ? true : false,
        };

        updateSubTopic(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    const cleanupSubTopics = (topicsInQuestion: any) => {
        if (topicsInQuestion) {
            const result = topicsInQuestion.map((topic: any) => {
                return { label: topic.title, value: topic?._id };
            });

            return result;
        }

        return [];
    };

    return (
        <div className="d-flex flex-column gap-4 mx-lg-5 mb-4">
            <>
                <div className="d-flex justify-content-between">
                    <Wrap>
                        <Title>Subtopic title</Title>
                        <Paragraph>
                            {parse(subTopicData?.data?.title)}
                        </Paragraph>
                    </Wrap>
                </div>

                <Wrap>
                    <Title>Subtopic description</Title>
                    <TextArea>
                        {parse(subTopicData?.data?.description)}
                    </TextArea>
                </Wrap>

                <div className="d-flex justify-content-center">
                    <CustomButton
                        className="py-3 mt-4"
                        text="Edit SubTopic"
                        onClick={() => {
                            setShowModal(true);
                        }}
                    />
                </div>
            </>

            <ModalComp
                show={showModal}
                handleClose={() => setShowModal(false)}
                centered
                close={true}
                size="lg"
                modalTitle="Edit subtopic"
                paragraph="Fill in the forms to edit a Subtopic"
                modalBody={
                    <form onSubmit={handleSubmit(onHandleEdit)}>
                        <div className="py-1 px-lg-4 ">
                            <div className="my-3">
                                <Controller
                                    name="priority"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                    }) => {
                                        setPriority(value);
                                        return (
                                            <TextInputComp
                                                labelText="Priority"
                                                inputClassName={'py-2'}
                                                name="priority"
                                                type="number"
                                                defaultValue={1}
                                                {...{ value, onChange }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="mb-4">
                                <div>
                                    {' '}
                                    <Controller
                                        name="topic"
                                        control={control}
                                        rules={{
                                            required: '*topic is required',
                                            min: '8',
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            formState: { errors },
                                        }) => {
                                            const errorMessage =
                                                errors.topic?.message;
                                            setSubTopicApiId(value);
                                            return (
                                                <CustomSearchDropDown
                                                    isShowLabel
                                                    placeholder="Select..."
                                                    defaultOptions={cleanupSubTopics(
                                                        allTopics?.data,
                                                    )}
                                                    label="Select Topic"
                                                    {...{
                                                        value,
                                                        onChange,
                                                        errors: [errorMessage],
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="my-4">
                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{
                                        required: 'Subtopic title is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                    }) => {
                                        setTitle(value);
                                        return (
                                            <TextEditor
                                                {...{ value, onChange }}
                                                labelText="Subtopic title"
                                                defaultValue={
                                                    subTopicData?.data?.title
                                                }
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-4">
                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{
                                        required:
                                            'Subtopic description is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                    }) => {
                                        setDesc(value);
                                        return (
                                            <TextEditor
                                                {...{ value, onChange }}
                                                labelText="Subtopic description"
                                                defaultValue={
                                                    subTopicData?.data
                                                        ?.description
                                                }
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <Controller
                                name="status"
                                control={control}
                                rules={{
                                    required: 'status is required',
                                }}
                                render={({
                                    field: { value, onChange },
                                    formState: { errors },
                                }) => {
                                    const errorMessage =
                                        errors?.status?.message;
                                    setStatus(value);
                                    return (
                                        <SelectComp
                                            labelText="Status"
                                            inputClassName={'py-2 '}
                                            arrayComp={['Active', 'Inactive']}
                                            name="status"
                                            defaultValue={
                                                subTopicData?.data?.status
                                                    ? 'Active'
                                                    : 'Inactive'
                                            }
                                            {...{
                                                value,
                                                onChange,
                                                errors: [errorMessage],
                                            }}
                                        />
                                    );
                                }}
                            />

                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        isUpdating
                                            ? 'Loading...'
                                            : 'Update Subtopic'
                                    }
                                />
                            </div>
                        </div>
                    </form>
                }
            />
        </div>
    );
};
const Wrap = styled.div`
    width: 100%;
`;
const Title = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${colors.black40};
    margin: 8px 0px;
`;
const Paragraph = styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    color: ${colors.black20};
`;
const TextArea = styled.div`
    background: #f7f9fa;
    border-radius: 6px;
    padding: 15px;
    font-size: 18px;
`;
