import { CSVLink } from "react-csv";
import { BsDownload } from "react-icons/bs";
import styled from "styled-components";
import { colors } from "../colors";

export const CsvDownloader = ({
	data = [],
	headers = [],
	fileName,
}: {
	fileName?: String;
	headers: any;
	data: any;
}) => {
	return (
		<CSVLink
			filename={fileName ? (fileName as string) : "IBX_Admin_file.csv"}
			data={data}
			headers={headers}
		>
			<CsvButton>
				CSV
				<span className="ms-2">
					<BsDownload size={15}/>
				</span>
			</CsvButton>
		</CSVLink>
	);
};

const CsvButton = styled.button`
	background: none;
	border: none;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	padding: 0px 5px;
	color: ${colors.purple100};
	cursor: pointer;

	&:hover {
		font-weight: 600;
	}
`;
