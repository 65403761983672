import { BackButton, TopText } from "../users/style";
import back_arrow from "../../common/images/back_arrow.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col } from "react-bootstrap";
import { ActiveButton, TradeBorderDiv } from "./style";
import { TradeChat } from "./tradeChat";
import { TradeInfo } from "./tradeInfo";

export const ViewTrade = () => {
	const params = useParams();
	const location = useLocation();
	const tradeId = params?.id as string;
	const navigate = useNavigate();

	return (
		<div className="pb-2">
			<div className="d-flex align-items-center my-2">
				<BackButton onClick={() => navigate(-1)}>
					<img src={back_arrow} alt="" /> Back
				</BackButton>
				<TopText>
					{" "}
					<span>/ Trade</span> / View Trades
				</TopText>
			</div>

			<TradeBorderDiv className=" my-4 ">
				<Col>
					<div className="d-flex gap-4">
						<ActiveButton
							active={
								location?.pathname ===
								`/dashboard/trade/view/trade-info/${tradeId}`
							}
							onClick={() => {
								navigate(`/dashboard/trade/view/trade-info/${tradeId}`);
							}}
						>
							Trade Info
						</ActiveButton>
						<ActiveButton
							active={
								location?.pathname ===
								`/dashboard/trade/view/trade-chat/${tradeId}`
							}
							onClick={() => {
								navigate(`/dashboard/trade/view/trade-chat/${tradeId}`);
							}}
						>
							Messages
						</ActiveButton>
					</div>
				</Col>
			</TradeBorderDiv>
			{location?.pathname === `/dashboard/trade/view/trade-chat/${tradeId}` ? (
				<TradeChat TradeId={tradeId} />
			) : location?.pathname ===
			  `/dashboard/trade/view/trade-info/${tradeId}` ? (
				<TradeInfo />
			) : null}
		</div>
	);
};
