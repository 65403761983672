import {
    BarLine,
    ButtonContainer,
    Container,
    InputWrap,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    Loader,
    PdfDownloader,
    SelectComp,
} from '../../common/component';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { TransactionHistoryTableHeader } from '../../common/utils/dataUtils';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as TransactionIcon } from '../../common/images/transaction_icon.svg';
import {
    useGetAllTransactionQuery,
    useGetTransactionStatsQuery,
} from '../../store/transactionApi';
import { ITransaction } from '../../store/transactionApi/interface';

export const Transaction = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropDownTerm, setDropDownTerm] = useState('');
    const [newArray, setNewArray] = useState<ITransaction[] | any>();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: allTransaction,
        isLoading,
        isFetching,
    } = useGetAllTransactionQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });
    const { data: TransactionStats } = useGetTransactionStatsQuery();

    useEffect(() => {
        setTotalRows(allTransaction?.pagination?.totalDocumentCount as number);
    }, [allTransaction?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allTransaction?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    // To implement search and dropdown input
    useEffect(() => {
        if (searchTerm === '' && dropDownTerm === '') {
            setNewArray(allTransaction?.data);
        }
        if (dropDownTerm !== '') {
            let filteredItems = allTransaction?.data?.filter(
                (item: ITransaction) => {
                    return item?.paymentStatus
                        .toLowerCase()
                        .includes(dropDownTerm.toLowerCase());
                },
            );
            console.log(filteredItems);
            console.log(dropDownTerm, 'Ann');
            setNewArray(filteredItems);
        }
        if (searchTerm !== '') {
            let searchItems = allTransaction?.data?.filter(
                (item: ITransaction) => {
                    return item?.paymentMethod
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase());
                },
            );
            setNewArray(searchItems);
        }
    }, [allTransaction, searchTerm, dropDownTerm]);

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'Payment method', key: 'paymentMethod' },
        {
            label: 'Transaction type',
            key: 'transactionType',
        },
        {
            label: 'Amount',
            key: 'amount',
        },
        {
            label: 'Description',
            key: 'description',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { header: 'Payment method', dataKey: 'paymentMethod' },
        {
            header: 'Transaction type',
            dataKey: 'transactionType',
        },
        {
            header: 'Amount',
            dataKey: 'amount',
        },
        {
            header: 'Description',
            dataKey: 'description',
        },
    ];

    return (
        <Container>
            {isLoading && <Loader />}
            <Header>Transaction</Header>
            <Grid>
                <Counter
                    counter_title="Total Transactions"
                    count={TransactionStats?.data?.totalTransactions || 0}
                    iconFill="blue"
                    iconBG="blue"
                >
                    <TransactionIcon />
                </Counter>
                <Counter
                    counter_title="Successful Transactions"
                    count={TransactionStats?.data?.successfulTransactions || 0}
                    iconFill="green"
                    iconBG="green"
                >
                    <TransactionIcon />
                </Counter>
                <Counter
                    counter_title="Pending transactions"
                    count={TransactionStats?.data?.pendingTransactions || 0}
                >
                    <TransactionIcon />
                </Counter>
                <Counter
                    counter_title="Failed Transactions"
                    count={TransactionStats?.data?.failedTransactions || 0}
                    iconFill="red"
                    iconBG="red"
                >
                    <TransactionIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={9}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col
                        xs={10}
                        sm={6}
                        className="d-flex align-items-center my-3"
                    >
                        <SelectComp
                            selectText="All transactions"
                            arrayComp={['Successful', 'Pending', 'Failed']}
                            onChange={(e: any) =>
                                setDropDownTerm(e.target.value)
                            }
                        />
                        <TotalResultText>{`${
                            allTransaction?.data?.length || 0
                        } result`}</TotalResultText>
                    </Col>
                    <InputWrap>
                        <img src={SearchIcon} alt="" />
                        <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Search"
                            className="border-start-0 border-bottom-0 border-top-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputWrap>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'TRANSACTION RECORD'}
                            {...{
                                columns: headersPdf,
                                body: allTransaction?.data,
                            }}
                        />
                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={allTransaction?.data}
                        />
                    </ButtonContainer>
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={TransactionHistoryTableHeader()}
                    data={newArray}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>
        </Container>
    );
};
