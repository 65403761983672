import styled from 'styled-components';
import { colors } from '../../common/component';

export const Flexbox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
`;
export const Subheader = styled.h3`
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: ${colors.neutral_black};
    @media (max-width: 600px) {
        font-size: 18px;
    }
`;
export const SeeAll = styled.h3`
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    @media (max-width: 600px) {
        font-size: 16px;
    }
`;
export const Card = styled.div`
    width: 100%;
    @media (max-width: 768px) {
        width: calc(50% - 24px);
    }
    @media (max-width: 426px) {
        width: calc(50% - 12px);
    }
    @media (max-width: 426px) {
        width: calc(100% - 0px);
    }
`;
export const SubHeading = styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: ${colors.gray30};
`;
export const Text = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #10192d;
`;
