import {
    BarLine,
    ButtonContainer,
    Container,
    InputWrap,
    TotalResultText,
} from '../users/style';
import { SubHeading, Card, Text } from './styles';
import {
    Counter,
    CsvDownloader,
    Loader,
    PdfDownloader,
    SelectComp,
    TextInputComp,
    TextEditor,
    DeleteModal,
} from '../../common/component';
const parse = require('html-react-parser');
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { FaqTableHeader, cleanUpFaqForPdf } from '../../common/utils/dataUtils';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { GrOverview } from 'react-icons/gr';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { CustomButton } from '../../common/component/button';
import { ModalComp } from '../../common/component/Ui/modalComp';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as FaqIcon } from '../../common/images/faq_icon.svg';
import TotalFaqImage from '../../common/images/totalfaq_icon.svg';
import {
    useAddFaqMutation,
    useDeleteFaqMutation,
    useEditFaqMutation,
    useGetAllFaqQuery,
    useGetSingleFaqQuery,
    useFaqStatsQuery,
    useGetActiveFaqQuery,
} from '../../store/faqApi';
import { showErrorToast, showSuccessToast } from '../../common/utils/toast';
import { useForm, Controller } from 'react-hook-form';
import { IFaq } from '../../store/faqApi/interface';

export const FrequentQuestion = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropDownTerm, setDropDownTerm] = useState('');
    const [filteredFAQ, setfilteredFAQ] = useState<IFaq[] | any>();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: allFaq,
        isLoading,
        isFetching,
    } = useGetAllFaqQuery(
        { ...paginate, isActive: dropDownTerm },
        {
            refetchOnMountOrArgChange: true,
        },
    );

    const [addFaq, { isLoading: isAdding }] = useAddFaqMutation();
    const [editFaq, { isLoading: isEditing }] = useEditFaqMutation();
    const [deleteFaq, { isLoading: isDeleting }] = useDeleteFaqMutation();
    const [faqId, setFaqId] = useState('');
    const { data: singlefaqData } = useGetSingleFaqQuery(faqId);
    const { data: faqStats } = useFaqStatsQuery();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    useEffect(() => {
        setTotalRows(allFaq?.pagination?.totalDocumentCount as number);
    }, [allFaq?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allFaq?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const handleConfirmDelete = () => {
        onHandleDelete(deleteId);
        setShowDeleteModal(false);
    };

    const Items = [
        {
            name: 'Edit item',
            icon: <BiEditAlt size={15} />,
            OnClick: (data: any) => {
                setFaqId(data?._id);
                setShowEditModal(true);
            },
        },
        {
            name: 'Delete item',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: any) => {
                setShowDeleteModal(true);
                setDeleteId(data?._id);
            },
        },
        {
            name: 'View more',
            icon: <GrOverview size={15} />,
            OnClick: (data: any) => {
                setFaqId(data?._id);
                setShowViewModal(true);
            },
        },
    ];

    const allFaqType = allFaq?.data?.map((item: any) => {
        return item?.name;
    });
    // add faq
    const faq = useForm({
        defaultValues: {
            question: '',
            answer: '',
            priority: 1,
        },
    });
    const { handleSubmit, control, reset } = faq;

    // edit faq
    const editFAQForm = useForm({
        defaultValues: {
            newData: {
                question: '',
                answer: '',
                status: '',
                priority: 0,
            },
        },
    });
    const {
        handleSubmit: handleEditSubmit,
        control: editControl,
        setValue,
    } = editFAQForm;

    useEffect(() => {
        if (singlefaqData?.data?._id) {
            setValue(
                'newData',
                {
                    question: singlefaqData?.data?.question,
                    answer: singlefaqData?.data?.answer,
                    priority: singlefaqData?.data?.priority,
                    status: singlefaqData?.data?.isActive
                        ? 'Active'
                        : 'Inactive',
                },
                { shouldValidate: true },
            );
        }
    }, [singlefaqData, setValue]);

    const onHandleSubmit = (value: {
        question: string;
        answer: string;
        priority: number;
    }) => {
        value.priority = Number(value.priority);
        addFaq(value)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowAddModal(false);
                reset();
            })
            .catch((error) => {
                showErrorToast(error?.data?.message[0]);
            });
    };

    const onHandleEdit = (value: {
        newData: {
            question: string;
            answer: string;
            status: string;
            priority: number;
        };
    }) => {
        let payload = {
            ...value?.newData,
            question: value?.newData?.question,
            answer: value?.newData?.answer,
            priority: Number(value?.newData?.priority),
            faqId: singlefaqData?.data?._id as string,
            isActive: value?.newData?.status === 'Active' ? true : false,
        };
        editFaq(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowEditModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message[0]);
            });
    };

    const onHandleDelete = (id: string) => {
        deleteFaq(id)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'Question', key: 'question' },
        {
            label: 'Answer',
            key: 'answer',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { header: 'Question', dataKey: 'question' },
        {
            header: 'Answer',
            dataKey: 'answer',
        },
    ];

    return (
        <Container>
            <Header>Frequently Asked Questions</Header>
            {(isLoading || isDeleting) && <Loader />}
            <Grid>
                <Counter
                    counter_title="Total FAQ"
                    count={faqStats?.data?.totalFAQ || '0'}
                    iconFill="blue"
                    iconBG="blue"
                    backgroundImage={TotalFaqImage}
                    isBackgroundImage={true}
                >
                    <FaqIcon />
                </Counter>

                <Counter
                    counter_title="Active FAQ"
                    count={faqStats?.data?.totalActiveFAQ || '0'}
                    iconFill="green"
                    iconBG="green"
                >
                    <FaqIcon />
                </Counter>
                <Counter
                    counter_title="Inactive FAQ"
                    count={faqStats?.data?.totalInactiveFAQ || '0'}
                    iconFill="red"
                    iconBG="red"
                >
                    <FaqIcon />
                </Counter>
            </Grid>

            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={7}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col
                        xs={10}
                        sm={6}
                        className="d-flex align-items-center my-3"
                    >
                        <SelectComp
                            selectText="All faqs"
                            arrayComp={[
                                { name: 'Active Faqs', value: 'true' },
                                { name: 'Inactive Faqs', value: 'false' },
                            ]}
                            onChange={(e: any) => {
                                setDropDownTerm(e.target.value);
                            }}
                        />
                        <TotalResultText>{`${
                            allFaq?.data?.length || 0
                        } result`}</TotalResultText>
                    </Col>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'FAQ Record'}
                            {...{
                                columns: headersPdf,
                                body: cleanUpFaqForPdf(allFaq?.data),
                            }}
                        />
                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={allFaq?.data}
                        />
                    </ButtonContainer>
                    <CustomButton
                        text="+ New FAQ"
                        onClick={() => setShowAddModal(true)}
                    />
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={FaqTableHeader(Items)}
                    data={allFaq?.data as IFaq[]}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>

            {/* add faq modal */}
            <ModalComp
                show={showAddModal}
                handleClose={() => setShowAddModal(false)}
                centered
                close={true}
                modalTitle="Add FAQ"
                paragraph="Fill in the  the forms to add FAQ"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleSubmit(onHandleSubmit)}>
                            <div className="my-3">
                                <Controller
                                    name="priority"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                    }) => {
                                        return (
                                            <TextInputComp
                                                labelText="Priority"
                                                inputClassName={'py-2'}
                                                name="priority"
                                                type="number"
                                                defaultValue={
                                                    singlefaqData?.data
                                                        ?.priority
                                                }
                                                {...{ value, onChange }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="question"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                    }) => {
                                        return (
                                            <TextInputComp
                                                labelText="Question"
                                                inputClassName={'py-2'}
                                                name="question"
                                                type="text"
                                                defaultValue={
                                                    singlefaqData?.data
                                                        ?.question
                                                }
                                                {...{ value, onChange }}
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="my-3">
                                <Controller
                                    name="answer"
                                    control={control}
                                    rules={{
                                        required: 'answer is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.answer?.message;
                                        return (
                                            <TextEditor
                                                labelText="Answer"
                                                arrayComp={allFaqType}
                                                name="answer"
                                                type="text"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isAdding ? 'Create FAQ' : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />

            {/* edit modal */}
            <ModalComp
                show={showEditModal}
                handleClose={() => setShowEditModal(false)}
                centered
                close={true}
                modalTitle="Edit Faq"
                paragraph="Fill in the  the forms to edit FAQ"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleEditSubmit(onHandleEdit)}>
                            <div className="my-3">
                                <Controller
                                    name="newData.priority"
                                    control={editControl}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.priority?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Priority"
                                                inputClassName={'py-2'}
                                                name="priority"
                                                type="number"
                                                defaultValue={
                                                    singlefaqData?.data
                                                        ?.priority
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.question"
                                    control={editControl}
                                    rules={{
                                        required: 'Question is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.question?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Question"
                                                arrayComp={allFaqType}
                                                name="faq"
                                                type="text"
                                                defaultValue={
                                                    singlefaqData?.data
                                                        ?.question
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.answer"
                                    control={editControl}
                                    rules={{
                                        required: 'Answer is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.answer?.message;
                                        return (
                                            <TextEditor
                                                labelText="Answer"
                                                arrayComp={allFaqType}
                                                name="answer"
                                                type="text"
                                                defaultValue={
                                                    singlefaqData?.data?.answer
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.status"
                                    control={editControl}
                                    rules={{
                                        required: 'status is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.status?.message;
                                        return (
                                            <SelectComp
                                                labelText="isActive?"
                                                inputClassName={'py-2 '}
                                                arrayComp={[
                                                    'Active',
                                                    'Inactive',
                                                ]}
                                                name="status"
                                                defaultValue={
                                                    singlefaqData?.data.isActive
                                                        ? 'Active'
                                                        : 'Inactive'
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isEditing ? 'Edit FAQ' : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />

            {/* view modal */}
            <ModalComp
                show={showViewModal}
                handleClose={() => setShowViewModal(false)}
                centered
                close={true}
                modalTitle="View FAQ"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form>
                            <Card className="my-3">
                                <SubHeading>Question</SubHeading>
                                <Text>{singlefaqData?.data?.question}</Text>
                            </Card>
                            <Card className="my-3">
                                <SubHeading>Answer</SubHeading>
                                <Text>
                                    {typeof singlefaqData?.data?.answer ===
                                    'string'
                                        ? parse(singlefaqData?.data?.answer)
                                        : ''}
                                </Text>
                            </Card>
                        </form>
                    </div>
                }
            />

            {/*delete  modal  */}
            <DeleteModal
                deleteItem={'FAQ'}
                showDeleteModal={showDeleteModal}
                cancelDelete={() => setShowDeleteModal(false)}
                confirmDelete={handleConfirmDelete}
            />
        </Container>
    );
};
