import {
    BarLine,
    ButtonContainer,
    Container,
    InputWrap,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    DeleteModal,
    Loader,
    PdfDownloader,
    SelectComp,
    TextInputComp,
} from '../../common/component';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import {
    CurrencyPairTableHeader,
    ExchangeRatesTableHeader,
    cleanUpCurrencyDataForPdf,
} from '../../common/utils/dataUtils';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { CustomButton } from '../../common/component/button';
import { ModalComp } from '../../common/component/Ui/modalComp';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as CurrencyPairIcon } from '../../common/images/currencypair_icon.svg';
import {
    useEditCurrencyPairMutation,
    useGetAllCurrencyPairQuery,
    useGetSingleCurrencyPairQuery,
} from '../../store/currencyPairApi';
import { showErrorToast, showSuccessToast } from '../../common/utils/toast';
import { useForm, Controller } from 'react-hook-form';
import { ICurrencyPair } from '../../store/currencyPairApi/interface';
import { useAllCurrencyQuery } from '../../store/currencyApi';
import {
    useAddExchangeRateMutation,
    useDeleteExchangeRateMutation,
    useEditExchangeRateMutation,
    useExchangeRateStatsQuery,
    useGetAllExchangeRatesQuery,
    useGetSingleExchangeRateQuery,
} from '../../store/exchangeRatesApi';
import { IExchangeRates } from '../../store/exchangeRatesApi/interface';

export const ExchangeRates = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropDownTerm, setDropDownTerm] = useState('');
    const [filteredCurrencyPair, setfilteredCurrencyPair] = useState<
        ICurrencyPair[] | any
    >();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(1000);
    const [selectedExchangeRate, setSelectedExchangeRate] = useState('');
    const [exchangeRateData, setExchangeRateData] = useState<string>('');
    const [deleteExchangeRateItem, setDeleteExchangeRateItem] =
        useState<boolean>(false);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: exchangeRates,
        isLoading,
        isFetching,
    } = useGetAllExchangeRatesQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });

    const { data: allCurrencyPair, isLoading: isLoadingCurrencyPair } =
        useGetAllCurrencyPairQuery(paginate, {
            refetchOnMountOrArgChange: true,
        });

    const [addExchangeRate, { isLoading: isAdding }] =
        useAddExchangeRateMutation();
    const [editExchangeRate, { isLoading: isEditing }] =
        useEditExchangeRateMutation();
    const [deleteExchangeRate, { isLoading: isDeleting }] =
        useDeleteExchangeRateMutation();
    const { data: singleExchangeRate } =
        useGetSingleExchangeRateQuery(selectedExchangeRate);
    const { data: exchangeRateStats } = useExchangeRateStatsQuery();
    const { data: allCurrency } = useAllCurrencyQuery(paginate);

    // useEffect(() => {
    //     setTotalRows(exchangeRates?.pagination?.totalDocumentCount as number);
    // }, [exchangeRates?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => {
        setCurrentPage(page);
    };
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!exchangeRates?.data?.length) return;
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };
    const handleConfirmDelete = () => {
        onHandleDelete(exchangeRateData);
        setDeleteExchangeRateItem(false);
    };
    const Items = [
        {
            name: 'Edit item',
            icon: <BiEditAlt size={15} />,
            OnClick: (data: any) => {
                setSelectedExchangeRate(data?._id);
                setShowEditModal(true);
            },
        },
        {
            name: 'Delete item',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: any) => {
                setDeleteExchangeRateItem((prev) => !prev);
                setExchangeRateData(data?._id);
            },
        },
    ];

    const allCurrencyType = allCurrency?.data?.map((item: any) => {
        return item?.name;
    });

    // add exchange rate
    const exchangeRateForm = useForm({
        defaultValues: {
            currencyPair: '',
            sellPrice: '',
            buyPrice: '',
            sellTrend: '',
            buyTrend: '',
            timePeriod: '',
        },
    });
    const { handleSubmit, control, reset } = exchangeRateForm;

    // edit exchange rate
    const editExchangeRateForm = useForm({
        defaultValues: {
            newData: {
                sellPrice: 0,
                buyPrice: 0,
                sellTrend: '',
                buyTrend: '',
                timePeriod: '',
                status: '',
            },
        },
    });
    const {
        handleSubmit: handleEditSubmit,
        control: editControl,
        setValue,
    } = editExchangeRateForm;

    useEffect(() => {
        if (singleExchangeRate?.data?._id) {
            setValue(
                'newData',
                {
                    sellPrice: singleExchangeRate?.data?.sellPrice,
                    buyPrice: singleExchangeRate?.data?.buyPrice,
                    sellTrend: singleExchangeRate?.data?.sellTrend,
                    buyTrend: singleExchangeRate?.data?.buyTrend,
                    timePeriod: singleExchangeRate?.data?.timePeriod,
                    status: singleExchangeRate?.data?.isActive
                        ? 'Active'
                        : 'Inactive',
                },
                { shouldValidate: true },
            );
        }
    }, [singleExchangeRate, setValue]);

    const handleCloseAddModal = () => {
        reset();
        setShowAddModal(false);
    };

    const handleCloseEditModal = () => {
        reset();
        setShowEditModal(false);
    };

    const onHandleSubmit = (value: {
        currencyPair: string;
        sellPrice: string;
        buyPrice: string;
        sellTrend: string;
        buyTrend: string;
        timePeriod: string;
    }) => {
        const payload = {
            ...value,
            sellPrice: Number(value?.sellPrice),
            buyPrice: Number(value?.buyPrice),
        };
        addExchangeRate(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowAddModal(false);
                reset();
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    const onHandleEdit = (value: {
        newData: {
            sellPrice: number;
            buyPrice: number;
            sellTrend: string;
            buyTrend: string;
            status: string;
            timePeriod: string;
        };
    }) => {
        let payload = {
            ...value?.newData,
            rateId: selectedExchangeRate,
            sellPrice: Number(value?.newData?.sellPrice),
            buyPrice: Number(value?.newData?.buyPrice),
            isActive: value?.newData?.status === 'Active' ? true : false,
        };
        editExchangeRate(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowEditModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    // DELETE FUNCTION
    const onHandleDelete = (id: string) => {
        deleteExchangeRate(id)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowDeleteModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'Base Currency', key: 'baseCurrency.name' },
        {
            label: 'Trading Currency',
            key: 'tradingCurrency.name',
        },
        {
            label: 'Category',
            key: 'baseCurrency.currencyType',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { header: 'Base Currency', dataKey: 'baseCurrency' },
        {
            header: 'Trading Currency',
            dataKey: 'tradingCurrency',
        },
        {
            header: 'Category',
            dataKey: 'category',
        },
    ];

    return (
        <Container>
            <Header>Exchange Rates</Header>
            {isLoading || isDeleting ? <Loader /> : null}
            <Grid>
                <Counter
                    counter_title="Total Active Rates"
                    count={exchangeRateStats?.data?.totalActiveRates || '0'}
                    iconFill="black"
                    iconBG="blue"
                >
                    <CurrencyPairIcon />
                </Counter>
                <Counter
                    counter_title="Total Rates"
                    count={exchangeRateStats?.data?.totalRates || '0'}
                    iconFill="green"
                    iconBG="green"
                >
                    <CurrencyPairIcon />
                </Counter>
            </Grid>

            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={7}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col
                        xs={10}
                        sm={6}
                        className="d-flex align-items-center my-3"
                    >
                        {/* <SelectComp
                            selectText="All currency pair"
                            arrayComp={['Crypto', 'Fiat']}
                            onChange={(e: any) =>
                                setDropDownTerm(e.target.value)
                            }
                        />
                        <TotalResultText>{`${
                            filteredCurrencyPair?.length || 0
                        } result`}</TotalResultText> */}
                    </Col>
                    {/* <InputWrap>
                        <img src={SearchIcon} alt="" />
                        <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Search"
                            className="border-start-0 border-bottom-0 border-top-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputWrap> */}
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        {/* <PdfDownloader
                            fileName={'CURRENCY PAIR RECORD'}
                            {...{
                                columns: headersPdf,
                                body: cleanUpCurrencyDataForPdf(
                                    exchangeRates?.data,
                                ),
                            }}
                        />
                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={exchangeRates?.data}
                        /> */}
                    </ButtonContainer>
                    <CustomButton
                        text="Create exchange rate"
                        onClick={() => setShowAddModal(true)}
                    />
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={ExchangeRatesTableHeader(Items)}
                    data={exchangeRates?.data as IExchangeRates[]}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>

            {/* add exchange rate modal */}
            <ModalComp
                show={showAddModal}
                handleClose={handleCloseAddModal}
                centered
                close={true}
                modalTitle="Add Exchange Rate"
                paragraph="Fill in the  the forms to add exchange rate"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleSubmit(onHandleSubmit)}>
                            <div className="my-3">
                                <Controller
                                    name="currencyPair"
                                    control={control}
                                    rules={{
                                        required: 'Currency pair is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.currencyPair?.message;
                                        return (
                                            <SelectComp
                                                labelText="Currency Pair"
                                                arrayComp={allCurrencyPair?.data.map(
                                                    (_currencyPair) => {
                                                        return {
                                                            name: `${_currencyPair?.tradingCurrency?.name.toUpperCase()}/${_currencyPair?.baseCurrency?.name.toUpperCase()}`,
                                                            value: _currencyPair?._id,
                                                        };
                                                    },
                                                )}
                                                name="currencyPair"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="my-3">
                                <Controller
                                    name="sellPrice"
                                    control={control}
                                    rules={{
                                        required: 'Sell Price is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.sellPrice?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Sell Price"
                                                placeholder="eg: 0.017"
                                                inputClassName={'py-2 '}
                                                name="sellPrice"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="my-3">
                                <Controller
                                    name="sellTrend"
                                    control={control}
                                    rules={{
                                        required: 'Sell trend is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.currencyPair?.message;
                                        return (
                                            <SelectComp
                                                labelText="Sell Trend"
                                                arrayComp={['UP', 'DOWN']}
                                                name="sellTrend"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="my-3">
                                <Controller
                                    name="buyPrice"
                                    control={control}
                                    rules={{
                                        required: 'Buy Price is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.buyPrice?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Buy Price"
                                                inputClassName={'py-2 '}
                                                name="buyPrice"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="my-3">
                                <Controller
                                    name="buyTrend"
                                    control={control}
                                    rules={{
                                        required: 'Buy trend is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.buyTrend?.message;
                                        return (
                                            <SelectComp
                                                labelText="Buy Trend"
                                                arrayComp={['UP', 'DOWN']}
                                                name="buyTrend"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="my-3">
                                <Controller
                                    name="timePeriod"
                                    control={control}
                                    rules={{
                                        required: 'Time period is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.timePeriod?.message;
                                        return (
                                            <SelectComp
                                                labelText="Time Period"
                                                arrayComp={[
                                                    'morning',
                                                    'afternoon',
                                                    'evening',
                                                ]}
                                                name="timePeriod"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isAdding
                                            ? 'Create Exchange Rate'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />

            {/* edit modal */}
            <ModalComp
                show={showEditModal}
                handleClose={handleCloseEditModal}
                centered
                close={true}
                modalTitle="Edit Exchange rate"
                paragraph="Fill in the forms to edit exchange rate"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleEditSubmit(onHandleEdit)}>
                            <div className="my-3">
                                <Controller
                                    name="newData.sellPrice"
                                    control={editControl}
                                    rules={{
                                        required: 'Sell price is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.sellPrice?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Sell Price"
                                                inputClassName={'py-2 '}
                                                name="sellPrice"
                                                inputType="number"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.sellTrend"
                                    control={editControl}
                                    rules={{
                                        required: 'Sell Trend is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.sellTrend?.message;
                                        return (
                                            <SelectComp
                                                labelText="Sell Trend"
                                                arrayComp={['UP', 'DOWN']}
                                                name="sellTrend"
                                                defaultValue={
                                                    singleExchangeRate?.data
                                                        ?.sellTrend
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="my-3">
                                <Controller
                                    name="newData.buyPrice"
                                    control={editControl}
                                    rules={{
                                        required: 'Buy price is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.buyPrice?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Buy Price"
                                                inputClassName={'py-2 '}
                                                name="buyPrice"
                                                inputType="number"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="my-3">
                                <Controller
                                    name="newData.buyTrend"
                                    control={editControl}
                                    rules={{
                                        required: 'Buy Trend is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.buyTrend?.message;
                                        return (
                                            <SelectComp
                                                labelText="Buy Trend"
                                                arrayComp={['UP', 'DOWN']}
                                                name="buyTrend"
                                                defaultValue={
                                                    singleExchangeRate?.data
                                                        ?.buyTrend
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.timePeriod"
                                    control={editControl}
                                    rules={{
                                        required: 'Time period is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.timePeriod
                                                ?.message;
                                        return (
                                            <SelectComp
                                                labelText="Time Period"
                                                arrayComp={[
                                                    'morning',
                                                    'afternoon',
                                                    'evening',
                                                ]}
                                                name="timePeriod"
                                                defaultValue={
                                                    singleExchangeRate?.data
                                                        ?.timePeriod
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <Controller
                                name="newData.status"
                                control={editControl}
                                rules={{
                                    required: 'status is required',
                                }}
                                render={({
                                    field: { value, onChange },
                                    formState: { errors },
                                }) => {
                                    const errorMessage =
                                        errors?.newData?.status?.message;
                                    return (
                                        <SelectComp
                                            labelText="isActive?"
                                            inputClassName={'py-2 '}
                                            arrayComp={['Active', 'Inactive']}
                                            name="status"
                                            defaultValue={
                                                singleExchangeRate?.data
                                                    .isActive
                                                    ? 'Active'
                                                    : 'Inactive'
                                            }
                                            {...{
                                                value,
                                                onChange,
                                                errors: [errorMessage],
                                            }}
                                        />
                                    );
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isEditing
                                            ? 'Edit Exchange Rate'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />
            <DeleteModal
                showDeleteModal={deleteExchangeRateItem}
                cancelDelete={() => setDeleteExchangeRateItem(false)}
                confirmDelete={handleConfirmDelete}
                deleteItem="Exchange rate"
                isDeleting={isDeleting}
            />
        </Container>
    );
};
