import { Container } from '../users/style';
import {
    CustomButton,
    PhoneInputComp,
    TextInputComp,
    colors,
} from '../../common/component';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Header } from '../dashboard/styles';
import { useForm, Controller } from 'react-hook-form';
import { validateNumber } from './helper';
import { useAddConfigMutation } from '../../store/configApi';
import { showErrorToast, showSuccessToast } from '../../common/utils/toast';
import { useNavigate } from 'react-router-dom';

export const NewConfiguration = () => {
    const navigate = useNavigate();

    const currencyPair = useForm({
        defaultValues: {
            partnerChargePerTrade: '',
            serviceChargeOnPartnerCharge: '',
            supportNumber: '',
            tiktok: '',
            youtube: '',
            twitter: '',
            facebook: '',
            instagram: '',
            linkedin: '',
            newUserMaxAmount: '',
            newUserMinAmount: '',
            advancedUserMinAmount: '',
            advancedUserMaxAmount: '',
            beginnerMinAmount: '',
            beginnerMaxAmount: '',
            intermediateUserMinAmount: '',
            intermediateUserMaxAmount: '',
        },
    });
    const { handleSubmit, control, reset } = currencyPair;

    const [addConfig, { isLoading: isAdding }] = useAddConfigMutation();

    const onHandleSubmit = (value: {
        partnerChargePerTrade: string;
        serviceChargeOnPartnerCharge: string;
        supportNumber: string;
        facebook: string;
        tiktok: string;
        twitter: string;
        youtube: string;
        instagram: string;
        linkedin: string;
        newUserMaxAmount: string;
        newUserMinAmount: string;
        advancedUserMinAmount: string;
        advancedUserMaxAmount: string;
        beginnerMinAmount: string;
        beginnerMaxAmount: string;
        intermediateUserMinAmount: string;
        intermediateUserMaxAmount: string;
    }) => {
        let payload = {
            partnerChargePerTrade: parseFloat(value?.partnerChargePerTrade),
            serviceChargeOnPartnerCharge: parseFloat(
                value?.serviceChargeOnPartnerCharge,
            ),
            supportNumber: value?.supportNumber,
            socialLinks: {
                facebook: value?.facebook,
                twitter: value?.twitter,
                youtube: value?.youtube,
                tiktok: value?.tiktok,
                instagram: value?.instagram,
                linkedin: value?.linkedin,
            },
            transactionLimits: {
                new_user: {
                    min: Number(value?.newUserMinAmount),
                    max: Number(value?.newUserMaxAmount),
                },
                beginner: {
                    min: Number(value?.beginnerMinAmount),
                    max: Number(value?.beginnerMaxAmount),
                },
                intermediate: {
                    min: Number(value?.intermediateUserMinAmount),
                    max: Number(value?.intermediateUserMaxAmount),
                },
                advanced: {
                    min: Number(value?.advancedUserMinAmount),
                    max: Number(value?.advancedUserMaxAmount),
                },
            },
        };
        addConfig(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                reset();
                navigate('/dashboard/configuration');
            })
            .catch((error) => {
                for (
                    let index = 0;
                    index < error?.data?.message.length;
                    index++
                ) {
                    const item = error?.data?.message[index];
                    showErrorToast(item);
                }
            });
    };

    return (
        <Container>
            {/* {isLoading || isDeleting ? <Loader /> : null} */}
            <Header>New Configurations</Header>
            <TextParagraphOne>
                Fill in the forms for new configuration
            </TextParagraphOne>
            <div className="py-4 px-lg-4 ">
                <form onSubmit={handleSubmit(onHandleSubmit)}>
                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="partnerChargePerTrade"
                                    control={control}
                                    rules={{
                                        required: 'Partner charge is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.partnerChargePerTrade
                                                ?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Partner Charge"
                                                placeholder="eg: 0.017"
                                                inputClassName={'py-2 '}
                                                name="partnerChargePerTrade"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="serviceChargeOnPartnerCharge"
                                    control={control}
                                    rules={{
                                        required: 'service charge is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.serviceChargeOnPartnerCharge
                                                ?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Service Charge"
                                                placeholder="eg: 0.5"
                                                inputClassName={'py-2 '}
                                                name="serviceChargeOnPartnerCharge"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="supportNumber"
                                    control={control}
                                    rules={{
                                        required: 'support number is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.supportNumber?.message;
                                        return (
                                            <PhoneInputComp
                                                labelText="Support Number"
                                                name="supportNumber"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="facebook"
                                    control={control}
                                    rules={{
                                        required:
                                            'facebook social link is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.facebook?.message;
                                        return (
                                            <TextInputComp
                                                inputType="url"
                                                labelText="Facebook Link"
                                                inputClassName={'py-2 '}
                                                name="facebook"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="twitter"
                                    control={control}
                                    rules={{
                                        required:
                                            'twitter social link is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.twitter?.message;
                                        return (
                                            <TextInputComp
                                                inputType="url"
                                                labelText="Twitter Link"
                                                inputClassName={'py-2 '}
                                                name="twitter"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="youtube"
                                    control={control}
                                    rules={{
                                        required:
                                            'youtube social link is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.youtube?.message;
                                        return (
                                            <TextInputComp
                                                inputType="url"
                                                labelText="Youtube Link"
                                                inputClassName={'py-2 '}
                                                name="youtube"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="tiktok"
                                    control={control}
                                    rules={{
                                        required:
                                            'tiktok social link is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.tiktok?.message;
                                        return (
                                            <TextInputComp
                                                inputType="url"
                                                labelText="Tiktok Link"
                                                inputClassName={'py-2 '}
                                                name="tiktok"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="instagram"
                                    control={control}
                                    rules={{
                                        required:
                                            'instagram social link is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.instagram?.message;
                                        return (
                                            <TextInputComp
                                                inputType="url"
                                                labelText="Instagram Link"
                                                inputClassName={'py-2 '}
                                                name="instagram"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="linkedin"
                                    control={control}
                                    rules={{
                                        required:
                                            'Linkedin social link is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.linkedin?.message;
                                        return (
                                            <TextInputComp
                                                inputType="url"
                                                labelText="Linkedin Link"
                                                inputClassName={'py-2 '}
                                                name="linkedin"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <hr />

                    <SubHeader>Transaction Limits</SubHeader>
                    <TextParagraphTwo>Set a limit for users</TextParagraphTwo>
                    <Row>
                        <Col sm={6} xs={12} className="pt-3">
                            <label>New User</label>
                            <TransactionLimitBox>
                                <Row>
                                    <Col sm={6}>
                                        <Controller
                                            name="newUserMinAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'min amount is required',
                                                validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors?.newUserMinAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        labelText="Min Amount"
                                                        inputClassName={'py-2 '}
                                                        name="newUserMinAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Controller
                                            name="newUserMaxAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'max amount is required',
                                                validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors?.newUserMaxAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        labelText="Max Amount"
                                                        inputClassName={'py-2 '}
                                                        name="newUserMaxAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </TransactionLimitBox>
                        </Col>
                        <Col sm={6} xs={12} className="pt-3">
                            <label>Beginner</label>
                            <TransactionLimitBox>
                                <Row>
                                    <Col sm={6}>
                                        <Controller
                                            name="beginnerMinAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'min amount is required',
                                                validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors?.beginnerMinAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        labelText="Min Amount"
                                                        inputClassName={'py-2 '}
                                                        name="beginnerMinAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Controller
                                            name="beginnerMaxAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'max amount is required',
                                                validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors?.beginnerMaxAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        labelText="Max Amount"
                                                        inputClassName={'py-2 '}
                                                        name="beginnerMaxAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </TransactionLimitBox>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} xs={12} className="pt-3">
                            <label>Intermediate User</label>
                            <TransactionLimitBox>
                                <Row>
                                    <Col sm={6}>
                                        <Controller
                                            name="intermediateUserMinAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'min amount is required',
                                                validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors
                                                        ?.intermediateUserMinAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        labelText="Min Amount"
                                                        inputClassName={'py-2 '}
                                                        name="intermediateUserMinAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Controller
                                            name="intermediateUserMaxAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'max amount is required',
                                                validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors
                                                        ?.intermediateUserMaxAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        labelText="Max Amount"
                                                        inputClassName={'py-2 '}
                                                        name="intermediateUserMaxAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </TransactionLimitBox>
                        </Col>
                        <Col sm={6} xs={12} className="pt-3">
                            <label>Advanced</label>
                            <TransactionLimitBox>
                                <Row>
                                    <Col sm={6}>
                                        <Controller
                                            name="advancedUserMinAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'min amount is required',
                                                validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors
                                                        ?.advancedUserMinAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        labelText="Min Amount"
                                                        inputClassName={'py-2 '}
                                                        name="advancedUserMinAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Controller
                                            name="advancedUserMaxAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'max amount is required',
                                                validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors
                                                        ?.advancedUserMaxAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        labelText="Max Amount"
                                                        inputClassName={'py-2 '}
                                                        name="advancedUserMaxAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </TransactionLimitBox>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-center">
                        <CustomButton
                            className="py-3 mt-4"
                            text={isAdding ? 'Loading...' : 'Create'}
                        />
                    </div>
                </form>
            </div>
        </Container>
    );
};

const TextParagraphOne = styled.p`
    margin-top: -1rem;
    color: ${colors?.gray31};
`;

const TextParagraphTwo = styled.p`
    color: ${colors?.gray31};
    font-size: 0.8rem;
`;

const TransactionLimitBox = styled.div`
    border: 1px solid #a1a1a1;
    border-radius: 8px;
    padding: 1rem;
    height: 10rem;
    margin-top: 0.5rem;
`;

export const SubHeader = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${colors.blue10};
`;
