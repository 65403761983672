import { FC } from 'react';
import { ModalComp } from '..';
import { CustomButton } from '..';

interface IDeleteModal {
    showDeleteModal: boolean;
    deleteItem: string;
    cancelDelete: () => void;
    confirmDelete: () => void;
    isDeleting?: boolean;
}

export const DeleteModal: FC<IDeleteModal> = ({
    showDeleteModal,
    deleteItem,
    cancelDelete,
    confirmDelete,
    isDeleting,
}) => {
    return (
        <ModalComp
            show={showDeleteModal}
            handleClose={cancelDelete}
            centered
            close={true}
            modalTitle={`Delete ${deleteItem}`}
            paragraph={`Are you sure you want to delete this ${deleteItem}?`}
            modalBody={
                <div className="py-2 ">
                    <div className="d-flex  justify-content-center">
                        <CustomButton
                            className="py-3 mx-4 mt-2"
                            text={'Cancel'}
                            onClick={cancelDelete}
                        />
                        <CustomButton
                            className="py-3 mt-2"
                            text={isDeleting ? 'Deleting...' : 'Delete'}
                            onClick={confirmDelete}
                        />
                    </div>
                </div>
            }
        />
    );
};
