import {
  ActiveButton,
  BackButton,
  BorderDiv,
  TextGrid,
  TopText,
  UserContact,
  UserName,
} from "../users/style";
import { ReactComponent as CareerIcon } from "../../common/images/career_icon.svg";
import location_marker from "../../common/images/location_marker.svg";
import calender from "../../common/images/calender.svg";
import back_arrow from "../../common/images/back_arrow.svg";
import dot_menu from "../../common/images/dot_menu.svg";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col } from "react-bootstrap";
import { CareerIconWrap, Span } from "./style";
import { JobDetails } from "./jobDetails";
import {
  useGetAllJobApplicantsWithJobIdQuery,
  useGetSingleJobsQuery,
} from "../../store/jobAPi";
import { getDateString } from "../../common/utils/date";
import { Status } from "../trade/style";
import { ViewApplicants } from "./viewApplicants";
import { Loader } from "../../common/component";

export const ViewCareer = () => {
  const param = useParams();
  const jobId = param?.id as string;
  const [active, setActive] = useState(1);

  const navigate = useNavigate();
  const { data: jobData, isLoading } = useGetSingleJobsQuery(jobId);

  const paginate = {
    jobId: jobId,
    perPage: 10,
    currentPage: 1,
  };

  const { data: allApplicants } = useGetAllJobApplicantsWithJobIdQuery(
    paginate,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  console.log("allApplicants: ", allApplicants);

  return (
    <div className="pb-4">
      <div className="d-flex align-items-center my-2">
        <BackButton onClick={() => navigate(-1)}>
          <img src={back_arrow} alt="" /> Back
        </BackButton>
        <TopText>
          {" "}
          <span>/ Career</span> / View Career
        </TopText>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div
            className="d-sm-flex align-items-center justify-content-between p-3"
            style={{ background: "rgba(250, 202, 21, 0.1)", borderRadius: "8" }}
          >
            <div className="d-flex align-items-center gap-3">
              <CareerIconWrap>
                <CareerIcon />
              </CareerIconWrap>
              <div>
                <UserName>{jobData?.data?.role}</UserName>
                <div className="d-lg-flex align-items-center mt-1">
                  <UserContact>
                    <img src={location_marker} alt="" />
                    {jobData?.data?.location}
                  </UserContact>{" "}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column align-items-end">
              <div className="mb-2">
                <img src={dot_menu} alt="" />
              </div>
              <UserContact>
                Date created <img src={calender} alt="" />{" "}
                {getDateString(jobData?.data?.createdAt as string)}
              </UserContact>
            </div>
          </div>
          <BorderDiv className=" my-4 ">
            <Col sm={8}>
              <TextGrid>
                <ActiveButton
                  active={active === 1}
                  onClick={() => setActive(1)}
                >
                  Job details
                </ActiveButton>
                <ActiveButton
                  active={active === 2}
                  onClick={() => {
                    setActive(2);
                  }}
                  className="d-flex gap-1"
                >
                  Applicants
                  <Span>{allApplicants?.pagination?.totalDocumentCount}</Span>
                </ActiveButton>
              </TextGrid>
            </Col>
            <Col className="d-flex align-items-center justify-content-end mb-2 mb-sm-0">
              <Status>{jobData?.data?.status ? "Active" : "Inactive"}</Status>
            </Col>
          </BorderDiv>
          {active === 1 ? <JobDetails /> : <ViewApplicants />}
        </>
      )}
    </div>
  );
};
