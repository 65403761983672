import {
    ActiveButton,
    BackButton,
    BorderDiv,
    TextGrid,
    TopText,
} from '../users/style';

import back_arrow from '../../common/images/back_arrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { Status } from '../trade/style';
import { Loader } from '../../common/component';
import { useGetSingleSubTopicQuery } from '../../store/topicApi';
import { SubTopicDetails } from './subTopicDetails';

export const ViewSubTopic = () => {
    const param = useParams();
    const subTopicId = param?.id as string;

    const navigate = useNavigate();
    const { data: subTopicData, isLoading } =
        useGetSingleSubTopicQuery(subTopicId);

    return (
        <div className="pb-4">
            <div className="d-flex align-items-center my-2">
                <BackButton onClick={() => navigate(-1)}>
                    <img src={back_arrow} alt="" /> Back
                </BackButton>
                <TopText>
                    {' '}
                    <span>/Subtopic</span> / View Subtopic
                </TopText>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <BorderDiv className=" my-4 ">
                        <Col sm={8}>
                            <TextGrid>
                                <ActiveButton>Subtopic details</ActiveButton>
                            </TextGrid>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-end mb-2 mb-sm-0">
                            <Status>
                                {subTopicData?.data?.status ? (
                                    <p style={{ color: '#34a853' }}>Active</p>
                                ) : (
                                    'Inactive'
                                )}
                            </Status>
                        </Col>
                    </BorderDiv>
                    {<SubTopicDetails />}
                </>
            )}
        </div>
    );
};
