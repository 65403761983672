import styled from 'styled-components';
import { colors } from '../../common/component';
import { UserImage } from '../users/style';

export const TableRow = styled.tr``;
export const TableRowBorder = styled.tr`
    border-bottom: 0.5px solid ${colors.black6};
`;
export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    column-gap: 20px;
    margin: 10px 0px 30px 0px;
`;
export const CompleteButton = styled.button`
    width: 161px;
    height: 36px;
    background: ${colors.red};
    color: ${colors.white};
    border: none;
    border-radius: 5px;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
`;
export const ChatButton = styled(CompleteButton)`
    background: ${colors.Primary2};
`;
export const TradeUser = styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    color: rgba(5, 13, 61, 0.7);
`;

export const TradeType = styled.p`
    background: ${colors.blue30};
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #021e29;
    padding: 5px 10px;
    width: 100px;
    text-align: center;
    border-radius: 4px;
`;
export const TradeBorderDiv = styled.div`
    border-bottom: 1px solid ${colors.black10};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const TradeChatContainer = styled.div`
    height: calc(100vh - 29vh);
    padding: 0px 10px;
    border: 1px solid #cdcfd0;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 0px;
    }
    @media (max-width: 600px) {
        height: calc(100vh - 28vh);
    }
`;
export const TradeInputField = styled.div`
    background: #f5f5f5;
    input {
        width: 100%;
        border-radius: 25px;
        padding: 10px 20px;
        background: ${colors.gray};
        border: none;
        font-size: 16px;
        box-shadow: 0.5px 0.5px 2px rgba(0, 0, 0, 0.25);
        outline: none;
    }
`;
export const TradeCard = styled.div`
    width: calc(33.33% - 32px);
    @media (max-width: 768px) {
        width: calc(50% - 24px);
    }
    @media (max-width: 426px) {
        /* width: calc(50% - 12px); */
        width: calc(100% - 0px);
    }
`;
export const BarLine = styled.div`
    border: 1px solid #bc8019;
    background: #bc8019;
`;
export const TradeHeader = styled.p`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.Primary1};
    margin-bottom: 7px;
`;
export const SubHeading = styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.gray30};
`;
export const TradeText = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #10192d;
`;
export const Status = styled(TradeText)<{ status?: string | boolean }>`
    color: ${(props) =>
        props.status === 'completed' ||
        props.status === 'open' ||
        props.status === 'Active'
            ? colors.Green80
            : colors.red100};
`;
export const SmallImage = styled(UserImage)`
    min-width: 25px;
    min-height: 25px;
    max-width: 25px;
    max-height: 25px;
`;
export const StarContainer = styled.div`
    svg {
        width: 10px;
    }
`;
export const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 50px;
    row-gap: 40px;
`;
export const ActiveButton = styled.div<{ active?: boolean }>`
    border-bottom: ${(props) =>
        props.active ? `2px solid ${colors.secondary80}` : 'none'};
    color: ${(props) => (props.active ? colors.secondary80 : colors.black50)};
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding: 8px 0px;
    cursor: pointer;
`;
export const InfoWrapper = styled.div`
    max-width: 65%;
    @media (max-width: 1024px) {
        max-width: 100%;
    }
`;
export const TradeChatWrapper = styled(InfoWrapper)`
    max-width: 60%;
    @media (max-width: 1024px) {
        max-width: 100%;
    }
`;
export const StyledButton = styled.button`
    background: ${colors.Primary1};
    color: ${colors.white};
    border: 1px solid ${colors.black10};
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    padding: 10px 30px;
`;
