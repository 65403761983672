import { FC } from 'react';
import styled from 'styled-components';
import { ICounterCard } from '../../types';
import { colors } from '../colors';
import FAQTOTAL from '../../images/totalfaq_icon.svg';
export const Counter: FC<ICounterCard> = ({
    count,
    counter_title,
    iconFill,
    iconBG,
    children,
    isBackgroundImage,
    backgroundImage,
}) => {
    return (
        <Card
            style={{
                background: isBackgroundImage
                    ? `url(${backgroundImage})`
                    : `${colors.white}`,
                backgroundPosition: 'bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
        >
            <IconWrap IconBg={iconBG} IconColor={iconFill}>
                {children}
            </IconWrap>
            <div>
                <Title>{counter_title}</Title>
                <Counts>{count}</Counts>
            </div>
        </Card>
    );
};
const Card = styled.div`
    background: ${colors.white};
    border: 1px solid rgba(0, 0, 0, 0.06);
    filter: drop-shadow(3px 3px 5px rgba(205, 207, 208, 0.25));
    border-radius: 10px;
    width: calc(25% - 15px);
    display: flex;
    column-gap: 10px;
    padding: 25px 15px;
    @media (max-width: 768px) {
        width: calc(50% - 10px);
    }
    @media (max-width: 376px) {
        width: calc(100% - 0px);
    }
`;
const IconWrap = styled.div<{ IconBg?: string; IconColor?: string }>`
    min-width: 35px;
    min-height: 35px;
    max-width: 35px;
    max-height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) =>
        props.IconBg === 'blue'
            ? colors.blue50
            : props.IconBg === 'green'
            ? colors.Green60
            : props.IconBg === 'red'
            ? colors.red10
            : colors.secondary20};
    svg {
        stroke: ${(props) =>
            props.IconColor === 'blue'
                ? colors.blue60
                : props.IconColor === 'green'
                ? colors.Green80
                : props.IconColor === 'red'
                ? colors.red100
                : props.IconColor === 'black'
                ? colors.black
                : props.IconColor === 'darkBlue'
                ? colors.blue20
                : props.IconColor === 'primaryBlue'
                ? colors.Primary1
                : colors.secondary30};
        fill: ${(props) =>
            props.IconColor === 'blue'
                ? colors.blue60
                : props.IconColor === 'green'
                ? colors.Green80
                : props.IconColor === 'red'
                ? colors.red100
                : props.IconColor === 'black'
                ? colors.black
                : props.IconColor === 'darkBlue'
                ? colors.blue20
                : props.IconColor === 'primaryBlue'
                ? colors.Primary1
                : colors.secondary30};
    }
`;
const Title = styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: ${colors.black1};
`;
const Counts = styled.p`
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    color: ${colors.black};
`;
