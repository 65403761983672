import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { IFaq, IFaqStats } from './interface';
import { paginatedResponse } from '../paginatedInterface';

export const faqApi = createApi({
    reducerPath: 'faqApi',
    baseQuery: baseQuery,
    tagTypes: ['faq'],
    endpoints: (builder) => ({
        addFaq: builder.mutation<
            Response<IFaq[]>,
            { question: string; answer: string }
        >({
            query: (body) => ({
                url: `/user/api/v1/faq`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['faq'],
        }),
        editFaq: builder.mutation<
            Response<IFaq[]>,
            {
                question: string;
                answer: string;
                faqId: string;
                priority: number;
                isActive: boolean;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/faq`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['faq'],
        }),
        getAllFaq: builder.query<
            paginatedResponse<IFaq[]>,
            {
                perPage: number;
                currentPage: number;
                isActive?: string;
            }
        >({
            query: ({ perPage, currentPage, isActive }) => {
                let query = `/user/api/v1/faq?perPage=${perPage}&currentPage=${currentPage}`;
                if (isActive) {
                    if (isActive === 'true') {
                        query = `${query}&isActive=true`;
                    }
                    if (isActive === 'false') {
                        query = `${query}&isActive=false`;
                    }
                }
                return {
                    url: query,
                    method: 'GET',
                };
            },
            providesTags: ['faq'],
        }),
        getActiveFaq: builder.query<
            paginatedResponse<IFaq[]>,
            {
                perPage: number;
                currentPage: number;

                isActive?: boolean;
            }
        >({
            query: ({ perPage, currentPage, isActive }) => {
                let query = `/user/api/v1/faq?isActive=true&perPage=${perPage}&currentPage=${currentPage}`;
                if (isActive) {
                    query = `${query}&active=${isActive}`;
                }
                return {
                    url: query,
                    method: 'GET',
                };
            },
            providesTags: ['faq'],
        }),
        getSingleFaq: builder.query<Response<IFaq>, string>({
            query: (query) => ({
                url: `/user/api/v1/faq/${query}`,
                method: 'GET',
            }),
        }),
        deleteFaq: builder.mutation<Response<IFaq>, string>({
            query: (query) => ({
                url: `/user//api/v1/faq/${query}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['faq'],
        }),
        faqStats: builder.query<Response<IFaqStats>, void>({
            query: () => ({
                url: `/user/api/v1/faq/a/stats`,
                method: 'GET',
            }),
            providesTags: ['faq'],
        }),
    }),
});

export const {
    useAddFaqMutation,
    useDeleteFaqMutation,
    useEditFaqMutation,
    useGetAllFaqQuery,
    useGetActiveFaqQuery,
    useGetSingleFaqQuery,
    useFaqStatsQuery,
} = faqApi;
