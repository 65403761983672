import styled from "styled-components";
import { colors } from "../../common/component";

export const Header = styled.p`
	font-weight: 600;
	font-size: 32px;
	line-height: 39px;
	margin: 20px 0px;
	color: ${colors.black};
`;
export const SubHeader = styled.p`
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	color: ${colors.blue10};
`;
export const SubHeader2 = styled(SubHeader)`
	color: ${colors.purple100};
	cursor: pointer;
`;
export const Grid = styled.div`
	display: flex;
	flex-wrap: wrap;
	column-gap: 20px;
	row-gap: 20px;
`;
export const Grid2 = styled.div`
	display: flex;
	flex-wrap: wrap;
	column-gap: 30px;
	row-gap: 30px;
`;
export const ImageContainer = styled.div<{ sent?: boolean }>`
	display: ${(props) => (props.sent ? `none` : `flex`)};
`;
export const Image = styled.img``;
export const ImageWrap = styled.div`
	min-width: 25px;
	max-width: 25px;
	border-radius: 50%;
	overflow: hidden;
	img {
		width: 100%;
	}
`;

export const ChartWrap = styled.div`
	background: #ffffff;
	border: 1px solid #e9e9e9;
	box-shadow: 0px 0px 10px 6px rgba(233, 233, 233, 0.5);
	border-radius: 8px;
	width: calc(50% - 15px);
	height: 350px;
	padding: 10px;
	@media (max-width: 768px) {
		width: 100%;
	}
`;
export const ChartLabel = styled.p`
	font-weight: 500;
	font-size: 16px;
	line-height: 9px;
`;
