import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { IUserRegistrationSummary, IUserStats } from './interface';
import { paginatedResponse } from '../paginatedInterface';

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQuery,
    tagTypes: ['user'],
    endpoints: (builder) => ({
        getAllUser: builder.query<
            paginatedResponse<IUser[]>,
            { perPage: number; currentPage: number }
        >({
            query: ({ perPage, currentPage }) => ({
                url: `/user/api/v1/user?perPage=${perPage}&currentPage=${currentPage}`,
                method: 'GET',
            }),
            providesTags: ['user'],
        }),
        getSingleUser: builder.query<Response<IUser>, string>({
            query: (userId) => ({
                url: `/user/api/v1/user/${userId}`,
                method: 'GET',
            }),
            providesTags: ['user'],
        }),
        updateUserStatus: builder.mutation<
            Response<IUser>,
            { accountStatus: string; userId: string }
        >({
            query: (payload) => ({
                url: `/user/api/v1/user/update-user-account-status`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['user'],
        }),
        updateUserRole: builder.mutation<
            Response<IUser>,
            { userType: string; userId: string }
        >({
            query: (body) => ({
                url: `/user/api/v1/user/update-user-type`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['user'],
        }),
        updateUserProfile: builder.mutation<
            Response<IUser>,
            {
                firstName: string;
                lastName: string;
                passportPhoto: string;
                userId: string;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/user/update-user-profile`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['user'],
        }),
        updateUserPhoneNumber: builder.mutation<
            Response<IUser>,
            {
                phone: string;
                userId: string;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/user/update-user-phone-number`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['user'],
        }),
        userStats: builder.query<Response<IUserStats>, void>({
            query: () => ({
                url: `/user/api/v1/user/a/stats`,
                method: 'GET',
            }),
            providesTags: ['user'],
        }),
        getUserRegistrationSummaryByYear: builder.query<
            Response<IUserRegistrationSummary[]>,
            number
        >({
            query: (year) => ({
                url: `/user/api/v1/user/a/stats/getUserRegistrationSummaryByYear/${year}`,
                method: 'GET',
            }),
            providesTags: ['user'],
        }),
        deleteUser: builder.mutation<
            Response<IUser>,
            { userId: string; authorizedBy: string }
        >({
            query: ({ userId, authorizedBy }) => ({
                url: `/user/api/v1/user/${userId}/${authorizedBy}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['user'],
        }),
    }),
});

export const {
    useGetAllUserQuery,
    useGetSingleUserQuery,
    useUserStatsQuery,
    useUpdateUserRoleMutation,
    useUpdateUserProfileMutation,
    useUpdateUserPhoneNumberMutation,
    useUpdateUserStatusMutation,
    useGetUserRegistrationSummaryByYearQuery,
    useDeleteUserMutation,
} = userApi;
