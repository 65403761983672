import { createSlice } from '@reduxjs/toolkit';
import { IBX_ADMIN_USER } from '../constant';

interface IAuthState {
    user: IUser | null;
}
const initialState: IAuthState = {
    user: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
            localStorage.setItem(IBX_ADMIN_USER, JSON.stringify(state));
        },
    },
});
export const { setUser } = authSlice.actions;
export default authSlice.reducer;
