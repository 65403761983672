import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { ICurrencyPair, IExchangeRates, IExchangeStats } from './interface';
import { paginatedResponse } from '../paginatedInterface';

export const exchangeRateApi = createApi({
    reducerPath: 'exchangeRateApi',
    baseQuery: baseQuery,
    tagTypes: ['exchangeRateApi'],
    endpoints: (builder) => ({
        addExchangeRate: builder.mutation<
            Response<ICurrencyPair[]>,
            {
                currencyPair: string;
                sellPrice: number;
                sellTrend: string;
                buyPrice: number;
                buyTrend: string;
            }
        >({
            query: (body) => ({
                url: `/marketPlace/api/v1/rates`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['exchangeRateApi'],
        }),
        editExchangeRate: builder.mutation<
            Response<IExchangeRates>,
            {
                rateId: string;
                sellPrice: number;
                sellTrend: string;
                buyPrice: number;
                buyTrend: string;
                isActive: boolean;
            }
        >({
            query: (body) => ({
                url: `/marketPlace/api/v1/rates`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['exchangeRateApi'],
        }),
        getAllExchangeRates: builder.query<
            paginatedResponse<IExchangeRates[]>,
            {
                perPage: number;
                currentPage: number;
                sellPrice?: number;
                buyPrice?: number;
                sellTrend?: 'UP' | 'DOWN';
                buyTrend?: 'UP' | 'DOWN';
                date?: string;
            }
        >({
            query: ({
                perPage,
                currentPage,
                sellPrice,
                buyPrice,
                sellTrend,
                buyTrend,
                date,
            }) => {
                let query = `/marketPlace/api/v1/rates?perPage=${perPage}&currentPage=${currentPage}`;
                if (sellPrice) {
                    query = `${query}&sellPrice=${sellPrice}`;
                }
                if (buyPrice) {
                    query = `${query}&buyPrice=${buyPrice}`;
                }
                if (sellTrend) {
                    query = `${query}&sellTrend=${sellTrend}`;
                }
                if (buyTrend) {
                    query = `${query}&buyTrend=${buyTrend}`;
                }
                if (date) {
                    query = `${query}&date=${date}`;
                }
                return {
                    url: query,
                    method: 'GET',
                };
            },
            providesTags: ['exchangeRateApi'],
        }),
        getSingleExchangeRate: builder.query<Response<IExchangeRates>, string>({
            query: (id) => ({
                url: `/marketPlace/api/v1/rates/${id}`,
                method: 'GET',
            }),
        }),
        deleteExchangeRate: builder.mutation<Response<ICurrencyPair>, string>({
            query: (id) => ({
                url: `/marketPlace/api/v1/rates/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['exchangeRateApi'],
        }),
        exchangeRateStats: builder.query<Response<IExchangeStats>, void>({
            query: () => ({
                url: `/marketPlace/api/v1/rates/a/stats`,
                method: 'GET',
            }),
            providesTags: ['exchangeRateApi'],
        }),
    }),
});

export const {
    useAddExchangeRateMutation,
    useDeleteExchangeRateMutation,
    useEditExchangeRateMutation,
    useGetAllExchangeRatesQuery,
    useGetSingleExchangeRateQuery,
    useExchangeRateStatsQuery,
} = exchangeRateApi;
