import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../api/baseUrl';
import { getAuthToken } from '../common/utils/helpers';

// Create our baseQuery instance
export const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,

    prepareHeaders: (headers) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = getAuthToken();

        if (token) {
            headers.set('authToken', token);
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});
