import {
    BarLine,
    ButtonContainer,
    Container,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    CustomButton,
    DeleteModal,
    Loader,
    ModalComp,
    PdfDownloader,
    SelectComp,
    TextEditor,
    TextInputComp,
} from '../../common/component';
const parse = require('html-react-parser');
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { BankTableHeader } from '../../common/utils/dataUtils';
import { IBank } from '../../store/bankApi/interface';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { useEffect, useState } from 'react';
import {
    useDeleteBankMutation,
    useEditBankMutation,
    useGetAllBankQuery,
    useGetSingleBankQuery,
    useBankStatsQuery,
} from '../../store/bankApi';
import { ReactComponent as FaqIcon } from '../../common/images/bank_icon.svg';
import TotalFaqImage from '../../common/images/totalfaq_icon.svg';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { Controller, useForm } from 'react-hook-form';
import { showErrorToast, showSuccessToast } from '../../common/utils/toast';
import { Col } from 'react-bootstrap';
export const Bank = () => {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(1000);
    const [bnkRecordId, setbankRecordId] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [dropDownTerm, setDropDownTerm] = useState('');

    const Items = [
        {
            name: 'Edit item',
            icon: <BiEditAlt size={15} />,
            OnClick: (data: any) => {
                setbankRecordId(data?._id);
                setShowEditModal(true);
            },
        },
        {
            name: 'Delete item',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: any) => {
                setDeleteId(data?._id);
                setShowDeleteModal(true);
            },
        },
    ];
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
        isActive: dropDownTerm,
    };
    const {
        data: allBank,
        isLoading,
        isFetching,
    } = useGetAllBankQuery(
        { ...paginate },
        {
            refetchOnMountOrArgChange: true,
        },
    );
    const [editBank, { isLoading: isEditing }] = useEditBankMutation();
    const [deleteBank, { isLoading: isDeleting }] = useDeleteBankMutation();
    const { data: bankStats } = useBankStatsQuery();
    const { data: singlebankData } = useGetSingleBankQuery(bnkRecordId);

    useEffect(() => {
        setTotalRows(allBank?.pagination?.totalDocumentCount as number);
    }, [allBank?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allBank?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    // edit bank
    const editBankForm = useForm({
        defaultValues: {
            newData: {
                bankCode: '',
                bankName: '',
                status: '',
                priority: 0,
            },
        },
    });
    const {
        handleSubmit: handleEditSubmit,
        control: editControl,
        setValue,
    } = editBankForm;

    useEffect(() => {
        if (singlebankData?.data?._id) {
            setValue(
                'newData',
                {
                    bankCode: singlebankData?.data?.bankCode,
                    bankName: singlebankData?.data?.bankName,
                    priority: singlebankData?.data?.priority,
                    status: singlebankData?.data?.isActive
                        ? 'Active'
                        : 'Inactive',
                },
                { shouldValidate: true },
            );
        }
    }, [singlebankData, setValue]);
    const onHandleEdit = (value: {
        newData: {
            bankName: string;
            bankCode: string;
            status: string;
            priority: number;
        };
    }) => {
        const payload = {
            ...value?.newData,
            bankCode: value?.newData?.bankCode,
            bankName: value?.newData?.bankName,
            priority: Number(value?.newData?.priority),
            bankRecordId: singlebankData?.data?._id as string,
            isActive: value?.newData?.status === 'Active',
        };
        editBank(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowEditModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message[0]);
            });
    };

    // DELETE FUNCTION
    const confirmDelete = () => {
        deleteBank(deleteId)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowDeleteModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'Bank name', key: 'bankName' },
        {
            label: 'Bank Code',
            key: 'bankCode',
        },
        {
            label: 'Country',
            key: 'country',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { header: 'Bank Name', dataKey: 'bankName' },
        {
            header: 'Bank Code',
            dataKey: 'bankCode',
        },
        {
            header: 'Country',
            dataKey: 'country',
        },
    ];

    return (
        <Container>
            <Header>Bank</Header>
            {isLoading && <Loader />}
            <Grid>
                <Counter
                    counter_title="Total Bank"
                    count={bankStats?.data?.totalBankRecords || '0'}
                    iconFill="blue"
                    iconBG="blue"
                    backgroundImage={TotalFaqImage}
                    isBackgroundImage={true}
                >
                    <FaqIcon />
                </Counter>

                <Counter
                    counter_title="Active Banks"
                    count={bankStats?.data?.totalActiveBankRecords || '0'}
                    iconFill="green"
                    iconBG="green"
                >
                    <FaqIcon />
                </Counter>
                <Counter
                    counter_title="Inactive Bank"
                    count={bankStats?.data?.totalInActiveBankRecords || '0'}
                    iconFill="red"
                    iconBG="red"
                >
                    <FaqIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={7}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col
                        xs={10}
                        sm={6}
                        className="d-flex align-items-center my-3"
                    >
                        <SelectComp
                            selectText="All banks"
                            arrayComp={[
                                { name: 'Active banks', value: 'true' },
                                { name: 'Inactive banks', value: 'false' },
                            ]}
                            onChange={(e: any) => {
                                setDropDownTerm(e.target.value);
                            }}
                        />
                        <TotalResultText>{`${
                            allBank?.data?.length || 0
                        } result`}</TotalResultText>
                    </Col>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'CAREER RECORD'}
                            {...{ columns: headersPdf, body: allBank?.data }}
                        />
                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={allBank?.data}
                        />
                    </ButtonContainer>
                </div>
            </div>
            <ContainerComp>
                <TableCompData
                    columns={BankTableHeader(Items) || []}
                    data={allBank?.data as IBank[]}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>
            {/* edit modal */}
            <ModalComp
                show={showEditModal}
                handleClose={() => setShowEditModal(false)}
                centered
                close={true}
                modalTitle="Edit Bank"
                paragraph="Fill in the  the forms to edit Bank"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleEditSubmit(onHandleEdit)}>
                            <div className="my-3">
                                <Controller
                                    name="newData.bankName"
                                    control={editControl}
                                    rules={{
                                        required: 'Bank Name is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.bankName?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Bank Name"
                                                name="bankName"
                                                type="text"
                                                defaultValue={
                                                    singlebankData?.data
                                                        ?.bankName
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.bankCode"
                                    control={editControl}
                                    rules={{
                                        required: 'Bank Code is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.bankCode?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Bank Code"
                                                name="bankCode"
                                                type="text"
                                                defaultValue={
                                                    singlebankData?.data
                                                        ?.bankCode
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="my-3">
                                <Controller
                                    name="newData.status"
                                    control={editControl}
                                    rules={{
                                        required: 'status is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.status?.message;
                                        return (
                                            <SelectComp
                                                labelText="isActive?"
                                                inputClassName={'py-2 '}
                                                arrayComp={[
                                                    'Active',
                                                    'Inactive',
                                                ]}
                                                name="status"
                                                defaultValue={
                                                    singlebankData?.data
                                                        .isActive
                                                        ? 'Active'
                                                        : 'Inactive'
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.priority"
                                    control={editControl}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.priority?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Priority"
                                                inputClassName={'py-2'}
                                                name="priority"
                                                type="number"
                                                defaultValue={
                                                    singlebankData?.data
                                                        ?.priority
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={!isEditing ? 'Save' : 'Loading...'}
                                />
                            </div>
                        </form>
                    </div>
                }
            />{' '}
            {/* Delete Modal */}
            <DeleteModal
                deleteItem={'Bank'}
                showDeleteModal={showDeleteModal}
                cancelDelete={() => setShowDeleteModal(false)}
                confirmDelete={confirmDelete}
                isDeleting={isDeleting}
            />
        </Container>
    );
};
