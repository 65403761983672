/* eslint-disable react/prop-types */
/* eslint-disable sort-keys */
/* eslint-disable react/display-name */
import React, { FC } from 'react';
import DataTable from 'react-data-table-component';

import styled from 'styled-components';
import { ITable } from '../../types';

const Style = styled.div`
    width: 100%;

    .bIvCTc {
        margin-bottom: 15rem;
    }
    .dkBBnC,
    .jbSrtK {
        padding: 0 1rem;
    }
    .rdt_TableHeadRow {
        font-size: 16px !important;
        color: #000000;
        font-weight: 600;
    }
    .rdt_TableRow {
        font-size: 16px !important;
        color: #0d0e0f;
        font-weight: 400;
    }
    .bMgaAx div:first-child,
    .eUeqdG {
        white-space: break-spaces !important;
    }
    .eSTlnH,
    .jNMKxq {
        margin-bottom: 12rem;
    }
    .rdt_TableBody {
        min-height: 300px;
    }
`;

export const TableCompData: FC<ITable> = ({
    title,
    data = [],
    columns,
    selectableRows,
    progressPending = false,
    setIsSelected = {},
    totalRows,
    rowsPerPage,
    currentPage,
    handlePageChange,
    handleRowsPerPageChange,
}) => {
    const handleRowSelected = (e: { allSelected: any }) => {
        if (typeof setIsSelected === 'function') {
            setIsSelected && setIsSelected(e?.allSelected);
        }
    };

    return (
        <Style>
            <DataTable
                responsive={true}
                title={title}
                columns={columns}
                data={data ? data : []}
                pagination={data?.length >= 10}
                paginationServer
                onSelectedRowsChange={handleRowSelected}
                selectableRows={selectableRows ? false : true}
                progressPending={progressPending}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                paginationRowsPerPageOptions={[10, 15, 20]}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
            />
        </Style>
    );
};
