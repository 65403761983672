import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { paginatedResponse } from '../paginatedInterface';
import { generateQueryString } from '../../common/utils';

export const kycApi = createApi({
    reducerPath: 'kycApi',
    baseQuery: baseQuery,
    tagTypes: ['kyc'],
    endpoints: (builder) => ({
        getAllKyc: builder.query<
            paginatedResponse<IKyc[]>,
            {
                params: {
                    perPage: number;
                    currentPage: number;
                    status: string;
                };
            }
        >({
            query: ({ params }) => ({
                url: `/user/api/v1/kyc?${generateQueryString({ ...params })}`,
                method: 'GET',
            }),
            providesTags: ['kyc'],
        }),
        getKycByStatus: builder.query<
            paginatedResponse<IKyc[]>,
            { currentPage: number; status: string; perPage: number }
        >({
            query: ({ currentPage, status, perPage }) => ({
                url: `/user/api/v1/kyc/checkUserKycStatus/${status}?perPage=${perPage}&currentPage=${currentPage}`,
                method: 'GET',
            }),

            providesTags: ['kyc'],
        }),
        updateKyc: builder.mutation<
            Response<IKyc>,
            {
                kycId: string;
                status: string;
                approvedBy: string;
                kycType: string;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/kyc/manageKyc`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['kyc'],
        }),
        getSingleKyc: builder.query<Response<IKyc>, string>({
            query: (kycId) => ({
                url: `/user/api/v1/kyc/${kycId}`,
                method: 'GET',
            }),
            providesTags: ['kyc'],
        }),
        deleteKyc: builder.mutation<Response<IKyc>, string>({
            query: (kycId) => ({
                url: `/user/api/v1/kyc/${kycId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['kyc'],
        }),
        KycStats: builder.query<Response<IKycStats>, void>({
            query: () => ({
                url: 'user/api/v1/kyc/a/stats',
                method: 'GET',
            }),
            providesTags: ['kyc'],
        }),
    }),
});

export const {
    useGetAllKycQuery,
    useGetKycByStatusQuery,
    useUpdateKycMutation,
    useDeleteKycMutation,
    useGetSingleKycQuery,
    useKycStatsQuery,
} = kycApi;
