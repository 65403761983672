import { ReactComponent as MenuIcon } from '../../../images/menu_icon.svg';
import { ReactComponent as Exit } from '../../../images/exit_icon.svg';
import { ReactComponent as DropdownIcon } from '../../../images/dropdown_icon1.svg';
import { ReactComponent as IbxLogo } from '../../../images/ibx_logo.svg';
import { ReactComponent as dashboard } from '../../../images/dashboard_icon.svg';
import { ReactComponent as UserIcon } from '../../../images/user_icon.svg';
import { ReactComponent as TransactionIcon } from '../../../images/transaction_icon.svg';
import { ReactComponent as ContactIcon } from '../../../images/support_icon.svg';
import { ReactComponent as CurrencyIcon } from '../../../images/currency_icon.svg';
import { ReactComponent as CurrencyPairIcon } from '../../../images/currencypair_icon.svg';
import { ReactComponent as ConfigurationIcon } from '../../../images/configuration_icon.svg';
import { ReactComponent as SettingIcon } from '../../../images/setting_icon.svg';
import { ReactComponent as AppversionIcon } from '../../../images/appversion_icon.svg';
import { ReactComponent as AdvertIcon } from '../../../images/advert_icon.svg';
import { ReactComponent as TradeIcon } from '../../../images/trade_icon.svg';
import { ReactComponent as LogoutIcon } from '../../../images/logout_icon.svg';
import { ReactComponent as ChangePasswordIcon } from '../../../images/change_password.svg';
import { ReactComponent as ProfileIcon } from '../../../images/profile.svg';
import { ReactComponent as CareerIcon } from '../../../images/career_icon.svg';
import { ReactComponent as FaqIcon } from '../../../images/faq_icon.svg';
import { FaExchangeAlt } from 'react-icons/fa';
import { NavLink, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../colors';
import { pxToRem } from '../../../utils/pxToRem';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setUser } from '../../../../store/auth';
import { setAuthToken } from '../../../utils/helpers';
import { useLogoutMutation } from '../../../../store/auth/authApi';
import NotificationIcon from '../../../images/notification.svg';

export const Navbar = () => {
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const [showMenu, setShowMenu] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [logout] = useLogoutMutation();

    const touchOutside = useRef<HTMLDivElement>(null);

    const clickOutside = (e: any) => {
        if (touchOutside.current?.contains(e.target)) return;
        setShowDropdown(false);
        setShowNotification(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', clickOutside);
        return () => {
            document.removeEventListener('mousedown', clickOutside);
        };
    }, [showDropdown, showNotification]);

    const onLogout = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        logout();
        dispatch(setUser(null));
        setAuthToken('');
        navigate('/login');
    };
    const menuBar = [
        {
            icon: dashboard,
            name: 'Dashboard',
            link: '/dashboard/admin',
        },
        {
            icon: ConfigurationIcon,
            name: 'Configuration',
            link: '/dashboard/configuration',
        },
        {
            icon: CurrencyIcon,
            name: 'Currency',
            link: '/dashboard/currency',
        },

        {
            icon: CurrencyPairIcon,
            name: 'Currency Pair',
            link: '/dashboard/currencypair',
        },
        {
            icon: AdvertIcon,
            name: 'Trade Adverts',
            link: '/dashboard/trade-advert',
        },

        {
            icon: TradeIcon,
            name: 'Trade',
            link: '/dashboard/trade',
        },
        {
            icon: TransactionIcon,
            name: 'Transaction',
            link: '/dashboard/transaction',
        },
        {
            icon: AppversionIcon,
            name: 'App Versions',
            link: '/dashboard/appversion',
        },
        {
            icon: UserIcon,
            name: 'Users',
            link: '/dashboard/users',
        },
        {
            icon: UserIcon,
            name: 'Partner Request',
            link: '/dashboard/partner-request',
        },
        {
            icon: UserIcon,
            name: 'Merchant Request',
            link: '/dashboard/merchant-request',
        },
        {
            icon: SettingIcon,
            name: 'Profile Settings',
            link: '/dashboard/profile-setting',
        },
        {
            icon: CareerIcon,
            name: 'Career',
            link: '/dashboard/career',
        },
        {
            icon: ContactIcon,
            name: 'Contact Us',
            link: '/dashboard/contact-us',
        },
        {
            icon: ContactIcon,
            name: 'Total Subscription',
            link: '/dashboard/totalsubscription',
        },
        {
            icon: FaExchangeAlt,
            name: 'Exchange Rates',
            link: '/dashboard/exchange-rates',
        },
        {
            icon: AppversionIcon,
            name: 'Referral Campaign',
            link: '/dashboard/referral-campaign',
        },
    ];

    return (
        <Container>
            <NavContent>
                <MenuIcon onClick={() => setShowMenu(true)} className="menu" />
                <Menu>
                    {showMenu && (
                        <MenuContainer>
                            <MenuContent>
                                <MenuHeader>
                                    <Logo>
                                        <IbxLogo />
                                    </Logo>

                                    <Exit
                                        onClick={() => setShowMenu(false)}
                                        className="exit"
                                    />
                                </MenuHeader>
                                <LinkContainer>
                                    {menuBar.map((option) => {
                                        const Icon = option.icon;
                                        return (
                                            <Link
                                                to={option.link}
                                                key={option.name}
                                                onClick={() =>
                                                    setShowMenu(false)
                                                }
                                            >
                                                <div>
                                                    <Icon />
                                                </div>
                                                <LinkParagraph>
                                                    {option.name}
                                                </LinkParagraph>
                                            </Link>
                                        );
                                    })}
                                    <Version>V.2.01</Version>
                                    <LogoutWrap as="div" onClick={onLogout}>
                                        <div>
                                            <LogoutIcon />
                                        </div>
                                        <LinkParagraph>Log out</LinkParagraph>
                                    </LogoutWrap>
                                </LinkContainer>
                            </MenuContent>
                        </MenuContainer>
                    )}
                </Menu>

                <Wrap>
                    <div>
                        <Image>
                            <img
                                src={NotificationIcon}
                                alt=""
                                className="note"
                            />
                        </Image>
                    </div>
                    <div className="d-flex align-items-center">
                        <Image>
                            <img src={user?.passportPhoto} alt="" />
                        </Image>
                        <Name>{`${user?.firstName} ${user?.lastName}`}</Name>
                    </div>
                    <div
                        onClick={() => setShowDropdown(!showDropdown)}
                        style={{ cursor: 'pointer' }}
                    >
                        <DropdownIcon />
                    </div>
                    {showDropdown && (
                        <DropdownDiv
                            ref={touchOutside}
                            onClick={() => setShowDropdown(!showDropdown)}
                        >
                            <DropdownElement
                                onClick={() =>
                                    navigate('/dashboard/profile-setting')
                                }
                            >
                                <ChangePasswordIcon />
                                <Text>Change Password</Text>
                            </DropdownElement>
                            <DropdownElement
                                onClick={() =>
                                    navigate('/dashboard/profile-setting')
                                }
                            >
                                <ProfileIcon />
                                <Text>Profile</Text>
                            </DropdownElement>
                            <DropdownElement
                                className="mt-3"
                                onClick={onLogout}
                            >
                                <LogoutIcon />
                                <Text>Log Out</Text>
                            </DropdownElement>
                        </DropdownDiv>
                    )}
                </Wrap>
            </NavContent>
        </Container>
    );
};

export const Container = styled.div`
    .menu {
        display: none;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        @media (max-width: 600px) {
            display: block;
        }
    }
`;
export const NavContent = styled.div`
    height: 6vh;
    position: fixed;
    top: 0px;
    z-index: 100;
    width: calc(100vw - 200px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: ${colors.primary12};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    @media (max-width: 600px) {
        width: calc(100%);
        left: 0px;
        justify-content: space-between;
        padding: 0px 10px;
    }
`;
const Wrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    padding: 0px 20px;
    margin: 5px 0px;
`;
const Image = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    .note {
        cursor: pointer;
        width: 22px;
        height: 22px;
        margin-top: 5px;
        margin-right: 25px;
    }
    @media (max-width: 600px) {
        width: 30px;
        height: 30px;
    }
    img {
        width: 100%;
    }
`;
const Name = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin: 0px 8px;
    width: 80px;
    height: 17px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${colors.black12};
`;
const DropdownDiv = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    background: ${colors.white};
    padding: 15px 20px;
    border-radius: 8px;
    position: absolute;
    top: 10vh;
    right: 20px;
    z-index: 150;
    box-shadow: 0px 0px 6px ${colors.light_black2};
    @media (max-width: 600px) {
        right: 2px;
        padding: 15px 15px;
    }
`;
const DropdownElement = styled.div`
    display: flex;
    align-items: center;
    column-gap: 15px;
    cursor: pointer;
    svg {
        /* fill: ${colors.neutral_black}; */
        width: 20px;
        height: 20px;
        stroke: ${colors.neutral_black};
    }
`;
const Text = styled.p`
    font-weight: 500;
    font-size: 16px;
    color: ${colors.black};
`;

//menu bar stylings
const Menu = styled.div`
    display: none;

    @media (max-width: 600px) {
        display: block;
        font-weight: 600;
        font-size: 18px;
        line-height: 29px;
    }
`;
const MenuContainer = styled.div`
    background: #eef0f34e;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 200;
`;
const MenuContent = styled.div`
    width: 70%;
    z-index: 300px;
    border-right: 1px solid ${colors.black6};
    background: ${colors.Primary1};
`;
const MenuHeader = styled.div`
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    border-bottom: 1px solid rgba(238, 240, 243, 0.3);
    .exit {
        fill: ${colors.white};
    }
`;
const Logo = styled.div`
    svg {
        width: 50px;
        height: 40px;
        background: ${colors.white};
        border-radius: 8px;
    }
`;
const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    height: calc(100vh - 40px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 0px;
        background: ${colors.Primary1};
    }
`;
const Link = styled(NavLink)`
    padding: 8px 10px;
    margin: 8px 8px;
    display: flex;
    column-gap: 8px;
    align-items: center;
    text-decoration: none;
    border-radius: 4px;
    color: ${colors.white};
    svg {
        fill: ${colors.white};
        stroke: ${colors.white};
        width: 14px;
        height: 14px;
    }
    &.active {
        background: ${colors.white};
        color: ${colors.Primary1};
        svg {
            fill: ${colors.Primary1};
            stroke: ${colors.Primary1};
        }
    }
`;
const LogoutWrap = styled(Link)`
    border-radius: 0px;
    padding-bottom: 15px;
    border-top: 1px solid rgba(238, 240, 243, 0.3);
`;
const Version = styled.span`
    font-size: 14px;
    line-height: 17px;
    margin: 15px 18px 0px 18px;
    color: ${colors.white};
`;
const LinkParagraph = styled.h3`
    font-weight: 400;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(28)};
`;
