import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
    colors,
    CustomButton,
    ModalComp,
    SelectComp,
    TextEditor,
} from '../../common/component';
import { showSuccessToast, showErrorToast } from '../../common/utils/toast';
import {
    useGetAllSubTopicQuery,
    useGetAllTopicsQuery,
    useGetSingleContentQuery,
    useUpdateContentMutation,
} from '../../store/topicApi';
import CustomSearchDropDown from '../../common/component/Ui/customSearch';
const parse = require('html-react-parser');

interface IContentValues {
    topic: string;
    subTopic: string;
    content: string;
    description: string;
    contentId: string;
    status: string;
}

export const ContentDetails = () => {
    const param = useParams();
    const contentId = param?.id as string;
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState<any>();
    const [desc, setDesc] = useState<any>();
    const [status, setStatus] = useState<any>();
    const [topicApiId, setTopicApiId] = useState<any>('');
    const [subTopicApiId, setSubTopicApiId] = useState<any>('');
    const { data: contentData } = useGetSingleContentQuery(contentId);
    const [updateContent, { isLoading: isUpdating }] =
        useUpdateContentMutation();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };

    const { data: allTopics } = useGetAllTopicsQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });

    const { data: allSubTopics } = useGetAllSubTopicQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });

    const { handleSubmit, control } = useForm({
        defaultValues: {
            topic: '',
            title: '',
            description: '',
            subTopic: '',
            content: '',
            contentId: '',
            status: '',
        },
    });

    const onHandleEdit = (value: IContentValues) => {
        const payload = {
            topic: topicApiId?.value,
            subTopic: subTopicApiId?.value,
            content: title,
            description: desc,
            contentId: contentId,
            status: status === 'Active' ? true : false,
        };

        updateContent(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    const cleanupTopics = (topicsInQuestion: any) => {
        if (topicsInQuestion) {
            const result = topicsInQuestion.map((topic: any) => {
                return { label: topic.title, value: topic?._id };
            });

            return result;
        }

        return [];
    };

    const cleanupSubTopics = (subTopicsInQuestion: any) => {
        if (subTopicsInQuestion) {
            const result = subTopicsInQuestion.map((subTopic: any) => {
                return { label: subTopic.title, value: subTopic?._id };
            });

            return result;
        }

        return [];
    };

    return (
        <div className="d-flex flex-column gap-4 mx-lg-5 mb-4">
            <>
                <div className="d-flex justify-content-between">
                    <Wrap>
                        <Title>Content title</Title>
                        <Paragraph>
                            {parse(contentData?.data?.content)}
                        </Paragraph>
                    </Wrap>
                </div>

                <Wrap>
                    <Title>Content description</Title>
                    <TextArea>{parse(contentData?.data?.description)}</TextArea>
                </Wrap>

                <div className="d-flex justify-content-center">
                    <CustomButton
                        className="py-3 mt-4"
                        text="Edit Content"
                        onClick={() => {
                            setShowModal(true);
                        }}
                    />
                </div>
            </>

            <ModalComp
                show={showModal}
                handleClose={() => setShowModal(false)}
                centered
                close={true}
                size="lg"
                modalTitle="Edit Content"
                paragraph="Fill in the forms to edit a content"
                modalBody={
                    <form onSubmit={handleSubmit(onHandleEdit)}>
                        <div className="py-1 px-lg-4 ">
                            <div className="mb-4">
                                <div>
                                    {' '}
                                    <Controller
                                        name="topic"
                                        control={control}
                                        rules={{
                                            required: '*topic is required',
                                            min: '8',
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            formState: { errors },
                                        }) => {
                                            const errorMessage =
                                                errors.topic?.message;
                                            setTopicApiId(value);
                                            return (
                                                <CustomSearchDropDown
                                                    isShowLabel
                                                    placeholder="Select..."
                                                    defaultOptions={cleanupTopics(
                                                        allTopics?.data,
                                                    )}
                                                    label="Select Topic"
                                                    {...{
                                                        value,
                                                        onChange,
                                                        errors: [errorMessage],
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="mb-4">
                                <div>
                                    {' '}
                                    <Controller
                                        name="subTopic"
                                        control={control}
                                        rules={{
                                            required: '*Subtopic is required',
                                            min: '8',
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            formState: { errors },
                                        }) => {
                                            const errorMessage =
                                                errors.topic?.message;
                                            setSubTopicApiId(value);
                                            return (
                                                <CustomSearchDropDown
                                                    isShowLabel
                                                    placeholder="Select..."
                                                    defaultOptions={cleanupSubTopics(
                                                        allSubTopics?.data,
                                                    )}
                                                    label="Select Subtopic"
                                                    {...{
                                                        value,
                                                        onChange,
                                                        errors: [errorMessage],
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="my-4">
                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{
                                        required: 'Content title is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                    }) => {
                                        setTitle(value);
                                        return (
                                            <TextEditor
                                                {...{ value, onChange }}
                                                labelText="Content title"
                                                defaultValue={
                                                    contentData?.data?.content
                                                }
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-4">
                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{
                                        required:
                                            'Content description is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                    }) => {
                                        setDesc(value);
                                        return (
                                            <TextEditor
                                                {...{ value, onChange }}
                                                labelText="Content description"
                                                defaultValue={
                                                    contentData?.data
                                                        ?.description
                                                }
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <Controller
                                name="status"
                                control={control}
                                rules={{
                                    required: 'status is required',
                                }}
                                render={({
                                    field: { value, onChange },
                                    formState: { errors },
                                }) => {
                                    const errorMessage =
                                        errors?.status?.message;
                                    setStatus(value);
                                    return (
                                        <SelectComp
                                            labelText="Status"
                                            inputClassName={'py-2 '}
                                            arrayComp={['Active', 'Inactive']}
                                            name="status"
                                            defaultValue={
                                                contentData?.data?.status
                                                    ? 'Active'
                                                    : 'Inactive'
                                            }
                                            {...{
                                                value,
                                                onChange,
                                                errors: [errorMessage],
                                            }}
                                        />
                                    );
                                }}
                            />

                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        isUpdating
                                            ? 'Loading...'
                                            : 'Update Content'
                                    }
                                />
                            </div>
                        </div>
                    </form>
                }
            />
        </div>
    );
};
const Wrap = styled.div`
    width: 100%;
`;
const Title = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${colors.black40};
    margin: 8px 0px;
`;
const Paragraph = styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    color: ${colors.black20};
`;
const TextArea = styled.div`
    background: #f7f9fa;
    border-radius: 6px;
    padding: 15px;
    font-size: 18px;
`;
