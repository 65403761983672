import { useEffect, useRef, useState } from 'react';
import { ReactComponent as EditIcon } from '../../images/editprofile_icon.svg';
import styled from 'styled-components';
import { colors } from '../colors';

export const FileUploaderComp = ({
    onChange,
    defaultValue,
    isImageSmall,
    imageSmall,
}: {
    defaultValue: string;
    onChange: (...event: any[]) => void;
    isImageSmall?: boolean;
    imageSmall?: string;
}) => {
    const imgRef = useRef<HTMLInputElement>(null);
    const [imagePreview, setImagePreview] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    const handleImageClick = (
        e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    ) => {
        e.preventDefault();
        imgRef.current?.click();
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files) {
            const files = e.target.files;
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(files[0]);
        }
    };
    useEffect(() => {
        if (imagePreview) {
            // Only proceed if imagePreview is not an empty string
            const formData = new FormData();
            formData.append('file', imagePreview);
            formData.append('upload_preset', 'ibxmedias');
            formData.append('cloud_name', 'dbg2z1svm');

            fetch('https://api.cloudinary.com/v1_1/dbg2z1svm/upload', {
                method: 'post',
                body: formData,
            })
                .then((resp) => resp.json())
                .then((data) => {
                    setImageUrl(data.secure_url);
                    return onChange(data.secure_url);
                })
                .catch((err) => console.log(err));
        }
    }, [imagePreview, onChange]);

    return (
        <ImageWrapper>
            <input ref={imgRef} type="file" onChange={handleImageChange} />

            <ProfileContainer>
                <ProfileWrap
                    style={{
                        width: isImageSmall ? imageSmall : '130px',
                        height: isImageSmall ? imageSmall : '130px',
                    }}
                >
                    <EditIcon onClick={handleImageClick} />
                    <img src={imageUrl || defaultValue} alt="" />
                </ProfileWrap>
            </ProfileContainer>
        </ImageWrapper>
    );
};

const ImageWrapper = styled.div`
    input {
        display: none;
    }
`;

const ProfileContainer = styled.div`
    width: 130px;
    height: 130px;
    position: relative;
`;
const ProfileWrap = styled.div`
    border-radius: 50%;
    border: 1px solid ${colors.gray};
    background: ${colors.gray};
    overflow: hidden;
    object-fit: cover;
    img {
        width: 100%;
        object-fit: cover;
    }
    svg {
        position: absolute;
        bottom: 5px;
        right: 5px;
        z-index: 50;
        cursor: pointer;
    }
`;
