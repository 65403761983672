import styled from 'styled-components';
import { colors } from '../../common/component';

export const Flexbox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
`;
export const Flexbox2 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;
export const Subheader = styled.h3`
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: ${colors.neutral_black};
    @media (max-width: 600px) {
        font-size: 18px;
    }
`;
export const SeeAll = styled.h3`
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    @media (max-width: 600px) {
        font-size: 16px;
    }
`;
