import { useParams } from "react-router-dom";
import { Loader, WalletCard } from "../../common/component";
import { TableCompData } from "../../common/component/table";
import { ContainerComp } from "../../common/component/Ui/containerComp";
import { TransactionHistoryTableHeader } from "../../common/utils/dataUtils";
import { Grid } from "../dashboard/styles";
import { SubHeading } from "./style";
import {
	useGetUserWalletQuery,
	useGetWalletTransactionQuery,
} from "../../store/transactionApi";
import { ITransaction, IWallet } from "../../store/transactionApi/interface";
import { useEffect, useState } from "react";
import { currencyFormater } from "../../common/utils/helpers";

export const WalletBalance = () => {
	const param = useParams();
	const userId = param?.id as string;
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRows, setTotalRows] = useState(100);
	const paginate = {
		userId: userId,
		perPage: rowsPerPage,
		currentPage: currentPage,
	};
	const { data: userWallet, isFetching } = useGetUserWalletQuery(paginate, {
		refetchOnMountOrArgChange: true,
	});
	const [walletId, setWalletId] = useState("");
	const TwalletQuery = {
		userId: userId,
		walletId: walletId || (userWallet?.data[0]?._id as string),
	};
	const { data: walletTransaction, isLoading: isGettingWalletTrans } =
		useGetWalletTransactionQuery(TwalletQuery);

	useEffect(() => {
		setTotalRows(userWallet?.pagination?.totalDocumentCount as number);
	}, [userWallet?.pagination?.totalDocumentCount]);
	const handlePageChange = (page: any) => setCurrentPage(page);
	const handleRowsPerPageChange = (newRowsPerPage: any) => {
		if (!userWallet?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
		setRowsPerPage(newRowsPerPage);
		setCurrentPage(1);
	};

	return (
		<div>
			{isGettingWalletTrans && <Loader />}
			<Grid className="mb-4 ">
				{userWallet?.data?.map((item: IWallet, index: number) => (
					<WalletCard
						key={index}
						amount={currencyFormater(item.walletBalance)}
						symbol={item.walletType === "ngn" ? "#" : "$"}
						onClick={() => setWalletId(item._id)}
					/>
				))}
			</Grid>
			<SubHeading className="my-2">Transaction history</SubHeading>
			<ContainerComp>
				<TableCompData
					columns={TransactionHistoryTableHeader()}
					data={walletTransaction?.data as ITransaction[]}
					progressPending={isFetching}
					totalRows={totalRows}
					rowsPerPage={rowsPerPage}
					currentPage={currentPage}
					handlePageChange={handlePageChange}
					handleRowsPerPageChange={handleRowsPerPageChange}
				/>
			</ContainerComp>
		</div>
	);
};
