import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { IBank, IBankStats } from './interface';
import { paginatedResponse } from '../paginatedInterface';
import { generateQueryString } from '../../common/utils';

export const bankApi = createApi({
    reducerPath: 'bankApi',
    baseQuery: baseQuery,
    tagTypes: ['bank'],
    endpoints: (builder) => ({
        editBank: builder.mutation<
            Response<IBank[]>,
            {
                bankCode: string;
                bankName: string;
                status: string;
                priority: number;
                isActive: boolean;
                bankRecordId: string;
            }
        >({
            query: (body) => ({
                url: `/account/api/v1/bankRecord/updateBankRecord`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['bank'],
        }),
        getAllBank: builder.query<
            paginatedResponse<IBank[]>,
            {
                perPage: number;
                currentPage: number;
                isActive: string;
            }
        >({
            query: ({ perPage, currentPage, isActive }) => ({
                url: `/account/api/v1/bankRecord?${generateQueryString({
                    perPage,
                    currentPage,
                    isActive,
                })}`,
                method: 'GET',
            }),
            providesTags: ['bank'],
        }),

        getSingleBank: builder.query<Response<IBank>, string>({
            query: (bankId) => ({
                url: `/account/api/v1/bankRecord/${bankId}`,
                method: 'GET',
            }),
        }),
        deleteBank: builder.mutation<Response<IBank>, string>({
            query: (bankId) => ({
                url: `/account/api/v1/bankRecord/${bankId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['bank'],
        }),
        bankStats: builder.query<Response<IBankStats>, void>({
            query: () => ({
                url: `/account/api/v1/bankRecord/a/stats`,
                method: 'GET',
            }),
            providesTags: ['bank'],
        }),
    }),
});

export const {
    useEditBankMutation,
    useGetAllBankQuery,
    useGetSingleBankQuery,
    useDeleteBankMutation,
    useBankStatsQuery,
} = bankApi;
