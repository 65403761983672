import { useNavigate } from "react-router-dom";
import IbxLogo from "../../../common/images/ibx_main_logo.svg";

import {
	ContentContainer,
	Header,
	AuthContainer,
	Paragraph,
	PrivacyText,
	IBXLogo,
} from "../login/style";
import { Container } from "react-bootstrap";
import {
	Loader,
	PinCodeInput,
} from "../../../common/component";
import { ColoredText, InputGrid } from "../forgetPassword/style";
import { useInitializeResetPasswordMutation } from "../../../store/auth/authApi";
import { useState } from "react";
import { showSuccessToast, showErrorToast } from "../../../common/utils/toast";

export const SetOtp = () => {
	const navigate = useNavigate();
	const userEmail = localStorage.getItem("userEmail");
	const [otp, setOtp] = useState("");
	const onChange = (value: string) => setOtp(value);
	const [initializeResetPassword, { isLoading }] =
		useInitializeResetPasswordMutation();

	const onHandleSubmit = () => {
		let payload = {
			email: userEmail as string,
		};
		initializeResetPassword(payload)
			.unwrap()
			.then((result) => {
				showSuccessToast(result?.message);
			})
			.catch((error) => {
				showErrorToast(error?.data?.message);
			});
	};
	const onFillOtp = () => {
		localStorage.setItem("ibxUserOtp", otp);
		navigate("/password/reset");
	};
	return (
		<Container>
			{isLoading ? <Loader /> : null}
			<AuthContainer>
				<ContentContainer>
					<IBXLogo className="d-flex justify-content-center mb-5 pt-2">
						<img src={IbxLogo} alt="" />
					</IBXLogo>
					<>
						<form>
							<div className="pb-2">
								<Header>Forget Password</Header>
							</div>
							<Paragraph>
								Enter the Verification Code sent to your email address
							</Paragraph>

							<InputGrid>
								<PinCodeInput length={4} setValue={onChange} />
							</InputGrid>
							<div className="text-align-right">
								<ColoredText onClick={() => onHandleSubmit()}>
									Resend Code ?
								</ColoredText>
							</div>
						</form>
						{otp.length === 4 ? onFillOtp() : null}
					</>
				</ContentContainer>
			</AuthContainer>
			<PrivacyText>
				Protected by reCAPTCHA and subject to the Google{" "}
				<span>Privacy Policy</span> and <span>Terms of Service.</span>
			</PrivacyText>
		</Container>
	);
};
