import { FC, Key } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import styled from 'styled-components';
import styledComponents from 'styled-components';
import { IDropDownComp, ISelectComp } from '../../types';

export const SelectComp: FC<ISelectComp> = ({
    LabelText,
    labelText,
    selectClassName,
    LabelClassName,
    arrayComp = [],
    selectText,
    input,
    inputValue,
    required,
    desc,
    errors,
    ...props
}) => {
    return (
        <div className=" w-100">
            <Form.Group className="">
                {(LabelText || labelText) && (
                    <Form.Label>
                        <Label className={`mb-0 ${LabelClassName}`}>
                            {LabelText || labelText}
                            {required && (
                                <span className={'text-danger'}>*</span>
                            )}
                        </Label>
                    </Form.Label>
                )}
                <Form.Select
                    className={` ${selectClassName}`}
                    placeholder="Select"
                    {...props}
                >
                    <option className="" value={''}>
                        {selectText || '--select--'}
                    </option>
                    {arrayComp?.length > 0 &&
                    arrayComp.some(
                        (_item) =>
                            _item?.name !== undefined ||
                            _item?.value !== undefined,
                    )
                        ? arrayComp.map((_item, index) => (
                              <option key={index} value={_item?.value}>
                                  {_item?.name}
                              </option>
                          ))
                        : arrayComp?.map((item, index) => (
                              <option key={index} value={item}>
                                  {item}
                              </option>
                          ))}
                </Form.Select>
                {desc && <small>{desc}</small>}
                {errors?.map((error: any, i: any) => (
                    <small className="text-danger mt-2" key={i}>
                        {error}
                    </small>
                ))}
            </Form.Group>
        </div>
    );
};

export const DropDownComp: FC<IDropDownComp> = ({
    arrayComp = [],
    DropDownText,
    input,
    show,
    dropLabelClassName,
}) => {
    return (
        <DropDownStyle Hide={show}>
            <Dropdown className="border-0 p-0">
                <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className={` p-0 ${dropLabelClassName}`}
                >
                    {DropDownText}
                </Dropdown.Toggle>

                {arrayComp?.length > 0 && (
                    <Dropdown.Menu className="border-0 px-0 py-3 px-2 shadow">
                        {arrayComp?.length > 0 &&
                            arrayComp?.map(
                                (item: any, index: Key | null | undefined) => (
                                    <Dropdown.Item
                                        className="mb-1 "
                                        key={index}
                                        onClick={item?.action}
                                    >
                                        {item[input]}
                                    </Dropdown.Item>
                                ),
                            )}
                    </Dropdown.Menu>
                )}
                {arrayComp.length <= 0 && (
                    <Dropdown.Menu className="border-0 px-0 py-3 px-2">
                        <Dropdown.Item className="border-0">
                            Nothing Yet
                        </Dropdown.Item>
                    </Dropdown.Menu>
                )}
            </Dropdown>
        </DropDownStyle>
    );
};

export const DropDownComp2: FC<IDropDownComp> = ({
    arrayComp,
    DropDownText,
    input,
    show,
    dropLabelClassName,
}) => {
    return (
        <DropDownStyle Hide={show}>
            <Dropdown className="border-3 p-0">
                <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className={` p-0 ${dropLabelClassName}`}
                >
                    {DropDownText}
                </Dropdown.Toggle>
                <Dropdown.Menu className="border-0 px-0 py-3 px-2 shadow ">
                    {arrayComp}
                </Dropdown.Menu>
            </Dropdown>
        </DropDownStyle>
    );
};

const DropDownStyle = styledComponents.div<{ Hide?: any }>`

.dropdown-toggle::after {
  display:${(prop) => (prop.Hide ? prop.Hide : 'none')};
}
a{
  color: gray;
  text-decoration: none;
}
`;
const Label = styled.label`
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
`;
