import { CSSObjectWithLabel, OptionsOrGroups } from 'react-select';
import AsyncSelect from 'react-select/async';
import Select, {
    FormatOptionLabelMeta,
} from 'react-select/dist/declarations/src/Select';
// import "./customSearchDropdown.css";
// import styles from "./customsearchdropdown.module.scss";
import { ReactNode } from 'react';

interface ICustomSearchDropdown {
    label?: string;
    onChange: any;
    iconType?: string;
    loadOptions?: (arg: string) => Promise<any>;
    register?: React.Ref<Select<string, boolean, any>> | undefined;
    name?: string;
    isShowLabel?: boolean;
    isMulti?: boolean;
    isSearchable?: boolean;
    defaultOptions?: boolean | OptionsOrGroups<string, any> | undefined;
    defaultValue?: any;
    disabled?: boolean;
    placeholder?: string;
    inputHeight?: number;
    labelStyle?: object;
    inputWidth?: number;
    options?: OptionsOrGroups<any, any> | undefined;
    formatOptionLabel?:
        | ((
              data: any,
              formatOptionLabelMeta: FormatOptionLabelMeta<any>,
          ) => ReactNode)
        | undefined;
    errors?: any;
}
const CustomSearchDropDown = ({
    label,
    onChange,
    iconType,
    loadOptions,
    register,
    name,
    isShowLabel,
    isMulti,
    isSearchable,
    defaultOptions,
    defaultValue,
    disabled,
    placeholder,
    inputHeight = 50,
    labelStyle,
    inputWidth,
    options,
    formatOptionLabel,
    errors,
}: ICustomSearchDropdown) => {
    const customStyles = {
        control: (base: CSSObjectWithLabel, state: any) => {
            return {
                ...base,
                '&:hover': {
                    borderColor: '',
                },
                '&:focus': {
                    borderColor: '',
                    boxShadow: '',
                },
                minHeight: inputHeight,
                minWidth: inputWidth,
                borderColor: !state?.isFocused ? '#dae0e6' : '#dae0e6',
                borderWidth: '1.3px',
                borderRadius: '10px',
                boxShadow: '',
                zIndex: '20',
            };
        },
    };

    return (
        <div className="">
            {isShowLabel && (
                <label style={{ ...labelStyle }}>
                    {iconType ? <i className={iconType} /> : ''}
                    {label}
                </label>
            )}

            <AsyncSelect
                options={options}
                placeholder={placeholder}
                defaultValue={defaultValue}
                isClearable
                isSearchable={isSearchable}
                loadOptions={loadOptions}
                defaultOptions={defaultOptions}
                onChange={onChange}
                ref={register}
                name={name}
                isMulti={isMulti}
                isDisabled={disabled}
                styles={customStyles}
                formatOptionLabel={formatOptionLabel}
            />

            {errors?.map((error: any, i: any) => (
                <small className="d-block text-danger mt-1" key={i}>
                    {error}
                </small>
            ))}
        </div>
    );
};

export default CustomSearchDropDown;
