import { ActiveButton, BorderDiv } from "../users/style";
import { useState } from "react";
import { Container } from "../users/style";
import { Header } from "../dashboard/styles";
import { ChangePassword } from "./changePassword";
import { Profile } from "./profile";
import { SettingsTextGrid } from "./style";
import { TwoFactor } from "./twoFactor";

export const ProfileSetting = () => {
	const [active, setActive] = useState(1);

	return (
		<Container>
			<Header>Profile Settings</Header>

			<BorderDiv className="my-4 ">
					<SettingsTextGrid>
						<ActiveButton active={active === 1} onClick={() => setActive(1)}>
							Profile
						</ActiveButton>
						<ActiveButton active={active === 2} onClick={() => setActive(2)}>
							Change password
						</ActiveButton>
						<ActiveButton active={active === 3} onClick={() => setActive(3)}>
							Two factor authentication
						</ActiveButton>
					</SettingsTextGrid>
			</BorderDiv>
			{active === 1 ? (
				<Profile />
			) : active === 2 ? (
				<ChangePassword />
			) : (
				<TwoFactor />
			)}
		</Container>
	);
};
