import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FC } from 'react';
import { IModalComp } from '../../types';
import { colors } from '../colors';

export const ModalComp: FC<IModalComp> = ({
    show,
    handleClose,
    modalTitle,
    modalBody,
    close,
    size,
    centered,
    designClass,
    paragraph,
}) => {
    return (
        <Styled>
            <div>
                <Modal
                    show={show}
                    onHide={handleClose}
                    centered={centered}
                    size={size}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    {close && (
                        <Modal.Header
                            closeButton
                            className={`border-0 mx-lg-4 pb-0`}
                        >
                            {modalTitle && (
                                <Modal.Title>
                                    <Header>{modalTitle}</Header>
                                    <Paragraph>{paragraph}</Paragraph>
                                </Modal.Title>
                            )}
                        </Modal.Header>
                    )}

                    <Modal.Body className={designClass}>{modalBody}</Modal.Body>
                </Modal>
            </div>
        </Styled>
    );
};

const Styled = styled.div`
    .modal-header {
        display: none !important;
    }
`;
const Header = styled.p`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${colors.black100};
    text-transform: capitalize;
`;
const Paragraph = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 10px;
    color: ${colors.black80};
`;
