import styled from 'styled-components';
import { colors } from '../../common/component';

export const Flexbox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
`;
export const Subheader = styled.h3`
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: ${colors.yellow50};
`;
export const SeeAll = styled.h3`
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    @media (max-width: 600px) {
        font-size: 16px;
    }
`;
