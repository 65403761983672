import { useState, useEffect, useRef,  FC } from "react";
import styled from "styled-components";
import { PinInputProps } from "../../types";
import { colors } from "../colors";


export const PinCodeInput: FC<PinInputProps> = ({
	length = 4,
	setValue,
	onBlur = () => {},
	mask,
	size,
	id = "pin",
	name = "pin",
	...rest
}) => {
	const [input, setInput] = useState<string[]>(new Array(length).fill(""));
	const [activeInputIndex, setActiveInputIndex] = useState<number>(0);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleOnChange = (
		{ target }: React.ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		const { value } = target;
		const newInput: string[] = [...input];
		newInput[index] = value.substring(value.length - 1);

		if (!value) setActiveInputIndex(index - 1);
		else setActiveInputIndex(index + 1);
		// setActiveOtpIndex(currentOtpIndex + 1)
		setInput(newInput);
		if (setValue) setValue(newInput.join(""));
	};

	// const handleOnKeyDown = ({key}:React.KeyboardEvent<HTMLInputElement>,index:number) => {
	//   currentOtpIndex = index
	//   if(key === "Backspace") setActiveOtpIndex(currentOtpIndex - 1)
	// }

	useEffect(() => {
		inputRef.current?.focus();
	}, [activeInputIndex]);
	return (
		<OTPWraper className="d-flex ">
			{input.map((item, index) => {
				let sizes = () => {
					if (size && size === "lg") {
						return "w-12 sm:w-16 h-12 sm:h-16";
					}

					if (size && size === "md") {
						return "w-10 sm:w-14 h-10 sm:h-14";
					}

					return "w-12 h-12";
				};
				return (
					<Input
						key={index}
						id={`${id}-${index}`}
						name={`${name}-${index}`}
						value={item}
						type="text"
						ref={index === activeInputIndex ? inputRef : null}
						className={`${sizes()}  pin-code-input`}
						onChange={(e) => handleOnChange(e, index)}
						onBlur={onBlur}
						placeholder={"_"}
						style={{
							textAlign: "center",
							color: "black",
						}}
						{...rest}
						// onKeyDown={(e)=>handleOnKeyDown(e,index)}
					/>
				);
			})}
		</OTPWraper>
	);
};


const OTPWraper = styled.div`
	width: 100%;
	display: flex;
	column-gap: 10px;
	padding: 10px 0px;
`;
const Input = styled.input`
	width: 100%;
	height: 60px;
	border: 1px solid ${colors.Primary1};
	border-radius: 5px;
	text-align: center;
	font-size: 26px;
	font-weight: 500;
	outline: none;
`;