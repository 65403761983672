import styled from "styled-components";
import { colors } from "../../common/component";

export const Date = styled.p`
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: ${colors.black};
`;
export const ImageWrap = styled.div`
	width: 35px;
	height: 35px;
	border-radius: 50%;
	object-fit: cover;
	overflow: hidden;
	img {
		width: 100%;
	}
`;
export const Gap = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1px;
`;
export const Name = styled.p`
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	margin: 0px 8px;
	color: ${colors.black};
`;
export const TextWrap = styled.div<{ sent?: boolean }>`
	padding: 10px;
	background: ${(props) =>
		props.sent ? "#ebf3ff" : "rgba(100, 210, 233, 0.4);"};
	border: 1px solid rgba(35, 35, 35, 0.02);
	box-shadow: 0px 2px 5px rgba(35, 35, 35, 0.05);
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	border-top-left-radius: ${(props) => (props.sent ? "20px" : "0px")};
	border-top-right-radius: ${(props) => (props.sent ? "0px" : "20px")};
	p {
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		color: ${colors.black100};
	}
`;
export const Time = styled.p`
	font-size: 12px;
	line-height: 15px;
	color: rgba(102, 102, 102, 0.8);
	text-align: right;
`;
export const ChatContainer = styled.div`
	height: calc(100vh - 20vh);
`;
export const Content = styled.div`
	height: calc(100vh - 26vh);
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 0px;
	}
`;
export const ChatWrap = styled.div<{ sent?: boolean }>`
	display: flex;
	justify-content: ${(props) => (props.sent ? `flex-end` : `unset`)};
`;
export const ChatWrap2 = styled.div`
	max-width: 85%;
`;
export const BottomWrap = styled.div`
	background: ${colors.white};
	border: 1px solid #c2c9d1;
	input {
		background: ${colors.gray3};
		border-radius: 18px;
		padding: 5px 10px;
		border: none;
		width: 100%;
	}
`;
export const RadioButtonWrap = styled.div`
	input {
		width: 15px;
		cursor: pointer;
	}
	@media (max-width: 600px) {
		margin: 7px 0px;
	}
`;
export const RadioButtonLabel = styled.label`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: ${colors.black};
`;
