import styled from 'styled-components';
import { TableDropDown } from '../component/tableDropDown';
import { FiMoreVertical } from 'react-icons/fi';
import { DropDownComp2 } from '../component/Ui/selectComp';
import { colors } from '../component';
import currencyPairIcon from '../images/currency_pair_arrow.svg';
import { getDateString, timeFormatter } from './date';
import { useNavigate } from 'react-router-dom';
import { BsDownload } from 'react-icons/bs';
import { currencyFormater } from './helpers';
import { ITrade } from '../../store/tradeApi/interface';
import { ICurrencyPair } from '../../store/currencyPairApi/interface';
import { IAdvert } from '../../store/advertApi/interface';
import { IFaq } from '../../store/faqApi/interface';
import { ISuperMerchant } from '../../store/superMerchantApi/interface';
const parse = require('html-react-parser');

const ImageContainer = styled.div`
    cursor: pointer;
    .imageWrapper {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid ${colors.black40};
        img {
            width: 100%;
        }
    }
`;

const StatusContainer = styled.div<{ status: string }>`
    color: ${({ status }) =>
        status === 'pending'
            ? colors.blue60
            : status === 'active'
            ? colors.Green90
            : status === 'open'
            ? colors.Green90
            : status === 'suspended'
            ? colors.secondary100
            : status === 'disabled'
            ? colors.red
            : colors.red100};
`;

const TransactionContainer = styled.p<{ status: string }>`
    color: ${(props) =>
        props.status === 'Deposite' ? colors.Green100 : colors.blue100};
`;

const CategoryContainer = styled.p<{ status: string }>`
    background: ${(props) =>
        props.status === 'Fiat' ? colors.blue30 : colors.secondary40};
    color: ${colors.black70};
    padding: 5px 14px;
    border-radius: 4px;
`;

const CurrencyPairContainer = styled.div<{ currency: any }>`
    color: ${(props) =>
        props.currency === 'NGN' || 'USD' ? colors.blue60 : '#A86D09'};
`;

const PrimaryColorText = styled.p`
    color: ${colors.Primary1};
`;

const Button = styled.button`
    background: transparent;
    border: none;
    color: ${colors.Primary1};
`;

//user
export const UserTableHeader = (items: any) => {
    const navigate = useNavigate();
    //item
    return [
        {
            name: 'Name',
            selector: (row: any) => (
                <ImageContainer
                    className="d-flex align-items-center"
                    onClick={() => navigate(`view/${row?._id}`)}
                >
                    <span className="imageWrapper me-2">
                        <img src={row.passportPhoto} alt="" />
                    </span>
                    {`${row.firstName} ${row.lastName}`}
                </ImageContainer>
            ),
            minWidth: '200px',
            reorder: true,
            sortable: true,
        },

        {
            name: 'Email',
            selector: (row: { email: any }) => row.email,

            reorder: true,
            sortable: true,
        },

        {
            name: 'Phone',
            selector: (row: { phone: string }) => row.phone,
            width: '200px',

            reorder: true,
            sortable: true,
        },

        {
            name: 'User type',
            selector: (row: any) => row.userType,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Account status',

            selector: (row: any) => (
                <StatusContainer status={row.accountStatus}>
                    {row.accountStatus}
                </StatusContainer>
            ),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Date created',

            selector: (row: any) => getDateString(row.createdAt),

            reorder: true,
            sortable: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={items} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//view user's trade
export const ViewUserTradeTableHeader = () => {
    const navigate = useNavigate();
    //item
    return [
        {
            name: 'Merchant',
            selector: (row: any) => (
                <ImageContainer
                    className="d-flex align-items-center"
                    onClick={() =>
                        navigate(`/dashboard/trade/view/trade-info/${row?._id}`)
                    }
                >
                    <span className="imageWrapper me-2">
                        <img src={row?.merchant?.passportPhoto} alt="" />
                    </span>
                    {`${row?.merchant?.firstName} ${row?.merchant?.lastName}`}
                </ImageContainer>
            ),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Currency pair',
            selector: (row: any) => (
                <div>{`${row?.advert?.currencyPair?.baseCurrency?.name.toUpperCase()}/${row?.advert?.currencyPair?.tradingCurrency?.name.toUpperCase()}`}</div>
            ),
            reorder: true,
            sortable: true,
        },

        {
            name: 'Category',
            selector: (row: any) => (
                <CategoryContainer
                    status={
                        row?.advert?.currencyPair?.baseCurrency?.currencyType
                    }
                >
                    {row?.advert?.currencyPair?.baseCurrency?.currencyType}
                </CategoryContainer>
            ),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Quantity',
            selector: (row: any) => (
                <div>
                    {`${row?.advert?.currencyPair?.tradingCurrency
                        ?.currencySymbol}${currencyFormater(
                        row?.amount / row?.advert?.price,
                    )}`}
                </div>
            ),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Amount',
            selector: (row: any) => (
                <div>
                    {`${row?.advert?.currencyPair?.baseCurrency?.currencySymbol?.toUpperCase()}${currencyFormater(
                        row?.amount,
                    )}`}
                </div>
            ),

            reorder: true,
            sortable: true,
        },

        {
            name: 'Date created',
            selector: (row: any) => getDateString(row.createdAt),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Trade status',
            selector: (row: any) => (
                <Status status={row.tradeStatus}>{row.tradeStatus}</Status>
            ),
            sortable: true,
            reorder: true,
        },
    ];
};

//
export const ViewUserTableHeader = () => {
    //item
    return [
        {
            name: 'Company',
            selector: (row: any) => (
                <ImageContainer className="d-flex align-items-center">
                    <span className="imageWrapper me-2">
                        <img src={row.image} alt="" />
                    </span>
                    {row.company}
                </ImageContainer>
            ),
            width: '200px',
            reorder: true,
            sortable: true,
        },

        {
            name: 'Transaction type',
            selector: (row: any) => (
                <TransactionContainer status={row.transactiontype}>
                    {row.transactiontype}
                </TransactionContainer>
            ),

            reorder: true,
            sortable: true,
        },

        {
            name: 'Amount',
            selector: (row: { amount: any }) => row.amount,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Time',
            selector: (row: { time: any }) => row.time,

            reorder: true,
            sortable: true,
        },

        {
            name: 'Date ',
            selector: (row: { date: any }) => row.date,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',

            selector: (row: { status: any }) => (
                <StatusContainer status={row.status}>
                    {row.status}
                </StatusContainer>
            ),
            sortable: true,
            reorder: true,
        },
    ];
};

//transaction history
export const TransactionHistoryTableHeader = () => {
    //item
    return [
        {
            name: 'Payment method',
            selector: (row: any) => row.paymentMethod,

            reorder: true,
            sortable: true,
        },

        {
            name: 'Transaction type',
            selector: (row: any) => (
                <TransactionContainer status={row.transactionType}>
                    {row.transactionType}
                </TransactionContainer>
            ),

            reorder: true,
            sortable: true,
        },

        {
            name: 'Amount',
            selector: (row: any) => row.amount,

            reorder: true,
            sortable: true,
        },

        {
            name: 'Date ',
            selector: (row: any) => getDateString(row.createdAt),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',

            selector: (row: any) => (
                <StatusContainer status={row.paymentStatus}>
                    {row.paymentStatus}
                </StatusContainer>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: 'Description',

            selector: (row: any) => row.description,
            sortable: true,
            reorder: true,
        },
    ];
};

//advert
export const AdvertTableHeader = (items: any) => {
    const navigate = useNavigate();
    const Status = styled.p<{ status: string }>`
        color: ${(props) =>
            props.status === 'active'
                ? colors.Green90
                : props.status === 'suspended'
                ? colors.yellow
                : colors.red100};
    `;
    //item
    return [
        {
            name: 'Creator',
            selector: (row: any) => (
                <ImageContainer
                    className="d-flex align-items-center"
                    onClick={() =>
                        navigate(`/dashboard/trade-advert/view/${row?._id}`)
                    }
                >
                    <span className="imageWrapper me-2">
                        <img src={row?.user?.passportPhoto} alt="" />
                    </span>
                    {`${row?.user?.firstName} ${row?.user?.lastName}`}
                </ImageContainer>
            ),
            width: '200px',
            reorder: true,
            sortable: true,
        },

        {
            name: 'Category',
            selector: (row: any) => (
                <CategoryContainer
                    status={row?.currencyPair?.baseCurrency?.currencyType}
                >
                    {row?.currencyPair?.baseCurrency?.currencyType}
                </CategoryContainer>
            ),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Currency pair',
            selector: (row: any) => (
                <div>{`${row?.currencyPair?.baseCurrency?.name.toUpperCase()}/${row?.currencyPair?.tradingCurrency?.name.toUpperCase()}`}</div>
            ),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Advert type',
            selector: (row: any) => row?.advertType?.toUpperCase(),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Date ',
            selector: (row: any) => getDateString(row.createdAt),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Advert status',

            selector: (row: any) => (
                <Status status={row?.advertStatus}>{row?.advertStatus}</Status>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={items} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//Trade
export const TradeTableHeader = (items?: any) => {
    const navigate = useNavigate();
    const Status = styled.p<{ status: string }>`
        color: ${(props) =>
            props.status === 'open' || props.status === 'completed'
                ? colors.Green80
                : colors.red100};
    `;
    //item
    return [
        {
            name: 'User',
            selector: (row: any) => (
                <ImageContainer
                    className="d-flex align-items-center"
                    onClick={() => navigate(`view/trade-info/${row?._id}`)}
                >
                    <span className="imageWrapper me-2">
                        <img src={row?.user?.passportPhoto} alt="" />
                    </span>
                    {`${row?.user?.firstName} ${row?.user?.lastName}`}
                </ImageContainer>
            ),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Merchant',
            selector: (row: any) => (
                <ImageContainer
                    className="d-flex align-items-center"
                    onClick={() => navigate(`view/trade-info/${row?._id}`)}
                >
                    <span className="imageWrapper me-2">
                        <img src={row?.merchant?.passportPhoto} alt="" />
                    </span>
                    {`${row?.merchant?.firstName} ${row?.merchant?.lastName}`}
                </ImageContainer>
            ),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Supervisor',
            selector: (row: any) => (
                <ImageContainer
                    className="d-flex align-items-center"
                    onClick={() => navigate(`view/trade-info/${row?._id}`)}
                >
                    <span className="imageWrapper me-2">
                        <img src={row?.partner?.passportPhoto} alt="" />
                    </span>
                    {`${row?.partner?.firstName || '-'} ${
                        row?.user?.partner || '-'
                    }`}
                </ImageContainer>
            ),
            reorder: true,
            sortable: true,
        },

        {
            name: 'Category',
            selector: (row: any) => (
                <CategoryContainer
                    status={
                        row?.advert?.currencyPair?.baseCurrency?.currencyType
                    }
                >
                    {row?.advert?.currencyPair?.baseCurrency?.currencyType}
                </CategoryContainer>
            ),

            reorder: true,
            sortable: true,
        },

        {
            name: 'Currency pair',
            selector: (row: any) => (
                <>
                    {row?.advert?.currencyPair?.baseCurrency?.name.toUpperCase()}
                    /
                    {row?.advert?.currencyPair?.tradingCurrency?.name.toUpperCase()}
                </>
            ),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Date ',
            selector: (row: any) => row.createdAt.split('T')[0],
            reorder: true,
            sortable: true,
        },
        {
            name: 'Trade status',

            selector: (row: any) => (
                <Status status={row.tradeStatus}>{row.tradeStatus}</Status>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: '',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={items} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            width: '50px',
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//Dashboard Trade
export const DashboardTradeTableHeader = () => {
    const navigate = useNavigate();
    const Status = styled.p<{ status: string }>`
        color: ${(props) =>
            props.status === 'open' || props.status === 'completed'
                ? colors.Green80
                : colors.red100};
    `;
    //item
    return [
        {
            name: 'User',
            selector: (row: any) => (
                <ImageContainer
                    className="d-flex align-items-center"
                    onClick={() =>
                        navigate(`/dashboard/trade/view/trade-info/${row?._id}`)
                    }
                >
                    <span className="imageWrapper me-2">
                        <img src={row?.user?.passportPhoto} alt="" />
                    </span>
                    {`${row?.user?.firstName} ${row?.user?.lastName}`}
                </ImageContainer>
            ),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Merchant',
            selector: (row: any) => (
                <ImageContainer
                    className="d-flex align-items-center"
                    onClick={() =>
                        navigate(`/dashboard/trade/view/trade-info/${row?._id}`)
                    }
                >
                    <span className="imageWrapper me-2">
                        <img src={row?.merchant?.passportPhoto} alt="" />
                    </span>
                    {`${row?.merchant?.firstName} ${row?.merchant?.lastName}`}
                </ImageContainer>
            ),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Partner',
            selector: (row: any) => (
                <ImageContainer
                    className="d-flex align-items-center"
                    onClick={() =>
                        navigate(`/dashboard/trade/view/trade-info/${row?._id}`)
                    }
                >
                    <span className="imageWrapper me-2">
                        <img src={row?.partner?.passportPhoto} alt="" />
                    </span>
                    {`${row?.partner?.firstName || '-'} ${
                        row?.partner?.lastName || '-'
                    }`}
                </ImageContainer>
            ),
            reorder: true,
            sortable: true,
        },

        {
            name: 'Category',
            selector: (row: any) => (
                <CategoryContainer
                    status={
                        row?.advert?.currencyPair?.baseCurrency?.currencyType
                    }
                >
                    {row?.advert?.currencyPair?.baseCurrency?.currencyType}
                </CategoryContainer>
            ),

            reorder: true,
            sortable: true,
        },

        {
            name: 'Currency pair',
            selector: (row: any) => (
                <>
                    {row?.advert?.currencyPair?.baseCurrency?.name.toUpperCase()}
                    /
                    {row?.advert?.currencyPair?.tradingCurrency?.name.toUpperCase()}
                </>
            ),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Date ',
            selector: (row: any) => getDateString(row.createdAt),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Trade status',

            selector: (row: any) => (
                <Status status={row.tradeStatus}>{row.tradeStatus}</Status>
            ),
            sortable: true,
            reorder: true,
        },
    ];
};

//Subscription
export const SubscribeTableHeader = (items: any) => {
    //item
    return [
        {
            name: 'Email',
            selector: (row: any) => row.email,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Date created',
            selector: (row: any) => row.createdAt.split('T')[0],
            reorder: true,
            sortable: true,
        },
        {
            name: 'Is Active?',
            selector: (row: { isActive: any }) => (
                <ConfigStatus status={row.isActive}>
                    {row.isActive ? 'Active' : 'InActive'}
                </ConfigStatus>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: 'Is Subscribed?',
            selector: (row: { isSubscribed: any }) => (
                <ConfigStatus status={row.isSubscribed}>
                    {row.isSubscribed ? 'Yes' : 'No'}
                </ConfigStatus>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={items} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//contact-us
export const ContactUsTableHeader = (items: any) => {
    //item
    return [
        {
            name: 'Name',
            selector: (row: any) => `${row.firstName} ${row.lastName}`,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row: any) => row.email,
            reorder: true,
            sortable: true,
        },
        // {
        //     name: 'Phone no',
        //     selector: (row: any) => row.phone,
        //     width: '200px',
        //     reorder: true,
        //     sortable: true,
        // },

        {
            name: 'Message',
            selector: (row: any) => row.message,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Date created',
            selector: (row: any) => getDateString(row.createdAt),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: { status: any }) => (
                <Status status={row.status}>{row.status}</Status>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={items} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//Frequent Questions
export const FaqTableHeader = (items: any) => {
    //item
    return [
        {
            name: 'Question',
            selector: (row: any) => row.question,
            width: '300px',
            reorder: true,
            sortable: true,
        },
        {
            name: 'Answer',
            selector: (row: any) => {
                return parse(row.answer);
            },
            width: '300px',
            reorder: true,
            sortable: true,
        },
        {
            name: 'Priority',
            selector: (row: any) => row.priority,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Date created',
            selector: (row: any) => row.createdAt.split('T')[0],
            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: any) => (
                <ConfigStatus status={row.isActive}>
                    {row.isActive ? 'Active' : 'InActive'}
                </ConfigStatus>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={items} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//Banks
export const BankTableHeader = (items: any) => {
    //item
    return [
        {
            name: 'Bank Name',
            selector: (row: any) => row.bankName,
            width: '300px',
            reorder: true,
            sortable: true,
        },
        {
            name: 'Bank Code',
            selector: (row: any) => {
                return row.bankCode;
            },
            width: '300px',
            reorder: true,
            sortable: true,
        },
        {
            name: 'Priority',
            selector: (row: any) => row.order,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Date created',
            selector: (row: any) => row.createdAt.split('T')[0],
            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: any) => (
                <ConfigStatus status={row.isActive}>
                    {row.isActive ? 'Active' : 'InActive'}
                </ConfigStatus>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={items} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//app version
export const AppVersionTableHeader = (item: any) => {
    //item
    return [
        {
            name: 'App version',
            selector: (row: any) => row.versionName,
            width: '200px',
            reorder: true,
            sortable: true,
        },

        {
            name: 'Date released',
            selector: (row: any) => getDateString(row.createdAt),

            reorder: true,
            sortable: true,
        },

        {
            name: 'Update Type',
            selector: (row: any) => row.updateType,

            reorder: true,
            sortable: true,
        },

        {
            name: 'App type',
            selector: (row: any) => row.appType,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Store url',
            minWidth: '300px',
            selector: (row: any) => row.storeURL,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Version number',
            selector: (row: any) => row.versionNumber,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Build number',
            selector: (row: any) => row.buildNumber,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',

            selector: (row: { status: any }) => (
                <Status status={row.status}>
                    {row.status ? 'Active' : 'Inactive'}
                </Status>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={item} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

// referralCampaign
export const ReferralCampaignTableHeader = (item: any) => {
    const StatusContainer = styled.p<{ status: string }>`
        color: ${(props) => (props.status ? colors.Green90 : colors.red100)};
    `;

    return [
        {
            name: 'Referral Campaign Name',
            selector: (row: any) => row.referralCampaignName,
            width: '300px',
            reorder: true,
            sortable: true,
        },
        {
            name: 'Referral Amount',
            selector: (row: any) => (
                <div>{`₦${currencyFormater(row.amount)}`}</div>
            ),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Date',
            selector: (row: any) => getDateString(row.createdAt),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: { status: any }) => (
                <StatusContainer status={row.status}>
                    {row.status ? 'Active' : 'Inactive'}
                </StatusContainer>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={item} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//configuration
const Status = styled.p<{ status: string | boolean }>`
    color: ${(props) =>
        props.status === 'open'
            ? colors.Green90
            : props.status === false
            ? colors.red100
            : props.status === 'blocked'
            ? colors.red100
            : props.status === 'pending'
            ? colors.yellow
            : props.status !== 'open'
            ? colors.Green90
            : colors.red100};
`;

export const ConfigStatus = styled.p<{ status: boolean }>`
    color: ${(props) =>
        props.status !== false ? colors.Green90 : colors.red100};
`;

export const ConfigurationTableHeader = (item: any) => {
    //item
    return [
        {
            name: 'Partner charge',
            selector: (row: any) => row.partnerChargePerTrade,
            reorder: true,
            sortable: true,
        },

        {
            name: 'Service charge',
            selector: (row: any) => row.serviceChargeOnPartnerCharge,

            reorder: true,
            sortable: true,
        },

        {
            name: 'Support number',
            selector: (row: any) => row.supportNumber,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Creation date',
            selector: (row: any) => row.createdAt.split('T')[0],

            reorder: true,
            sortable: true,
        },
        {
            name: 'Updated date',
            selector: (row: any) => row.updatedAt.split('T')[0],

            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',

            selector: (row: any) => (
                <ConfigStatus status={row.isActive}>
                    {row.isActive ? 'Active' : 'Inactive'}
                </ConfigStatus>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={item} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//currency
export const CurrencyTableHeader = (items: any) => {
    //item
    return [
        {
            name: 'Currency name',
            selector: (row: any) => row.name,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Currency type',
            selector: (row: any) => row.currencyType,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Currency symbol',
            selector: (row: any) => row.currencySymbol,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Image',
            selector: (row: any) => (
                <ImageContainer className="d-flex align-items-center">
                    <span className="imageWrapper me-2">
                        <img src={row.imageUrl} alt="" />
                    </span>
                </ImageContainer>
            ),
            minWidth: '100px',
            reorder: true,
            sortable: true,
        },
        {
            name: 'Date created',
            selector: (row: any) => row.createdAt.split('T')[0],
            sortable: true,
            reorder: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={items} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//currency pair
export const CurrencyPairTableHeader = (item: any) => {
    //item
    return [
        {
            name: 'Base Currency',
            selector: (row: any) => row?.baseCurrency?.name,
            reorder: true,
            sortable: true,
        },

        {
            name: 'Trading Currency',
            selector: (row: any) => row?.tradingCurrency?.name,

            reorder: true,
            sortable: true,
        },

        {
            name: 'Currency pair',
            selector: (row: any) => (
                <CurrencyPairContainer currency={row.baseCurrency.name}>
                    {row?.baseCurrency?.name}{' '}
                    <img src={currencyPairIcon} alt="" />{' '}
                    {row?.tradingCurrency?.name}
                </CurrencyPairContainer>
            ),

            reorder: true,
            sortable: true,
        },

        {
            name: 'Category',
            selector: (row: any) => (
                <CategoryContainer status={row.baseCurrency.currencyType}>
                    {row?.baseCurrency?.currencyType}
                </CategoryContainer>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: 'Date created',
            selector: (row: any) => row.createdAt.split('T')[0],

            sortable: true,
            reorder: true,
        },
        {
            name: 'Status',
            selector: (row: any) => (
                <Status status={row.status}>
                    {row.status ? 'Active' : 'Inactive'}
                </Status>
            ),

            sortable: true,
            reorder: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={item} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//career
export const CareerTableHeader = (item: any) => {
    const navigate = useNavigate();
    //item
    return [
        {
            name: 'Job title',
            selector: (row: any) => (
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(`view/${row?._id}`)}
                >
                    {row.role}
                </div>
            ),
            reorder: true,
            sortable: true,
        },

        {
            name: 'Location',
            selector: (row: any) => row.location,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Schedule',
            selector: (row: any) => row.schedule,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: any) => (
                <Status status={row.status}>
                    {row.status ? 'Active' : 'Inactive'}
                </Status>
            ),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Creation date',
            selector: (row: any) => getDateString(row.createdAt),

            reorder: true,
            sortable: true,
        },

        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={item} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//applicants
export const ApplicantsTableHeader = (item: any) => {
    return [
        {
            name: 'Applicant’s name',
            selector: (row: any) => `${row.firstName} ${row.lastName}`,
            reorder: true,
            sortable: true,
        },

        {
            name: 'Email',
            selector: (row: any) => row.email,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Documents',
            selector: (row: any) => (
                <PrimaryColorText>
                    <a href={row.applicantCV} download="IBX Job Applicant CV">
                        <Button>
                            <BsDownload
                                color={`${colors.Primary1}`}
                                size={13}
                            />{' '}
                            {row.applicantCV}
                        </Button>
                    </a>
                </PrimaryColorText>
            ),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: any) => (
                <Status status={row.isAccepted}>
                    {row.isAccepted ? 'Accepted' : 'Not accepted'}
                </Status>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: 'Application date',
            selector: (row: any) => getDateString(row.createdAt),
            reorder: true,
            sortable: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={item} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//partner data
export const PartnerRequestHeader = (items: any) => {
    const navigate = useNavigate();
    const StatusContainer = styled.div<{ status: string }>`
        color: ${(props) =>
            props.status === 'approved'
                ? colors.Green90
                : props.status === 'pending'
                ? colors.yellow
                : colors.red100};
    `;
    //item
    return [
        {
            name: 'Name',
            selector: (row: any) => (
                <ImageContainer
                    className="d-flex align-items-center"
                    onClick={() => navigate(`view/${row?._id}`)}
                >
                    <span className="imageWrapper me-2">
                        <img src={row?.user?.passportPhoto} alt="" />
                    </span>
                    {`${row?.user?.firstName} ${row?.user?.lastName}`}
                </ImageContainer>
            ),
            minWidth: '200px',
            reorder: true,
            sortable: true,
        },

        {
            name: 'Email',
            selector: (row: any) => row?.user?.email,
            reorder: true,
            sortable: true,
        },

        {
            name: 'Means of identification',
            selector: (row: any) =>
                row?.userMeansOfIdentification[0]?.idCardType,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Collateral',
            selector: (row: any) => row?.collateral[0]?.item,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Date created',
            selector: (row: any) => getDateString(row?.createdAt),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: any) => (
                <StatusContainer status={row?.status}>
                    {row?.status}
                </StatusContainer>
            ),

            reorder: true,
            sortable: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={items} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//merchant data
export const MerchantRequestHeader = (items: any) => {
    const navigate = useNavigate();
    const StatusContainer = styled.div<{ status: string }>`
        color: ${(props) =>
            props.status === 'pending'
                ? colors.yellow
                : props.status === 'approved'
                ? colors.Green90
                : colors.red100};
    `;
    //item
    return [
        {
            name: 'Name',
            selector: (row: any) => (
                <ImageContainer
                    className="d-flex align-items-center"
                    onClick={() => navigate(`view/${row?._id}`)}
                >
                    <span className="imageWrapper me-2">
                        <img src={row?.user?.passportPhoto} alt="" />
                    </span>
                    {`${row?.user?.firstName} ${row?.user?.lastName}`}
                </ImageContainer>
            ),
            minWidth: '200px',
            reorder: true,
            sortable: true,
        },

        {
            name: 'Email',
            selector: (row: any) => row?.user?.email,
            reorder: true,
            sortable: true,
        },

        {
            name: 'Means of identification',
            selector: (row: any) => row?.meansOfIdentification[0]?.idCardType,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Address',
            selector: (row: any) => row?.address,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Date created',
            selector: (row: any) => getDateString(row?.createdAt),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: any) => (
                <StatusContainer status={row?.status}>
                    {row?.status}
                </StatusContainer>
            ),

            reorder: true,
            sortable: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={items} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//withdrawal data
export const WithdrawalRequestHeader = (items: any) => {
    const navigate = useNavigate();
    const StatusContainer = styled.div<{ status: string }>`
        color: ${(props) =>
            props.status === 'pending'
                ? colors.yellow
                : props.status === 'paid'
                ? colors.Green90
                : colors.red100};
    `;
    //item
    return [
        {
            name: 'Name',
            selector: (row: any) => (
                <ImageContainer
                    className="d-flex align-items-center"
                    onClick={() => navigate(`view/${row?._id}`)}
                >
                    <span className="imageWrapper me-2">
                        <img src={row?.user?.passportPhoto} alt="" />
                    </span>
                    {`${row?.user?.firstName} ${row?.user?.lastName}`}
                </ImageContainer>
            ),
            minWidth: '200px',
            reorder: true,
            sortable: true,
        },

        {
            name: 'Email',
            selector: (row: any) => row?.user?.email,
            reorder: true,
            sortable: true,
        },

        {
            name: 'Wallet Type',
            selector: (row: any) => row?.wallet?.walletType,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Bank',
            selector: (row: any) => row?.bank?.bankName,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Amount',
            selector: (row: any) => row?.amount,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Account Type',
            selector: (row: any) => row?.bank?.accountType,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Date created',
            selector: (row: any) => getDateString(row?.createdAt),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: any) => (
                <StatusContainer status={row?.requestStatus}>
                    {row?.requestStatus}
                </StatusContainer>
            ),

            reorder: true,
            sortable: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={items} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

//kyc data
export const KYCHeader = (items: any) => {
    const navigate = useNavigate();
    const StatusContainer = styled.div<{ status: string }>`
        color: ${(props) =>
            props.status === 'pending'
                ? colors.yellow
                : props.status === 'approved'
                ? colors.Green90
                : colors.red100};
    `;
    //item
    return [
        {
            name: 'Name',
            selector: (row: IKyc) => (
                <ImageContainer
                    className="d-flex align-items-center"
                    onClick={() => navigate(`view/${row?._id}`)}
                >
                    <span className="imageWrapper me-2">
                        <img src={row?.user?.passportPhoto} alt="" />
                    </span>
                    {`${row?.user?.firstName} ${row?.user?.lastName}`}
                </ImageContainer>
            ),
            minWidth: '200px',
            reorder: true,
            sortable: true,
        },

        {
            name: 'Email',
            selector: (row: IKyc) => row?.user?.email,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Bvn Verification',
            selector: (row: IKyc) => (
                <StatusContainer status={row?.bvnVerification?.status}>
                    {row?.bvnVerification?.status ?? '--'}
                </StatusContainer>
            ),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Identity Verification',
            selector: (row: IKyc) => (
                <StatusContainer status={row?.identityVerification?.status}>
                    {row?.identityVerification?.status ?? '--'}
                </StatusContainer>
            ),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Address Verification',
            selector: (row: IKyc) => (
                <StatusContainer status={row?.addressVerification?.status}>
                    {row?.addressVerification?.status ?? '--'}
                </StatusContainer>
            ),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Date created',
            selector: (row: IKyc) => getDateString(row?.createdAt),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: IKyc) => (
                <StatusContainer status={row?.status}>
                    {row?.status}
                </StatusContainer>
            ),

            reorder: true,
            sortable: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: IKyc) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={items} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

// Super Merchant
export const SuperHeader = (items: any) => {
    const navigate = useNavigate();
    const StatusContainer = styled.div<{ status: string }>`
        color: ${(props) =>
            props.status === 'pending'
                ? colors.yellow
                : props.status === 'approved'
                ? colors.Green90
                : colors.red100};
    `;
    //item
    return [
        {
            name: 'Name',
            selector: (row: any) => (
                <ImageContainer
                    className="d-flex align-items-center"
                    onClick={() => navigate(`view/${row?._id}`)}
                >
                    <span className="imageWrapper me-2">
                        <img src={row?.user?.passportPhoto} alt="" />
                    </span>
                    {`${row?.user?.firstName} ${row?.user?.lastName}`}
                </ImageContainer>
            ),
            minWidth: '150px',
            reorder: true,
            sortable: true,
        },

        {
            name: 'Email',
            selector: (row: any) => row?.user?.email,
            reorder: true,
            sortable: true,
        },

        {
            name: 'Date created',
            selector: (row: any) => getDateString(row?.createdAt),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: any) => (
                <StatusContainer status={row?.status}>
                    {row?.status}
                </StatusContainer>
            ),

            reorder: true,
            sortable: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={items} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

// Exchange rate
export const ExchangeRatesTableHeader = (items: any) => {
    //item
    return [
        {
            name: 'Currency Pair',
            selector: (row: any) =>
                `${row?.currencyPair?.tradingCurrency?.name.toUpperCase()}/${row?.currencyPair?.baseCurrency?.name.toUpperCase()}`,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Buy Price',
            selector: (row: any) => row.buyPrice,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Buy Trend',
            selector: (row: any) => row.buyTrend,
            reorder: true,
            sortable: true,
        },

        {
            name: 'Sell Price',
            selector: (row: any) => row.sellPrice,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Sell Trend',
            selector: (row: any) => row.sellTrend,
            reorder: true,
            sortable: true,
        },
        {
            name: 'Created At',
            width: '250px',
            selector: (row: any) =>
                row.createdAt.split('T')[0] +
                '~' +
                timeFormatter(row.createdAt),
            sortable: true,
            reorder: true,
        },
        {
            name: 'Time Period',
            width: '250px',
            selector: (row: any) => row?.timePeriod,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Status',
            selector: (row: any) => (
                <ConfigStatus status={row.isActive}>
                    {row.isActive ? 'Active' : 'InActive'}
                </ConfigStatus>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={items} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

// FOR TRADE
export const cleanUpTradeDataForPdf = (
    _dataInQuestion: ITrade[] | undefined,
) => {
    if (_dataInQuestion && _dataInQuestion.length > 0) {
        let result = _dataInQuestion.map((_item) => {
            return {
                ..._item,
                firstName: _item?.user?.firstName,
                lastName: _item?.user?.lastName,
                category:
                    _item?.advert?.currencyPair?.baseCurrency?.currencyType,
                currency: _item?.advert?.currencyPair?.baseCurrency?.name,
            };
        });

        return result;
    }

    return [];
};

// FOR CURRENCYPAIR
export const cleanUpCurrencyDataForPdf = (
    dataInQuestion: ICurrencyPair[] | undefined,
) => {
    if (dataInQuestion && dataInQuestion.length > 0) {
        let result = dataInQuestion.map((item) => {
            return {
                ...item,
                baseCurrency: item?.baseCurrency?.name,
                tradingCurrency: item?.tradingCurrency?.name,
                category: item?.baseCurrency?.currencyType,
            };
        });

        return result;
    }

    return [];
};

// TRADE ADVERT
export const cleanUpTradeAdvertDataForPdf = (
    dataInQuestion: IAdvert[] | undefined,
) => {
    if (dataInQuestion && dataInQuestion.length > 0) {
        let result = dataInQuestion.map((item) => {
            return {
                ...item,
                firstName: item?.user?.firstName,
                lastName: item?.user?.lastName,
                category: item?.currencyPair?.baseCurrency?.currencyType,
                amount: item?.price,
                advertType: item?.advertType,
            };
        });

        return result;
    }

    return [];
};

// SUPER MERCHANT
export const cleanUpSuperMerchantDataForPdf = (
    dataInQuestion: ISuperMerchant[] | undefined,
) => {
    if (dataInQuestion && dataInQuestion.length > 0) {
        let result = dataInQuestion.map((item) => {
            return {
                ...item,
                firstName: item?.user?.firstName,
                lastName: item?.user?.lastName,
                about: item?.about,
                additionalInformation: item?.additionalInformation,
            };
        });

        return result;
    }

    return [];
};

// FAQ
function removeHtmlTags(input: string) {
    return input.replace(/<[^>]+>/g, ''); // This regex removes all HTML tags
}

export const cleanUpFaqForPdf = (dataInQuestion: IFaq[] | undefined) => {
    if (dataInQuestion && dataInQuestion.length > 0) {
        let result = dataInQuestion.map((item) => {
            const answerText =
                typeof item?.answer === 'string' ? item?.answer : '';
            const plainAnswer = removeHtmlTags(answerText);

            return {
                ...item,
                question: item?.question,
                answer: plainAnswer,
            };
        });

        return result;
    }

    return [];
};

//WIKI TOPIC
export const TopicHeader = (item: any) => {
    const navigate = useNavigate();
    //item
    return [
        {
            name: 'Topic title',
            selector: (row: any) => (
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(`view/${row?._id}`)}
                >
                    {parse(row.title)}
                </div>
            ),
            reorder: true,
            sortable: true,
        },

        {
            name: 'Status',
            selector: (row: any) => (
                <Status status={row.status}>
                    {row.status ? 'Active' : 'Inactive'}
                </Status>
            ),

            reorder: true,
            sortable: true,
        },

        {
            name: 'Priority',
            selector: (row: any) => row.priority,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Creation date',
            selector: (row: any) => getDateString(row.createdAt),

            reorder: true,
            sortable: true,
        },

        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={item} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

export const SubTopicHeader = (item: any) => {
    const navigate = useNavigate();
    //item
    return [
        {
            name: 'Topic title',
            selector: (row: any) => (
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(`view/${row?._id}`)}
                >
                    {parse(row?.topic?.title)}
                </div>
            ),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Subtopic title',
            selector: (row: any) => (
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(`view/${row?._id}`)}
                >
                    {parse(row.title)}
                </div>
            ),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: any) => (
                <Status status={row.status}>
                    {row.status ? 'Active' : 'Inactive'}
                </Status>
            ),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Priority',
            selector: (row: any) => row.priority,

            reorder: true,
            sortable: true,
        },
        {
            name: 'Creation date',
            selector: (row: any) => getDateString(row.createdAt),

            reorder: true,
            sortable: true,
        },

        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={item} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};

export const ContentHeader = (item: any) => {
    const navigate = useNavigate();
    return [
        {
            name: 'Topic title',
            selector: (row: any) => (
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(`view/${row?._id}`)}
                >
                    {parse(row?.topic?.title)}
                </div>
            ),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Subtopic title',
            selector: (row: any) => (
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(`view/${row?._id}`)}
                >
                    {parse(row.subTopic?.title)}
                </div>
            ),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Content title',
            selector: (row: any) => (
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(`view/${row?._id}`)}
                >
                    {parse(row.content)}
                </div>
            ),
            reorder: true,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: any) => (
                <Status status={row.status}>
                    {row.status ? 'Active' : 'Inactive'}
                </Status>
            ),

            reorder: true,
            sortable: true,
        },
        {
            name: 'Creation date',
            selector: (row: any) => getDateString(row.createdAt),

            reorder: true,
            sortable: true,
        },

        {
            name: '',
            width: '50px',
            cell: (row: any) => (
                <DropDownComp2
                    arrayComp={<TableDropDown Items={item} row={row} />}
                    input="name"
                    DropDownText={
                        <span style={{ cursor: 'pointer' }}>
                            <FiMoreVertical />
                        </span>
                    }
                />
            ),
            selector: (row: { runtime: any }) => row.runtime,
            sortable: true,
            right: true,
            reorder: true,
        },
    ];
};
