import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { PasswordInputComp, TextInputComp } from '../../common/component';
import { CustomButton } from '../../common/component/button';
import { showSuccessToast, showErrorToast } from '../../common/utils/toast';
import { useChangePasswordMutation } from '../../store/auth/authApi';
import { useAppSelector } from '../../store/hooks';

export const ChangePassword = () => {
    const [changePassword, { isLoading }] = useChangePasswordMutation();

    const { user } = useAppSelector((state) => state.auth);

    const changeUserPassword = useForm({
        defaultValues: {
            // email: user?.email,
            oldPassword: '',
            newPassword: '',
        },
    });
    const { handleSubmit, control } = changeUserPassword;

    const onChangePassword = (value: {
        // email: string;
        oldPassword: string;
        newPassword: string;
    }) => {
        let payload = {
            email: user?.email,
            oldPassword: value.oldPassword,
            newPassword: value.newPassword,
        };
        changePassword(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };
    return (
        <div>
            <form onSubmit={handleSubmit(onChangePassword)}>
                <Container className="d-flex flex-column gap-3 py-4 px-sm-5 ">
                    {/* <Controller
						name="email"
						control={control}
						rules={{
							required: "Email is required",
						}}
						render={({ field: { value, onChange }, formState: { errors } }) => {
							const errorMessage = errors?.email?.message;
							return (
								<TextInputComp
									labelText="Email Address"
									placeholder="Email Address"
									inputClassName={"py-2"}
									name="firstname"
									{...{ value, onChange, errors: [errorMessage] }}
								/>
							);
						}}
					/> */}
                    <Controller
                        name="oldPassword"
                        control={control}
                        rules={{
                            required: 'Old password is required',
                        }}
                        render={({
                            field: { value, onChange },
                            formState: { errors },
                        }) => {
                            const errorMessage = errors?.oldPassword?.message;
                            return (
                                <PasswordInputComp
                                    labelText="Old Password"
                                    type="password"
                                    placeholder="Enter old password"
                                    inputClassName={'py-2'}
                                    name="oldPassword"
                                    {...{
                                        value,
                                        onChange,
                                        errors: [errorMessage],
                                    }}
                                />
                            );
                        }}
                    />
                    <Controller
                        name="newPassword"
                        control={control}
                        rules={{
                            required: 'New password is required',
                        }}
                        render={({
                            field: { value, onChange },
                            formState: { errors },
                        }) => {
                            const errorMessage = errors?.newPassword?.message;
                            return (
                                <PasswordInputComp
                                    labelText="New Password"
                                    type="password"
                                    placeholder="Enter new password"
                                    inputClassName={'py-2'}
                                    name="newPassword"
                                    {...{
                                        value,
                                        onChange,
                                        errors: [errorMessage],
                                    }}
                                />
                            );
                        }}
                    />
                    <div className="d-flex justify-content-center">
                        <CustomButton
                            className="py-3 mt-4"
                            text={isLoading ? 'Loading...' : 'Save changes'}
                            onClick={() => {}}
                        />
                    </div>
                </Container>
            </form>
        </div>
    );
};
const Container = styled.div`
    width: 40%;
    @media (max-width: 1024px) {
        width: 50%;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`;
