import {
    BarLine,
    ButtonContainer,
    Container,
    InputWrap,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    DeleteModal,
    Loader,
    PdfDownloader,
    SelectComp,
} from '../../common/component';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { SubscribeTableHeader } from '../../common/utils/dataUtils';
import { BsEye } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as SupportIcon } from '../../common/images/subscription_icon.svg';
import {
    useGetAllSubscribeQuery,
    useDeleteSubscribeMutation,
    useSubscribeStatsQuery,
} from '../../store/subscribeApi';
import { useEffect, useState } from 'react';
import { ISubscribe } from '../../store/subscribeApi/interface';
import { showErrorToast, showSuccessToast } from '../../common/utils/toast';

export const TotalSubscription = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropDownTerm, setDropDownTerm] = useState('');
    const [newArray, setNewArray] = useState<ISubscribe[] | any>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const [totalSubscriptionData, setTotalSubscriptionData] =
        useState<string>('');
    const [deleteTotalSubscriptionItem, setDeleteTotalSubscriptionItem] =
        useState<boolean>(false);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: allContants,
        isLoading,
        isFetching,
    } = useGetAllSubscribeQuery(
        { ...paginate, email: searchTerm },
        {
            refetchOnMountOrArgChange: true,
        },
    );
    const { data: subscribeStats } = useSubscribeStatsQuery();
    const [deleteSubscribe, { isLoading: isDeleting }] =
        useDeleteSubscribeMutation();

    console.log('allContants: ', allContants);

    useEffect(() => {
        setTotalRows(allContants?.pagination?.totalDocumentCount as number);
    }, [allContants?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allContants?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const handleConfirmDelete = () => {
        onHandleDelete(totalSubscriptionData);
        setDeleteTotalSubscriptionItem(false);
    };

    const Items = [
        {
            name: 'Delete item',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: any) => {
                setDeleteTotalSubscriptionItem((prev) => !prev);
                setTotalSubscriptionData(data?._id);
            },
        },
    ];

    const onHandleDelete = (id: string) => {
        deleteSubscribe(id)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowDeleteModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        {
            label: 'Email',
            key: 'email',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        {
            header: 'Email',
            dataKey: 'email',
        },
    ];

    return (
        <Container>
            {isLoading || isDeleting ? <Loader /> : null}
            <Header>News Letter</Header>
            <Grid>
                <Counter
                    counter_title="Total Subscribers"
                    count={subscribeStats?.data?.totalSubscribed || 0}
                    iconFill="red"
                    iconBG="red"
                >
                    <SupportIcon />
                </Counter>
                <Counter
                    counter_title="Total Active Subscribers"
                    count={subscribeStats?.data?.activeNewsletter || 0}
                    iconFill="green"
                    iconBG="green"
                >
                    <SupportIcon />
                </Counter>
                <Counter
                    counter_title="Total Deleted Subscribers"
                    count={subscribeStats?.data?.deletedNewsletter || 0}
                    iconFill="blue"
                    iconBG="blue"
                >
                    <SupportIcon />
                </Counter>
                <Counter
                    counter_title="Total Unsubscribers"
                    count={subscribeStats?.data?.totalUnsubscribed || 0}
                    iconFill="red"
                    iconBG="red"
                >
                    <SupportIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={9}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <InputWrap>
                        <img src={SearchIcon} alt="" />
                        <FormControl
                            aria-label="Search"
                            placeholder="Search by email"
                            className="border-start-0 border-bottom-0 border-top-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputWrap>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'CONTACT US RECORD'}
                            {...{
                                columns: headersPdf,
                                body: allContants?.data,
                            }}
                        />
                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={allContants?.data}
                        />
                    </ButtonContainer>
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={SubscribeTableHeader(Items)}
                    data={allContants?.data as ISubscribe[]}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>
            <DeleteModal
                showDeleteModal={deleteTotalSubscriptionItem}
                cancelDelete={() => setDeleteTotalSubscriptionItem(false)}
                confirmDelete={handleConfirmDelete}
                deleteItem="Subscription"
                isDeleting={isDeleting}
            />
        </Container>
    );
};
