import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../api";
import { Response } from "../genericInterface";
import { IBank, ITransaction, ITransactionStats, IWallet } from "./interface";
import { paginatedResponse } from "../paginatedInterface";

export const transactionApi = createApi({
	reducerPath: "transactionApi",
	baseQuery: baseQuery,
	tagTypes: ["transaction"],
	endpoints: (builder) => ({
		getUserWallet: builder.query<
			paginatedResponse<IWallet[]>,
			{ userId: string; perPage: number; currentPage: number }
		>({
			query: ({ perPage, userId, currentPage }) => ({
				url: `/account/api/v1/wallet/getUserWallets/${userId}?perPage=${perPage}&currentPage=${currentPage}`,
				method: "GET",
			}),
		}),
		getWalletTransaction: builder.query<
			Response<ITransaction[]>,
			{ userId: string; walletId: string }
		>({
			query: ({ userId, walletId }) => ({
				url: `/account/api/v1/wallet/walletTransactionHistory/${userId}/${walletId}`,
				method: "GET",
			}),
		}),
		getUserBank: builder.query<Response<IBank[]>, string>({
			query: (query) => ({
				url: `/account/api/v1/bank/getUserBank/${query}`,
				method: "GET",
			}),
		}),
		getAllTransaction: builder.query<
			paginatedResponse<ITransaction[]>,
			{ perPage: number; currentPage: number }
		>({
			query: ({ perPage, currentPage }) => ({
				url: `/account/api/v1/transactions?perPage=${perPage}&currentPage=${currentPage}`,
				method: "GET",
			}),
		}),
		getUserTransaction: builder.query<
			paginatedResponse<ITransaction[]>,
			{ userId: string; perPage: number; currentPage: number }
		>({
			query: ({ perPage, userId, currentPage }) => ({
				url: `/account/api/v1/transactions/getUserTransactions/${userId}?perPage=${perPage}&currentPage=${currentPage}`,
				method: "GET",
			}),
		}),
		getTransactionStats: builder.query<Response<ITransactionStats>, void>({
			query: () => ({
				url: `/account/api/v1/transactions/a/stats`,
				method: "GET",
			}),
		}),
	}),
});

export const {
	useGetUserWalletQuery,
	useGetWalletTransactionQuery,
	useGetUserBankQuery,
	useGetAllTransactionQuery,
	useGetTransactionStatsQuery,
	useGetUserTransactionQuery,
} = transactionApi;
