import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { IApplicant, IJob, IJobStat } from './interface';
import { paginatedResponse } from '../paginatedInterface';

export const jobApi = createApi({
    reducerPath: 'jobApi',
    baseQuery: baseQuery,
    tagTypes: ['job', 'applicant'],
    endpoints: (builder) => ({
        addJob: builder.mutation<
            Response<IJob>,
            {
                role: string;
                title: string;
                description: string;
                location: string;
                schedule: string;
                category: string;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/jobs/create`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['job'],
        }),
        updateJob: builder.mutation<
            Response<IJob>,
            {
                role: string;
                title: string;
                description: string;
                location: string;
                schedule: string;
                category: string;
                jobsId: string;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/jobs/update-job`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['job'],
        }),
        getAllJobs: builder.query<
            paginatedResponse<IJob[]>,
            { perPage: number; currentPage: number }
        >({
            query: ({ perPage, currentPage }) => ({
                url: `/user/api/v1/jobs?perPage=${perPage}&currentPage=${currentPage}`,
                method: 'GET',
            }),
            providesTags: ['job'],
        }),
        getSingleJobs: builder.query<Response<IJob>, string>({
            query: (query) => ({
                url: `/user/api/v1/jobs/get-job-by-id/${query}`,
                method: 'GET',
            }),
            providesTags: ['job'],
        }),
        getAllJobApplicantsWithJobId: builder.query<
            paginatedResponse<IApplicant[]>,
            { jobId: string; currentPage: number; perPage: number }
        >({
            query: ({ jobId, perPage, currentPage }) => ({
                url: `/user/api/v1/jobs/get-all-applicants/${jobId}?perPage=${perPage}&currentPage=${currentPage}`,
                method: 'GET',
            }),
            providesTags: ['applicant'],
        }),
        deleteJob: builder.mutation<Response<IJob>, string>({
            query: (query) => ({
                url: `/user/api/v1/jobs/remove-job/${query}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['job'],
        }),
        deleteApplicant: builder.mutation<Response<IApplicant>, string>({
            query: (query) => ({
                url: `/user/api/v1/jobs/remove-applicant/${query}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['applicant'],
        }),
        getSingleApplicant: builder.query<Response<IApplicant>, string>({
            query: (query) => ({
                url: `/user/api/v1/jobs/get-applicant-by-id/${query}`,
            }),
            providesTags: ['applicant'],
        }),
        jobStats: builder.query<Response<IJobStat>, void>({
            query: () => ({
                url: `/user/api/v1/jobs/a/stats`,
                method: 'GET',
            }),
            providesTags: ['job'],
        }),
    }),
});

export const {
    useDeleteJobMutation,
    useGetAllJobsQuery,
    useJobStatsQuery,
    useGetSingleJobsQuery,
    useGetSingleApplicantQuery,
    useGetAllJobApplicantsWithJobIdQuery,
    useDeleteApplicantMutation,
    useAddJobMutation,
    useUpdateJobMutation,
} = jobApi;
