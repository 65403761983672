import {
    BarLine,
    ButtonContainer,
    Container,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    CustomButton,
    DeleteModal,
    Loader,
    ModalComp,
    PdfDownloader,
} from '../../common/component';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { WithdrawalRequestHeader } from '../../common/utils/dataUtils';
import { BsEye } from 'react-icons/bs';
import { MdOutlinePersonAddDisabled } from 'react-icons/md';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as UserIcon } from '../../common/images/people_icon.svg';
import { SelectComp } from '../../common/component/Ui/selectComp';
import { Controller, useForm } from 'react-hook-form';
import { showSuccessToast, showErrorToast } from '../../common/utils/toast';
import { IPartnerRequest } from '../../store/partnerRequestApi/interface';
import { AiOutlineDelete } from 'react-icons/ai';
import { useAppSelector } from '../../store/hooks';
import {
    useGetAllWithdrawalRequestQuery,
    useUpdateWithdrawalRequestMutation,
    useDeleteWithdrawalRequestMutation,
    useGetSingleWithdrawalRequestQuery,
    useGetWithdrawalRequestStatsQuery,
} from '../../store/withdrawalRequestApi';

export const WithdrawalRequest = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropDownTerm, setDropDownTerm] = useState('');
    const [withdrawalRequestId, setWithdrawalRequestId] = useState('');
    const { user } = useAppSelector((state) => state?.auth);
    const [newArray, setNewArray] = useState<IPartnerRequest[] | any>();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const params = {
        perPage: rowsPerPage,
        currentPage: currentPage,
        // user: string;
        // wallet: string;
        // bank: string;
        requestStatus: dropDownTerm.toLowerCase(),
    };
    const {
        data: allWithdrawalRequest,
        isLoading,
        isFetching,
    } = useGetAllWithdrawalRequestQuery(
        { params },
        {
            refetchOnMountOrArgChange: true,
        },
    );

    const [
        updateWithdrawalRequest,
        { isLoading: isProcessingWithdrawalRequest },
    ] = useUpdateWithdrawalRequestMutation();
    const { data: singleWithdrawalRequest } =
        useGetSingleWithdrawalRequestQuery(withdrawalRequestId);
    const { data: withdrawalRequestStats } =
        useGetWithdrawalRequestStatsQuery();
    const [requestStatusModal, setRequestStatusModal] = useState(false);

    useEffect(() => {
        if (dropDownTerm === '') {
            setTotalRows(
                allWithdrawalRequest?.pagination?.totalDocumentCount as number,
            );
        }
    }, [allWithdrawalRequest?.pagination?.totalDocumentCount, dropDownTerm]);
    const handlePageChange = (page: any) => setCurrentPage(page);

    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allWithdrawalRequest?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };
    const Items = [
        {
            name: ' View Request',
            icon: <BsEye size={15} />,
            link: 'view',
            id: '_id',
        },
        {
            name: 'Manage request',
            icon: <MdOutlinePersonAddDisabled size={15} />,
            OnClick: (row: IUser) => {
                setWithdrawalRequestId(row._id);
                setRequestStatusModal(true);
                console.log(row);
            },
        },
    ];

    // To implement dropdown input
    useEffect(() => {
        if (dropDownTerm === '' || searchTerm === '') {
            setNewArray(allWithdrawalRequest?.data);
        }
    }, [allWithdrawalRequest?.data, dropDownTerm, searchTerm]);

    // To implement search  input
    useEffect(() => {
        if (searchTerm !== '') {
            let searchItems = newArray?.filter((item: IPartnerRequest) => {
                return (
                    item?.user?.firstName
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase()) ||
                    item?.user?.lastName
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase())
                );
            });
            setNewArray(searchItems);
        }
    }, [newArray, searchTerm]);

    //handle partner request status
    const editUserStatus = useForm({
        defaultValues: {
            newData: { requestStatus: '' },
        },
    });
    const { handleSubmit, control, setValue } = editUserStatus;

    useEffect(() => {
        if (singleWithdrawalRequest?.data) {
            setValue(
                'newData',
                {
                    requestStatus: singleWithdrawalRequest?.data?.requestStatus,
                },
                { shouldValidate: true },
            );
        }
    }, [singleWithdrawalRequest?.data, setValue]);

    const handleWithdrawalStatus = (value: {
        newData: { requestStatus: string };
    }) => {
        let payload = {
            requestId: withdrawalRequestId,
            requestStatus: value?.newData?.requestStatus,
            approvedBy: user?._id as string,
        };

        updateWithdrawalRequest(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setRequestStatusModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        {
            label: 'First Name',
            key: 'user?.firstName',
        },
        {
            label: 'Last Name',
            key: 'user?.lastName',
        },
        {
            label: 'Email',
            key: 'user?.email',
        },
        {
            label: 'Wallet Type',
            key: 'wallet?.walletType',
        },
        {
            label: 'Bank',
            key: 'bank?.bankName',
        },
        {
            label: 'Account Type',
            key: 'bank?.accountType',
        },
        {
            label: 'Status',
            key: 'status',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { header: 'First Name', dataKey: 'firstName' },
        {
            header: 'Last Name',
            dataKey: 'lastName',
        },
        {
            header: 'Email',
            dataKey: 'email',
        },
        {
            header: 'Wallet Type',
            dataKey: 'wallet?.walletType',
        },
        {
            header: 'Bank',
            dataKey: 'bank?.bankName',
        },
        {
            header: 'Account Type',
            dataKey: 'bank?.accountType',
        },
        {
            header: 'Status',
            dataKey: 'status',
        },
    ];

    return (
        <Container>
            {isLoading && <Loader />}
            <Header>Withdrawal Requests</Header>
            <Grid>
                <Counter
                    counter_title="Total Requests"
                    count={
                        withdrawalRequestStats?.data?.totalWithdrawalRequests ||
                        '0'
                    }
                    iconFill="darkBlue"
                    iconBG="blue"
                >
                    <UserIcon />
                </Counter>
                <Counter
                    counter_title="Pending Requests"
                    count={
                        withdrawalRequestStats?.data
                            ?.pendingWithdrawalRequests || '0'
                    }
                    iconFill="green"
                    iconBG="green"
                >
                    <UserIcon />
                </Counter>
                <Counter
                    counter_title="Paid Requests"
                    count={
                        withdrawalRequestStats?.data
                            ?.successfulWithdrawalRequests || '0'
                    }
                >
                    <UserIcon />
                </Counter>
                <Counter
                    counter_title="Rejected Requests"
                    count={
                        withdrawalRequestStats?.data
                            ?.rejectedWithdrawalRequests || '0'
                    }
                    iconFill="red"
                    iconBG="red"
                >
                    <UserIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={9}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col
                        xs={10}
                        sm={6}
                        className="d-flex align-items-center my-3"
                    >
                        <SelectComp
                            selectText="All request"
                            arrayComp={['Pending', 'Paid', 'Rejected']}
                            onChange={(e: any) =>
                                setDropDownTerm(e.target.value)
                            }
                        />
                        <TotalResultText>
                            {`${
                                allWithdrawalRequest?.pagination
                                    ?.totalDocumentCount || 0
                            } result`}
                        </TotalResultText>
                    </Col>

                    {/* <InputWrap>
                        <img src={SearchIcon} alt="" />
                        <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Search"
                            className="border-start-0 border-bottom-0 border-top-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputWrap> */}
                </Col>

                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'WITHDRAWAL REQUESTS RECORD'}
                            {...{ columns: headersPdf, body: newArray }}
                        />
                        <BarLine />
                        <CsvDownloader headers={headersCsv} data={newArray} />
                    </ButtonContainer>
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={WithdrawalRequestHeader(Items)}
                    data={newArray}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>

            {/* handle partner request */}
            <ModalComp
                show={requestStatusModal}
                handleClose={() => setRequestStatusModal(false)}
                centered
                close={true}
                modalTitle="Withdrawal status"
                paragraph="Fill in the  the forms to update withdrawal request"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleSubmit(handleWithdrawalStatus)}>
                            <Controller
                                name="newData.requestStatus"
                                control={control}
                                rules={{
                                    required: 'acount status is required',
                                }}
                                render={({
                                    field: { value, onChange },
                                    formState: { errors },
                                }) => {
                                    const errorMessage =
                                        errors?.newData?.requestStatus?.message;
                                    return (
                                        <SelectComp
                                            labelText="Status"
                                            arrayComp={['paid', 'rejected']}
                                            name="serviceChargeOnPartnerCharge"
                                            defaultValue={
                                                singleWithdrawalRequest?.data
                                                    ?.requestStatus
                                            }
                                            {...{
                                                value,
                                                onChange,
                                                errors: [errorMessage],
                                            }}
                                        />
                                    );
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isProcessingWithdrawalRequest
                                            ? 'Save changes'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />
        </Container>
    );
};
