import { PaymentStatus, UserImage, UserName } from '../users/style';
import {
    BarLine,
    Grid,
    SmallImage,
    StarContainer,
    Status,
    SubHeading,
    TradeCard,
    TradeHeader,
    TradeText,
    TradeType,
    TradeUser,
    InfoWrapper,
    StyledButton,
} from './style';
import { ReactComponent as StarIcon } from '../../common/images/star_icon.svg';
import { ITrade } from '../../store/tradeApi/interface';
import { getDateString } from '../../common/utils/date';
import { useGetSingleTradeQuery } from '../../store/tradeApi';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../../common/component';
import { currencyFormater } from '../../common/utils/helpers';

export const TradeInfo = () => {
    const params = useParams();
    const { data: tradeItem, isLoading } = useGetSingleTradeQuery(
        params?.id as string,
    );
    let tradeDetails = tradeItem?.data as ITrade;
    const navigate = useNavigate();

    return (
        <InfoWrapper className="d-flex flex-column gap-5 ">
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="d-flex gap-3">
                        <BarLine />
                        <div className="d-flex flex-column gap-4 w-100">
                            <Grid className="d-flex gap-4 gap-sm-5">
                                <TradeCard>
                                    <div className="pb-1 d-flex align-items-center gap-4">
                                        <TradeUser>User</TradeUser>
                                        <PaymentStatus
                                            status={
                                                tradeDetails?.paidPaymentStatus
                                                    ?.user
                                            }
                                        >
                                            {tradeDetails?.paidPaymentStatus
                                                ?.user
                                                ? 'PAID'
                                                : 'Not Paid'}
                                        </PaymentStatus>
                                    </div>
                                    <div className="d-flex align-items-center gap-3 gap-lg-2">
                                        <UserImage>
                                            <img
                                                src={
                                                    tradeDetails?.user
                                                        ?.passportPhoto
                                                }
                                                alt=""
                                            />
                                        </UserImage>
                                        <UserName>{`${tradeDetails?.user?.firstName} ${tradeDetails?.user?.lastName}`}</UserName>
                                    </div>
                                </TradeCard>
                                <TradeCard>
                                    <div className="pb-1 d-flex align-items-center gap-4">
                                        <TradeUser>Merchant</TradeUser>
                                        <PaymentStatus
                                            status={
                                                tradeDetails?.paidPaymentStatus
                                                    ?.merchant
                                            }
                                        >
                                            {tradeDetails?.paidPaymentStatus
                                                ?.merchant
                                                ? 'PAID'
                                                : 'Not Paid'}
                                        </PaymentStatus>
                                    </div>
                                    <div className="d-flex align-items-center gap-3 gap-lg-2">
                                        <UserImage>
                                            <img
                                                src={
                                                    tradeDetails?.merchant
                                                        ?.passportPhoto
                                                }
                                                alt=""
                                            />
                                        </UserImage>
                                        <UserName>{`${tradeDetails?.merchant?.firstName} ${tradeDetails?.merchant?.lastName}`}</UserName>
                                    </div>
                                </TradeCard>
                                <TradeCard>
                                    <div className="pb-1 d-flex align-items-center gap-4">
                                        <TradeUser>Partner</TradeUser>
                                        <PaymentStatus
                                            status={
                                                tradeDetails?.paidPaymentStatus
                                                    ?.partner
                                            }
                                        >
                                            {tradeDetails?.paidPaymentStatus
                                                ?.partner
                                                ? 'PAID'
                                                : 'Not Paid'}
                                        </PaymentStatus>
                                    </div>
                                    <div className="d-flex align-items-center gap-3 gap-lg-2">
                                        <UserImage>
                                            <img
                                                src={
                                                    tradeDetails?.partner
                                                        ?.passportPhoto
                                                }
                                                alt=""
                                            />
                                        </UserImage>
                                        <UserName>{`${
                                            tradeDetails?.partner?.firstName ||
                                            '-'
                                        } ${
                                            tradeDetails?.partner?.lastName ||
                                            '-'
                                        }`}</UserName>
                                    </div>
                                </TradeCard>
                                <TradeCard className="d-flex flex-column gap-2">
                                    <SubHeading>Creation date</SubHeading>
                                    <TradeText>
                                        {getDateString(tradeDetails?.createdAt)}
                                    </TradeText>
                                </TradeCard>
                                <TradeCard className="d-flex flex-column gap-2">
                                    <SubHeading>Currency pair</SubHeading>
                                    <TradeText>{`${tradeDetails?.advert?.currencyPair?.baseCurrency?.name.toUpperCase()}/${tradeDetails?.advert?.currencyPair?.tradingCurrency?.name.toUpperCase()}`}</TradeText>
                                </TradeCard>
                                <TradeCard className="d-flex flex-column gap-2">
                                    <SubHeading>Advert type</SubHeading>
                                    <TradeText>{`${tradeDetails?.advert?.advertType?.toUpperCase()}`}</TradeText>
                                </TradeCard>
                                <TradeCard className="d-flex flex-column gap-2">
                                    <SubHeading>{`Quantity to ${tradeDetails?.advert?.advertType}`}</SubHeading>
                                    <TradeText>{`${tradeDetails?.advert
                                        ?.currencyPair?.tradingCurrency
                                        ?.currencySymbol}${currencyFormater(
                                        tradeDetails?.amount /
                                            tradeDetails?.advert?.price,
                                    )}`}</TradeText>
                                </TradeCard>
                                <TradeCard className="d-flex flex-column gap-2">
                                    <SubHeading>{`Price per ${tradeDetails?.advert?.currencyPair?.tradingCurrency?.name?.toUpperCase()}`}</SubHeading>
                                    <TradeText>
                                        {`${tradeDetails?.advert?.currencyPair
                                            ?.tradingCurrency
                                            ?.currencySymbol}${currencyFormater(
                                            tradeDetails?.advert?.price,
                                        )}`}
                                    </TradeText>
                                </TradeCard>
                                <TradeCard className="d-flex flex-column gap-2">
                                    <SubHeading>Trade amount</SubHeading>
                                    <TradeText>{`${tradeDetails?.advert?.currencyPair?.baseCurrency?.currencySymbol?.toUpperCase()}${currencyFormater(
                                        tradeDetails?.amount,
                                    )}`}</TradeText>
                                </TradeCard>
                                <TradeCard className="d-flex flex-column gap-2">
                                    <SubHeading>Partner charge</SubHeading>
                                    <TradeText>{`${tradeDetails?.advert?.currencyPair?.baseCurrency?.currencySymbol?.toUpperCase()}${currencyFormater(
                                        tradeDetails?.pertnerCharge,
                                    )}`}</TradeText>
                                </TradeCard>
                                <TradeCard className="d-flex flex-column gap-2">
                                    <SubHeading>Category</SubHeading>
                                    <TradeType>
                                        {
                                            tradeDetails?.advert?.currencyPair
                                                ?.baseCurrency?.currencyType
                                        }
                                    </TradeType>
                                </TradeCard>
                                <TradeCard className="d-flex flex-column gap-2">
                                    <SubHeading>Trade status</SubHeading>
                                    <Status status={tradeDetails?.tradeStatus}>
                                        {tradeDetails?.tradeStatus}
                                    </Status>
                                </TradeCard>
                            </Grid>
                        </div>
                    </div>
                    {/* User wrap */}
                    <div>
                        <TradeHeader>User's details</TradeHeader>
                        <div className="d-flex gap-3">
                            <BarLine />
                            <div className="d-flex flex-column gap-4 w-100">
                                <Grid className="d-flex gap-4 gap-sm-5">
                                    <TradeCard className="d-flex flex-column gap-2">
                                        <SubHeading>Bank name</SubHeading>
                                        <TradeText>
                                            {`${tradeDetails?.userBankDetails
                                                ?.bankName} / ${tradeDetails?.userBankDetails?.accountType.toUpperCase()}`}
                                        </TradeText>
                                    </TradeCard>
                                    <TradeCard className="d-flex flex-column gap-2">
                                        <SubHeading>Account name</SubHeading>
                                        <TradeText>
                                            {
                                                tradeDetails?.userBankDetails
                                                    ?.accountName
                                            }
                                        </TradeText>
                                    </TradeCard>
                                    <TradeCard className="d-flex flex-column gap-2">
                                        <SubHeading>Account number</SubHeading>
                                        <TradeText>
                                            {
                                                tradeDetails?.userBankDetails
                                                    ?.accountNumber
                                            }
                                        </TradeText>
                                    </TradeCard>
                                </Grid>
                                <div>
                                    {tradeDetails?.userRating > 0 ? (
                                        <div className="d-flex align-items-center gap-2">
                                            <SmallImage>
                                                <img
                                                    src={
                                                        tradeDetails?.user
                                                            ?.passportPhoto
                                                    }
                                                    alt=""
                                                />
                                            </SmallImage>

                                            <StarContainer className="d-flex align-items-center gap-1">
                                                {[
                                                    ...Array(
                                                        tradeDetails?.userRating,
                                                    ),
                                                ].map((__, index) => (
                                                    <StarIcon key={index} />
                                                ))}
                                            </StarContainer>
                                        </div>
                                    ) : null}
                                    <SubHeading>
                                        <i>{tradeDetails?.userComments}</i>
                                    </SubHeading>
                                </div>
                            </div>
                        </div>
                    </div>{' '}
                    {/* Merchant wrap */}
                    <div>
                        <TradeHeader>Merchant's details</TradeHeader>
                        <div className="d-flex gap-3">
                            <BarLine />
                            <div className="d-flex flex-column gap-4 w-100">
                                <Grid className="d-flex gap-4 gap-sm-5">
                                    <TradeCard className="d-flex flex-column gap-2">
                                        <SubHeading>Bank name</SubHeading>
                                        <TradeText>
                                            {`${tradeDetails
                                                ?.merchantBankDetails
                                                ?.bankName} / ${tradeDetails?.merchantBankDetails?.accountType.toUpperCase()}`}
                                        </TradeText>
                                    </TradeCard>
                                    <TradeCard className="d-flex flex-column gap-2">
                                        <SubHeading>Account name</SubHeading>
                                        <TradeText>
                                            {
                                                tradeDetails
                                                    ?.merchantBankDetails
                                                    ?.accountName
                                            }
                                        </TradeText>
                                    </TradeCard>
                                    <TradeCard className="d-flex flex-column gap-2">
                                        <SubHeading>Account number</SubHeading>
                                        <TradeText>
                                            {
                                                tradeDetails
                                                    ?.merchantBankDetails
                                                    ?.accountNumber
                                            }
                                        </TradeText>
                                    </TradeCard>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    {/* Partner wrap */}
                    {tradeDetails?.partnerBankDetails && (
                        <div>
                            <TradeHeader>Partner's details</TradeHeader>
                            <div className="d-flex gap-3">
                                <BarLine />
                                <div className="d-flex flex-column gap-4 w-100">
                                    <Grid className="d-flex gap-4 gap-sm-5">
                                        <TradeCard className="d-flex flex-column gap-2">
                                            <SubHeading>Bank name</SubHeading>
                                            <TradeText>
                                                {`${tradeDetails
                                                    ?.partnerBankDetails
                                                    ?.bankName} / ${tradeDetails?.partnerBankDetails?.accountType.toUpperCase()}`}
                                            </TradeText>
                                        </TradeCard>
                                        <TradeCard className="d-flex flex-column gap-2">
                                            <SubHeading>
                                                Account name
                                            </SubHeading>
                                            <TradeText>
                                                {
                                                    tradeDetails
                                                        ?.partnerBankDetails
                                                        ?.accountName
                                                }
                                            </TradeText>
                                        </TradeCard>
                                        <TradeCard className="d-flex flex-column gap-2">
                                            <SubHeading>
                                                Account number
                                            </SubHeading>
                                            <TradeText>
                                                {
                                                    tradeDetails
                                                        ?.partnerBankDetails
                                                        ?.accountNumber
                                                }
                                            </TradeText>
                                        </TradeCard>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="d-flex justify-content-center my-4">
                        <StyledButton
                            onClick={() =>
                                navigate(
                                    `/dashboard/trade-advert/view/${tradeDetails?.advert?._id}`,
                                )
                            }
                        >
                            View Trade Advert
                        </StyledButton>
                    </div>
                </>
            )}
        </InfoWrapper>
    );
};
