import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RouterConfig } from "./route";
import { connectToSocket } from "./socket";

function App() {
	connectToSocket();
	return (
		<div>
			<RouterConfig />
			<ToastContainer />
		</div>
	);
}

export default App;
