import styled from 'styled-components';
import { colors, FileUploaderComp } from '../../common/component';
import { CustomButton } from '../../common/component/button';
import {
    PhoneInputComp,
    TextInputComp,
} from '../../common/component/Ui/textInputComp';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useEffect, useState } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { GiCheckMark } from 'react-icons/gi';
import { MdVerified, MdCancel } from 'react-icons/md';
import { showSuccessToast, showErrorToast } from '../../common/utils/toast';
import {
    useUpdateUserProfileMutation,
    useUpdateUserPhoneNumberMutation,
} from '../../store/userApi';
import { setUser } from '../../store/auth';
import { Spinner } from 'react-bootstrap';

export const Profile = () => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state: any) => state.auth);
    console.log('YEYEYEYYE', user);
    const [disable, setDisable] = useState(true);
    const [updateUserProfile, { isLoading }] = useUpdateUserProfileMutation();
    const [updateUserPhoneNumber, { isLoading: isUpdatingPhone }] =
        useUpdateUserPhoneNumberMutation();

    const { handleSubmit, control, setValue } = useForm({
        defaultValues: {
            newData: {
                firstName: '',
                lastName: '',
                passportPhoto: '',
            },
        },
    });
    useEffect(() => {
        if (user) {
            setValue(
                'newData',
                {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    passportPhoto: user.passportPhoto,
                },
                { shouldValidate: true },
            );
        }
    }, [setValue, user]);

    const onEditProfile = (values: {
        newData: {
            firstName: string;
            lastName: string;
            passportPhoto: string;
        };
    }) => {
        const { firstName, lastName, passportPhoto } = values.newData;
        console.log(values.newData, 'values.newdata');
        if (
            firstName === (user?.firstName as string) &&
            lastName === (user?.lastName as string) &&
            passportPhoto === (user?.passportPhoto as string)
        ) {
            showErrorToast('No changes made');
        } else {
            let payload = {
                firstName: firstName,
                lastName: lastName,
                passportPhoto: passportPhoto,
                userId: user?._id as string,
            };
            console.log(payload, 'ann');
            updateUserProfile(payload)
                .unwrap()
                .then((result) => {
                    showSuccessToast(result?.message);
                    dispatch(setUser(result?.data));
                })
                .catch((error) => {
                    showErrorToast(error?.data?.message);
                });
        }
    };

    //change phone number
    const {
        handleSubmit: handlePhoneNumberSubmit,
        control: phoneNumberControl,
        setValue: setPhoneValue,
    } = useForm({
        defaultValues: {
            newData2: {
                phone: '',
            },
        },
    });

    useEffect(() => {
        if (user) {
            setPhoneValue(
                'newData2',
                {
                    phone: user?.phone,
                },
                { shouldValidate: true },
            );
        }
    }, [setPhoneValue, user]);

    const onEditPhoneNumber = (values: { newData2: { phone: string } }) => {
        let payload = {
            userId: user?._id as string,
            phone: values?.newData2?.phone,
        };
        if (values?.newData2?.phone === user?.phone) {
            showErrorToast('No change made');
        } else {
            updateUserPhoneNumber(payload)
                .unwrap()
                .then((result) => {
                    showSuccessToast(result?.message);
                    dispatch(setUser(result?.data));
                    setDisable(true);
                })
                .catch((error) => {
                    showErrorToast(error?.data?.message);
                });
        }
    };

    return (
        <Container>
            <div className=" py-4 px-lg-5 ">
                <form onSubmit={handleSubmit(onEditProfile)}>
                    <div className="d-sm-flex gap-4">
                        <div className="d-flex justify-content-center mb-4">
                            <div>
                                <Controller
                                    name="newData.passportPhoto"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        return (
                                            <FileUploaderComp
                                                defaultValue={
                                                    user?.passportPhoto as string
                                                }
                                                {...{ value, onChange }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="w-100">
                            <div>
                                <Controller
                                    name="newData.firstName"
                                    control={control}
                                    rules={{
                                        required: '*required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.firstName?.message;
                                        return (
                                            <TextInputComp
                                                labelText="First Name"
                                                placeholder="Enter first name"
                                                inputClassName={'py-2'}
                                                name="firstname"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3 ">
                                <Controller
                                    name="newData.lastName"
                                    control={control}
                                    rules={{
                                        required: '*require',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.lastName?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Last Name"
                                                placeholder="Enter last name"
                                                inputClassName={'py-2'}
                                                name="lastName"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 my-3 w-100 d-flex justify-content-center "
                                    text={
                                        !isLoading
                                            ? 'Save Changes'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div className="d-flex align-items-center my-2 gap-2">
                    {user?.isEmailVerified ? (
                        <MdVerified size={15} color={`${colors.Green80}`} />
                    ) : (
                        <MdCancel size={15} color={`${colors.gray20}`} />
                    )}
                    <div className="my-3 mx-1 w-100">
                        <TextInputComp
                            labelText="Email Address"
                            placeholder="Email Address"
                            inputClassName={'py-'}
                            disabled={true}
                            defaultValue={user?.email}
                        />
                    </div>
                </div>
                <form onSubmit={handlePhoneNumberSubmit(onEditPhoneNumber)}>
                    <div
                        className="d-flex align-items-center my-2 gap-2"
                        style={{
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        {user?.isPhoneVerified ? (
                            <div>
                                <MdVerified
                                    size={15}
                                    color={`${colors.Green80}`}
                                />
                            </div>
                        ) : (
                            <div>
                                <MdCancel
                                    size={15}
                                    color={`${colors.gray20}`}
                                />
                            </div>
                        )}
                        <div className=" my-3 w-100 m-0">
                            <Controller
                                name="newData2.phone"
                                control={phoneNumberControl}
                                rules={{
                                    required: '*require',
                                }}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <PhoneInputComp
                                            labelText="Phone number"
                                            disabled={disable}
                                            name="phone"
                                            {...{ value, onChange }}
                                        />
                                    );
                                }}
                            />
                        </div>

                        <div
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: 50,
                            }}
                        >
                            {disable ? (
                                <BiEditAlt
                                    size={20}
                                    color={`${colors.gray30}`}
                                    onClick={() => setDisable(false)}
                                />
                            ) : (
                                <>
                                    {isUpdatingPhone ? (
                                        <Spinner color={`${colors.Primary1}`} />
                                    ) : (
                                        <button
                                            style={{
                                                border: 'none',
                                                background: 'transparent',
                                            }}
                                        >
                                            <GiCheckMark
                                                size={20}
                                                color={`${colors.Green80}`}
                                            />
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </Container>
    );
};

const Container = styled.div`
    width: 40%;
    @media (max-width: 1440px) {
        width: 50%;
    }
    @media (max-width: 1024px) {
        width: 55%;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`;
