import {
    BarLine,
    ButtonContainer,
    Container,
    InputWrap,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    CustomButton,
    DeleteModal,
    Loader,
    PdfDownloader,
    SelectComp,
    TextInputComp,
} from '../../common/component';
import {
    useAddAppVersionMutation,
    useAppVersionStatsQuery,
    useEditAppVersionMutation,
    useGetAllAppVersionQuery,
    useGetSingleAppVersionQuery,
    useDeleteAppVersionMutation,
} from '../../store/appVersionApi';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { AppVersionTableHeader } from '../../common/utils/dataUtils';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { ModalComp } from '../../common/component/Ui/modalComp';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as AppVersionIcon } from '../../common/images/appversion_icon.svg';
import { IAppVersion } from '../../store/appVersionApi/interface';
import { Controller, useForm } from 'react-hook-form';
import { showErrorToast, showSuccessToast } from '../../common/utils/toast';

export const AppVersion = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [newArray, setNewArray] = useState<IAppVersion[] | any>();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [appVersionId, versionName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: allAppVersion,
        isLoading,
        isFetching,
    } = useGetAllAppVersionQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });
    const { data: singleAppVersion } =
        useGetSingleAppVersionQuery(appVersionId);
    const { data: appVersionStats } = useAppVersionStatsQuery();
    const [addAppVersion, { isLoading: isAdding }] = useAddAppVersionMutation();
    const [deleteAppVersion, { isLoading: isDeleting }] =
        useDeleteAppVersionMutation();
    const [editAppVersion, { isLoading: isEditing }] =
        useEditAppVersionMutation();

    useEffect(() => {
        setTotalRows(allAppVersion?.pagination?.totalDocumentCount as number);
    }, [allAppVersion?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allAppVersion?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const Items = [
        {
            name: 'Edit item',
            icon: <BiEditAlt size={15} />,
            OnClick: (data: any) => {
                versionName(data?._id);
                setShowEditModal(true);
            },
        },
        {
            name: 'Delete item',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: any) => {
                setDeleteId(data?._id);
                setShowDeleteModal(true);
            },
        },
    ];

    // To implement search and dropdown input
    useEffect(() => {
        if (searchTerm === '') {
            setNewArray(allAppVersion?.data);
        } else {
            let searchItems = allAppVersion?.data?.filter(
                (item: IAppVersion) => {
                    return (
                        item?.versionName
                            ?.toLowerCase()
                            .includes(searchTerm?.toLowerCase()) ||
                        item?.appType
                            ?.toLowerCase()
                            .includes(searchTerm?.toLowerCase()) ||
                        item?.updateType
                            ?.toLowerCase()
                            .includes(searchTerm?.toLowerCase())
                    );
                },
            );
            setNewArray(searchItems);
        }
    }, [allAppVersion, searchTerm]);

    // add version
    const appVersion = useForm({
        defaultValues: {
            versionName: '',
            versionNumber: '',
            buildNumber: '',
            storeURL: '',
            appType: '',
            updateType: '',
        },
    });
    const { handleSubmit, control, reset } = appVersion;

    // edit version
    const editAppVersionForm = useForm({
        defaultValues: {
            newData: {
                versionName: '',
                versionNumber: '',
                buildNumber: '',
                storeURL: '',
                appType: '',
                updateType: '',
                status: '',
            },
        },
    });
    const {
        handleSubmit: handleEditSubmit,
        control: editControl,
        setValue,
    } = editAppVersionForm;

    useEffect(() => {
        if (singleAppVersion?.data?._id) {
            setValue(
                'newData',
                {
                    versionName: singleAppVersion?.data?.versionName,
                    versionNumber: singleAppVersion?.data?.versionNumber,
                    buildNumber: singleAppVersion?.data?.buildNumber,
                    storeURL: singleAppVersion?.data?.storeURL,
                    appType: singleAppVersion?.data?.appType,
                    updateType: singleAppVersion?.data?.updateType,
                    status: singleAppVersion?.data?.status
                        ? 'Active'
                        : 'Inactive',
                },
                { shouldValidate: true },
            );
        }
    }, [singleAppVersion, setValue]);

    const onHandleSubmit = (value: {
        versionName: string;
        versionNumber: string;
        buildNumber: string;
        storeURL: string;
        appType: string;
        updateType: string;
    }) => {
        let payload = {
            versionName: value.versionName,
            versionNumber: parseFloat(value.versionNumber),
            buildNumber: parseFloat(value.buildNumber),
            storeURL: value.storeURL,
            appType: value.appType,
            updateType: value.updateType,
        };
        addAppVersion(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowAddModal(false);
                reset();
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    const onHandleEdit = (value: {
        newData: {
            versionName: string;
            versionNumber: string;
            buildNumber: string;
            storeURL: string;
            appType: string;
            updateType: string;
            status: string;
        };
    }) => {
        const {
            versionName,
            versionNumber,
            buildNumber,
            storeURL,
            appType,
            updateType,
            status,
        } = value?.newData;
        let payload = {
            versionName: versionName,
            versionNumber: parseFloat(versionNumber),
            buildNumber: parseFloat(buildNumber),
            storeURL: storeURL,
            appType: appType,
            updateType: updateType,
            appVersionId: singleAppVersion?.data?._id,
            status: status === 'Active' ? true : false,
        };
        editAppVersion(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowEditModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    const confirmDelete = () => {
        deleteAppVersion(deleteId)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowDeleteModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'App version', key: 'versionName' },
        {
            label: 'Update Type',
            key: 'updateType',
        },
        {
            label: 'App type',
            key: 'appType',
        },
        {
            label: 'Store url',
            key: 'storeURL',
        },
        {
            label: 'Version number',
            key: 'versionNumber',
        },
        {
            label: 'Build number',
            key: 'buildNumber',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { header: 'App version', dataKey: 'versionName' },
        {
            header: 'Update Type',
            dataKey: 'updateType',
        },
        {
            header: 'App type',
            dataKey: 'appType',
        },
        {
            header: 'Store url',
            dataKey: 'storeURL',
        },
        {
            header: 'Version number',
            dataKey: 'versionNumber',
        },
        {
            header: 'Build number',
            dataKey: 'buildNumber',
        },
    ];

    return (
        <Container>
            {isLoading || isDeleting ? <Loader /> : null}
            <Header>App version</Header>
            <Grid>
                <Counter
                    counter_title="Total Versions"
                    count={appVersionStats?.data?.totalAppVersions || 0}
                    iconFill="blue"
                    iconBG="blue"
                >
                    <AppVersionIcon />
                </Counter>
                <Counter
                    counter_title="Active Versions"
                    count={appVersionStats?.data?.activeAppVersions || 0}
                    iconFill="green"
                    iconBG="green"
                >
                    <AppVersionIcon />
                </Counter>
                <Counter
                    counter_title="Inactive Versions"
                    count={appVersionStats?.data?.InActiveAppVersions || 0}
                    iconFill="red"
                    iconBG="red"
                >
                    <AppVersionIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={8}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col xl={3} className="d-flex align-items-center my-3">
                        <TotalResultText>{`${
                            allAppVersion?.data?.length || 0
                        } versions`}</TotalResultText>
                    </Col>
                    <InputWrap>
                        <img src={SearchIcon} alt="" />
                        <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Search"
                            className="border-start-0 border-bottom-0 border-top-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputWrap>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'APP VERSION RECORD'}
                            {...{
                                columns: headersPdf,
                                body: allAppVersion?.data,
                            }}
                        />
                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={allAppVersion?.data}
                        />
                    </ButtonContainer>
                    <CustomButton
                        text="+ New version"
                        onClick={() => setShowAddModal(true)}
                    />
                </div>
            </div>
            <ContainerComp>
                <TableCompData
                    columns={AppVersionTableHeader(Items)}
                    data={newArray}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>

            {/* add app-version modal */}
            <ModalComp
                show={showAddModal}
                handleClose={() => setShowAddModal(false)}
                centered
                close={true}
                modalTitle="Add App Version"
                paragraph="Fill in the  the forms to add a version"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleSubmit(onHandleSubmit)}>
                            <div className="my-3">
                                <Controller
                                    name="versionName"
                                    control={control}
                                    rules={{
                                        required: 'App version is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.versionName?.message;
                                        return (
                                            <TextInputComp
                                                labelText="App Version"
                                                placeholder="eg: v1.0.0"
                                                inputClassName={'py-2 '}
                                                name="versionName"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="versionNumber"
                                    control={control}
                                    rules={{
                                        required: 'Version number is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.versionNumber?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Version Number"
                                                placeholder="eg: 1"
                                                inputClassName={'py-2 '}
                                                name="versionNumber"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="buildNumber"
                                    control={control}
                                    rules={{
                                        required: 'Build number is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.buildNumber?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Build Number"
                                                placeholder="eg: 1"
                                                inputClassName={'py-2 '}
                                                name="buildNumber"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="storeURL"
                                    control={control}
                                    rules={{
                                        required: 'Store url is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.storeURL?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Store Url"
                                                placeholder="eg: https://...."
                                                inputClassName={'py-2 '}
                                                name="storeURL"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="appType"
                                    control={control}
                                    rules={{
                                        required: 'App type is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.appType?.message;
                                        return (
                                            <SelectComp
                                                labelText="App Type"
                                                arrayComp={['ios', 'android']}
                                                name="appType"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="updateType"
                                    control={control}
                                    rules={{
                                        required: 'Update type is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.updateType?.message;
                                        return (
                                            <SelectComp
                                                labelText="Update Type"
                                                arrayComp={['minor', 'major']}
                                                name="appType"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isAdding
                                            ? 'Create App version'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />

            {/* edit modal */}
            <ModalComp
                show={showEditModal}
                handleClose={() => setShowEditModal(false)}
                centered
                close={true}
                modalTitle="Edit App version"
                paragraph="Fill in the  the forms to edit a version"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleEditSubmit(onHandleEdit)}>
                            <div className="my-3">
                                <Controller
                                    name="newData.versionName"
                                    control={editControl}
                                    rules={{
                                        required: 'App version is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.versionName
                                                ?.message;
                                        return (
                                            <TextInputComp
                                                labelText="App Version"
                                                placeholder="eg: v1.0.0"
                                                inputClassName={'py-2 '}
                                                name="versionName"
                                                defaultValue={
                                                    singleAppVersion?.data
                                                        ?.versionName
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.versionNumber"
                                    control={editControl}
                                    rules={{
                                        required: 'Version number is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.versionNumber
                                                ?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Version Number"
                                                placeholder="eg: 1"
                                                inputClassName={'py-2 '}
                                                name="versionNumber"
                                                defaultValue={
                                                    singleAppVersion?.data
                                                        ?.versionNumber
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.buildNumber"
                                    control={editControl}
                                    rules={{
                                        required: 'Build number is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.buildNumber
                                                ?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Build Number"
                                                placeholder="eg: 1"
                                                inputClassName={'py-2 '}
                                                name="buildNumber"
                                                defaultValue={
                                                    singleAppVersion?.data
                                                        ?.buildNumber
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.storeURL"
                                    control={editControl}
                                    rules={{
                                        required: 'Store url is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.storeURL?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Store Url"
                                                placeholder="eg: https://...."
                                                inputClassName={'py-2 '}
                                                name="storeURL"
                                                defaultValue={
                                                    singleAppVersion?.data
                                                        ?.storeURL
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.appType"
                                    control={editControl}
                                    rules={{
                                        required: 'App type is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.versionNumber
                                                ?.message;
                                        return (
                                            <SelectComp
                                                labelText="App Type"
                                                arrayComp={['ios', 'android']}
                                                name="appType"
                                                defaultValue={
                                                    singleAppVersion?.data
                                                        ?.appType
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.updateType"
                                    control={editControl}
                                    rules={{
                                        required: 'Update type is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.updateType
                                                ?.message;
                                        return (
                                            <SelectComp
                                                labelText="Update Type"
                                                arrayComp={['minor', 'major']}
                                                name="appType"
                                                defaultValue={
                                                    singleAppVersion?.data
                                                        ?.updateType
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <Controller
                                name="newData.status"
                                control={editControl}
                                rules={{
                                    required: 'status is required',
                                }}
                                render={({
                                    field: { value, onChange },
                                    formState: { errors },
                                }) => {
                                    const errorMessage =
                                        errors?.newData?.status?.message;
                                    return (
                                        <SelectComp
                                            labelText="Status"
                                            inputClassName={'py-2 '}
                                            arrayComp={['Active', 'Inactive']}
                                            name="status"
                                            defaultValue={
                                                singleAppVersion?.data?.status
                                                    ? 'Active'
                                                    : 'Inactive'
                                            }
                                            {...{
                                                value,
                                                onChange,
                                                errors: [errorMessage],
                                            }}
                                        />
                                    );
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isEditing
                                            ? 'Edit App version'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />
            <DeleteModal
                deleteItem={'App Version'}
                showDeleteModal={showDeleteModal}
                cancelDelete={() => setShowDeleteModal(false)}
                confirmDelete={confirmDelete}
                isDeleting={isDeleting}
            />
        </Container>
    );
};
