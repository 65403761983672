import {
	BarLine,
	Grid,
	ImageContainer,
	StatusContainer,
	SubHeading,
	Card,
	Header,
	Text,
	UserWrap,
} from "./style";
import { BackButton, TopText, UserImage, UserName } from "../users/style";
import { useNavigate, useParams } from "react-router-dom";
import back_arrow from "../../common/images/back_arrow.svg";
import { Loader, ModalComp } from "../../common/component";
import { useGetSinglePartnerRequestQuery } from "../../store/partnerRequestApi";
import { getDateString } from "../../common/utils/date";
import { useState } from "react";

export const ViewPartner = () => {
	const params = useParams();
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState(false);
	const partnerRequestId = params?.id as string;
	const { data: partnerData, isLoading } =
		useGetSinglePartnerRequestQuery(partnerRequestId);

	return (
		<div>
			<div className="d-flex align-items-center my-2">
				<BackButton onClick={() => navigate(-1)}>
					<img src={back_arrow} alt="" /> Back
				</BackButton>
				<TopText>
					{" "}
					<span>/ Partner Request</span> / View Request
				</TopText>
			</div>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<div className="d-flex gap-3 mt-4">
						<BarLine />
						<div className="d-flex flex-column gap-4 w-100">
							<Grid className="d-flex gap-4 gap-sm-5">
								<Card>
									<div className="pb-1">
										<UserWrap>Applied by</UserWrap>
									</div>
									<div className="d-flex align-items-center gap-3 gap-lg-2">
										<UserImage>
											<img
												src={partnerData?.data?.user?.passportPhoto}
												alt=""
											/>
										</UserImage>
										<UserName>{`${partnerData?.data?.user?.firstName} ${partnerData?.data?.user?.lastName}`}</UserName>
									</div>
								</Card>
								{partnerData?.data?.approvedBy && (
									<Card>
										<div className="pb-1">
											<UserWrap>Approved by</UserWrap>
										</div>
										<div className="d-flex align-items-center gap-3 gap-lg-2">
											<UserImage>
												<img
													src={partnerData?.data?.approvedBy?.passportPhoto}
													alt=""
												/>
											</UserImage>
											<UserName>{`${partnerData?.data?.approvedBy?.firstName} ${partnerData?.data?.approvedBy?.lastName}`}</UserName>
										</div>
									</Card>
								)}
								<Card>
									<div className="pb-1">
										<UserWrap>Referred by</UserWrap>
									</div>
									<div className="d-flex align-items-center gap-3 gap-lg-2">
										<UserName>{partnerData?.data?.nameOfReferral}</UserName>
									</div>
								</Card>
								<Card className="d-flex flex-column gap-2">
									<SubHeading>Applied on</SubHeading>
									<Text>
										{getDateString(partnerData?.data?.createdAt as string)}
									</Text>
								</Card>
								<Card className="d-flex flex-column gap-2">
									<SubHeading>Means of identification</SubHeading>
									<div className="d-flex gap-1">
										<Text>{`${partnerData?.data?.userMeansOfIdentification[0]?.idCardType}/`}</Text>
										<SubHeading
											style={{ cursor: "pointer" }}
											onClick={() => setShowModal(true)}
										>{`View image`}</SubHeading>
									</div>
								</Card>
								<Card className="d-flex flex-column gap-2">
									<SubHeading>Collateral</SubHeading>
									<Text>
										{partnerData?.data?.collateral[0]?.item}
									</Text>
								</Card>
								<Card className="d-flex flex-column gap-2">
									<SubHeading>Collateral Amount</SubHeading>
									<Text>
										{partnerData?.data?.collateral[0]?.amount}
									</Text>
								</Card>
								<Card className="d-flex flex-column gap-2">
									<SubHeading>Status</SubHeading>
									<StatusContainer status={partnerData?.data?.status as string}>
										{partnerData?.data?.status}
									</StatusContainer>
								</Card>
							</Grid>
						</div>
					</div>

					{/* applicant details */}
					<div className="my-4">
						<Header>About applicant</Header>
						<div className="d-flex gap-3">
							<BarLine />
							<div className="d-flex flex-column gap-1 w-100">
								<SubHeading>
									<i>{partnerData?.data?.about}</i>
								</SubHeading>
								<SubHeading>
									<i>{partnerData?.data?.additionalInformation}</i>
								</SubHeading>
								<SubHeading>
									<i>{partnerData?.data?.additionalNotes}</i>
								</SubHeading>
							</div>
						</div>
					</div>
				</>
			)}

			<ModalComp
				show={showModal}
				handleClose={() => setShowModal(false)}
				centered
				close={true}
				modalTitle={`${partnerData?.data?.userMeansOfIdentification[0]?.idCardType} image`}
				modalBody={
					<div className="d-flex flex-column gap-4 py-2 px-lg-4">
						{partnerData?.data?.userMeansOfIdentification[0]
							?.idCardFrontURL && (
							<ImageContainer>
								<img
									src={
										partnerData?.data?.userMeansOfIdentification[0]
											?.idCardFrontURL
									}
									alt=""
								/>
							</ImageContainer>
						)}
						{partnerData?.data?.userMeansOfIdentification[0]?.idCardBackURL && (
							<ImageContainer>
								<img
									src={
										partnerData?.data?.userMeansOfIdentification[0]
											?.idCardBackURL
									}
									alt=""
								/>
							</ImageContainer>
						)}
					</div>
				}
			/>
		</div>
	);
};
