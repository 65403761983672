import { FC, useState } from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';
import styledComponents from 'styled-components';
import {
    IPasswordInputComp,
    IPhoneInputComp,
    ITextInputComp,
} from '../../types';
import { colors } from '../colors';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

export const TextInputComp: FC<ITextInputComp> = ({
    LabelText,
    labelText,
    LabelClassName,
    inputClassName,
    inputType,
    required,
    desc,
    errors,
    ...props
}) => {
    return (
        <TextInputCompStyle>
            <div className="w-100">
                <Form.Group className="w-100">
                    {(LabelText || labelText) && (
                        <Form.Label className={LabelClassName}>
                            {LabelText || labelText}
                            {required && (
                                <span
                                    className="text6"
                                    style={{ color: 'red' }}
                                >
                                    *
                                </span>
                            )}
                        </Form.Label>
                    )}
                    <Form.Control
                        type={inputType}
                        className={inputClassName}
                        {...props}
                    />
                    {desc && (
                        <Form.Text className="text-muted">{desc}</Form.Text>
                    )}
                    {errors?.map((error: any, i: any) => (
                        <small className="text-danger mt-2" key={i}>
                            {error}
                        </small>
                    ))}
                </Form.Group>
            </div>
        </TextInputCompStyle>
    );
};

export const PasswordInputComp: FC<IPasswordInputComp> = ({
    type,
    placeholder,
    LabelText,
    labelText,
    LabelClassName,
    required,
    errors,
    ...props
}) => {
    const [show, setShow] = useState(false);
    return (
        <TextInputCompStyle>
            <div className="w-100  bg8">
                <InputGroup className="w-100">
                    {(LabelText || labelText) && (
                        <Form.Label className={`${LabelClassName} w-100`}>
                            {LabelText || labelText}
                            {required && (
                                <span
                                    className="text6"
                                    style={{ color: 'red' }}
                                >
                                    *
                                </span>
                            )}
                        </Form.Label>
                    )}
                    <FormControl
                        className="border-1 rounded"
                        type={show ? 'text' : type}
                        placeholder={placeholder}
                        {...props}
                    />
                    <InputGroup.Text
                        onClick={() => setShow(!show)}
                        style={{ cursor: 'pointer' }}
                    >
                        {show ? <BsEye /> : <BsEyeSlash />}
                    </InputGroup.Text>
                    {errors?.map((error: any, i: any) => (
                        <small className="text-danger mt-2 w-100" key={i}>
                            {error}
                        </small>
                    ))}
                </InputGroup>
            </div>
        </TextInputCompStyle>
    );
};

export const PhoneInputComp: FC<IPhoneInputComp> = ({
    LabelText,
    labelText,
    InputClassName,
    value,
    onChange,
    errors,
    ...props
}) => {
    return (
        <DropDownStyle>
            <div className="w-100">
                <Form.Group className="mb-3">
                    {(LabelText || labelText) && (
                        <Form.Label className={'label'}>
                            {LabelText || labelText}
                        </Form.Label>
                    )}
                    <div>
                        <PhoneInput
                            className="border border-1 py-1 my-1 rounded px-1"
                            placeholder={'+234'}
                            international
                            defaultCountry="NG"
                            value={value || props?.defaultValue}
                            onChange={(e: any | undefined) => onChange(e)}
                            {...props}
                        />
                    </div>
                </Form.Group>
            </div>
        </DropDownStyle>
    );
};

const TextInputCompStyle = styled.div`
    width: 100%;
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
`;
const DropDownStyle = styledComponents.div`
.label{
	font-weight: 400;
	font-size: 16px;
	line-height: 15px;
	// color: ${colors.black80};
}
input{
	border: none;
	outline: none;
  height: 2rem;
}`;
