import { FC } from "react";
import styled from "styled-components";
import { colors } from "../colors";
import { IRequestCArd } from "../../types";
import walletgb from "../../images/wallet.svg";
import cardLogo from "../../images/bankCardLogo.svg";
import { ReactComponent as EditIcon } from "../../images/edit.svg";
import { ReactComponent as GoodIcon } from "../../images/good_icon.svg";
import React from "react";

export const Card: FC<{ children: any }> = ({ children }) => {
	return <CardContainer>{children}</CardContainer>;
};

export const RequestCard: FC<IRequestCArd> = ({
	image,
	name,
	handleCancle,
	handleApprove,
}) => {
	return (
		<RequestCardContainer>
			<ImageWrap>
				<Image src={image} alt="" />
			</ImageWrap>
			<Div>
				<Name>{name}</Name>
				<ButtonWrap>
					<Button cancel="cancel" onClick={handleCancle}>
						Cancel
					</Button>
					<Button onClick={handleApprove}>Approve</Button>
				</ButtonWrap>
			</Div>
		</RequestCardContainer>
	);
};

export const WalletCard = ({
	amount,
	symbol,
	onClick,
}: {
	amount: number;
	symbol: string;
	onClick: () => void;
}) => {
	return (
		<WalletWrap onClick={onClick}>
			<WalletText>Available balance</WalletText>
			<BalanceText>
				{symbol}
				{amount}
			</BalanceText>
		</WalletWrap>
	);
};
export const BankCard = ({
	bgColor,
	bankName,
	acctNumber,
}: {
	bgColor?: number | any;
	bankName?: string;
	acctNumber?: string;
}) => {
	return (
		<BankCardWrap color={bgColor} >
			<Flexbox>
				<Bank>{bankName}</Bank>
				<BankText>Account Number</BankText>
				<AccountNumber>{acctNumber}</AccountNumber>
			</Flexbox>
			<div>
				<img src={cardLogo} alt="" />
			</div>
		</BankCardWrap>
	);
};

export const ConfigCard = () => {
	const [disable, setDisable] = React.useState(true);
	return (
		<ConfigCardWrap>
			<ConfigText>Supervisor Transaction fee</ConfigText>
			<Div2>
				<input disabled={disable} placeholder="$2" />
				{disable ? (
					<EditIcon onClick={() => setDisable(false)} />
				) : (
					<GoodIcon onClick={() => setDisable(true)} />
				)}
			</Div2>
		</ConfigCardWrap>
	);
};

const CardContainer = styled.div`
	background: ${colors.white};
	border: 1px solid rgba(0, 0, 0, 0.06);
	border-radius: 10px;
	display: flex;
	align-items: center;
	padding: 15px;
	width: calc(33.33% - 52px);
	height: 70px;

	@media (max-width: 768px) {
		width: calc(50% - 47px);
	}
	@media (max-width: 600px) {
		width: calc(100%);
	}
`;

const RequestCardContainer = styled(CardContainer)`
	display: flex;
	column-gap: 15px;
	height: 80px;
	border: 1px solid rgba(0, 0, 0, 0.04);
	box-shadow: 1px 17px 44px rgba(3, 2, 41, 0.03);
	border-radius: 10px;
	@media (max-width: 600px) {
		width: calc(100%);
	}
`;
const Image = styled.img``;
const ImageWrap = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	overflow: hidden;
	background: ${colors.Primary8};
	@media (max-width: 600px) {
		width: 50px;
		height: 50px;
	}
	img {
		width: 100%;
	}
`;
const Div = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 15px;
	width: 60%;
`;
const Name = styled.p`
	font-weight: 600;
	font-size: 20px;
	line-height: 27px;
	color: ${colors.black};
`;
const ButtonWrap = styled.div`
	display: flex;
	column-gap: 15px;
`;
const Button = styled.button<{ cancel?: string }>`
	width: 100%;
	height: 36px;
	background: ${({ cancel }) => (cancel ? colors.red : colors.green1)};
	color: ${colors.white};
	border-radius: 5px;
	font-weight: 700;
	font-size: 13px;
	line-height: 16px;
	border: none;
`;
const WalletWrap = styled.div`
	background: url(${walletgb}) no-repeat;
	width: calc(25% - 10px);
	height: 140px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0px 20px;
	row-gap: 8px;
	cursor: pointer;
	overflow: hidden;
	@media (max-width: 1800px) {
		width: calc(33% - 10px);
	}
	@media (max-width: 768px) {
		width: calc(50% - 10px);
	}
	@media (max-width: 600px) {
		width: calc(100% - 0px);
	}
`;
const WalletText = styled.p`
	font-weight: 600;
	font-size: 18px;
	color: ${colors.secondary70};
`;
const BalanceText = styled.p`
	font-weight: 800;
	font-size: 36px;
	color: ${colors.white};
`;
const BankCardWrap = styled.div<{ color?: number }>`
	background: ${(props) =>
		props.color === 0 || 4
			? colors.green70
			: props.color === 2 || 6
			? colors.blue20
			: colors.secondary60};
	color: ${(props) =>
		props.color === 1 ? colors.white : props.color === 2 ? colors.white : colors.black};
	width: calc(25% - 10px);
	height: 130px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 15px;
	row-gap: 8px;
	img {
		width: 80px;
		height: 80px;
	}
	@media (max-width: 1800px) {
		width: calc(33% - 12px);
	}
	@media (max-width: 768px) {
		width: calc(50% - 10px);
	}
	@media (max-width: 600px) {
		width: calc(100% - 0px);
	}
`;
const Flexbox = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 5px;
`;
const Bank = styled.p`
	font-weight: 800;
	font-size: 26px;
`;
const BankText = styled.p`
	font-weight: 600;
	font-size: 16px;
`;
const AccountNumber = styled.p`
	font-weight: 300;
	font-size: 16px;
`;
const ConfigCardWrap = styled.div`
	background: ${colors.white};
	border: 1px solid #e9e9e9;
	box-shadow: 1px 17px 44px rgba(3, 2, 41, 0.03);
	border-radius: 10px;
	height: 130px;
	padding: 0px 20px;
	width: calc(25% - 20px);
	@media (max-width: 1800px) {
		width: calc(33% - 12px);
	}
	@media (max-width: 768px) {
		width: calc(50% - 10px);
	}
	@media (max-width: 600px) {
		width: calc(100% - 0px);
	}
`;
const ConfigText = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	margin-top: 15px;
`;
const Div2 = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 30px 0px;
	input {
		padding: 5px 0px;
		border: none;
		border-bottom: 1px solid ${colors.black50};
		outline: none;
		color: ${colors.black20};
		&:disabled {
			border: none;
		}
	}
	svg {
		width: 17px;
		fill: ${colors.black50};
		cursor: pointer;
		stroke: ${colors.Primary1};
	}
`;
