import {
    BarLine,
    ButtonContainer,
    Container,
    InputWrap,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    DeleteModal,
    FileUploaderComp,
    Loader,
    PdfDownloader,
    SelectComp,
    TextInputComp,
} from '../../common/component';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { CurrencyTableHeader } from '../../common/utils/dataUtils';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { CustomButton } from '../../common/component/button';
import { ModalComp } from '../../common/component/Ui/modalComp';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as CurrencyIcon } from '../../common/images/currency_icon.svg';
import {
    useAddCurrencyMutation,
    useCurrencyStatsQuery,
    useDeleteCurrencyMutation,
    useAllCurrencyQuery,
    useEditCurrencyMutation,
    useGetSingleCurrencyQuery,
} from '../../store/currencyApi';
import { Controller, useForm } from 'react-hook-form';
import { showErrorToast, showSuccessToast } from '../../common/utils/toast';
import { ICurrency } from '../../store/currencyApi/interface';

export const Currency = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dateSearch, setDateSearch] = useState('');
    const [dropDownTerm, setDropDownTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [addCurrency, { isLoading }] = useAddCurrencyMutation();
    const [editCurrency, { isLoading: isEditing }] = useEditCurrencyMutation();
    const { data: currencyStats } = useCurrencyStatsQuery();
    const [deleteCurrency, { isLoading: isDeleting }] =
        useDeleteCurrencyMutation();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const [currencyData, setCurrencyData] = useState<string>('');
    const [deleteCurrencyItem, setDeleteCurrencyItem] =
        useState<boolean>(false);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: allCurrencyList,
        isLoading: isGettingAllCurrency,
        isFetching,
    } = useAllCurrencyQuery(
        {
            ...paginate,
            currencyType: dropDownTerm.toLowerCase(),
            name: searchTerm,
            createdAt: dateSearch,
        },
        {
            refetchOnMountOrArgChange: true,
        },
    );
    const [currencyId, setCurrencyId] = useState('');
    const { data: singleCurrencyData } = useGetSingleCurrencyQuery(currencyId);

    useEffect(() => {
        setTotalRows(allCurrencyList?.pagination?.totalDocumentCount as number);
    }, [allCurrencyList?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allCurrencyList?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const handleDeleteCurrency = () => {
        onHandleDelete(currencyData);
        setDeleteCurrencyItem(false);
    };

    const Items = [
        {
            name: 'Edit item',
            icon: <BiEditAlt size={15} />,
            OnClick: (data: any) => {
                setCurrencyId(data?._id);
                setShowEditModal(true);
            },
        },
        {
            name: 'Delete item',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: any) => {
                setDeleteCurrencyItem((prev) => !prev);
                setCurrencyData(data?._id);
            },
        },
    ];

    // add currency
    const currency = useForm({
        defaultValues: {
            name: '',
            imageUrl: '',
            currencySymbol: '',
            currencyType: '',
        },
    });
    const { handleSubmit, control, reset } = currency;

    // edit currency
    const editCurrencyForm = useForm({
        defaultValues: {
            newData: {
                name: '',
                imageUrl: '',
                currencySymbol: '',
                currencyType: '',
            },
        },
    });
    const {
        handleSubmit: handleEditSubmit,
        control: editControl,
        setValue,
    } = editCurrencyForm;

    useEffect(() => {
        if (singleCurrencyData?.data?._id) {
            setValue(
                'newData',
                {
                    name: singleCurrencyData?.data?.name,
                    imageUrl: singleCurrencyData?.data?.imageUrl,
                    currencySymbol: singleCurrencyData?.data?.currencySymbol,
                    currencyType: singleCurrencyData?.data?.currencyType,
                },
                { shouldValidate: true },
            );
        }
    }, [singleCurrencyData, setValue]);

    const onHandleSubmit = (value: {
        name: string;
        currencySymbol: string;
        currencyType: string;
        imageUrl: string;
    }) => {
        addCurrency(value)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowModal(false);
                reset();
            })
            .catch((error) => {
                if (!value.imageUrl)
                    return showErrorToast(error?.data?.message[0]);

                showErrorToast(error?.data?.message);
            });
    };

    const onHandleEdit = (value: {
        newData: {
            name: string;
            imageUrl: string;
            currencySymbol: string;
            currencyType: string;
        };
    }) => {
        const { name, currencySymbol, currencyType, imageUrl } = value?.newData;
        let payload = {
            name: name,
            imageUrl: imageUrl,
            currencySymbol: currencySymbol,
            currencyType: currencyType,
            currencyId: singleCurrencyData?.data?._id as string,
        };
        editCurrency(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowEditModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    const onHandleDelete = (id: string) => {
        deleteCurrency(id)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'Currency name', key: 'name' },
        {
            label: 'Currency type',
            key: 'currencyType',
        },
        {
            label: 'Currency symbol',
            key: 'currencySymbol',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { header: 'Currency name', dataKey: 'name' },
        {
            header: 'Currency type',
            dataKey: 'currencyType',
        },
        {
            header: 'Currency symbol',
            dataKey: 'currencySymbol',
        },
        {
            header: 'Date Created',
            dataKey: 'createdAt',
        },
    ];

    const refinePdfTableData = () => {
        if (allCurrencyList && allCurrencyList?.data.length > 0) {
            let result = allCurrencyList?.data.map((_item) => {
                if (
                    _item?.name.toLowerCase() === 'btc' ||
                    _item?.name.toLowerCase() === 'bitcoin'
                ) {
                    return {
                        ..._item,
                        currencySymbol: 'B',
                        createdAt: _item?.createdAt.split('T')[0],
                    };
                } else if (
                    _item?.name.toLowerCase() === 'ngn' ||
                    _item?.name.toLowerCase() === 'naira'
                ) {
                    return {
                        ..._item,
                        currencySymbol: 'N',
                        createdAt: _item?.createdAt.split('T')[0],
                    };
                } else {
                    return {
                        ..._item,
                        createdAt: _item?.createdAt.split('T')[0],
                    };
                }
            });

            return result;
        }

        return [];
    };

    return (
        <Container>
            <Header>Currency</Header>
            {isGettingAllCurrency || isDeleting ? <Loader /> : null}
            <Grid>
                <Counter
                    counter_title="Total currencies"
                    count={currencyStats?.data?.totalCurrencies || '0'}
                    iconFill="black"
                    iconBG="blue"
                >
                    <CurrencyIcon />
                </Counter>
                <Counter
                    counter_title="Active currencies"
                    count={currencyStats?.data?.activeCurrencies || '0'}
                    iconFill="green"
                    iconBG="green"
                >
                    <CurrencyIcon />
                </Counter>
                <Counter
                    counter_title="Crypto currencies"
                    count={currencyStats?.data?.cryptoCurrencies || '0'}
                >
                    <CurrencyIcon />
                </Counter>
                <Counter
                    counter_title="Fiat trades"
                    count={currencyStats?.data?.fiatCurrencies || '0'}
                    iconFill="red"
                    iconBG="red"
                >
                    <CurrencyIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={8}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col
                        xs={10}
                        sm={4}
                        className="d-flex align-items-center my-3"
                    >
                        <SelectComp
                            selectText="All currencies"
                            arrayComp={['Crypto', 'Fiat']}
                            onChange={(e: any) =>
                                setDropDownTerm(e.target.value)
                            }
                        />
                        <TotalResultText>{`${
                            allCurrencyList?.data?.length || 0
                        } result`}</TotalResultText>
                    </Col>
                    <Col
                        xs={10}
                        sm={5}
                        className="d-flex align-items-center my-3"
                    >
                        <InputWrap>
                            <img src={SearchIcon} alt="" />
                            <FormControl
                                aria-label="Amount (to the nearest dollar)"
                                placeholder="Search"
                                className="border-start-0 border-bottom-0 border-top-0"
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </InputWrap>
                    </Col>

                    <Col
                        xs={10}
                        sm={4}
                        className="d-flex align-items-center my-3"
                    >
                        <InputWrap>
                            <img src={SearchIcon} alt="" />
                            <FormControl
                                className="border-start-0 border-bottom-0 border-top-0"
                                onChange={(e) => setDateSearch(e.target.value)}
                                type="date"
                            />
                        </InputWrap>
                    </Col>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'CURRENCY RECORD'}
                            {...{
                                columns: headersPdf,
                                body: refinePdfTableData(),
                            }}
                        />
                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={allCurrencyList?.data}
                        />
                    </ButtonContainer>
                    <CustomButton
                        text="Create currency"
                        onClick={() => setShowModal(true)}
                    />
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={CurrencyTableHeader(Items)}
                    data={allCurrencyList?.data as ICurrency[] | any[]}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>

            <ModalComp
                show={showModal}
                handleClose={() => setShowModal(false)}
                centered
                close={true}
                modalTitle="Create Currency"
                paragraph="Fill in the  the forms to create a currency"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleSubmit(onHandleSubmit)}>
                            <div className="my-3">
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: 'Currency name is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.name?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Currency Name"
                                                placeholder="Enter currency name"
                                                inputClassName={'py-2 '}
                                                name="name"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="currencyType"
                                    control={control}
                                    rules={{
                                        required: 'Currency type is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.currencyType?.message;
                                        return (
                                            <SelectComp
                                                labelText="Currency Type"
                                                arrayComp={['crypto', 'fiat']}
                                                name="currencyType"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>{' '}
                            <div className="my-3">
                                <Controller
                                    name="currencySymbol"
                                    rules={{
                                        required: 'Currency Symbol is required',
                                    }}
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.currencySymbol?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Currency Symbol"
                                                placeholder="Enter currency symbol"
                                                inputClassName={'py-2'}
                                                name="currencySymbol"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="imageUrl"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                    }) => {
                                        return (
                                            <FileUploaderComp
                                                isImageSmall={true}
                                                imageSmall={'60px'}
                                                defaultValue={
                                                    singleCurrencyData?.data
                                                        ?.imageUrl as string
                                                }
                                                {...{ value, onChange }}
                                            />
                                        );
                                    }}
                                />
                                <label
                                    htmlFor="imageUpload"
                                    style={{ textAlign: 'center' }}
                                >
                                    Upload Image
                                </label>
                            </div>
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isLoading
                                            ? 'Create Currency'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />

            {/* edit modal */}
            <ModalComp
                show={showEditModal}
                handleClose={() => setShowEditModal(false)}
                centered
                close={true}
                modalTitle="Edit Currency"
                paragraph="Fill in the  the forms to edit a currency"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleEditSubmit(onHandleEdit)}>
                            <div className="my-3">
                                <Controller
                                    name="newData.name"
                                    control={editControl}
                                    rules={{
                                        required: 'Currency name is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.name?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Currency Name"
                                                placeholder="Enter currency name"
                                                inputClassName={'py-2 '}
                                                name="name"
                                                defaultValue={
                                                    singleCurrencyData?.data
                                                        ?.name
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.currencyType"
                                    control={editControl}
                                    rules={{
                                        required: 'Currency type is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.currencyType
                                                ?.message;
                                        return (
                                            <SelectComp
                                                labelText="Currency Type"
                                                arrayComp={['crypto', 'fiat']}
                                                name="currencyType"
                                                defaultValue={
                                                    singleCurrencyData?.data
                                                        ?.currencyType
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>{' '}
                            <div className="my-3">
                                <Controller
                                    name="newData.currencySymbol"
                                    control={editControl}
                                    render={({
                                        field: { value, onChange },
                                    }) => {
                                        return (
                                            <TextInputComp
                                                labelText="Currency Symbol"
                                                placeholder="Enter currency symbol"
                                                inputClassName={'py-2'}
                                                name="currencySymbol"
                                                defaultValue={
                                                    singleCurrencyData?.data
                                                        ?.currencySymbol
                                                }
                                                {...{ value, onChange }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.imageUrl"
                                    control={editControl}
                                    render={({
                                        field: { value, onChange },
                                    }) => {
                                        return (
                                            <FileUploaderComp
                                                isImageSmall={true}
                                                imageSmall={'60px'}
                                                defaultValue={
                                                    singleCurrencyData?.data
                                                        ?.imageUrl as string
                                                }
                                                {...{ value, onChange }}
                                            />
                                        );
                                    }}
                                />
                                <label
                                    htmlFor="imageUpload"
                                    style={{ textAlign: 'center' }}
                                >
                                    Upload Image
                                </label>
                            </div>
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isEditing
                                            ? 'Edit Currency'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />
            <DeleteModal
                showDeleteModal={deleteCurrencyItem}
                cancelDelete={() => setDeleteCurrencyItem(false)}
                confirmDelete={handleDeleteCurrency}
                deleteItem="Currency"
                isDeleting={isDeleting}
            />
        </Container>
    );
};
