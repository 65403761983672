import {
	AuthCard,
	ButtomDiv,
	Paragraph,
	ImageWrapper,
	SubHeading,
	Mode,
} from "./style";
import {
	CustomButton,
	PasswordInputComp,
	PinCodeInput,
} from "../../common/component";
import { ReactComponent as AuthIcon } from "../../common/images/auth_icon.svg";
import back_arrow from "../../common/images/back_arrow.svg";
import { BackButton } from "../users/style";
import { useEffect, useState } from "react";
import {
	useInitializeTwoFactorMutation,
	useFinalizeTwoFactorMutation,
	useDisableTwoFactorMutation,
} from "../../store/twoFactor";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { showErrorToast, showSuccessToast } from "../../common/utils/toast";
import QRCode from "qrcode";
import { setUser } from "../../store/auth";

export const TwoFactor = () => {
	const dispatch = useAppDispatch();
	const [active, setActive] = useState(1);
	const [password, setPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [QRCodeUrl, setQRCodeUrl] = useState("");
	const [otp, setOtp] = useState("");
	const onChange = (value: string) => setOtp(value);
	const { user } = useAppSelector((state: any) => state.auth);
	const twoFactorQR = localStorage.getItem("IbxTwoFactor");
	const [initializeTwoFactor, { isLoading: isInitializing }] =
		useInitializeTwoFactorMutation();
	const [finalizeTwoFactor, { isLoading: isFinalizing }] =
		useFinalizeTwoFactorMutation();
	const [disableTwoFactor, { isLoading: isDisabling }] =
		useDisableTwoFactorMutation();

	//generate QR url
	useEffect(() => {
		const generateQR = async (text: string) => {
			try {
				setQRCodeUrl(await QRCode.toDataURL(text));
			} catch (err) {
				console.error(err);
			}
		};
		generateQR(twoFactorQR as string);
	}, [twoFactorQR]);

	//initialize two factor
	const onInitializeTwoFactor = () => {
		let payload = {
			userId: user?._id as string,
		};
		initializeTwoFactor(payload)
			.unwrap()
			.then((result) => {
				showSuccessToast(result?.message);
				localStorage.setItem(
					"IbxTwoFactor",
					result?.data?.otpauth_url as string
				);
				setActive(2);
			})
			.catch((error) => {
				showErrorToast(error?.data?.message);
			});
	};

	//finalize two factor
	const onFinalizeTwoFactor = () => {
		if (otp === "") {
			showErrorToast("Input can't be empty");
		} else {
			let payload = {
				userId: user?._id as string,
				userToken: otp,
			};
			finalizeTwoFactor(payload)
				.unwrap()
				.then((result) => {
					showSuccessToast(result?.message);
					localStorage.removeItem("IbxTwoFactor");
					dispatch(setUser(result?.data));
				})
				.catch((error) => {
					showErrorToast(error?.data?.message);
				});
		}
	};

	//disable two factor
	const onDisableTwoFactor = () => {
		if (password === "") {
			setErrorMessage("*password can't be empty");
		} else {
			let payload = {
				userId: user?._id as string,
				userPassword: password,
			};
			disableTwoFactor(payload)
				.unwrap()
				.then((result) => {
					showSuccessToast(result?.message);
					setActive(1);
					dispatch(setUser(result?.data));
				})
				.catch((error) => {
					showErrorToast(error?.data?.message);
				});
		}
	};

	return (
		<div className="d-flex gap-3 py-4 px-sm-5 mt-4">
			{!user?.twoFactorAuthEnabled ? (
				<>
					{active === 1 ? (
						<AuthCard>
							<ImageWrapper className="d-flex align-self-center">
								<AuthIcon />
							</ImageWrapper>
							<Paragraph className="px-3">
								Protect your account with 2-factor authentication and prevent
								hackers from accessing your account
							</Paragraph>
							<ButtomDiv className="d-flex justify-content-between align-items-center p-3 mt-2">
								<Mode>OFF</Mode>
								<CustomButton
									text={isInitializing ? "Switching..." : "Switch On"}
									onClick={() => onInitializeTwoFactor()}
								/>
							</ButtomDiv>
						</AuthCard>
					) : active === 2 ? (
						<AuthCard>
							<div className="d-flex flex-column align-items-center gap-3 px-3 pb-3">
								<SubHeading>
									Scan the QR code using any authenticator app to get an OTP
								</SubHeading>
								<ImageWrapper>
									<img src={QRCodeUrl} alt="" />
								</ImageWrapper>
							</div>
							<ButtomDiv className="d-flex justify-content-between align-items-center p-3 mt-3">
								<BackButton onClick={() => setActive(1)}>
									<img src={back_arrow} alt="" /> Back
								</BackButton>
								<CustomButton text="Enter OTP" onClick={() => setActive(3)} />
							</ButtomDiv>
						</AuthCard>
					) : (
						<AuthCard>
							<div className="d-flex flex-column gap-5 px-3 pb-3">
								<SubHeading>
									Enter your valid two-step verification pin
								</SubHeading>
								<div>
									<div className="d-flex gap-3 mb-3">
										<PinCodeInput length={6} setValue={onChange} />
									</div>
								</div>
							</div>
							<ButtomDiv className="d-flex justify-content-between align-items-center p-3 mt-1">
								<BackButton onClick={() => setActive(2)}>
									<img src={back_arrow} alt="" /> Back
								</BackButton>
								<CustomButton
									text={isFinalizing ? "Loading..." : "Turn on"}
									onClick={() => onFinalizeTwoFactor()}
								/>
							</ButtomDiv>
						</AuthCard>
					)}
				</>
			) : (
				<>
					{active === 1 ? (
						<AuthCard>
							<ImageWrapper className="d-flex align-self-center">
								<AuthIcon />
							</ImageWrapper>
							<Paragraph className="px-3">
								Your account is protected with 2-factor. Switching it off won't
								guarantee maximum protection. Switch off anyways?
							</Paragraph>
							<ButtomDiv className="d-flex justify-content-between align-items-center p-3 mt-2">
								<CustomButton
									text={"Switch off"}
									onClick={() => setActive(2)}
								/>
								<Mode>ON</Mode>
							</ButtomDiv>
						</AuthCard>
					) : (
						<AuthCard>
							<div className="d-flex flex-column gap-5 px-3 pb-3">
								<SubHeading>Enter your password to disable 2-factor</SubHeading>
								<div>
									<PasswordInputComp
										labelText="Password"
										placeholder="Enter your password"
										type="password"
										errors={[errorMessage]}
										onChange={(e: any) => {
											setPassword(e.target.value);
											setErrorMessage("");
										}}
									/>
								</div>
							</div>
							<ButtomDiv className="d-flex justify-content-between align-items-center p-3 mt-1">
								<BackButton onClick={() => setActive(1)}>
									<img src={back_arrow} alt="" /> Back
								</BackButton>
								<CustomButton
									text={isDisabling ? "Loading..." : "Turn off"}
									onClick={() => onDisableTwoFactor()}
								/>
							</ButtomDiv>
						</AuthCard>
					)}
				</>
			)}
		</div>
	);
};
