import styled from "styled-components";
import { colors } from "../../../common/component";

export const AuthContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 16px);
`;

export const ContentContainer = styled.div`
	width: 35%;
	@media (max-width: 768px) {
		width: 50%;
	}

	@media (max-width: 600px) {
		width: 100%;
	}
	
`;
export const Header = styled.h3`
	font-weight: 700;
	font-size: 32px;
	text-align: center;
	line-height: 29px;
	color: ${colors.neutral_black};
	@media (max-width: 600px) {
		font-size: 28px;
	}
`;
export const Paragraph = styled.p`
	font-weight: 400;
	font-size: 14px;
	text-align: center;
	color: ${colors.light_black};
`;
export const ForgetPassword = styled.p`
	font-size: 15px;
	line-height: 20px;
	text-align: right;
	font-weight: 500;
	margin: 8px 0px;
	color: ${colors.Primary1};
	cursor: pointer;
`;
export const Button = styled.button`
	padding: 10px 0px;
	width: 180px;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	text-align: center;
	color: ${colors.white};
	border-radius: 15px;
	background: ${colors.Primary1};
	border: 1px solid ${colors.Primary1};
	cursor: pointer;
`;
export const PrivacyText = styled.p`
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	margin-bottom: 3px; 
	color: ${colors.neutral_grey};
	span{
		color: ${colors.purple2}
	}
`;

export const IBXLogo = styled.div`
	img {
		width: 55px;
		height: 50px;
		background: ${colors.Primary1};
		border-radius: 8px;
	}
`;