import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import styled from "styled-components";
import { colors } from "../colors";
import { BsDownload } from "react-icons/bs";

export const PdfDownloader = ({
	fileName,
	...rest
}: {
	fileName: string | string[];
}) => {
	const exportPDF = () => {
		const unit = "pt";
		const size = "A4"; // Use A1, A2, A3 or A4
		const orientation = "portrait"; // portrait or landscape

		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);

		doc.setFontSize(15);

		const title = `${fileName ? fileName : "IBX ADMIN RECORD"}`;

		doc.text(title, marginLeft, 40);
		autoTable(doc, {
			startY: 60,
			// styles: { fillColor: [37, 50, 128] },
			...rest,
		});
		doc.save("IBX_Admin_file.pdf");
	};
	return (
		<DownloadButton onClick={() => exportPDF()}>
			PDF
			<span className="ms-2">
				<BsDownload size={15} />
			</span>
		</DownloadButton>
	);
};

const DownloadButton = styled.button`
	background: none;
	border: none;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	padding: 0px 5px;
	color: ${colors.purple100};
	cursor: pointer;

	&:hover {
		font-weight: 600;
	}
`;
