const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const getDateString = (dateString: string | Date) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const monthName = months[date.getMonth()];
    const year = date.getFullYear();
    const day = date.getDate();
    const th =
        day % 10 === 1 && (day < 10 || day > 20)
            ? 'st'
            : day % 10 === 2 && (day < 10 || day > 20)
            ? 'nd'
            : day % 10 === 3 && (day < 10 || day > 20)
            ? 'rd'
            : 'th';
    return `${day}${th} ${monthName} ${year}`;
};
export const timeFormatter = (dateString: string | Date | number) => {
    const date = new Date(dateString);
    var hours = date.getHours();
    var minutes: number | string = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
};
