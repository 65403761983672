import styled from "styled-components";
import { colors } from "../../common/component";

export const Option = styled.div`
	color: ${colors.Primary2};
	background: ${colors.Primary6};
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	float: right;
	border-radius: 15px;
	padding: 8px 20px;
	clear: both;
`;
export const SettingsTextGrid = styled.div`
	display: flex;
	column-gap: 20px;
	width: 120%;
	@media (max-width: 376px) {
	width: 140%;
	}
	@media (max-width: 320px) {
	width: 170%;
	}
`;
export const AuthCard = styled.div`
	width: 380px;
	display: flex;
	flex-direction: column;
	row-gap: 25px;
	padding-top: 15px;
	margin-bottom: 20px;
	overflow: hidden;
	background: ${colors.white};
	border: 1px solid ${colors.black10};
	border-radius: 12px;
	@media (max-width: 425px) {
		width: 100%;
	}
`;
export const Paragraph = styled.p`
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	text-align: center;
	color: ${colors.black};
`;
export const Mode = styled.p`
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: ${colors.black100};
`;
export const ButtomDiv = styled.div`
	background: #f9fafa;
	border-radius: 0px 0px 8px 8px;
`;
export const SubHeading = styled.p`
	font-weight: 400;
	font-size: 20px;
	line-height: 27px;
	color: ${colors.black};
	text-align: center;
`;
export const ImageWrapper = styled.div`
	width: 60%;
	height: 150px;
	object-fit: contain;
	img {
		width: 100%;
	}
	svg {
		width: 100%;
		height: 100%;
	}
`;
