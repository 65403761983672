import { downloadExcel } from "react-export-table-to-excel";
import styled from "styled-components";
import { colors } from "../colors";
import { BsDownload } from "react-icons/bs";

export const ExcelDownloader = () => {
	const header = ["Firstname", "Lastname", "Age"];
	// const body = [
	// 	["Edison", "Padilla", 14],
	// 	["Cheila", "Rodrigez", 56],
	// ];

	/**
	 * @description:
	 *  also accepts an array of objects; the method (downloadExcel) will take
	 *  as order of each column, the order that each property of the object brings with it.
	 *  the method(downloadExcel) will only take the value of each property.
	 */
	const body2 = [
		{ firstname: "Edison", lastname: "Padilla", age: 14 },
		{ firstname: "Cheila", lastname: "Rodrigez", age: 56 },
	];
	function handleDownloadExcel() {
		downloadExcel({
			fileName: "Excel report",
			sheet: "react-table-to-excel",
			tablePayload: {
				header,
				// accept two different data structures
				body:  body2,
			},
		});
	}
	return (
		<DownloadButton onClick={handleDownloadExcel}>
			EXCEL
			<span className="ms-2">
				<BsDownload size={15} />
			</span>
		</DownloadButton>
	);
};

const DownloadButton = styled.button`
	background: none;
	border: none;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	padding: 0px 5px;
	color: ${colors.purple100};
	cursor: pointer;

	&:hover {
		font-weight: 600;
	}
`;
