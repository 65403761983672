import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Response } from '../genericInterface';
import { baseQuery } from '../api';

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        userLogin: builder.mutation<
            Response<IUser>,
            { email?: string; password: string }
        >({
            query: (body) => ({
                url: '/user/api/v1/auth/login',
                method: 'PATCH',
                body,
            }),
        }),
        changePassword: builder.mutation<
            Response<IUser>,
            { email?: string; oldPassword: string; newPassword: string }
        >({
            query: (body) => ({
                url: '/user/api/v1/auth/change-password',
                method: 'PATCH',
                body,
            }),
        }),
        initializeResetPassword: builder.mutation<
            Response<IUser>,
            { email: string }
        >({
            query: (body) => ({
                url: '/user/api/v1/auth/initialize-reset-password',
                method: 'PATCH',
                body,
            }),
        }),
        resetPassword: builder.mutation<
            Response<IUser>,
            { token: string; email: string; password: string }
        >({
            query: (body) => ({
                url: '/user/api/v1/auth/reset-password',
                method: 'PATCH',
                body,
            }),
        }),
        logout: builder.mutation<Response<IUser>, void>({
            query: () => ({
                url: '/user/api/v1/auth/logout',
                method: 'POST',
            }),
        }),
    }),
});

export const {
    useUserLoginMutation,
    useChangePasswordMutation,
    useInitializeResetPasswordMutation,
    useResetPasswordMutation,
    useLogoutMutation,
} = authApi;
