import { AiOutlineDelete } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import {
    ContainerComp,
    CustomButton,
    Loader,
    ModalComp,
    TableCompData,
} from '../../common/component';
import { ApplicantsTableHeader } from '../../common/utils/dataUtils';
import { showSuccessToast, showErrorToast } from '../../common/utils/toast';
import {
    useDeleteApplicantMutation,
    useGetAllJobApplicantsWithJobIdQuery,
    useGetSingleApplicantQuery,
} from '../../store/jobAPi';
import { IApplicant } from '../../store/jobAPi/interface';
import { useState, useEffect } from 'react';
import { Flexbox2 } from '../currencyPair/style';

export const ViewApplicants = () => {
    const param = useParams();
    const jobId = param.id as string;
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [deleteModal, setDeleteModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const paginate = {
        jobId: jobId,
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: allApplicants,
        isLoading,
        isFetching,
    } = useGetAllJobApplicantsWithJobIdQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });
    const [deleteApplicant, { isLoading: isDeleting }] =
        useDeleteApplicantMutation();
    const [applicantId, setApplicantId] = useState('');
    useGetSingleApplicantQuery(applicantId);

    useEffect(() => {
        setTotalRows(allApplicants?.pagination?.totalDocumentCount as number);
    }, [allApplicants?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allApplicants?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const Items = [
        {
            name: ' Delete ',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (row: any) => {
                setApplicantId(row?._id);
                setDeleteModal(true);
            },
        },
        {
            name: ' Accept',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (row: any) => {
                console.log(row, 'row');
            },
        },
    ];

    const onDeleteApplicant = (id: string) => {
        deleteApplicant(id)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setDeleteModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    return (
        <div>
            {isLoading || isDeleting ? <Loader /> : null}
            <ContainerComp>
                <TableCompData
                    columns={ApplicantsTableHeader(Items)}
                    data={allApplicants?.data as IApplicant[]}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>

            <ModalComp
                show={deleteModal}
                handleClose={() => setDeleteModal(false)}
                centered
                close={true}
                modalTitle={'Delete this Applicant'}
                modalBody={
                    <Flexbox2>
                        <CustomButton
                            className="py-3 mt-4"
                            text={'Cancel'}
                            onClick={() => setDeleteModal(false)}
                        />
                        <CustomButton
                            className="py-3 mt-4"
                            text={!isDeleting ? 'Delete' : 'Loading...'}
                            onClick={() => onDeleteApplicant(applicantId)}
                        />
                    </Flexbox2>
                }
            />
        </div>
    );
};
