import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { advertApi } from './advertApi';
import { appVersionApi } from './appVersionApi';
import auth from './auth';
import { authApi } from './auth/authApi';
import { chatApi } from './chatApi';
import { configurationApi } from './configApi';
import { IBX_ADMIN_USER } from './constant';
import { contactUsApi } from './contactUsApi';
import { currencyApi } from './currencyApi';
import { subscribeApi } from './subscribeApi';
import { currencyPairApi } from './currencyPairApi';
import { jobApi } from './jobAPi';
import { merchantRequestApi } from './merchantRequestApi';
import { partnerRequestApi } from './partnerRequestApi';
import { tradeApi } from './tradeApi';
import { transactionApi } from './transactionApi';
import { twoFactorApi } from './twoFactor';
import { userApi } from './userApi';
import { exchangeRateApi } from './exchangeRatesApi';
import { faqApi } from './faqApi';
import { kycApi } from './kycApi';
import { superMerchantApi } from './superMerchantApi';
import { bankApi } from './bankApi';
import { referralCampaignApi } from './referralCampaignApi';
import { withdrawalRequestApi } from './withdrawalRequestApi';
import { topicApi } from './topicApi';

const userResult = localStorage.getItem(IBX_ADMIN_USER);

export const store = configureStore({
    preloadedState: {
        auth: userResult ? JSON.parse(userResult) : undefined,
    },
    reducer: {
        auth: auth,
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [currencyApi.reducerPath]: currencyApi.reducer,
        [currencyPairApi.reducerPath]: currencyPairApi.reducer,
        [configurationApi.reducerPath]: configurationApi.reducer,
        [advertApi.reducerPath]: advertApi.reducer,
        [tradeApi.reducerPath]: tradeApi.reducer,
        [appVersionApi.reducerPath]: appVersionApi.reducer,
        [referralCampaignApi.reducerPath]: referralCampaignApi.reducer,
        [transactionApi.reducerPath]: transactionApi.reducer,
        [contactUsApi.reducerPath]: contactUsApi.reducer,
        [subscribeApi.reducerPath]: subscribeApi.reducer,
        [jobApi.reducerPath]: jobApi.reducer,
        [twoFactorApi.reducerPath]: twoFactorApi.reducer,
        [chatApi.reducerPath]: chatApi.reducer,
        [partnerRequestApi.reducerPath]: partnerRequestApi.reducer,
        [merchantRequestApi.reducerPath]: merchantRequestApi.reducer,
        [superMerchantApi.reducerPath]: superMerchantApi.reducer,
        [exchangeRateApi.reducerPath]: exchangeRateApi.reducer,
        [faqApi.reducerPath]: faqApi.reducer,
        [kycApi.reducerPath]: kycApi.reducer,
        [bankApi.reducerPath]: bankApi.reducer,
        [withdrawalRequestApi.reducerPath]: withdrawalRequestApi.reducer,
        [topicApi.reducerPath]: topicApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            authApi.middleware,
            userApi.middleware,
            currencyApi.middleware,
            currencyPairApi.middleware,
            configurationApi.middleware,
            advertApi.middleware,
            tradeApi.middleware,
            appVersionApi.middleware,
            referralCampaignApi.middleware,
            transactionApi.middleware,
            contactUsApi.middleware,
            subscribeApi.middleware,
            jobApi.middleware,
            twoFactorApi.middleware,
            chatApi.middleware,
            partnerRequestApi.middleware,
            merchantRequestApi.middleware,
            superMerchantApi.middleware,
            exchangeRateApi.middleware,
            faqApi.middleware,
            kycApi.middleware,
            bankApi.middleware,
            withdrawalRequestApi.middleware,
            topicApi.middleware,
        ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
setupListeners(store.dispatch);
