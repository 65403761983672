import {
    BarLine,
    ButtonContainer,
    Container,
    InputWrap,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    CustomButton,
    Loader,
    ModalComp,
    PdfDownloader,
} from '../../common/component';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import {
    SuperHeader,
    cleanUpSuperMerchantDataForPdf,
} from '../../common/utils/dataUtils';
import { BsEye } from 'react-icons/bs';
import { MdOutlinePersonAddDisabled } from 'react-icons/md';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as UserIcon } from '../../common/images/people_icon.svg';
import { SelectComp } from '../../common/component/Ui/selectComp';
import { Controller, useForm } from 'react-hook-form';
import { showSuccessToast, showErrorToast } from '../../common/utils/toast';
import { IPartnerRequest } from '../../store/partnerRequestApi/interface';
import { AiOutlineDelete } from 'react-icons/ai';
import { useAppSelector } from '../../store/hooks';
import {
    useDeleteSuperMerchantMutation,
    useGetAllSuperMerchantQuery,
    useGetSuperMerchantByStatusQuery,
    useGetSingleSuperMerchantQuery,
    useSuperMerchantStatsQuery,
    useUpdateSuperMerchantMutation,
} from '../../store/superMerchantApi';
import { ISuperMerchant } from '../../store/superMerchantApi/interface';

export const SuperMerchant = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropDownTerm, setDropDownTerm] = useState('');
    const [requestId, setrequestId] = useState('');
    const { user } = useAppSelector((state) => state?.auth);
    const [newArray, setNewArray] = useState<ISuperMerchant[] | any>();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: allMerchantRequest,
        isLoading,
        isFetching,
    } = useGetAllSuperMerchantQuery(
        {
            ...paginate,
            status: dropDownTerm.toLowerCase(),
        },
        {
            refetchOnMountOrArgChange: true,
        },
    );

    const [updateMerchantRequest, { isLoading: isProcessingPartnerRquest }] =
        useUpdateSuperMerchantMutation();
    const [deleteMerchantRequest, { isLoading: isDeleting }] =
        useDeleteSuperMerchantMutation();
    const { data: singleMerchantRequest } =
        useGetSingleSuperMerchantQuery(requestId);
    const { data: merchantRequestStats } = useSuperMerchantStatsQuery();
    const [requestStatusModal, setRequestStatusModal] = useState(false);

    useEffect(() => {
        if (dropDownTerm === '') {
            setTotalRows(
                allMerchantRequest?.pagination?.totalDocumentCount as number,
            );
        }
    }, [allMerchantRequest?.pagination?.totalDocumentCount, dropDownTerm]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allMerchantRequest?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        if (dropDownTerm === '' || searchTerm === '') {
            setNewArray(allMerchantRequest?.data);
        }
    }, [allMerchantRequest?.data, dropDownTerm, searchTerm]);

    useEffect(() => {
        if (searchTerm !== '') {
            // Logging the initial state of newArray and searchTerm
            console.log('New Array before filtering: ', newArray);
            console.log('Search Term: ', searchTerm);

            // Split the search term into words, handling cases where a full name is entered
            const searchTerms = searchTerm.toLowerCase().split(' ');
            console.log('Search Terms Split: ', searchTerms);

            const searchItems = allMerchantRequest?.data?.filter(
                (item: ISuperMerchant) => {
                    const firstName =
                        item?.user?.firstName?.toLowerCase() || '';
                    const lastName = item?.user?.lastName?.toLowerCase() || '';
                    console.log('Current Item First Name: ', firstName);
                    console.log('Current Item Last Name: ', lastName);

                    // Check if each word in the search term matches either the first or last name
                    return searchTerms.some((term) => {
                        const match =
                            firstName.includes(term) || lastName.includes(term);
                        console.log('Does the term match?: ', match);
                        return match;
                    });
                },
            );

            console.log('Filtered Items: ', searchItems);
            setNewArray(searchItems);
        } else {
            // Resetting to all items if searchTerm is empty
            setNewArray(allMerchantRequest?.data);
        }
    }, [searchTerm, allMerchantRequest?.data]);

    const Items = [
        {
            name: ' View User',
            icon: <BsEye size={15} />,
            link: 'view',
            id: '_id',
        },
        {
            name: 'Delete item',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: any) => {
                return onHandleDelete(data?._id);
            },
        },
        {
            name: 'Manage request',
            icon: <MdOutlinePersonAddDisabled size={15} />,
            OnClick: (row: IUser) => {
                setrequestId(row._id);
                setRequestStatusModal(true);
            },
        },
    ];

    //handle partner request status
    const editUserStatus = useForm({
        defaultValues: {
            newData: { requestStatus: '' },
        },
    });
    const { handleSubmit, control, setValue } = editUserStatus;

    useEffect(() => {
        if (singleMerchantRequest?.data) {
            setValue(
                'newData',
                {
                    requestStatus: singleMerchantRequest?.data?.status,
                },
                { shouldValidate: true },
            );
        }
    }, [singleMerchantRequest?.data, setValue]);

    const handlePartnerRequestStatus = (value: {
        newData: { requestStatus: string };
    }) => {
        let payload = {
            supermerchantRequestId: requestId,
            status: value?.newData?.requestStatus,
            approvedBy: user?._id as string,
        };
        updateMerchantRequest(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setRequestStatusModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message[0]);
            });
    };

    //handle delete partner request
    const onHandleDelete = (requestId: string) => {
        deleteMerchantRequest(requestId)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'First Name', key: 'user?.firstName' },
        {
            label: 'Last Name',
            key: 'user?.lastName',
        },
        {
            label: 'Email',
            key: 'user?.email',
        },
        {
            label: 'Additional Information',
            key: 'aditionalInformation',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { header: 'First Name', dataKey: 'firstName' },
        {
            header: 'Last Name',
            dataKey: 'lastName',
        },
        {
            header: 'Email',
            dataKey: 'email',
        },
        {
            header: 'About',
            dataKey: 'about',
        },
    ];

    return (
        <Container>
            {(isLoading || isDeleting) && <Loader />}
            <Header>Super Merchant Requests</Header>
            <Grid>
                <Counter
                    counter_title="Total Applicants"
                    count={merchantRequestStats?.data?.totalApplicants || '0'}
                    iconFill="darkBlue"
                    iconBG="blue"
                >
                    <UserIcon />
                </Counter>
                <Counter
                    counter_title="Pending Applicants"
                    count={
                        merchantRequestStats?.data?.totalPendingApplicants ||
                        '0'
                    }
                    iconFill="green"
                    iconBG="green"
                >
                    <UserIcon />
                </Counter>
                <Counter
                    counter_title="Successful Applicants"
                    count={
                        merchantRequestStats?.data?.totalSuccessfulApplicants ||
                        '0'
                    }
                >
                    <UserIcon />
                </Counter>
                <Counter
                    counter_title="Rejected Applicants"
                    count={
                        merchantRequestStats?.data?.totalRejectedApplicants ||
                        '0'
                    }
                    iconFill="red"
                    iconBG="red"
                >
                    <UserIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={9}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col
                        xs={10}
                        sm={6}
                        className="d-flex align-items-center my-3"
                    >
                        <SelectComp
                            selectText="All request"
                            arrayComp={[
                                { name: 'pending', value: 'pending' },
                                { name: 'approved', value: 'approved' },
                                { name: 'rejected', value: 'rejected' },
                            ]}
                            onChange={(e: any) =>
                                setDropDownTerm(e.target.value)
                            }
                        />
                        <TotalResultText>{`${
                            allMerchantRequest?.pagination
                                ?.totalDocumentCount || 0
                        } result`}</TotalResultText>
                    </Col>
                    <InputWrap>
                        <img src={SearchIcon} alt="" />
                        <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Search"
                            className="border-start-0 border-bottom-0 border-top-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputWrap>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'PARTNER REQUESTS RECORD'}
                            {...{
                                columns: headersPdf,
                                body: cleanUpSuperMerchantDataForPdf(
                                    allMerchantRequest?.data,
                                ),
                            }}
                        />
                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={allMerchantRequest?.data}
                        />
                    </ButtonContainer>
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={SuperHeader(Items)}
                    data={newArray || []}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>

            {/* handle partner request */}
            <ModalComp
                show={requestStatusModal}
                handleClose={() => setRequestStatusModal(false)}
                centered
                close={true}
                modalTitle="User status"
                paragraph="Fill in the  the forms to update merchant request"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form
                            onSubmit={handleSubmit(handlePartnerRequestStatus)}
                        >
                            <Controller
                                name="newData.requestStatus"
                                control={control}
                                rules={{
                                    required: 'acount status is required',
                                }}
                                render={({
                                    field: { value, onChange },
                                    formState: { errors },
                                }) => {
                                    const errorMessage =
                                        errors?.newData?.requestStatus?.message;
                                    return (
                                        <SelectComp
                                            labelText="Status"
                                            arrayComp={[
                                                'pending',
                                                'approved',
                                                'rejected',
                                            ]}
                                            name="serviceChargeOnPartnerCharge"
                                            defaultValue={
                                                singleMerchantRequest?.data
                                                    ?.status
                                            }
                                            {...{
                                                value,
                                                onChange,
                                                errors: [errorMessage],
                                            }}
                                        />
                                    );
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isProcessingPartnerRquest
                                            ? 'Save changes'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />
        </Container>
    );
};
