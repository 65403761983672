import {
    BarLine,
    ButtonContainer,
    Container,
    InputWrap,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    Loader,
    PdfDownloader,
    TextEditor,
} from '../../common/component';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { CareerTableHeader } from '../../common/utils/dataUtils';
import { BsEye } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { CustomButton } from '../../common/component/button';
import { ModalComp } from '../../common/component/Ui/modalComp';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as CareerIcon } from '../../common/images/career_icon.svg';
import { SelectComp } from '../../common/component/Ui/selectComp';
import { TextInputComp } from '../../common/component/Ui/textInputComp';
import {
    useAddJobMutation,
    useDeleteJobMutation,
    useGetAllJobsQuery,
    useGetSingleJobsQuery,
    useJobStatsQuery,
} from '../../store/jobAPi';
import { IJob } from '../../store/jobAPi/interface';
import { showSuccessToast, showErrorToast } from '../../common/utils/toast';
import { Controller, useForm } from 'react-hook-form';
import { Flexbox2 } from '../currencyPair/style';

export const Career = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropDownTerm, setDropDownTerm] = useState('');
    const [newArray, setNewArray] = useState<IJob[] | any>();
    const [showModal, setShowModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const { data: jobStats } = useJobStatsQuery();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: allJobs,
        isLoading,
        isFetching,
    } = useGetAllJobsQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });
    const [addJob, { isLoading: isAddingJob }] = useAddJobMutation();
    const [deleteJob, { isLoading: isDeleting }] = useDeleteJobMutation();
    const [jobIb, setJobId] = useState('');
    useGetSingleJobsQuery(jobIb);
    useEffect(() => {
        setTotalRows(allJobs?.pagination?.totalDocumentCount as number);
    }, [allJobs?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allJobs?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const Items = [
        {
            name: ' View job',
            icon: <BsEye size={15} />,
            link: 'view',
            id: '_id',
        },
        {
            name: ' Delete job',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: any) => {
                setJobId(data?._id);
                setDeleteModal(true);
            },
        },
    ];

    // To implement search and dropdown input
    useEffect(() => {
        if (searchTerm === '' && dropDownTerm === '') {
            setNewArray(allJobs?.data);
        }
        if (dropDownTerm !== '') {
            let filteredItems = allJobs?.data?.filter((item: IJob) => {
                return item?.schedule
                    .toLowerCase()
                    .includes(dropDownTerm.toLowerCase());
            });
            setNewArray(filteredItems);
        }
        if (searchTerm !== '') {
            let searchItems = allJobs?.data?.filter((item: IJob) => {
                return item?.role
                    ?.toLowerCase()
                    .includes(searchTerm?.toLowerCase());
            });
            setNewArray(searchItems);
        }
    }, [allJobs, searchTerm, dropDownTerm]);

    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
            role: '',
            title: '',
            description: '',
            location: '',
            schedule: '',
            category: '',
        },
    });

    const onHandleSubmit = (value: {
        role: string;
        title: string;
        description: string;
        location: string;
        schedule: string;
        category: string;
    }) => {
        addJob(value)
            .unwrap()
            .then((result) => {
                console.log('Job added successfully:', result);
                showSuccessToast(result?.message);
                setShowModal(false);
                reset();
            })
            .catch((error) => {
                console.error('Error adding job:', error);
                showErrorToast(error?.data?.message);
            });
    };

    const onDeleteJob = (id: string) => {
        deleteJob(id)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setDeleteModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'Job title', key: 'role' },
        {
            label: 'Location',
            key: 'location',
        },
        {
            label: 'Schedule',
            key: 'schedule',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { header: 'Job title', dataKey: 'role' },
        {
            header: 'Location',
            dataKey: 'location',
        },
        {
            header: 'Schedule',
            dataKey: 'schedule',
        },
    ];

    return (
        <Container>
            {isLoading || isDeleting ? <Loader /> : null}
            <Header>Career</Header>
            <Grid>
                <Counter
                    counter_title="Total jobs"
                    count={jobStats?.data?.totalJobs || 0}
                    iconFill="blue"
                    iconBG="blue"
                >
                    <CareerIcon />
                </Counter>
                <Counter
                    counter_title="Total applicants"
                    count={jobStats?.data?.totalApplicants || 0}
                >
                    <CareerIcon />
                </Counter>
                <Counter
                    counter_title="Active jobs"
                    count={jobStats?.data?.totalActiveJobs || 0}
                    iconFill="green"
                    iconBG="green"
                >
                    <CareerIcon />
                </Counter>
                <Counter
                    counter_title="Inactive jobs"
                    count={jobStats?.data?.totalInactiveJobs || 0}
                    iconFill="red"
                    iconBG="red"
                >
                    <CareerIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={8}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col
                        xs={10}
                        sm={6}
                        className="d-flex align-items-center my-3"
                    >
                        <SelectComp
                            selectText="All jobs"
                            arrayComp={['full-time', 'contract', 'part-time']}
                            onChange={(e: any) =>
                                setDropDownTerm(e.target.value)
                            }
                        />
                        <TotalResultText>{`${
                            allJobs?.data?.length || 0
                        } result`}</TotalResultText>
                    </Col>
                    <InputWrap>
                        <img src={SearchIcon} alt="" />
                        <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Search"
                            className="border-start-0 border-bottom-0 border-top-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputWrap>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'CAREER RECORD'}
                            {...{ columns: headersPdf, body: allJobs?.data }}
                        />
                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={allJobs?.data}
                        />
                    </ButtonContainer>
                    <CustomButton
                        text="Create job"
                        onClick={() => setShowModal(true)}
                    />
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={CareerTableHeader(Items)}
                    data={newArray}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>
            <ModalComp
                show={showModal}
                handleClose={() => setShowModal(false)}
                centered
                close={true}
                size="lg"
                modalTitle="Create job"
                paragraph="Fill in the forms to create a job"
                modalBody={
                    <form onSubmit={handleSubmit(onHandleSubmit)}>
                        <div className="py-1 px-lg-4 ">
                            <div className="d-sm-flex gap-2">
                                <div className="my-3 w-100">
                                    <Controller
                                        name="role"
                                        control={control}
                                        rules={{
                                            required: 'Job role is required',
                                        }}
                                        render={({
                                            field: { value, onChange },
                                            formState: { errors },
                                        }) => {
                                            const errorMessage =
                                                errors?.role?.message;
                                            return (
                                                <TextInputComp
                                                    labelText="Job role"
                                                    placeholder="eg. UI/UX Designer"
                                                    inputClassName={'py-2 '}
                                                    name="role"
                                                    {...{
                                                        value,
                                                        onChange,
                                                        errors: [errorMessage],
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                                <div className="my-3 w-100">
                                    <Controller
                                        name="title"
                                        control={control}
                                        rules={{
                                            required: 'Job title is required',
                                        }}
                                        render={({
                                            field: { value, onChange },
                                            formState: { errors },
                                        }) => {
                                            const errorMessage =
                                                errors?.title?.message;
                                            return (
                                                <TextInputComp
                                                    labelText="Job title"
                                                    placeholder="eg. UI/UX Designer needed"
                                                    inputClassName={'py-2 '}
                                                    name="title"
                                                    {...{
                                                        value,
                                                        onChange,
                                                        errors: [errorMessage],
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="d-lg-flex gap-2">
                                <div className="my-3 w-100">
                                    <Controller
                                        name="location"
                                        control={control}
                                        rules={{
                                            required:
                                                'Job location is required',
                                        }}
                                        render={({
                                            field: { value, onChange },
                                            formState: { errors },
                                        }) => {
                                            const errorMessage =
                                                errors?.location?.message;
                                            return (
                                                <SelectComp
                                                    labelText="Job location"
                                                    arrayComp={[
                                                        'Remote',
                                                        'On-site',
                                                    ]}
                                                    name="location"
                                                    {...{
                                                        value,
                                                        onChange,
                                                        errors: [errorMessage],
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                                <div className="my-3 w-100">
                                    <Controller
                                        name="schedule"
                                        control={control}
                                        rules={{
                                            required: 'Job type is required',
                                        }}
                                        render={({
                                            field: { value, onChange },
                                            formState: { errors },
                                        }) => {
                                            const errorMessage =
                                                errors?.schedule?.message;
                                            return (
                                                <SelectComp
                                                    labelText="Job type"
                                                    arrayComp={[
                                                        'full-time',
                                                        'part-time',
                                                        'contract',
                                                    ]}
                                                    name="schedule"
                                                    {...{
                                                        value,
                                                        onChange,
                                                        errors: [errorMessage],
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="my-3 w-50">
                                <Controller
                                    name="category"
                                    control={control}
                                    rules={{
                                        required: 'Job category is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.category?.message;
                                        return (
                                            <SelectComp
                                                labelText="job Category"
                                                arrayComp={[
                                                    'engineering',
                                                    'marketing',
                                                    'design',
                                                    'management',
                                                    'operations',
                                                    'finance',
                                                    'customer-service',
                                                ]}
                                                name="category"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-4">
                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{
                                        required: 'Job description is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                    }) => {
                                        return (
                                            <TextEditor
                                                {...{ value, onChange }}
                                                labelText="Job description"
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        isAddingJob
                                            ? 'Loading...'
                                            : 'Create job'
                                    }
                                />
                            </div>
                        </div>
                    </form>
                }
            />
            <ModalComp
                show={deleteModal}
                handleClose={() => setDeleteModal(false)}
                centered
                close={true}
                modalTitle="Delete Job"
                modalBody={
                    <Flexbox2>
                        <CustomButton
                            className="py-3 mt-4"
                            text={'Cancel'}
                            onClick={() => setDeleteModal(false)}
                        />
                        <CustomButton
                            className="py-3 mt-4"
                            text={!isDeleting ? 'Delete' : 'Loading...'}
                            onClick={() => onDeleteJob(jobIb)}
                        />
                    </Flexbox2>
                }
            />
        </Container>
    );
};
