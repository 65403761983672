import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	BarElement,
	ArcElement,
} from "chart.js";
import { Line, Bar, Doughnut } from "react-chartjs-2";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	BarElement,
	ArcElement
);

export enum CHART_TYPE {
	LINE = "line",
	BAR = "bar",
	AREA = "area",
	DOUGHNUT = "doughnut",
}

type ChartProps = {
	type:
		| CHART_TYPE.LINE
		| CHART_TYPE.BAR
		| CHART_TYPE.AREA
		| CHART_TYPE.DOUGHNUT;
	width?: string | number;
	height?: string | number;
	datasets: any;
};

export const Chart = (props: ChartProps) => {
	const { width="100%", height= 300, type = "line", datasets } = props;

	const allProps = {
		width,
		height,
		options: {
			elements: {},
			scales: {},
			maintainAspectRatio: false,
			responsive: true,
			plugins: {
				legend: {
					position: "top" as const,
				},
			},
		},
		redraw: true,
		// updateMode: "redraw" as const,
	};

	switch (type) {
		case CHART_TYPE.BAR:
			// allProps.options.scales = {
			// 	xAxes: [
			// 		{
			// 			stacked: false,
			// 		},
			// 	],
			// 	yAxes: [
			// 		{
			// 			stacked: false,
			// 		},
			// 	],
			// };
			return <Bar {...allProps} data={datasets} />;
		case CHART_TYPE.DOUGHNUT:
			return <Doughnut {...allProps} data={datasets} />;

		case CHART_TYPE.LINE:
		default:
			allProps.options.elements = {
				line: {
					tension:0.5,
					// bezierCurve: true
				},
			};
			return <Line {...allProps} data={datasets} />;
	}
};
