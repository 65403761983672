import styled from "styled-components";
import { colors } from "../../common/component";

export const CareerIconWrap = styled.div<{
	IconBg?: string;
	IconColor?: string;
}>`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${colors.purple10};
	svg {
		stroke: ${colors.purple100};
	}
`;
export const Span = styled.p`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	overflow: hidden;
	font-weight: 400;
	font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
	background: ${colors.Green80};
	color: ${colors.white};
`;
