import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { IReferralCampaign, IReferralCampaignStats } from './interface';
import { paginatedResponse } from '../paginatedInterface';

export const referralCampaignApi = createApi({
    reducerPath: 'referralCampaignApi',
    baseQuery: baseQuery,
    tagTypes: ['referralCampaign'],
    endpoints: (builder) => ({
        addReferralCampaign: builder.mutation<
            Response<IReferralCampaign>,
            {
                referralCampaignName: string;
                amount: number;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/referral-campaign`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['referralCampaign'],
        }),
        editReferralCampaign: builder.mutation<
            Response<IReferralCampaign>,
            {
                referralCampaignName: string;
                amount: number;
                status: boolean;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/referral-campaign`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['referralCampaign'],
        }),
        getAllReferralCampaign: builder.query<
            paginatedResponse<IReferralCampaign[]>,
            { perPage: number; currentPage: number }
        >({
            query: ({ perPage, currentPage }) => ({
                url: `/user/api/v1/referral-campaign?perPage=${perPage}&currentPage=${currentPage}`,
                method: 'GET',
            }),
            providesTags: ['referralCampaign'],
        }),
        getSingleReferralCampaign: builder.query<
            Response<IReferralCampaign>,
            string
        >({
            query: (query) => ({
                url: `/user/api/v1/referral-campaign/${query}`,
                method: 'GET',
            }),
        }),
        deleteReferralCampaign: builder.mutation<
            Response<IReferralCampaign>,
            string
        >({
            query: (query) => ({
                url: `/user/api/v1/referral-campaign/${query}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['referralCampaign'],
        }),
        referralCampaignStats: builder.query<
            Response<IReferralCampaignStats>,
            void
        >({
            query: () => ({
                url: `/user/api/v1/referral-campaign/a/stats`,
                method: 'GET',
            }),
            providesTags: ['referralCampaign'],
        }),
    }),
});

export const {
    useAddReferralCampaignMutation,
    useReferralCampaignStatsQuery,
    useGetSingleReferralCampaignQuery,
    useGetAllReferralCampaignQuery,
    useEditReferralCampaignMutation,
    useDeleteReferralCampaignMutation,
} = referralCampaignApi;
