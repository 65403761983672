import { useNavigate } from "react-router-dom";
import IbxLogo from "../../../common/images/ibx_main_logo.svg";
import {
	Button,
	ContentContainer,
	Header,
	AuthContainer,
	Paragraph,
	PrivacyText,
	IBXLogo,
} from "../login/style";
import { Container } from "react-bootstrap";
import { PasswordInputComp, TextInputComp } from "../../../common/component";
import { useForm, Controller } from "react-hook-form";
import { showSuccessToast, showErrorToast } from "../../../common/utils/toast";
import { useResetPasswordMutation } from "../../../store/auth/authApi";

export const ResetPassword = () => {
	const navigate = useNavigate();
	const userEmail = localStorage.getItem("userEmail");
	const OTP = localStorage.getItem("ibxUserOtp");
	const [resetPassword, { isLoading }] = useResetPasswordMutation();

	const { handleSubmit, control, watch } = useForm({
		defaultValues: {
			password: "",
			confirmPassword: "",
		},
	});
	const onResetPassword = (value: { password: string }) => {
		let payload = {
			email: userEmail as string,
			token: OTP as string,
			password: value.password,
		};
		console.log(payload, "payload");

		resetPassword(payload)
			.unwrap()
			.then((result) => {
				showSuccessToast(result?.message);
				localStorage.removeItem("userEmail");
				localStorage.removeItem("ibxUserOtp");
				navigate("/login", { replace: true });
			})
			.catch((error) => {
				showErrorToast(error?.data?.message);
			});
	};
	return (
		<Container>
			<AuthContainer>
				<ContentContainer>
					<IBXLogo className="d-flex justify-content-center mb-5 pt-2">
						<img src={IbxLogo} alt="" />
					</IBXLogo>
					<div className="pb-2">
						<Header>Create New Password</Header>
					</div>
					<Paragraph>Please Enter a new password</Paragraph>
					<form onSubmit={handleSubmit(onResetPassword)}>
						<div className="my-3">
							<TextInputComp
								labelText="Email Address"
								placeholder="Email Address"
								inputClassName={"py-2"}
								defaultValue={userEmail}
								disabled={true}
							/>
						</div>
						<div className="my-3">
							<Controller
								name="password"
								control={control}
								rules={{
									required: "New password is required",
									minLength: 8 || "Password must be at least 8 characters",
								}}
								render={({
									field: { value, onChange },
									formState: { errors },
								}) => {
									const errorMessage = errors?.password?.message;
									return (
										<PasswordInputComp
											labelText="New Password"
											type="password"
											placeholder="Enter new password"
											inputClassName={"py-2"}
											name="password"
											{...{ value, onChange, errors: [errorMessage] }}
										/>
									);
								}}
							/>
						</div>
						<div className="my-3">
							<Controller
								name="confirmPassword"
								control={control}
								rules={{
									required: "Confirm new password is required",
									validate: (value) =>
										value === watch("password") || "Passwords don't match",
								}}
								render={({
									field: { value, onChange },
									formState: { errors },
								}) => {
									const errorMessage = errors?.confirmPassword?.message;
									return (
										<PasswordInputComp
											labelText="Confirm New Password"
											type="password"
											placeholder="Confirm new password"
											inputClassName={"py-2"}
											name="confirmPassword"
											{...{ value, onChange, errors: [errorMessage] }}
										/>
									);
								}}
							/>
						</div>

						<div className="d-flex justify-content-center my-4">
							<Button>{isLoading ? "Loading..." : "Reset password"}</Button>
						</div>
					</form>
				</ContentContainer>
			</AuthContainer>
			<PrivacyText>
				Protected by reCAPTCHA and subject to the Google{" "}
				<span>Privacy Policy</span> and <span>Terms of Service.</span>
			</PrivacyText>
		</Container>
	);
};
