import {
    ChatWrap,
    ChatWrap2,
    Date,
    Gap,
    Name,
    TextWrap,
    Time,
} from '../contactUs/style';
import { TradeChatContainer, TradeChatWrapper, TradeInputField } from './style';
import Profile_Image from '../../common/images/oge.jpg';
import { ReactComponent as SendIcon } from '../../common/images/icon_send.svg';
import { ReactComponent as AttachmentIcon } from '../../common/images/attachment_icon.svg';
import { ImageContainer, ImageWrap } from '../dashboard/styles';
import { socket } from '../../socket';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { useEffect, useState } from 'react';
import { useGetTradeMessagesQuery } from '../../store/chatApi';
import { Loader } from '../../common/component';
import { timeFormatter } from '../../common/utils';

export const TradeChat = ({ TradeId }: { TradeId: string }) => {
    const params = useParams();
    const tradeId = (params?.id as string) || TradeId;
    const { user } = useAppSelector((state: any) => state.auth);
    const userId = user?._id as string;
    const [chatId, setChatId] = useState('');
    const [message, setMessage] = useState('');
    const { data: tradeMessages, isLoading } = useGetTradeMessagesQuery({
        chatId: chatId,
    });
    const [messagesRecieved, setMessagesReceived] = useState<any>();

    useEffect(() => {
        if (tradeMessages?.data) {
            setMessagesReceived(tradeMessages?.data);
        }
    }, [tradeMessages?.data]);

    useEffect(() => {
        socket.emit('join-trade', { tradeId: tradeId, userId: userId }); //to join a trade
        socket.on('trade-joined', (data) => {
            setChatId(data._id);
        });
    }, [tradeId, userId, chatId]);

    //listen to incoming message
    useEffect(() => {
        socket.on('trade-message', (data) => {
            //update chat messages with the received message
            setMessagesReceived((prevData: any) => [...prevData, data._doc]);
        });
    }, []);

    const handleSendMessage = () => {
        if (message !== '') {
            socket.emit('add-trade-message', {
                chatId: chatId,
                userId: userId,
                text: message,
                attachments: [],
            }); //to join a trade
            setMessage('');
        }
    };

    console.log(messagesRecieved);

    return (
        <TradeChatWrapper>
            {isLoading && <Loader />}
            <TradeChatContainer>
                <div className="d-flex align-items-center justify-content-center py-2">
                    <Date>August 16, 2024</Date>
                </div>
                {/* right-div */}
                {messagesRecieved?.map((data: any, index: number) => (
                    <ChatWrap sent={userId === data?.sender?._id} key={index}>
                        <ChatWrap2 className="my-1">
                            <Gap>
                                <ImageContainer
                                    sent={userId === data?.sender?._id}
                                    className="align-items-center"
                                >
                                    <ImageWrap>
                                        <img src={Profile_Image} alt="" />
                                    </ImageWrap>
                                    <Name>{`${data.sender.firstName} ${data.sender.lastName}`}</Name>
                                </ImageContainer>
                                <TextWrap sent={userId === data?.sender?._id}>
                                    <p>{data.text}</p>
                                </TextWrap>
                                <Time>{timeFormatter(data?.createdAt)}</Time>
                            </Gap>
                        </ChatWrap2>
                    </ChatWrap>
                ))}
            </TradeChatContainer>
            <TradeInputField className="d-flex align-items-center gap-3 py-2 px-1">
                <AttachmentIcon />
                <input
                    placeholder="Type a message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <SendIcon onClick={() => handleSendMessage()} />
            </TradeInputField>
        </TradeChatWrapper>
    );
};
