import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { IContactUs, IContactUsStats } from './interface';
import { paginatedResponse } from '../paginatedInterface';

export const contactUsApi = createApi({
    reducerPath: 'contactUsApi',
    baseQuery: baseQuery,
    tagTypes: ['contactUs'],
    endpoints: (builder) => ({
        getAllContactUs: builder.query<
            paginatedResponse<IContactUs[]>,
            { perPage: number; currentPage: number }
        >({
            query: ({ perPage, currentPage }) => ({
                url: `/user/api/v1/contact-us?perPage=${perPage}&currentPage=${currentPage}`,
                method: 'GET',
            }),
            providesTags: ['contactUs'],
        }),
        deleteContactUs: builder.mutation<Response<IContactUs>, string>({
            query: (query) => ({
                url: `/user/api/v1/contact-us/${query}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['contactUs'],
        }),
        getSingleContact: builder.query<Response<IContactUs>, string>({
            query: (query) => ({
                url: `/user/api/v1/contact-us/${query}`,
                method: 'GET',
            }),
        }),
        contactUsStats: builder.query<Response<IContactUsStats>, void>({
            query: () => ({
                url: `/user/api/v1/contact-us/a/stats`,
                method: 'GET',
            }),
            providesTags: ['contactUs'],
        }),
        updateContactUs: builder.mutation<
            Response<IContactUs>,
            {
                contactUsId: string;
                status: string;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/contact-us`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['contactUs'],
        }),
    }),
});

export const {
    useGetAllContactUsQuery,
    useGetSingleContactQuery,
    useDeleteContactUsMutation,
    useContactUsStatsQuery,
    useUpdateContactUsMutation,
} = contactUsApi;
