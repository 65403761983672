import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IbxShell } from '../common/component';
import {
    AdminMain,
    Advert,
    AppVersion,
    Career,
    Configuration,
    Currency,
    CurrencyPair,
    ForgetPassword,
    LandingPage,
    Login,
    MerchantRequest,
    Notification,
    PartnerRequest,
    ProfileSetting,
    ResetPassword,
    SetOtp,
    SetTwoFactor,
    Support,
    Trade,
    Transaction,
    Users,
    TotalSubscription,
    FrequentQuestion,
    KYC,
    WithdrawalRequest,
    Topic,
    SubTopic,
    Content,
} from '../pages';
import { ViewAdvert } from '../pages/tradeAdvert/viewAdvert';
import { ViewCareer } from '../pages/career/viewCareer';
import { ViewTrade } from '../pages/trade/viewTrade';
import { ViewUser } from '../pages/users/viewUser';
import { useAppSelector } from '../store/hooks';
import { ViewPartner } from '../pages/partnerRequest/viewPartner';
import { ViewKyc } from '../pages/kycPage/viewKyc';
import { ViewContact } from '../pages/contactUs/viewContact';
import { ViewMerchant } from '../pages/merchantRequest/viewMerchantRequest';
import { NewConfiguration } from '../pages/newConfiguration';
import { EditConfiguration } from '../pages/editConfiguration';
import { ViewConfiguration } from '../pages/viewConfiguration';
import { ExchangeRates } from '../pages/exchangeRates';
import { SuperMerchant } from '../pages/superMerchant';
import { ViewSuperMerchant } from '../pages/superMerchant/viewSuperMerchant';
import { Bank } from '../pages/bank';
import { ReferralCampaign } from '../pages/referralCampaign';
import { ViewWithdrawalRequest } from '../pages/withdrawalRequest/viewWithdrawalRequest';
import { ViewTopic } from '../pages/topic/viewTopic';
import { ViewSubTopic } from '../pages/subTopic/viewSubTopic';
import { ViewContent } from '../pages/content/viewContent';
export const RouterConfig = () => {
    const { user } = useAppSelector((state: any) => state.auth);

    return (
        <BrowserRouter>
            <Routes>
                <>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/password/forgot"
                        element={<ForgetPassword />}
                    />
                    <Route path="/password/set-otp" element={<SetOtp />} />
                    <Route path="/password/reset" element={<ResetPassword />} />
                </>
                {user ? (
                    <>
                        <Route path="/two-factor" element={<SetTwoFactor />} />
                        <Route path="/dashboard" element={<IbxShell />}>
                            <Route path="admin" element={<AdminMain />} />
                            <Route path="users" element={<Users />} />
                            <Route
                                path="users/view/:id"
                                element={<ViewUser />}
                            />
                            <Route path="trade-advert" element={<Advert />} />
                            <Route
                                path="trade-advert/view/:id"
                                element={<ViewAdvert />}
                            />
                            <Route path="trade" element={<Trade />} />
                            <Route
                                path="trade/view/:id/:id"
                                element={<ViewTrade />}
                            />
                            <Route
                                path="transaction"
                                element={<Transaction />}
                            />
                            <Route path="contact-us" element={<Support />} />
                            <Route
                                path="contact-us/view/:id"
                                element={<ViewContact />}
                            />
                            <Route path="currency" element={<Currency />} />
                            <Route
                                path="currencypair"
                                element={<CurrencyPair />}
                            />
                            <Route
                                path="newsletter"
                                element={<TotalSubscription />}
                            />
                            <Route
                                path="configuration"
                                element={<Configuration />}
                            />
                            <Route
                                path="configuration/new"
                                element={<NewConfiguration />}
                            />
                            <Route
                                path="configuration/edit"
                                element={<EditConfiguration />}
                            />
                            <Route
                                path="configuration/view"
                                element={<ViewConfiguration />}
                            />
                            <Route
                                path="profile-setting"
                                element={<ProfileSetting />}
                            />
                            <Route path="appversion" element={<AppVersion />} />
                            <Route
                                path="referral-campaign"
                                element={<ReferralCampaign />}
                            />
                            <Route path="career" element={<Career />} />
                            <Route
                                path="career/view/:id"
                                element={<ViewCareer />}
                            />
                            <Route
                                path="notification"
                                element={<Notification />}
                            />
                            <Route
                                path="merchant-request"
                                element={<MerchantRequest />}
                            />
                            <Route
                                path="merchant-request/view/:id"
                                element={<ViewMerchant />}
                            />
                            <Route
                                path="super-merchant"
                                element={<SuperMerchant />}
                            />
                            <Route
                                path="super-merchant/view/:id"
                                element={<ViewSuperMerchant />}
                            />
                            <Route
                                path="partner-request"
                                element={<PartnerRequest />}
                            />{' '}
                            <Route path="bank" element={<Bank />} />
                            <Route path="faq" element={<FrequentQuestion />} />
                            <Route path="kyc" element={<KYC />} />
                            <Route path="kyc/view/:id" element={<ViewKyc />} />
                            <Route
                                path="partner-request/view/:id"
                                element={<ViewPartner />}
                            />
                            <Route
                                path="totalsubscription"
                                element={<TotalSubscription />}
                            />
                            <Route
                                path="exchange-rates"
                                element={<ExchangeRates />}
                            />
                            <Route
                                path="withdrawal-request"
                                element={<WithdrawalRequest />}
                            />
                            <Route
                                path="withdrawal-request/view/:id"
                                element={<ViewWithdrawalRequest />}
                            />
                            <Route path="topic" element={<Topic />} />
                            <Route
                                path="topic/view/:id"
                                element={<ViewTopic />}
                            />
                            <Route path="subtopic" element={<SubTopic />} />
                            <Route
                                path="subTopic/view/:id"
                                element={<ViewSubTopic />}
                            />
                            <Route path="content" element={<Content />} />
                            <Route
                                path="content/view/:id"
                                element={<ViewContent />}
                            />
                        </Route>
                    </>
                ) : null}
            </Routes>
        </BrowserRouter>
    );
};
