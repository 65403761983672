import {
    BarLine,
    Grid,
    ImageContainer,
    StatusContainer,
    SubHeading,
    Card,
    Text,
    UserWrap,
} from '../partnerRequest/style';
import {
    BackButton,
    TopText,
    UserImage,
    UserName,
    DownText,
} from '../users/style';
import { useNavigate, useParams } from 'react-router-dom';
import back_arrow from '../../common/images/back_arrow.svg';
import { colors, Loader, ModalComp } from '../../common/component';
import { getDateString } from '../../common/utils/date';
import { useState } from 'react';
import { useGetSingleKycQuery } from '../../store/kycApi';
import Calender from '../../common/images/calendar.svg';
import { Subheader } from './style';

export const ViewKyc = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const kycId = params?.id as string;
    const { data: kycData, isLoading } = useGetSingleKycQuery(kycId);
    const [image1, setImage1] = useState('');
    const [image2, setImage2] = useState('');
    const [imageTitle, setImageTitle] = useState('');

    return (
        <div>
            <div className="d-flex align-items-center my-4">
                <BackButton onClick={() => navigate(-1)}>
                    <img src={back_arrow} alt="" /> Back
                </BackButton>
                <TopText>
                    {' '}
                    <span>/ KYC</span> / View KYC
                </TopText>
            </div>
            <DownText>
                <Text>KYC Application</Text>
                <p className="date">
                    <span className="purple"></span>
                    <p>Date created </p>
                    <img src={Calender} alt="calender" />
                    <p>{getDateString(kycData?.data?.createdAt as string)}</p>
                </p>

                <p
                    style={{
                        background:
                            kycData?.data?.status === 'pending'
                                ? colors.yellow
                                : kycData?.data?.status === 'approved'
                                ? colors.Green90
                                : colors.red100,
                    }}
                    className="DownStatus"
                >
                    {' '}
                    {kycData?.data?.status}
                </p>
            </DownText>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="d-flex gap-3 mt-5 pb-4">
                        <BarLine />
                        <div className="d-flex flex-column gap-4 w-100">
                            <Grid className="d-flex gap-4 gap-sm-5">
                                <Card>
                                    <div className="pb-1">
                                        <UserWrap>Applied by</UserWrap>
                                    </div>
                                    <div className="d-flex align-items-center gap-3 gap-lg-2">
                                        <UserImage>
                                            <img
                                                src={
                                                    kycData?.data?.user
                                                        ?.passportPhoto
                                                }
                                                alt=""
                                            />
                                        </UserImage>
                                        <UserName>{`${kycData?.data?.user?.firstName} ${kycData?.data?.user?.lastName}`}</UserName>
                                    </div>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Applied on</SubHeading>
                                    <Text>
                                        {getDateString(
                                            kycData?.data?.createdAt as string,
                                        )}
                                    </Text>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Account Status</SubHeading>
                                    <Text>
                                        {kycData?.data?.user?.accountStatus}
                                    </Text>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Email</SubHeading>
                                    <Text>{kycData?.data?.user?.email}</Text>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Phone number</SubHeading>
                                    <Text>
                                        {kycData?.data?.user?.phone ?? '--'}
                                    </Text>
                                </Card>{' '}
                                {kycData?.data?.bvnVerification && (
                                    <>
                                        <div className="d-flex flex-column gap-4 w-100">
                                            <Subheader>
                                                BVN VERICATION
                                            </Subheader>
                                            <Grid className="d-flex gap-4 gap-sm-5">
                                                <Card className="d-flex flex-column gap-2">
                                                    <SubHeading>
                                                        BVN Verification Number
                                                    </SubHeading>
                                                    <Text>
                                                        {
                                                            kycData?.data
                                                                ?.bvnVerification
                                                                ?.bvnNumber
                                                        }
                                                    </Text>
                                                </Card>
                                                <Card className="d-flex flex-column gap-2">
                                                    <SubHeading>
                                                        Status
                                                    </SubHeading>
                                                    <StatusContainer
                                                        status={
                                                            kycData?.data
                                                                ?.bvnVerification
                                                                ?.status as string
                                                        }
                                                    >
                                                        {
                                                            kycData?.data
                                                                ?.bvnVerification
                                                                ?.status
                                                        }
                                                    </StatusContainer>
                                                </Card>
                                                {kycData?.data?.bvnVerification
                                                    ?.approvedBy && (
                                                    <Card className="d-flex flex-column gap-2">
                                                        <SubHeading>
                                                            Approved By
                                                        </SubHeading>
                                                        <Text>
                                                            {' '}
                                                            {`${kycData?.data?.bvnVerification?.approvedBy?.firstName} ${kycData?.data?.bvnVerification?.approvedBy?.lastName}`}
                                                        </Text>
                                                    </Card>
                                                )}
                                            </Grid>
                                        </div>
                                    </>
                                )}
                                {kycData?.data?.addressVerification && (
                                    <>
                                        <div className="d-flex flex-column gap-4 w-100">
                                            <Subheader>
                                                ADDRESS VERIFICATION DOCUMENT
                                            </Subheader>
                                            <Grid className="d-flex gap-4 gap-sm-5">
                                                <Card className="d-flex flex-column gap-2">
                                                    <SubHeading>
                                                        Adddress Document Type
                                                    </SubHeading>
                                                    <Text>{`${kycData?.data?.addressVerification?.addressDocumentType}`}</Text>
                                                </Card>
                                                <Card>
                                                    <SubHeading>
                                                        Address document image
                                                    </SubHeading>
                                                    <SubHeading
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            setImage1(
                                                                kycData?.data
                                                                    ?.addressVerification
                                                                    ?.addressDocumentImageUrl,
                                                            );
                                                            setImageTitle(
                                                                kycData?.data
                                                                    ?.addressVerification
                                                                    ?.addressDocumentType,
                                                            );
                                                            setShowModal(true);
                                                        }}
                                                    >
                                                        View image
                                                    </SubHeading>
                                                </Card>
                                                <Card>
                                                    <SubHeading>
                                                        User Address
                                                    </SubHeading>
                                                    <Text>
                                                        {kycData?.data
                                                            ?.addressVerification
                                                            .address +
                                                            ' ' +
                                                            kycData?.data
                                                                ?.addressVerification
                                                                .city +
                                                            ', ' +
                                                            kycData?.data
                                                                ?.addressVerification
                                                                .state +
                                                            ' ' +
                                                            kycData?.data
                                                                ?.addressVerification
                                                                .country}
                                                    </Text>
                                                </Card>
                                                <Card className="d-flex flex-column gap-2">
                                                    <SubHeading>
                                                        Status
                                                    </SubHeading>
                                                    <StatusContainer
                                                        status={
                                                            kycData?.data
                                                                ?.addressVerification
                                                                ?.status
                                                        }
                                                    >
                                                        {
                                                            kycData?.data
                                                                ?.addressVerification
                                                                ?.status
                                                        }
                                                    </StatusContainer>
                                                </Card>
                                                {kycData?.data
                                                    ?.addressVerification
                                                    ?.approvedBy && (
                                                    <Card className="d-flex flex-column gap-2">
                                                        <SubHeading>
                                                            Approved By
                                                        </SubHeading>
                                                        <Text>
                                                            {`${kycData?.data?.addressVerification?.approvedBy?.firstName} ${kycData?.data?.addressVerification?.approvedBy?.lastName}`}
                                                        </Text>
                                                    </Card>
                                                )}
                                            </Grid>
                                        </div>
                                    </>
                                )}
                                {kycData?.data?.identityVerification && (
                                    <>
                                        <div className="d-flex flex-column gap-4 w-100">
                                            <Subheader>
                                                IDENTITY DOCUMENT
                                            </Subheader>
                                            <Grid className="d-flex gap-4 gap-sm-5">
                                                <Card className="d-flex flex-column gap-2">
                                                    <SubHeading>
                                                        Identity Document Type
                                                    </SubHeading>

                                                    <div className="d-flex gap-1">
                                                        <Text>{`${kycData?.data?.identityVerification?.identityDocumentType}`}</Text>
                                                    </div>
                                                </Card>
                                                <Card>
                                                    <SubHeading>
                                                        Identity document image
                                                    </SubHeading>
                                                    <SubHeading
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            setImage1(
                                                                kycData?.data
                                                                    ?.identityVerification
                                                                    ?.identityDocumentFrontImageUrl,
                                                            );
                                                            setImage2(
                                                                kycData?.data
                                                                    ?.identityVerification
                                                                    ?.identityDocumentBackImageUrl,
                                                            );
                                                            setImageTitle(
                                                                kycData?.data
                                                                    ?.identityVerification
                                                                    ?.identityDocumentType,
                                                            );
                                                            setShowModal(true);
                                                        }}
                                                    >{`View image`}</SubHeading>
                                                </Card>
                                                <Card>
                                                    <SubHeading>
                                                        User Selfie
                                                    </SubHeading>
                                                    <SubHeading
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            setImage1(
                                                                kycData?.data
                                                                    ?.identityVerification
                                                                    ?.selfieImageUrl,
                                                            );
                                                            setImageTitle(
                                                                'Selfie',
                                                            );
                                                            setShowModal(true);
                                                        }}
                                                    >{`View image`}</SubHeading>
                                                </Card>

                                                <Card className="d-flex flex-column gap-2">
                                                    <SubHeading>
                                                        Status
                                                    </SubHeading>
                                                    <StatusContainer
                                                        status={
                                                            kycData?.data
                                                                ?.identityVerification
                                                                ?.status as string
                                                        }
                                                    >
                                                        {
                                                            kycData?.data
                                                                ?.identityVerification
                                                                ?.status
                                                        }
                                                    </StatusContainer>
                                                </Card>
                                                {kycData?.data
                                                    ?.identityVerification
                                                    ?.approvedBy && (
                                                    <Card className="d-flex flex-column gap-2">
                                                        <SubHeading>
                                                            Approved By
                                                        </SubHeading>
                                                        <Text>
                                                            {`${kycData?.data?.identityVerification?.approvedBy?.firstName} ${kycData?.data?.identityVerification?.approvedBy?.lastName}`}
                                                        </Text>
                                                    </Card>
                                                )}
                                            </Grid>
                                        </div>
                                    </>
                                )}
                            </Grid>
                        </div>
                    </div>
                    <ModalComp
                        show={showModal}
                        handleClose={() => {
                            setShowModal(false);
                            setImage1('');
                            setImage2('');
                            setImageTitle('');
                        }}
                        centered
                        close={true}
                        modalTitle={`${imageTitle} image`}
                        modalBody={
                            <div className="d-flex flex-column gap-4 py-2 px-lg-4">
                                {image1 && (
                                    <ImageContainer>
                                        <a href={image1} target="_blank">
                                            <img src={image1} alt="" />
                                        </a>
                                    </ImageContainer>
                                )}
                                {image2 && (
                                    <ImageContainer>
                                        <a href={image1} target="_blank">
                                            <img src={image2} alt="" />
                                        </a>
                                    </ImageContainer>
                                )}
                            </div>
                        }
                    />
                </>
            )}
        </div>
    );
};
