import {
    BarLine,
    ButtonContainer,
    Container,
    InputWrap,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    CustomButton,
    DeleteModal,
    Loader,
    ModalComp,
    PdfDownloader,
} from '../../common/component';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { MerchantRequestHeader } from '../../common/utils/dataUtils';
import { BsEye } from 'react-icons/bs';
import { MdOutlinePersonAddDisabled } from 'react-icons/md';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as UserIcon } from '../../common/images/people_icon.svg';
import { SelectComp } from '../../common/component/Ui/selectComp';
import { Controller, useForm } from 'react-hook-form';
import { showSuccessToast, showErrorToast } from '../../common/utils/toast';
import { IPartnerRequest } from '../../store/partnerRequestApi/interface';
import { AiOutlineDelete } from 'react-icons/ai';
import { useAppSelector } from '../../store/hooks';
import {
    useDeleteMerchantRequestMutation,
    useGetAllMerchantRequestQuery,
    useGetMerchantRequestByStatusQuery,
    useGetSingleMerchantRequestQuery,
    useMerchantRequestStatsQuery,
    useUpdateMerchantRequestMutation,
} from '../../store/merchantRequestApi';

export const MerchantRequest = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropDownTerm, setDropDownTerm] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [merchantRequestId, setMerchantRequestId] = useState('');
    const { user } = useAppSelector((state) => state?.auth);
    const [newArray, setNewArray] = useState<IPartnerRequest[] | any>();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: allMerchantRequest,
        isLoading,
        isFetching,
    } = useGetAllMerchantRequestQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });
    console.log('data', allMerchantRequest);
    let queryParam = {
        perPage: rowsPerPage,
        currentPage: currentPage,
        status: dropDownTerm.toLowerCase(),
    };
    const {
        data: allMerchantRequestByStatus,
        isLoading: isGettingDataByStatus,
        isFetching: isFetchingByStatus,
    } = useGetMerchantRequestByStatusQuery(queryParam, {
        refetchOnMountOrArgChange: true,
    });
    const [updateMerchantRequest, { isLoading: isProcessingPartnerRquest }] =
        useUpdateMerchantRequestMutation();
    const [deleteMerchantRequest, { isLoading: isDeleting }] =
        useDeleteMerchantRequestMutation();
    const { data: singleMerchantRequest } =
        useGetSingleMerchantRequestQuery(merchantRequestId);
    const { data: merchantRequestStats } = useMerchantRequestStatsQuery();
    const [requestStatusModal, setRequestStatusModal] = useState(false);

    useEffect(() => {
        if (dropDownTerm === '') {
            setTotalRows(
                allMerchantRequest?.pagination?.totalDocumentCount as number,
            );
        } else {
            setTotalRows(
                allMerchantRequestByStatus?.pagination
                    ?.totalDocumentCount as number,
            );
        }
    }, [
        allMerchantRequest?.pagination?.totalDocumentCount,
        allMerchantRequestByStatus?.pagination?.totalDocumentCount,
        dropDownTerm,
    ]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allMerchantRequestByStatus?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };
    const Items = [
        {
            name: ' View User',
            icon: <BsEye size={15} />,
            link: 'view',
            id: '_id',
        },
        {
            name: 'Delete item',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: any) => {
                setDeleteId(data?._id);
                setShowDeleteModal(true);
            },
        },
        {
            name: 'Manage request',
            icon: <MdOutlinePersonAddDisabled size={15} />,
            OnClick: (row: IUser) => {
                setMerchantRequestId(row._id);
                setRequestStatusModal(true);
                console.log(row);
            },
        },
    ];

    // To implement dropdown input
    useEffect(() => {
        if (dropDownTerm === '' || searchTerm === '') {
            setNewArray(allMerchantRequest?.data);
        }
        if (dropDownTerm !== '') {
            setNewArray(allMerchantRequestByStatus?.data);
        }
    }, [
        allMerchantRequest?.data,
        allMerchantRequestByStatus?.data,
        dropDownTerm,
        searchTerm,
    ]);

    // To implement search  input
    useEffect(() => {
        if (searchTerm !== '') {
            let searchItems = newArray?.filter((item: IPartnerRequest) => {
                return (
                    item?.user?.firstName
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase()) ||
                    item?.user?.lastName
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase())
                );
            });
            setNewArray(searchItems);
        }
    }, [newArray, searchTerm]);

    //handle partner request status
    const editUserStatus = useForm({
        defaultValues: {
            newData: { requestStatus: '' },
        },
    });
    const { handleSubmit, control, setValue } = editUserStatus;

    useEffect(() => {
        if (singleMerchantRequest?.data) {
            setValue(
                'newData',
                {
                    requestStatus: singleMerchantRequest?.data?.status,
                },
                { shouldValidate: true },
            );
        }
    }, [singleMerchantRequest?.data, setValue]);

    const handlePartnerRequestStatus = (value: {
        newData: { requestStatus: string };
    }) => {
        let payload = {
            merchantRequestId: merchantRequestId,
            status: value?.newData?.requestStatus,
            approvedBy: user?._id as string,
        };
        updateMerchantRequest(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setRequestStatusModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //handle delete partner request
    const confirmDelete = () => {
        deleteMerchantRequest(deleteId)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowDeleteModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'First Name', key: 'user?.firstName' },
        {
            label: 'Last Name',
            key: 'user?.lastName',
        },
        {
            label: 'Email',
            key: 'user?.email',
        },
        {
            label: 'User Type',
            key: 'userType',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { header: 'First Name', dataKey: 'firstName' },
        {
            header: 'Last Name',
            dataKey: 'lastName',
        },
        {
            header: 'Email',
            dataKey: 'email',
        },
        {
            header: 'User type',
            dataKey: 'userType',
        },
    ];

    return (
        <Container>
            {(isLoading || isGettingDataByStatus || isDeleting) && <Loader />}
            <Header>Merchant Requests</Header>
            <Grid>
                <Counter
                    counter_title="Total Applicants"
                    count={merchantRequestStats?.data?.totalApplicants || '0'}
                    iconFill="darkBlue"
                    iconBG="blue"
                >
                    <UserIcon />
                </Counter>
                <Counter
                    counter_title="Pending Applicants"
                    count={
                        merchantRequestStats?.data?.totalPendingApplicants ||
                        '0'
                    }
                    iconFill="green"
                    iconBG="green"
                >
                    <UserIcon />
                </Counter>
                <Counter
                    counter_title="Successful Applicants"
                    count={
                        merchantRequestStats?.data?.totalSuccessfulApplicants ||
                        '0'
                    }
                >
                    <UserIcon />
                </Counter>
                <Counter
                    counter_title="Rejected Applicants"
                    count={
                        merchantRequestStats?.data?.totalRejectedApplicants ||
                        '0'
                    }
                    iconFill="red"
                    iconBG="red"
                >
                    <UserIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={9}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col
                        xs={10}
                        sm={6}
                        className="d-flex align-items-center my-3"
                    >
                        <SelectComp
                            selectText="All request"
                            arrayComp={['Pending', 'Approved', 'Rejected']}
                            onChange={(e: any) =>
                                setDropDownTerm(e.target.value)
                            }
                        />
                        <TotalResultText>{`${
                            allMerchantRequest?.pagination
                                ?.totalDocumentCount || 0
                        } result`}</TotalResultText>
                    </Col>
                    <InputWrap>
                        <img src={SearchIcon} alt="" />
                        <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Search"
                            className="border-start-0 border-bottom-0 border-top-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputWrap>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'PARTNER REQUESTS RECORD'}
                            {...{ columns: headersPdf, body: newArray }}
                        />
                        <BarLine />
                        <CsvDownloader headers={headersCsv} data={newArray} />
                    </ButtonContainer>
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={MerchantRequestHeader(Items)}
                    data={newArray}
                    progressPending={isFetching || isFetchingByStatus}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>

            {/* handle partner request */}
            <ModalComp
                show={requestStatusModal}
                handleClose={() => setRequestStatusModal(false)}
                centered
                close={true}
                modalTitle="User status"
                paragraph="Fill in the  the forms to update merchant request"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form
                            onSubmit={handleSubmit(handlePartnerRequestStatus)}
                        >
                            <Controller
                                name="newData.requestStatus"
                                control={control}
                                rules={{
                                    required: 'acount status is required',
                                }}
                                render={({
                                    field: { value, onChange },
                                    formState: { errors },
                                }) => {
                                    const errorMessage =
                                        errors?.newData?.requestStatus?.message;
                                    return (
                                        <SelectComp
                                            labelText="Status"
                                            arrayComp={[
                                                'pending',
                                                'approved',
                                                'rejected',
                                            ]}
                                            name="serviceChargeOnPartnerCharge"
                                            defaultValue={
                                                singleMerchantRequest?.data
                                                    ?.status
                                            }
                                            {...{
                                                value,
                                                onChange,
                                                errors: [errorMessage],
                                            }}
                                        />
                                    );
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isProcessingPartnerRquest
                                            ? 'Save changes'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />
            <DeleteModal
                deleteItem={'Merchant Request'}
                showDeleteModal={showDeleteModal}
                cancelDelete={() => setShowDeleteModal(false)}
                confirmDelete={confirmDelete}
                isDeleting={isDeleting}
            />
        </Container>
    );
};
