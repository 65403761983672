import { useNavigate } from "react-router-dom";
import IbxLogo from "../../../common/images/ibx_main_logo.svg";

import {
	ContentContainer,
	Header,
	AuthContainer,
	Paragraph,
	PrivacyText,
	IBXLogo,
	Button,
} from "../login/style";
import { Container } from "react-bootstrap";
import { PinCodeInput } from "../../../common/component";
import { InputGrid } from "../forgetPassword/style";
import { useState } from "react";
import { showSuccessToast, showErrorToast } from "../../../common/utils/toast";
import { useAuthenticateTwoFactorMutation } from "../../../store/twoFactor";
import { useAppSelector } from "../../../store/hooks";

export const SetTwoFactor = () => {
	const navigate = useNavigate();
	const { user } = useAppSelector((state: any) => state.auth);
	const [otp, setOtp] = useState("");
	const onChange = (value: string) => setOtp(value);
	const [authenticateTwoFactor, { isLoading }] =
		useAuthenticateTwoFactorMutation();
		

	const onHandleSubmit = () => {
		if (otp === "") {
			showErrorToast("Inputs can't be empty");
		} else {
			let payload = {
				userId: user?._id as string,
				userToken: otp,
			};
			authenticateTwoFactor(payload)
				.unwrap()
				.then((result) => {
					showSuccessToast(result?.message);
					navigate("/dashboard/admin");
					console.log("res", result?.data);
				})
				.catch((error) => {
					showErrorToast(error?.data?.message);
				});
		}
	};
	return (
		<Container>
			<AuthContainer>
				<ContentContainer>
					<IBXLogo className="d-flex justify-content-center mb-5 pt-2">
						<img src={IbxLogo} alt="" />
					</IBXLogo>
					<>
						<div className="pb-2">
							<Header>Two Factor</Header>
						</div>
						<Paragraph>
							Enter a valid two-step verification pin generated from your authentication app
						</Paragraph>

						<InputGrid>
							<PinCodeInput length={6} setValue={onChange} />
						</InputGrid>
						<div className="d-flex justify-content-center my-5">
							<Button onClick={onHandleSubmit}>
								{!isLoading ? "Send" : "Loading..."}
							</Button>
						</div>
					</>
				</ContentContainer>
			</AuthContainer>
			<PrivacyText>
				Protected by reCAPTCHA and subject to the Google{" "}
				<span>Privacy Policy</span> and <span>Terms of Service.</span>
			</PrivacyText>
		</Container>
	);
};
