import {
    BarLine,
    ButtonContainer,
    Container,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    CustomButton,
    DeleteModal,
    Loader,
    ModalComp,
    PdfDownloader,
} from '../../common/component';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { KYCHeader } from '../../common/utils/dataUtils';
import { BsEye } from 'react-icons/bs';
import { MdOutlinePersonAddDisabled } from 'react-icons/md';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { ReactComponent as KycIcon } from '../../common/images/kyc_icon.svg';
import KYCImage from '../../common/images/kyc_Image.svg';
import { SelectComp } from '../../common/component/Ui/selectComp';
import { Controller, useForm } from 'react-hook-form';
import { showSuccessToast, showErrorToast } from '../../common/utils/toast';
import { IPartnerRequest } from '../../store/partnerRequestApi/interface';
import { AiOutlineDelete } from 'react-icons/ai';
import { useAppSelector } from '../../store/hooks';

import {
    useGetAllKycQuery,
    useUpdateKycMutation,
    useDeleteKycMutation,
    useGetSingleKycQuery,
    useKycStatsQuery,
} from '../../store/kycApi';

export const KYC = () => {
    const [dropDownTerm, setDropDownTerm] = useState('');
    const [KycId, setKycId] = useState('');
    const { user } = useAppSelector((state) => state?.auth);
    const [newArray, setNewArray] = useState<IPartnerRequest[] | any>();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const params = {
        perPage: rowsPerPage,
        currentPage: currentPage,
        status: dropDownTerm.toLowerCase(),
    };
    const {
        data: allKyc,
        isLoading,
        isFetching,
    } = useGetAllKycQuery(
        { params },
        {
            refetchOnMountOrArgChange: true,
        },
    );

    const [updateKyc, { isLoading: isProcessingKyc }] = useUpdateKycMutation();
    const [deleteKyc, { isLoading: isDeleting }] = useDeleteKycMutation();
    const { data: singleKyc } = useGetSingleKycQuery(KycId);
    const { data: KycStats } = useKycStatsQuery();
    const [requestStatusModal, setRequestStatusModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    useEffect(() => {
        if (dropDownTerm === '') {
            setTotalRows(allKyc?.pagination?.totalDocumentCount as number);
        }
    }, [allKyc?.pagination?.totalDocumentCount, dropDownTerm]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allKyc?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const Items = [
        {
            name: ' View Kyc',
            icon: <BsEye size={15} />,
            link: 'view',
            id: '_id',
        },
        {
            name: 'Delete item',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: IKyc) => {
                setShowDeleteModal(true);
                setDeleteId(data?._id);
            },
        },
        {
            name: 'Manage request',
            icon: <MdOutlinePersonAddDisabled size={15} />,
            OnClick: (row: IKyc) => {
                setKycId(row._id);
                setRequestStatusModal(true);
            },
        },
    ];

    const editUserStatus = useForm({
        defaultValues: {
            newData: { requestStatus: '', requestType: '' },
        },
    });
    const { handleSubmit, control } = editUserStatus;

    const handleKycStatus = (value: {
        newData: { requestStatus: string; requestType: string };
    }) => {
        let payload = {
            kycId: KycId,
            status: value?.newData?.requestStatus,
            kycType: value?.newData?.requestType,
            approvedBy: user?._id as string,
        };

        updateKyc(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setRequestStatusModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //handle delete partner request
    const onHandleDelete = () => {
        deleteKyc(deleteId)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowDeleteModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'First Name', key: 'user?.firstName' },
        { label: 'Last Name', key: 'user?.lastName' },
        {
            label: 'Email',
            key: 'user?.email',
        },
        {
            label: 'Bvn Verification',
            key: 'bvnVerification?.status',
        },
        {
            label: 'Identity Verification',
            key: 'identityVerification?.status',
        },
        {
            label: 'Address Verification',
            key: 'addressVerification?.status',
        },
        {
            label: 'kyc Status',
            key: 'status',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { header: 'First Name', dataKey: 'user?.firstName' },
        { header: 'Last Name', dataKey: 'user?.lastName' },
        {
            header: 'Email',
            dataKey: 'user?.email',
        },
        {
            header: 'Bvn Verification',
            dataKey: 'bvnVerification?.status',
        },
        {
            header: 'Identity Verification',
            dataKey: 'identityVerification?.status',
        },
        {
            header: 'Address Verification',
            dataKey: 'addressVerification?.status',
        },
        {
            header: 'kyc Status',
            dataKey: 'status',
        },
    ];

    return (
        <Container>
            {(isLoading || isDeleting) && <Loader />}
            <Header>KYC</Header>
            <Grid>
                <Counter
                    counter_title="Total KYC"
                    count={KycStats?.data?.totalKYC || '0'}
                    iconFill="darkBlue"
                    iconBG="blue"
                    backgroundImage={KYCImage}
                    isBackgroundImage={true}
                >
                    <KycIcon />
                </Counter>
                <Counter
                    counter_title="Approved KYC"
                    count={KycStats?.data?.totalApprovedKYC || '0'}
                    iconFill="green"
                    iconBG="green"
                >
                    <KycIcon />
                </Counter>
                <Counter
                    counter_title="Pending KYC"
                    count={KycStats?.data?.totalPendingKYC || '0'}
                >
                    <KycIcon />
                </Counter>
                <Counter
                    counter_title="Rejected KYC"
                    count={KycStats?.data?.totalRejectedKYC || '0'}
                    iconFill="red"
                    iconBG="red"
                >
                    <KycIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={9}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col
                        xs={10}
                        sm={6}
                        className="d-flex align-items-center my-3"
                    >
                        <SelectComp
                            selectText="All Kyc"
                            arrayComp={[
                                { name: 'pending', value: 'pending' },
                                { name: 'approved', value: 'approved' },
                                { name: 'rejected', value: 'rejected' },
                            ]}
                            onChange={(e: any) =>
                                setDropDownTerm(e.target.value)
                            }
                        />
                        <TotalResultText>{`${
                            allKyc?.data?.length || 0
                        } result`}</TotalResultText>
                    </Col>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'KYC RECORD'}
                            {...{ columns: headersPdf, body: allKyc?.data }}
                        />
                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={allKyc?.data}
                        />
                    </ButtonContainer>
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={KYCHeader(Items)}
                    data={allKyc?.data as IKyc[]}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>

            {/* handle partner request */}
            <ModalComp
                show={requestStatusModal}
                handleClose={() => setRequestStatusModal(false)}
                centered
                close={true}
                modalTitle="User status"
                paragraph="Fill in the  the forms to update merchant request"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleSubmit(handleKycStatus)}>
                            <Controller
                                name="newData.requestType"
                                control={control}
                                rules={{
                                    required: 'Kyc type is required',
                                }}
                                render={({
                                    field: { value, onChange },
                                    formState: { errors },
                                }) => {
                                    const errorMessage =
                                        errors?.newData?.requestType?.message;
                                    return (
                                        <SelectComp
                                            labelText="Kyc type"
                                            arrayComp={[
                                                'bvn',
                                                'address',
                                                'identity',
                                            ]}
                                            name="serviceChargeOnPartnerCharge"
                                            {...{
                                                value,
                                                onChange,
                                                errors: [errorMessage],
                                            }}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="newData.requestStatus"
                                control={control}
                                rules={{
                                    required: 'acount status is required',
                                }}
                                render={({
                                    field: { value, onChange },
                                    formState: { errors },
                                }) => {
                                    const errorMessage =
                                        errors?.newData?.requestStatus?.message;
                                    return (
                                        <SelectComp
                                            labelText="Status"
                                            arrayComp={['approved', 'rejected']}
                                            name="serviceChargeOnPartnerCharge"
                                            {...{
                                                value,
                                                onChange,
                                                errors: [errorMessage],
                                            }}
                                        />
                                    );
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isProcessingKyc
                                            ? 'Save changes'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />

            {/*delete  modal  */}
            <DeleteModal
                deleteItem={'KYC'}
                showDeleteModal={showDeleteModal}
                cancelDelete={() => setShowDeleteModal(false)}
                confirmDelete={onHandleDelete}
                isDeleting={isDeleting}
            />
        </Container>
    );
};
