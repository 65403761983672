import {
	Button,
	ContentContainer,
	ForgetPassword,
	Header,
	AuthContainer,
	Paragraph,
	PrivacyText,
	IBXLogo,
} from "./style";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import IbxLogo from "../../../common/images/ibx_main_logo.svg";
import { Container } from "react-bootstrap";
import { PasswordInputComp, TextInputComp } from "../../../common/component";
import { useUserLoginMutation } from "../../../store/auth/authApi";
import { showErrorToast, showSuccessToast } from "../../../common/utils/toast";
import { setAuthToken } from "../../../common/utils/helpers";
import { useAppDispatch } from "../../../store/hooks";
import { setUser } from "../../../store/auth";

export const Login = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [userLogin, { isLoading }] = useUserLoginMutation();

	const login = useForm({
		defaultValues: {
			email: "",
			password: "",
		},
	});
	const { handleSubmit, control } = login;

	const onLogin = (data: { email: string; password: string }) => {
		userLogin(data)
			.unwrap()
			.then((result) => {
				if (result?.data?.userType === "admin") {
					showSuccessToast(result?.message);
					setAuthToken(result?.data?.authToken);
					dispatch(setUser(result?.data));
					if (!result?.data?.twoFactorAuthEnabled) {
						navigate("/dashboard/admin");
					} else {
						navigate("/two-factor");
					}
				} else {
					showErrorToast("You are not authorized to login");
				}
			})
			.catch((error) => {
				showErrorToast(error?.data?.message);
			});
	};

	return (
		<Container>
			<AuthContainer>
				<ContentContainer>
					<IBXLogo className="d-flex justify-content-center mb-5 pt-2">
						<img src={IbxLogo} alt="" />
					</IBXLogo>
					<div className="pb-3">
						<Header>Login</Header>
					</div>
					<Paragraph>
						Please enter the details to get access of your account.
					</Paragraph>
					<form onSubmit={handleSubmit(onLogin)}>
						<div className="my-3">
							<Controller
								name="email"
								control={control}
								rules={{
									required: "Email is required",
								}}
								render={({
									field: { value, onChange },
									formState: { errors },
								}) => {
									const errorMessage = errors?.email?.message;
									return (
										<TextInputComp
											labelText="Email Address"
											placeholder="Email Address"
											// inputClassName={"py-2"}
											name="email"
											{...{ value, onChange, errors: [errorMessage] }}
										/>
									);
								}}
							/>
						</div>
						<Controller
							name="password"
							control={control}
							rules={{
								required: "Password is required",
							}}
							render={({
								field: { value, onChange },
								formState: { errors },
							}) => {
								const errorMessage = errors?.password?.message;
								return (
									<PasswordInputComp
										labelText="Password"
										type="password"
										placeholder="Password"
										// inputClassName={"py-2"}
										name="password"
										{...{ value, onChange, errors: [errorMessage] }}
									/>
								);
							}}
						/>
						<ForgetPassword onClick={() => navigate("/password/forgot")}>
							Forgot password?
						</ForgetPassword>
						<div className="d-flex justify-content-center my-3">
							<Button>{!isLoading ? "Login" : "Loading..."}</Button>
						</div>
					</form>
				</ContentContainer>
			</AuthContainer>
			<PrivacyText>
				Protected by reCAPTCHA and subject to the Google{" "}
				<span>Privacy Policy</span> and <span>Terms of Service.</span>
			</PrivacyText>
		</Container>
	);
};
