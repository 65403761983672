import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { paginatedResponse } from '../paginatedInterface';
import { IPartnerRequest, IPartnerRequestStats } from './interface';

export const partnerRequestApi = createApi({
    reducerPath: 'partnerRequestApi',
    baseQuery: baseQuery,
    tagTypes: ['partnerRequest'],
    endpoints: (builder) => ({
        getAllPartnerRequest: builder.query<
            paginatedResponse<IPartnerRequest[]>,
            { currentPage: number; perPage: number }
        >({
            query: ({ currentPage, perPage }) => ({
                url: `/user/api/v1/partner-request?perPage=${perPage}&currentPage=${currentPage}`,
                method: 'GET',
            }),
            providesTags: ['partnerRequest'],
        }),
        getPartnerRequestByStatus: builder.query<
            paginatedResponse<IPartnerRequest[]>,
            { currentPage: number; status?: string; perPage: number }
        >({
            query: ({ currentPage, status, perPage }) => ({
                url: `/user/api/v1/partner-request`,
                method: 'GET',
                params: { currentPage, status, perPage },
            }),
            providesTags: ['partnerRequest'],
        }),
        updatePartnerRequest: builder.mutation<
            Response<IPartnerRequest>,
            {
                partnerRequestId: string;
                status: string;
                approvedBy: string;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/partner-request/approvePartnerRequest`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['partnerRequest'],
        }),
        getSinglePartnerRequest: builder.query<
            Response<IPartnerRequest>,
            string
        >({
            query: (partnerRequestId) => ({
                url: `/user/api/v1/partner-request/${partnerRequestId}`,
                method: 'GET',
            }),
            providesTags: ['partnerRequest'],
        }),
        deletePartnerRequest: builder.mutation<
            Response<IPartnerRequest>,
            string
        >({
            query: (partnerRequestId) => ({
                url: `/user/api/v1/partner-request/${partnerRequestId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['partnerRequest'],
        }),
        partnerRequestStats: builder.query<
            Response<IPartnerRequestStats>,
            void
        >({
            query: () => ({
                url: 'user/api/v1/partner-request/a/stats',
                method: 'GET',
            }),
            providesTags: ['partnerRequest'],
        }),
    }),
});

export const {
    useGetAllPartnerRequestQuery,
    useUpdatePartnerRequestMutation,
    useGetSinglePartnerRequestQuery,
    useDeletePartnerRequestMutation,
    usePartnerRequestStatsQuery,
    useGetPartnerRequestByStatusQuery,
} = partnerRequestApi;
