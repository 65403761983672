import {
    BarLine,
    ButtonContainer,
    Container,
    InputWrap,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    CustomButton,
    DeleteModal,
    Loader,
    PdfDownloader,
    SelectComp,
    TextInputComp,
} from '../../common/component';
import {
    useAddReferralCampaignMutation,
    useReferralCampaignStatsQuery,
    useGetSingleReferralCampaignQuery,
    useGetAllReferralCampaignQuery,
    useEditReferralCampaignMutation,
    useDeleteReferralCampaignMutation,
} from '../../store/referralCampaignApi';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { ReferralCampaignTableHeader } from '../../common/utils/dataUtils';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { ModalComp } from '../../common/component/Ui/modalComp';
import SearchIcon from '../../common/images/search_icon.svg';
import { IReferralCampaign } from '../../store/referralCampaignApi/interface';
import { Controller, useForm } from 'react-hook-form';
import { showErrorToast, showSuccessToast } from '../../common/utils/toast';
import { ReactComponent as ReferralCampaignIcon } from '../../common/images/referral_campaign_icon.svg';

export const ReferralCampaign = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [newArray, setNewArray] = useState<IReferralCampaign[] | any>();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [referralCampaignId, referralCampaignName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: allReferralCampaign,
        isLoading,
        isFetching,
    } = useGetAllReferralCampaignQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });
    const { data: singleReferralCampaign } =
        useGetSingleReferralCampaignQuery(referralCampaignId);
    const { data: referralCampaignStats } = useReferralCampaignStatsQuery();
    const [addReferralCampaign, { isLoading: isAdding }] =
        useAddReferralCampaignMutation();
    const [deleteReferralCampaign, { isLoading: isDeleting }] =
        useDeleteReferralCampaignMutation();
    const [editReferralCampaign, { isLoading: isEditing }] =
        useEditReferralCampaignMutation();

    useEffect(() => {
        setTotalRows(
            allReferralCampaign?.pagination?.totalDocumentCount as number,
        );
    }, [allReferralCampaign?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allReferralCampaign?.data?.length) return;
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const handleConfirmDelete = () => {
        onHandleDelete(deleteId);
        setShowDeleteModal(false);
    };

    const Items = [
        {
            name: 'Edit item',
            icon: <BiEditAlt size={15} />,
            OnClick: (data: any) => {
                referralCampaignName(data?._id);
                setShowEditModal(true);
            },
        },
        {
            name: 'Delete item',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: any) => {
                setShowDeleteModal(true);
                setDeleteId(data?._id);
            },
        },
    ];

    // To implement search and dropdown input
    useEffect(() => {
        if (searchTerm === '') {
            setNewArray(allReferralCampaign?.data);
        } else {
            let searchItems = allReferralCampaign?.data?.filter(
                (item: IReferralCampaign) => {
                    return item?.referralCampaignName
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase());
                },
            );
            setNewArray(searchItems);
        }
    }, [allReferralCampaign, searchTerm]);

    const referralCampaign = useForm({
        defaultValues: {
            referralCampaignName: '',
            amount: '',
        },
    });
    const { handleSubmit, control, reset } = referralCampaign;

    const editReferralCampaignForm = useForm({
        defaultValues: {
            newData: {
                referralCampaignName: '',
                amount: '',
                status: '',
            },
        },
    });
    const {
        handleSubmit: handleEditSubmit,
        control: editControl,
        setValue,
    } = editReferralCampaignForm;

    useEffect(() => {
        if (singleReferralCampaign?.data?._id) {
            setValue(
                'newData',
                {
                    referralCampaignName:
                        singleReferralCampaign?.data?.referralCampaignName,
                    amount: singleReferralCampaign?.data?.amount,
                    status: singleReferralCampaign?.data?.status
                        ? 'Active'
                        : 'Inactive',
                },
                { shouldValidate: true },
            );
        }
    }, [singleReferralCampaign, setValue]);

    const onHandleSubmit = (value: {
        referralCampaignName: string;
        amount: string;
    }) => {
        let payload = {
            referralCampaignName: value.referralCampaignName,
            amount: parseFloat(value.amount),
        };
        addReferralCampaign(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowAddModal(false);
                reset();
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    const onHandleEdit = (value: {
        newData: {
            referralCampaignName: string;
            amount: string;
            status: string;
        };
    }) => {
        const { referralCampaignName, amount, status } = value?.newData;

        let payload = {
            referralCampaignName: referralCampaignName,
            amount: parseFloat(amount),
            referralCampaignId: singleReferralCampaign?.data?._id,
            status: status === 'Active' ? true : false,
        };
        console.log('ref id: ' + singleReferralCampaign?.data?._id);
        editReferralCampaign(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowEditModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    const onHandleDelete = (id: string) => {
        deleteReferralCampaign(id)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'Referral Campaign Name', key: 'referralCampaignName' },
        {
            label: 'Referral amount',
            key: 'amount',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { label: 'Referral Campaign Name', key: 'referralCampaignName' },
        {
            label: 'Referral amount',
            key: 'amount',
        },
    ];

    return (
        <Container>
            {isLoading || isDeleting ? <Loader /> : null}
            <Header>Referral Campaign</Header>
            <Grid>
                <Counter
                    counter_title="Total Campaigns"
                    count={
                        referralCampaignStats?.data?.totalReferralCampaigns || 0
                    }
                    iconFill="blue"
                    iconBG="blue"
                >
                    <ReferralCampaignIcon />
                </Counter>
                <Counter
                    counter_title="Active Campaigns"
                    count={
                        referralCampaignStats?.data?.activeReferralCampaigns ||
                        0
                    }
                    iconFill="green"
                    iconBG="green"
                >
                    <ReferralCampaignIcon />
                </Counter>
                <Counter
                    counter_title="Inactive Campaigns"
                    count={
                        referralCampaignStats?.data
                            ?.InActiveReferralCampaigns || 0
                    }
                    iconFill="red"
                    iconBG="red"
                >
                    <ReferralCampaignIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={8}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col xl={3} className="d-flex align-items-center my-3">
                        <TotalResultText>{`${
                            allReferralCampaign?.data?.length || 0
                        } ref campaigns`}</TotalResultText>
                    </Col>
                    <InputWrap>
                        <img src={SearchIcon} alt="" />
                        <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Search"
                            className="border-start-0 border-bottom-0 border-top-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputWrap>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'REFERRAL CAMPAIGN RECORD'}
                            {...{
                                columns: headersPdf,
                                body: allReferralCampaign?.data,
                            }}
                        />
                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={allReferralCampaign?.data}
                        />
                    </ButtonContainer>
                    <CustomButton
                        text="+ New Campaign"
                        onClick={() => setShowAddModal(true)}
                    />
                </div>
            </div>
            <ContainerComp>
                <TableCompData
                    columns={ReferralCampaignTableHeader(Items)}
                    data={newArray}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>

            {/* add referral campaign modal */}
            <ModalComp
                show={showAddModal}
                handleClose={() => setShowAddModal(false)}
                centered
                close={true}
                modalTitle="Add Referral campaign"
                paragraph="Fill in the  the forms to add a referral campaign"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleSubmit(onHandleSubmit)}>
                            <div className="my-3">
                                <Controller
                                    name="referralCampaignName"
                                    control={control}
                                    rules={{
                                        required:
                                            'Referral campaign Name is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.referralCampaignName
                                                ?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Referral Campaign Name"
                                                placeholder="eg: April referral campaign"
                                                inputClassName={'py-2 '}
                                                name="referralCampaignName"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="amount"
                                    control={control}
                                    rules={{
                                        required: 'Referral amount is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.amount?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Referral amount"
                                                placeholder="eg: 500"
                                                inputClassName={'py-2 '}
                                                name="amount"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isAdding
                                            ? 'Create Referral campaign'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />

            {/* edit modal */}
            <ModalComp
                show={showEditModal}
                handleClose={() => setShowEditModal(false)}
                centered
                close={true}
                modalTitle="Edit Referral campaign"
                paragraph="Fill in the forms to edit a referral campaign"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleEditSubmit(onHandleEdit)}>
                            <div className="my-3">
                                <Controller
                                    name="newData.referralCampaignName"
                                    control={editControl}
                                    rules={{
                                        required:
                                            'Referral Campaign name is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData
                                                ?.referralCampaignName?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Referral campaign name"
                                                placeholder="eg: April referral campaign"
                                                inputClassName={'py-2 '}
                                                name="referralCampaignName"
                                                defaultValue={
                                                    singleReferralCampaign?.data
                                                        ?.referralCampaignName
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.amount"
                                    control={editControl}
                                    rules={{
                                        required: 'Referral amount is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.amount?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Referral amount"
                                                placeholder="eg: 500"
                                                inputClassName={'py-2 '}
                                                name="amount"
                                                defaultValue={
                                                    singleReferralCampaign?.data
                                                        ?.amount
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <Controller
                                name="newData.status"
                                control={editControl}
                                rules={{
                                    required: 'status is required',
                                }}
                                render={({
                                    field: { value, onChange },
                                    formState: { errors },
                                }) => {
                                    const errorMessage =
                                        errors?.newData?.status?.message;
                                    return (
                                        <SelectComp
                                            labelText="Status"
                                            inputClassName={'py-2 '}
                                            arrayComp={['Active', 'Inactive']}
                                            name="status"
                                            defaultValue={
                                                singleReferralCampaign?.data
                                                    ?.status
                                                    ? 'Active'
                                                    : 'Inactive'
                                            }
                                            {...{
                                                value,
                                                onChange,
                                                errors: [errorMessage],
                                            }}
                                        />
                                    );
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isEditing
                                            ? 'Edit Referral campaign'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />

            {/*delete  modal  */}
            <DeleteModal
                deleteItem={'Referral Campaign'}
                showDeleteModal={showDeleteModal}
                cancelDelete={() => setShowDeleteModal(false)}
                confirmDelete={handleConfirmDelete}
            />
        </Container>
    );
};
