import { useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { Container } from '../users/style';
import {
    CustomButton,
    Loader,
    PhoneInputComp,
    SelectComp,
    TextInputComp,
    colors,
} from '../../common/component';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Header } from '../dashboard/styles';
import { useForm, Controller } from 'react-hook-form';
import { validateNumber } from './helper';
import {
    useEditConfigMutation,
    useGetSingleConfigQuery,
} from '../../store/configApi';
import { showErrorToast, showSuccessToast } from '../../common/utils/toast';

export const EditConfiguration = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const currencyPairId = searchParams.get('id');

    const [editConfig, { isLoading: isEditing }] = useEditConfigMutation();
    const {
        data: singleConfig,
        isLoading: isFetchingConfig,
        refetch,
    } = useGetSingleConfigQuery(currencyPairId);

    const editConfiguraitonForm = useForm({
        defaultValues: {
            newData: {
                partnerChargePerTrade: '',
                serviceChargeOnPartnerCharge: '',
                supportNumber: '',
                tiktok: '',
                youtube: '',
                twitter: '',
                facebook: '',
                instagram: '',
                linkedin: '',
                newUserMaxAmount: '',
                newUserMinAmount: '',
                advancedUserMinAmount: '',
                advancedUserMaxAmount: '',
                beginnerMinAmount: '',
                beginnerMaxAmount: '',
                intermediateUserMinAmount: '',
                intermediateUserMaxAmount: '',
                status: '',
            },
        },
    });
    const { handleSubmit, control, reset, setValue } = editConfiguraitonForm;

    useEffect(() => {
        if (singleConfig?.data?._id) {
            setValue(
                'newData',
                {
                    partnerChargePerTrade:
                        singleConfig?.data?.partnerChargePerTrade,
                    serviceChargeOnPartnerCharge:
                        singleConfig?.data?.serviceChargeOnPartnerCharge,
                    supportNumber: singleConfig?.data?.supportNumber,
                    tiktok: singleConfig?.data?.socialLinks?.tiktok,
                    youtube: singleConfig?.data?.socialLinks?.youtube,
                    twitter: singleConfig?.data?.socialLinks?.twitter,
                    facebook: singleConfig?.data?.socialLinks?.facebook,
                    instagram: singleConfig?.data?.socialLinks?.instagram,
                    linkedin: singleConfig?.data?.socialLinks?.linkedin,
                    newUserMinAmount:
                        singleConfig?.data?.transactionLimits?.new_user?.min,
                    newUserMaxAmount:
                        singleConfig?.data?.transactionLimits?.new_user?.max,
                    advancedUserMinAmount:
                        singleConfig?.data?.transactionLimits?.advanced?.min,
                    advancedUserMaxAmount:
                        singleConfig?.data?.transactionLimits?.advanced?.max,
                    beginnerMinAmount:
                        singleConfig?.data?.transactionLimits?.beginner?.min,
                    beginnerMaxAmount:
                        singleConfig?.data?.transactionLimits?.beginner?.max,
                    intermediateUserMinAmount:
                        singleConfig?.data?.transactionLimits?.intermediate
                            ?.min,
                    intermediateUserMaxAmount:
                        singleConfig?.data?.transactionLimits?.intermediate
                            ?.max,
                    status: singleConfig?.data?.isActive
                        ? 'Active'
                        : 'Inactive',
                },
                { shouldValidate: true },
            );
        }
    }, [singleConfig, setValue]);

    const onHandleSubmit = (value: {
        newData: {
            partnerChargePerTrade: string;
            serviceChargeOnPartnerCharge: string;
            supportNumber: string;
            facebook: string;
            tiktok: string;
            twitter: string;
            youtube: string;
            instagram: string;
            linkedin: string;
            newUserMaxAmount: string;
            newUserMinAmount: string;
            advancedUserMinAmount: string;
            advancedUserMaxAmount: string;
            beginnerMinAmount: string;
            beginnerMaxAmount: string;
            intermediateUserMinAmount: string;
            intermediateUserMaxAmount: string;
            status: string;
        };
    }) => {
        let payload = {
            partnerChargePerTrade: parseFloat(
                value?.newData?.partnerChargePerTrade,
            ),
            serviceChargeOnPartnerCharge: parseFloat(
                value?.newData?.serviceChargeOnPartnerCharge,
            ),
            supportNumber: value?.newData?.supportNumber,
            socialLinks: {
                facebook: value?.newData?.facebook,
                twitter: value?.newData?.twitter,
                youtube: value?.newData?.youtube,
                tiktok: value?.newData?.tiktok,
                instagram: value?.newData?.instagram,
                linkedin: value?.newData?.instagram,
            },
            transactionLimits: {
                new_user: {
                    min: Number(value?.newData?.newUserMinAmount),
                    max: Number(value?.newData?.newUserMaxAmount),
                },
                beginner: {
                    min: Number(value?.newData?.beginnerMinAmount),
                    max: Number(value?.newData?.beginnerMaxAmount),
                },
                intermediate: {
                    min: Number(value?.newData?.intermediateUserMinAmount),
                    max: Number(value?.newData?.intermediateUserMaxAmount),
                },
                advanced: {
                    min: Number(value?.newData?.advancedUserMinAmount),
                    max: Number(value?.newData?.advancedUserMaxAmount),
                },
            },
            isActive: value?.newData?.status === 'Active' ? true : false,
            setupId: singleConfig?.data?._id as string,
        };
        editConfig(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                reset();
                refetch();
                setTimeout(() => {
                    navigate('/dashboard/configuration');
                }, 3000);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    return (
        <Container>
            {isFetchingConfig ? <Loader /> : null}
            <Header>Edit Configurations</Header>
            <TextParagraphOne>Edit existing configuration</TextParagraphOne>
            <div className="py-4 px-lg-4 ">
                <form onSubmit={handleSubmit(onHandleSubmit)}>
                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="newData.partnerChargePerTrade"
                                    control={control}
                                    rules={{
                                        required: 'Partner charge is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData
                                                ?.partnerChargePerTrade
                                                ?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Partner Charge"
                                                placeholder="eg: 0.017"
                                                inputClassName={'py-2 '}
                                                name="partnerChargePerTrade"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="newData.serviceChargeOnPartnerCharge"
                                    control={control}
                                    rules={{
                                        required: 'service charge is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData
                                                ?.serviceChargeOnPartnerCharge
                                                ?.message;
                                        return (
                                            <TextInputComp
                                                labelText="Service Charge"
                                                placeholder="eg: 0.5"
                                                inputClassName={'py-2 '}
                                                name="serviceChargeOnPartnerCharge"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="newData.supportNumber"
                                    control={control}
                                    rules={{
                                        required: 'support number is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.supportNumber
                                                ?.message;
                                        return (
                                            <PhoneInputComp
                                                labelText="Support Number"
                                                name="supportNumber"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="newData.facebook"
                                    control={control}
                                    rules={{
                                        required:
                                            'facebook social link is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.facebook?.message;
                                        return (
                                            <TextInputComp
                                                inputType="url"
                                                labelText="Facebook Link"
                                                inputClassName={'py-2 '}
                                                name="facebook"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="newData.twitter"
                                    control={control}
                                    rules={{
                                        required:
                                            'twitter social link is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.twitter?.message;
                                        return (
                                            <TextInputComp
                                                inputType="url"
                                                labelText="Twitter Link"
                                                inputClassName={'py-2 '}
                                                name="twitter"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="newData.youtube"
                                    control={control}
                                    rules={{
                                        required:
                                            'youtube social link is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.youtube?.message;
                                        return (
                                            <TextInputComp
                                                inputType="url"
                                                labelText="Youtube Link"
                                                inputClassName={'py-2 '}
                                                name="youtube"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="newData.tiktok"
                                    control={control}
                                    rules={{
                                        required:
                                            'tiktok social link is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.tiktok?.message;
                                        return (
                                            <TextInputComp
                                                inputType="url"
                                                labelText="Tiktok Link"
                                                inputClassName={'py-2 '}
                                                name="tiktok"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="newData.instagram"
                                    control={control}
                                    rules={{
                                        required:
                                            'instagram social link is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.instagram?.message;
                                        return (
                                            <TextInputComp
                                                inputType="url"
                                                labelText="Instagram Link"
                                                inputClassName={'py-2 '}
                                                name="instagram"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="newData.linkedin"
                                    control={control}
                                    rules={{
                                        required:
                                            'Linkedin social link is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.linkedin?.message;
                                        return (
                                            <TextInputComp
                                                inputType="url"
                                                labelText="Linkedin Link"
                                                inputClassName={'py-2 '}
                                                name="linkedin"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>

                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <Controller
                                    name="newData.status"
                                    control={control}
                                    rules={{
                                        required: 'status is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.status?.message;
                                        return (
                                            <SelectComp
                                                labelText="Status"
                                                inputClassName={'py-2 '}
                                                arrayComp={[
                                                    'Active',
                                                    'Inactive',
                                                ]}
                                                name="serviceChargeOnPartnerCharge"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <hr />

                    <SubHeader>Transaction Limits</SubHeader>
                    <TextParagraphTwo>Set a limit for users</TextParagraphTwo>
                    <Row>
                        <Col sm={6} xs={12} className="pt-3">
                            <label>New User</label>
                            <TransactionLimitBox>
                                <Row>
                                    <Col sm={6}>
                                        <Controller
                                            name="newData.newUserMinAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'min amount is required',
                                                // validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors?.newData
                                                        ?.newUserMinAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        inputType="number"
                                                        labelText="Min Amount"
                                                        inputClassName={'py-2 '}
                                                        name="newUserMinAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Controller
                                            name="newData.newUserMaxAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'max amount is required',
                                                // validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors?.newData
                                                        ?.newUserMaxAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        inputType="number"
                                                        labelText="Max Amount"
                                                        inputClassName={'py-2 '}
                                                        name="newUserMaxAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </TransactionLimitBox>
                        </Col>
                        <Col sm={6} xs={12} className="pt-3">
                            <label>Beginner</label>
                            <TransactionLimitBox>
                                <Row>
                                    <Col sm={6}>
                                        <Controller
                                            name="newData.beginnerMinAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'min amount is required',
                                                // validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors?.newData
                                                        ?.beginnerMinAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        inputType="number"
                                                        labelText="Min Amount"
                                                        inputClassName={'py-2 '}
                                                        name="beginnerMinAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Controller
                                            name="newData.beginnerMaxAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'max amount is required',
                                                // validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors?.newData
                                                        ?.beginnerMaxAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        inputType="number"
                                                        labelText="Max Amount"
                                                        inputClassName={'py-2 '}
                                                        name="beginnerMaxAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </TransactionLimitBox>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} xs={12} className="pt-3">
                            <label>Intermediate User</label>
                            <TransactionLimitBox>
                                <Row>
                                    <Col sm={6}>
                                        <Controller
                                            name="newData.intermediateUserMinAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'min amount is required',
                                                // validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors?.newData
                                                        ?.intermediateUserMinAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        inputType="number"
                                                        labelText="Min Amount"
                                                        inputClassName={'py-2 '}
                                                        name="intermediateUserMinAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Controller
                                            name="newData.intermediateUserMaxAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'max amount is required',
                                                // validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors?.newData
                                                        ?.intermediateUserMaxAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        inputType="number"
                                                        labelText="Max Amount"
                                                        inputClassName={'py-2 '}
                                                        name="intermediateUserMaxAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </TransactionLimitBox>
                        </Col>
                        <Col sm={6} xs={12} className="pt-3">
                            <label>Advanced</label>
                            <TransactionLimitBox>
                                <Row>
                                    <Col sm={6}>
                                        <Controller
                                            name="newData.advancedUserMinAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'min amount is required',
                                                // validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors?.newData
                                                        ?.advancedUserMinAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        inputType="number"
                                                        labelText="Min Amount"
                                                        inputClassName={'py-2 '}
                                                        name="advancedUserMinAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Controller
                                            name="newData.advancedUserMaxAmount"
                                            control={control}
                                            rules={{
                                                required:
                                                    'max amount is required',
                                                // validate: validateNumber,
                                            }}
                                            render={({
                                                field: { value, onChange },
                                                formState: { errors },
                                            }) => {
                                                const errorMessage =
                                                    errors?.newData
                                                        ?.advancedUserMaxAmount
                                                        ?.message;
                                                return (
                                                    <TextInputComp
                                                        inputType="number"
                                                        labelText="Max Amount"
                                                        inputClassName={'py-2 '}
                                                        name="advancedUserMaxAmount"
                                                        {...{
                                                            value,
                                                            onChange,
                                                            errors: [
                                                                errorMessage,
                                                            ],
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </TransactionLimitBox>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-center">
                        <CustomButton
                            className="py-3 mt-4"
                            text={
                                isEditing ? 'Loading...' : 'Edit Configuration'
                            }
                        />
                    </div>
                </form>
            </div>
        </Container>
    );
};

const TextParagraphOne = styled.p`
    margin-top: -1rem;
    color: ${colors?.gray31};
`;

const TextParagraphTwo = styled.p`
    color: ${colors?.gray31};
    font-size: 0.8rem;
`;

const TransactionLimitBox = styled.div`
    border: 1px solid #a1a1a1;
    border-radius: 8px;
    padding: 1rem;
    height: 10rem;
    margin-top: 0.5rem;
`;

export const SubHeader = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${colors.blue10};
`;
