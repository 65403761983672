import { BankCard, Loader } from "../../common/component";
import { Grid } from "../dashboard/styles";
import { TableCompData } from "../../common/component/table";
import { ContainerComp } from "../../common/component/Ui/containerComp";
import { TransactionHistoryTableHeader } from "../../common/utils/dataUtils";
import { SubHeading } from "./style";
import {
	useGetUserBankQuery,
	useGetUserTransactionQuery,
} from "../../store/transactionApi";
import { ITransaction } from "../../store/transactionApi/interface";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

export const BankAccount = () => {
	const param = useParams();
	const userId = param?.id as string;
	const { data: userBanks } = useGetUserBankQuery(userId);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRows, setTotalRows] = useState(100);
	const paginate = {
		userId: userId,
		perPage: rowsPerPage,
		currentPage: currentPage,
	};
	const {
		data: userTransaction,
		isLoading,
		isFetching,
	} = useGetUserTransactionQuery(paginate, {
		refetchOnMountOrArgChange: true,
	});

	useEffect(() => {
		setTotalRows(userTransaction?.pagination?.totalDocumentCount as number);
	}, [userTransaction?.pagination?.totalDocumentCount]);
	const handlePageChange = (page: any) => setCurrentPage(page);
	const handleRowsPerPageChange = (newRowsPerPage: any) => {
		if (!userTransaction?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
		setRowsPerPage(newRowsPerPage);
		setCurrentPage(1);
	};

	return (
		<div>
			{isLoading && <Loader />}
			<Grid>
				{userBanks?.data?.map((bank, index) => (
					<BankCard
						bgColor={index}
						key={index}
						bankName={bank.bankName}
						acctNumber={bank.accountNumber}
					/>
				))}
			</Grid>
			<SubHeading className="my-3">Transaction history</SubHeading>
			<ContainerComp>
				<TableCompData
					columns={TransactionHistoryTableHeader()}
					data={userTransaction?.data as ITransaction[]}
					progressPending={isFetching}
					totalRows={totalRows}
					rowsPerPage={rowsPerPage}
					currentPage={currentPage}
					handlePageChange={handlePageChange}
					handleRowsPerPageChange={handleRowsPerPageChange}
				/>
			</ContainerComp>
		</div>
	);
};
