import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { ITwoFactor } from './interface';

export const twoFactorApi = createApi({
    reducerPath: 'twoFactorApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        initializeTwoFactor: builder.mutation<
            Response<ITwoFactor>,
            { userId: string }
        >({
            query: (body) => ({
                url: `/user/api/v1/two-factor-auth/initialize-two-factor-auth`,
                method: 'PATCH',
                body,
            }),
        }),
        finalizeTwoFactor: builder.mutation<
            Response<IUser>,
            { userId: string; userToken: string }
        >({
            query: (body) => ({
                url: `/user/api/v1/two-factor-auth/finalize-two-factor-auth`,
                method: 'PATCH',
                body,
            }),
        }),
        authenticateTwoFactor: builder.mutation<
            Response<IUser>,
            { userId: string; userToken: string }
        >({
            query: (body) => ({
                url: `/user/api/v1/two-factor-auth/authenticate-with-two-factor-auth`,
                method: 'PATCH',
                body,
            }),
        }),
        disableTwoFactor: builder.mutation<
            Response<IUser>,
            { userId: string; userPassword: string }
        >({
            query: (body) => ({
                url: `/user/api/v1/two-factor-auth/disable-two-factor-auth`,
                method: 'PATCH',
                body,
            }),
        }),
    }),
});

export const {
    useFinalizeTwoFactorMutation,
    useInitializeTwoFactorMutation,
    useAuthenticateTwoFactorMutation,
    useDisableTwoFactorMutation,
} = twoFactorApi;
