import styled from "styled-components";
import { colors } from "../colors";

const Spinner = styled.div`
	width: 50px;
	height: 50px;
	border: 5px solid ${colors.gray10};
	border-top: 5px solid ${colors.Primary1};
	border-right: 5px solid ${colors.Primary1};
	border-bottom: 5px solid ${colors.Primary1};
	border-radius: 50%;
	animation: spinner 2s linear infinite;

	@keyframes spinner {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
const LoaderContainer = styled.div`
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: rgba(0, 0, 0, 0.4);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 300;
`;

export const Loader = () => {
	return (
		<LoaderContainer>
			<Spinner />
		</LoaderContainer>
	);
};
