import styled from 'styled-components';
import { colors } from '../../common/component';
import { UserImage } from '../users/style';

export const BarLine = styled.div`
    border: 1px solid ${colors.yellow50};
    background: ${colors.yellow50};
`;
export const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 50px;
    row-gap: 40px;
`;
export const SmallImage = styled(UserImage)`
    min-width: 25px;
    min-height: 25px;
    max-width: 25px;
    max-height: 25px;
`;
export const Card = styled.div`
    width: calc(33.33% - 32px);
    @media (max-width: 768px) {
        width: calc(50% - 24px);
    }
    @media (max-width: 426px) {
        width: calc(50% - 12px);
    }
    @media (max-width: 426px) {
        width: calc(100% - 0px);
    }
`;
export const Header = styled.p`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.Primary1};
    margin-bottom: 7px;
`;
export const SubHeading = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${colors.gray30};
`;
export const UserWrap = styled.p`
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: rgba(5, 13, 61, 0.7);
`;
export const Text = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #10192d;
`;
export const StatusContainer = styled(Text)<{ status: string }>`
    color: ${(props) =>
        props.status === 'pending'
            ? colors.yellow
            : props.status === 'approved'
            ? colors.Green90
            : colors.red100};
`;
export const ImageContainer = styled.div`
    width: 100%;
    height: 250px;
    background: ${colors.gray};
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;
