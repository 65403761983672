import { Container } from "../users/style";
import { Header } from "../dashboard/styles";
import { colors } from "../../common/component";
import styled from "styled-components";
import { ReactComponent as PersonIcon } from "../../common/images/person_icon.svg";

export const Notification = () => {
	return (
		<Container>
			<Header>Notification</Header>
			<Box className="w-100 p-3">
				<NotificationContainer className="d-flex justify-content-between gap-1 gap-sm-3 py-3">
					<div className="d-flex gap-3">
						<div className="d-flex">
							<div className="notify" />
							<PersonIcon />
						</div>
						<div>
							<p className="notificationHeader">Transaction summary</p>
							<p className="text">
								Find the monthly transaction summary for Ju..
							</p>
						</div>
					</div>
					<div className="d-flex align-items-end">
						<p className="time">30 mins ago</p>
					</div>
				</NotificationContainer>
				<NotificationContainer className="d-flex justify-content-between gap-1 gap-sm-3 py-3">
					<div className="d-flex gap-3">
						<div className="d-flex">
							<div className="notify" />
							<PersonIcon />
						</div>
						<div>
							<p className="notificationHeader">Transaction summary</p>
							<p className="text">
								Find the monthly transaction summary for Ju..
							</p>
						</div>
					</div>
					<div className="d-flex align-items-end">
						<p className="time">30 mins ago</p>
					</div>
				</NotificationContainer>
				<NotificationContainer className="d-flex justify-content-between gap-1 gap-sm-3 py-3">
					<div className="d-flex gap-3">
						<div className="d-flex">
							<div className="notify" />
							<PersonIcon />
						</div>
						<div>
							<p className="notificationHeader">Transaction summary</p>
							<p className="text">
								Find the monthly transaction summary for Lorem ipsum dolor, sit
								amet consectetur adipisicing elit. Deleniti velit minima quo
								distinctio id placeat vitae libero totam ex iste? Nulla
								veritatis tenetur, quod obcaecati iusto fugit ut? Soluta,
								deleniti?
							</p>
						</div>
					</div>
					<div className="d-flex align-items-end">
						<p className="time">30 mins ago</p>
					</div>
				</NotificationContainer>
				<NotificationContainer className="d-flex justify-content-between gap-1 gap-sm-3 py-3">
					<div className="d-flex gap-3">
						<div className="d-flex">
							<div className="notify" />
							<PersonIcon />
						</div>
						<div>
							<p className="notificationHeader">Transaction summary</p>
							<p className="text">
								Find the monthly transaction summary for Lorem ipsum dolor, sit
								amet consectetur adipisicing elit. Deleniti velit minima quo
								distinctio id placeat vitae libero totam ex iste? Nulla
								veritatis tenetur, quod obcaecati iusto fugit ut? Soluta,
								deleniti?
							</p>
						</div>
					</div>
					<div className="d-flex align-items-end">
						<p className="time">30 mins ago</p>
					</div>
				</NotificationContainer>
				<NotificationContainer className="d-flex justify-content-between gap-1 gap-sm-3 py-3">
					<div className="d-flex gap-3">
						<div className="d-flex">
							<div className="notify" />
							<PersonIcon />
						</div>
						<div>
							<p className="notificationHeader">Transaction summary</p>
							<p className="text">
								Find the monthly transaction summary for Lorem ipsum dolor, sit
								amet consectetur adipisicing elit. Deleniti velit minima quo
								distinctio id placeat vitae libero totam ex iste? Nulla
								veritatis tenetur, quod obcaecati iusto fugit ut? Soluta,
								deleniti?
							</p>
						</div>
					</div>
					<div className="d-flex align-items-end">
						<p className="time">30 mins ago</p>
					</div>
				</NotificationContainer>
				<NotificationContainer className="d-flex justify-content-between gap-1 gap-sm-3 py-3">
					<div className="d-flex gap-3">
						<div className="d-flex">
							<div className="notify" />
							<PersonIcon />
						</div>
						<div>
							<p className="notificationHeader">Transaction summary</p>
							<p className="text">
								Find the monthly transaction summary for Lorem ipsum dolor, sit
								amet consectetur adipisicing elit. Deleniti velit minima quo
								distinctio id placeat vitae libero totam ex iste? Nulla
								veritatis tenetur, quod obcaecati iusto fugit ut? Soluta,
								deleniti?
							</p>
						</div>
					</div>
					<div className="d-flex align-items-end">
						<p className="time">30 mins ago</p>
					</div>
				</NotificationContainer>
			</Box>
		</Container>
	);
};

const Box = styled.div`
	background: ${colors.white};
	border-radius: 8px;
	border: 1px solid #e9e9e9;
    height: calc(100vh - 20vh);
    overflow-y: scroll;
    &::-webkit-scrollbar {
		width: 0px;
		background: ${colors.black6};
	}
    @media (max-width: 768px) {
        height: calc(100vh - 24vh);
    }
`;
const NotificationContainer = styled.div`
	border-bottom: 1px solid #e9e9e9;
	svg {
		fill: ${colors.Primary1};
		stroke: ${colors.Primary1};
		margin: 5px;
	}
	.notify {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: ${colors.Primary1};
	}
	.notificationHeader {
		font-weight: 500;
		font-size: 16px;
		line-height: 17px;
		color: ${colors.Primary1};
		/* background: green; */
	}
	.text {
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		/* width: 250px; */
		height: 17px;
		box-sizing: border-box;
		overflow: hidden;
		/* background: red; */
		text-overflow: ellipsis;
		color: ${colors.black20};
		@media (max-width: 600px) {
			width: 100%;
		}
	}
	.time {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		text-align: right;
		color: #848283;
		min-width: 70px;
	}
	.viewMore {
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		color: ${colors.purple100};
		cursor: pointer;
	}
`;
