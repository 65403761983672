import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../api";
import { Response } from "../genericInterface";
import { IMessage } from "./interface";

export const chatApi = createApi({
	reducerPath: "chatApi",
	baseQuery: baseQuery,
	endpoints: (builder) => ({
		getTradeMessages: builder.query<Response<IMessage[]>, {chatId:string}>({
			query: (query) => ({
				url: `/chat/api/v1/message/getChatMessages/${query.chatId}`,
				method: "GET",
			}),
		}),
	}),
});

export const { useGetTradeMessagesQuery } = chatApi;
