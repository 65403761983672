import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { paginatedResponse } from '../paginatedInterface';
import { generateQueryString } from '../../common/utils';

export const withdrawalRequestApi = createApi({
    reducerPath: 'withdrawalRequestApi',
    baseQuery: baseQuery,
    tagTypes: ['withdrawalRequest'],
    endpoints: (builder) => ({
        getAllWithdrawalRequest: builder.query<
            paginatedResponse<IWithdrawalRequest[]>,
            {
                params: {
                    perPage: number;
                    currentPage: number;
                    user?: string;
                    wallet?: string;
                    bank?: string;
                    requestStatus?: string;
                };
            }
        >({
            query: ({ params }) => ({
                url: `/account/api/v1/withdrawal-request?${generateQueryString({
                    ...params,
                })}`,
                method: 'GET',
            }),
            providesTags: ['withdrawalRequest'],
        }),

        // getWithdrawalRequestByStatus: builder.query<
        //     paginatedResponse<IWithdrawalRequest[]>,
        //     { currentPage: number; status: string; perPage: number }
        // >({
        //     query: ({ currentPage, status, perPage }) => ({
        //         url: `/account/api/v1/withdrawal-request/checkUserWithdrawalRequestStatus/${status}?perPage=${perPage}&currentPage=${currentPage}`,
        //         method: 'GET',
        //     }),

        //     providesTags: ['withdrawalRequest'],
        // }),

        getSingleWithdrawalRequest: builder.query<
            Response<IWithdrawalRequest>,
            string
        >({
            query: (requestId) => ({
                url: `/account/api/v1/withdrawal-request/${requestId}`,
                method: 'GET',
            }),
            providesTags: ['withdrawalRequest'],
        }),

        deleteWithdrawalRequest: builder.mutation<
            Response<IWithdrawalRequest>,
            string
        >({
            query: (requestId) => ({
                url: `/account/api/v1/withdrawal-request/${requestId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['withdrawalRequest'],
        }),

        updateWithdrawalRequest: builder.mutation<
            Response<IWithdrawalRequest>,
            IUpdateWithdrawalRequest
        >({
            query: (payload) => ({
                url: `/account/api/v1/withdrawal-request/manage-withdrawal-request/${payload.requestId}`,
                method: 'PATCH',
                body: payload,
            }),
            invalidatesTags: ['withdrawalRequest'],
        }),

        getWithdrawalRequestStats: builder.query<
            Response<IWithdrawalRequestStats>,
            void
        >({
            query: () => ({
                url: 'account/api/v1/withdrawal-request/a/stats',
                method: 'GET',
            }),
            providesTags: ['withdrawalRequest'],
        }),
    }),
});

export const {
    useGetAllWithdrawalRequestQuery,
    // useGetWithdrawalRequestByStatusQuery,
    useUpdateWithdrawalRequestMutation,
    useDeleteWithdrawalRequestMutation,
    useGetSingleWithdrawalRequestQuery,
    useGetWithdrawalRequestStatsQuery,
} = withdrawalRequestApi;
