import { Card } from "react-bootstrap";

export const ContainerComp = ({
	children,
	containerClass,
	bodyClassName,
}: {
	children: any;
	containerClass?: any;
	bodyClassName?: any;
}) => {
	return (
		<Card className={`border-1  ${containerClass}`}>
			<Card.Body className={` p-0 ${bodyClassName}`}>{children}</Card.Body>
		</Card>
	);
};
