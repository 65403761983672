import { ComponentPropsWithoutRef, FC } from 'react';
import styled from 'styled-components';
import { colors } from '../colors';
interface IInput extends ComponentPropsWithoutRef<'button'> {
    text: string;
    icon?: string;
}
export const CustomButton: FC<IInput> = ({ text, ...rest }) => {
    return <Button {...rest}>{text}</Button>;
};
export const CustomButtonWithIcon: FC<IInput> = ({ text, icon, ...rest }) => {
    return (
        <Button {...rest}>
            <img src={icon} alt="" />
            {text}
        </Button>
    );
};
const Button = styled.button`
    display: flex;
    column-gap: 5px;
    align-items: center;
    padding: 8px 15px;
    background: ${colors.purple3};
    border: none;
    color: ${colors.white};
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    border-radius: 6px;
    cursor: pointer;
`;
