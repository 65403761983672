import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { paginatedResponse } from '../paginatedInterface';
import { ISuperMerchant, IMerchantRequestStats } from './interface';

export const superMerchantApi = createApi({
    reducerPath: 'superMerchantApi',
    baseQuery: baseQuery,
    tagTypes: ['superMerchant'],
    endpoints: (builder) => ({
        getAllSuperMerchant: builder.query<
            paginatedResponse<ISuperMerchant[]>,
            {
                perPage: number;
                currentPage: number;
                status?: string;
            }
        >({
            query: ({ perPage, currentPage, status }) => {
                let query = `/user/api/v1/supermerchant-request?perPage=${perPage}&currentPage=${currentPage}`;
                if (status) {
                    if (status === 'pending') {
                        query = `${query}&status=pending`;
                    }
                    if (status === 'approved') {
                        query = `${query}&status=approved`;
                    }
                    if (status === 'rejected') {
                        query = `${query}&status=rejected`;
                    }
                }

                return {
                    url: query,
                    method: 'GET',
                };
            },
            providesTags: ['superMerchant'],
        }),
        getSuperMerchantByStatus: builder.query<
            paginatedResponse<ISuperMerchant[]>,
            { currentPage: number; status: string; perPage: number }
        >({
            query: ({ currentPage, status, perPage }) => ({
                url: `/user/api/v1/merchant-request/findAllByStatus/${status}?perPage=${perPage}&currentPage=${currentPage}`,
                method: 'GET',
            }),
            providesTags: ['superMerchant'],
        }),
        updateSuperMerchant: builder.mutation<
            Response<ISuperMerchant>,
            {
                supermerchantRequestId: string;
                status: string;
                approvedBy: string;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/supermerchant-request/approveRequest`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['superMerchant'],
        }),
        getSingleSuperMerchant: builder.query<Response<ISuperMerchant>, string>(
            {
                query: (requestId) => ({
                    url: `/user/api/v1/supermerchant-request/${requestId}`,
                    method: 'GET',
                }),
                providesTags: ['superMerchant'],
            },
        ),
        deleteSuperMerchant: builder.mutation<Response<ISuperMerchant>, string>(
            {
                query: (requestId) => ({
                    url: `/user/api/v1/supermerchant-request/${requestId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['superMerchant'],
            },
        ),
        superMerchantStats: builder.query<
            Response<IMerchantRequestStats>,
            void
        >({
            query: () => ({
                url: 'user/api/v1/supermerchant-request/a/stats',
                method: 'GET',
            }),
            providesTags: ['superMerchant'],
        }),
    }),
});

export const {
    useGetAllSuperMerchantQuery,
    useGetSuperMerchantByStatusQuery,
    useUpdateSuperMerchantMutation,
    useDeleteSuperMerchantMutation,
    useGetSingleSuperMerchantQuery,
    useSuperMerchantStatsQuery,
} = superMerchantApi;
