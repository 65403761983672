import {
	ChartWrap,
	Grid,
	Grid2,
	Header,
	SubHeader,
	SubHeader2,
} from "./styles";
import {
	Chart,
	CHART_TYPE,
	ContainerComp,
	Counter,
	Loader,
	TableCompData,
} from "../../common/component";
import { Container } from "../users/style";
import { useEffect, useState } from "react";
import { ReactComponent as AdvertIcon } from "../../common/images/advert_icon.svg";
import { ReactComponent as UserIcon } from "../../common/images/user_icon.svg";
import { ReactComponent as TransactionIcon } from "../../common/images/transaction_icon.svg";
import { ReactComponent as CurrencyPairIcon } from "../../common/images/currencypair_icon.svg";
import {
	useGetUserRegistrationSummaryByYearQuery,
	useUserStatsQuery,
} from "../../store/userApi";
import { IUserRegistrationSummary } from "../../store/userApi/interface";
import { DashboardTradeTableHeader } from "../../common/utils/dataUtils";
import { useGetAllTradeQuery, useTradeStatsQuery } from "../../store/tradeApi";
import { ITrade } from "../../store/tradeApi/interface";
import { useNavigate } from "react-router-dom";
import { useAdvertStatsQuery } from "../../store/advertApi";
import { useGetTransactionStatsQuery } from "../../store/transactionApi";

export const AdminMain = () => {
	const navigate = useNavigate();
	const date = new Date().getFullYear();
	const { data: userRegistrationSummary, isLoading } =
		useGetUserRegistrationSummaryByYearQuery(date);
	const paginate = {
		perPage: 50,
		currentPage: 1,
	};
	const { data: allTrade, isLoading: isGettingTrades } =
		useGetAllTradeQuery(paginate);
	const [userChartData, setUserChartData] = useState<number[]>();
	const { data: advertStats } = useAdvertStatsQuery();
	const { data: userStats } = useUserStatsQuery();
	const { data: tradeStats } = useTradeStatsQuery();
	const { data: TransactionStats } = useGetTransactionStatsQuery();

	useEffect(() => {
		let totalUser: number[] = [];
		if (userRegistrationSummary?.data) {
			userRegistrationSummary?.data?.map((item: IUserRegistrationSummary) =>
				totalUser.push(item?.totalUsers)
			);
			setUserChartData(totalUser);
		}
	}, [userRegistrationSummary?.data]);

	const chartData = {
		labels: [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		],
		datasets: [
			{
				label: "Total Registerd User per Year",
				data: userChartData,
				backgroundColor: [
					"rgba(239, 122, 148,0.7)",
					"rgba(255, 159, 64, 0.7)",
					"rgba(255, 205, 86, 0.7)",
					"rgba(75, 192, 192, 0.7)",
					"rgba(54, 162, 235, 0.7)",
					"rgba(153, 102, 255, 0.7)",
					"rgba(201, 203, 207, 0.7)",
				],
			},
		],
	};

	return (
		<Container>
			<Header>Dashboard</Header>
			<Grid>
				<Counter
					counter_title="Total Ads"
					count={advertStats?.data?.totalAdverts || 0}
					iconFill="primaryBlue"
				>
					<AdvertIcon />
				</Counter>
				<Counter
					counter_title="Total Users"
					count={userStats?.data?.totalUsers || 0}
					iconFill="primaryBlue"
				>
					<UserIcon />
				</Counter>
				<Counter
					counter_title="Total Trades"
					count={tradeStats?.data?.totalTrades || 0}
					iconFill="primaryBlue"
				>
					<TransactionIcon />
				</Counter>
				<Counter
					counter_title="Total Transactions"
					count={TransactionStats?.data?.totalTransactions || 0}
					iconFill="primaryBlue"
				>
					<CurrencyPairIcon />
				</Counter>
			</Grid>
			{isLoading || isGettingTrades ? (
				<Loader />
			) : (
				<Grid2 className="my-5">
					<ChartWrap>
						<Chart type={CHART_TYPE.LINE} datasets={chartData} />
					</ChartWrap>
					<ChartWrap>
						<Chart type={CHART_TYPE.BAR} datasets={chartData} />
					</ChartWrap>
				</Grid2>
			)}

			{/* table section */}
			<div className="d-flex justify-content-between align-items-center my-3">
				<SubHeader>Recent trades</SubHeader>
				<SubHeader2 onClick={() => navigate("/dashboard/trade")}>
					View all trades
				</SubHeader2>
			</div>
			<ContainerComp>
				<TableCompData
					columns={DashboardTradeTableHeader()}
					data={allTrade?.data?.slice(0, 5) as ITrade[]}
					defaultSortFieldId={1}
				/>
			</ContainerComp>
		</Container>
	);
};
