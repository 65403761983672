export const colors = {
    background: '#F5F5F5',
    white: '#FFFFFF',
    black: '#000000',

    //gray
    gray: ' #EEF0F3',
    gray3: '#F5F5F5',
    gray10: '#e1e1e1',
    gray20: ' #A7A6A6',
    gray30: '#64748B',
    gray31: '#777777',
    gray2: '#A1A1A1',

    //black
    black100: ' #333f51',
    black90: ' #383637',
    black80: ' #4E4D4D',
    black70: '  #232123;',
    black50: ' #919090',
    black40: '#646364',
    black20: ' #232123',
    black10: ' #E9E9E9;',
    black12: '#000000',

    // green
    Green100: '#14532D',
    Green90: ' #3DAF33',
    Green80: '  #009A49',
    green70: ' #1C94AD',
    Green60: '  rgb(0, 154, 73,0.15)',
    Green10: '#F0FDF4',
    green1: 'rgba(69, 208, 35,1)',
    green2: 'rgba(69, 208, 35, 0.1)',

    //blue
    blue100: '#6BA4F9',
    blue10: '#060e3d',
    blue20: ' #253280',
    blue30: '#D5F0FC',
    blue40: ' #050D3D',
    blue50: ' rgba(0, 122, 255,0.15)',
    blue60: ' #007AFF',

    //red
    red: 'rgba(255, 0, 0, 1)',
    red2: 'rgba(255, 0, 0, 0.1)',
    red100: ' #F65556',
    red10: ' rgba(246, 85, 86,0.15)',

    //purple
    purple100: '#4B24B0',
    purple10: 'rgba(75, 36, 176,0.15)',
    purple2: '#3C1688',
    purple3: '#7041DE',
    //secondary
    secondary100: '#FBAE2D',
    secondary90: '#FCE395',
    secondary80: '#B77912',
    secondary70: ' #FFD036',
    secondary60: ' #EE9D13',
    secondary50: ' #FACA15',
    secondary40: ' #F8E2C7',
    secondary30: ' #B47711',
    secondary20: ' rgba(250, 202, 21,0.15)',
    secondary10: '#FACC15',

    //primary
    Primary1: '#253280',
    Primar: 'rgb(37, 50, 128)',
    Primary2: '#060e3d',
    Primary6: '#D8CAF5',
    Primary8: '#E5E1FB',
    Primary9: 'rgba(75, 36, 176, 0.1)',
    primary12: '#FFFFFF',

    //yellow
    yellow: 'rgba(243, 186, 47,1)',
    yellow2: 'rgba(243, 186, 47,0.1)',
    yellow50: '#bc8019',

    orange2: 'rgba(233, 156, 53, 0.1)',

    blue2: ' rgba(28, 42, 171, 0.1)',

    black1: '#030229',
    black3: '#030229',
    black6: '#E2E8F0',

    // // new colors.
    light_black: ' rgba(0, 0, 0, 0.9)',
    neutral_black: '#141522',
    light_black2: '#8A8D99',
    neutral_grey: '#9C9CA4',
};
