import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { ICurrencyPair, ICurrencyPairStats } from './interface';
import { paginatedResponse } from '../paginatedInterface';

export const currencyPairApi = createApi({
    reducerPath: 'currencyPairApi',
    baseQuery: baseQuery,
    tagTypes: ['currencyPair'],
    endpoints: (builder) => ({
        addCurrencyPair: builder.mutation<
            Response<ICurrencyPair[]>,
            { baseCurrency: string; tradingCurrency: string }
        >({
            query: (body) => ({
                url: `/marketPlace/api/v1/currency-pairs`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['currencyPair'],
        }),
        editCurrencyPair: builder.mutation<
            Response<ICurrencyPair[]>,
            {
                baseCurrency: string;
                tradingCurrency: string;
                tradingPairId: string;
                status: boolean;
            }
        >({
            query: (body) => ({
                url: `/marketPlace/api/v1/currency-pairs`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['currencyPair'],
        }),
        getAllCurrencyPair: builder.query<
            paginatedResponse<ICurrencyPair[]>,
            {
                perPage: number;
                currentPage: number;
                category?: string;
                active?: string;
                currency?: string;
                date?: string;
            }
        >({
            query: ({
                perPage,
                currentPage,
                category,
                active,
                currency,
                date,
            }) => {
                let query = `/marketPlace/api/v1/currency-pairs?perPage=${perPage}&currentPage=${currentPage}`;
                if (category) {
                    query = `${query}&category=${category}`;
                }
                if (active) {
                    query = `${query}&active=${active}`;
                }
                if (currency) {
                    query = `${query}&currency=${currency}`;
                }
                if (date) {
                    query = `${query}&date=${date}`;
                }
                return {
                    url: query,
                    method: 'GET',
                };
            },
            providesTags: ['currencyPair'],
        }),
        getSingleCurrencyPair: builder.query<Response<ICurrencyPair>, string>({
            query: (query) => ({
                url: `/marketPlace/api/v1/currency-pairs/${query}`,
                method: 'GET',
            }),
        }),
        deleteCurrencyPair: builder.mutation<Response<ICurrencyPair>, string>({
            query: (query) => ({
                url: `/marketPlace/api/v1/currency-pairs/${query}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['currencyPair'],
        }),
        currencyPairStats: builder.query<Response<ICurrencyPairStats>, void>({
            query: () => ({
                url: `/marketPlace/api/v1/currency-pairs/a/stats`,
                method: 'GET',
            }),
            providesTags: ['currencyPair'],
        }),
    }),
});

export const {
    useAddCurrencyPairMutation,
    useDeleteCurrencyPairMutation,
    useEditCurrencyPairMutation,
    useGetAllCurrencyPairQuery,
    useGetSingleCurrencyPairQuery,
    useCurrencyPairStatsQuery,
} = currencyPairApi;
