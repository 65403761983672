import {
    BarLine,
    ButtonContainer,
    Container,
    InputWrap,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    Loader,
    PdfDownloader,
    SelectComp,
} from '../../common/component';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import {
    TradeTableHeader,
    cleanUpTradeDataForPdf,
} from '../../common/utils/dataUtils';
import { BsEye } from 'react-icons/bs';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as TradeIcon } from '../../common/images/trade_icon.svg';
import { useGetAllTradeQuery, useTradeStatsQuery } from '../../store/tradeApi';
import { ITrade } from '../../store/tradeApi/interface';
import { useEffect, useState } from 'react';

export const Trade = () => {
    const [searchNameTerm, setSearchByName] = useState('');
    const [searchCurrencyTerm, setSearchByCurrency] = useState('');
    const [dateSearch, setDateSearch] = useState('');
    const [category, setCategory] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: allTrade,
        isLoading,
        isFetching,
        isError,
        isSuccess,
        error,
    } = useGetAllTradeQuery(
        {
            ...paginate,
            category: category,
            date: dateSearch,
            name: searchNameTerm,
            currency: searchCurrencyTerm,
        },
        {
            refetchOnMountOrArgChange: true,
        },
    );

    const { data: tradeStats } = useTradeStatsQuery();

    useEffect(() => {
        setTotalRows(allTrade?.pagination?.totalDocumentCount as number);
    }, [allTrade?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allTrade?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const Items = [
        {
            name: ' View Trade',
            icon: <BsEye size={15} />,
            link: `view/trade-info`,
            id: '_id',
        },
    ];

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'First Name', key: 'user.firstName' },
        {
            label: 'Last Name',
            key: 'user.lastName',
        },
        {
            label: 'Category',
            key: `advert.currencyPair.baseCurrency.currencyType`,
        },
        {
            label: 'Currency',
            key: `advert.currencyPair.baseCurrency.name`,
        },
        {
            label: 'Date',
            key: 'createdAt',
        },
        {
            label: 'Trade Status',
            key: 'tradeStatus',
        },
        {
            label: 'Amount',
            key: 'amount',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { header: 'First Name', dataKey: 'firstName' },
        {
            header: 'Last Name',
            dataKey: 'lastName',
        },
        {
            header: 'Category',
            dataKey: `category`,
        },
        {
            header: 'Currency',
            dataKey: `currency`,
        },
        {
            header: 'Date',
            dataKey: `createdAt`,
        },
        {
            header: 'Trade Status',
            dataKey: `tradeStatus`,
        },
        {
            header: 'Amount',
            dataKey: 'amount',
        },
    ];

    return (
        <Container>
            {isLoading && <Loader />}
            <Header>Trade</Header>
            <Grid>
                <Counter
                    counter_title="Total Trades"
                    count={tradeStats?.data?.totalTrades || 0}
                    iconFill="black"
                    iconBG="blue"
                >
                    <TradeIcon />
                </Counter>
                <Counter
                    counter_title="Open Trades"
                    count={tradeStats?.data?.openTrades || 0}
                    iconFill="red"
                    iconBG="red"
                >
                    <TradeIcon />
                </Counter>
                <Counter
                    counter_title="Completed Trades"
                    count={tradeStats?.data?.completedTrades || 0}
                >
                    <TradeIcon />
                </Counter>
                <Counter
                    counter_title="Cancelled Trades"
                    count={tradeStats?.data?.cancelledTrades || 0}
                    iconFill="green"
                    iconBG="green"
                >
                    <TradeIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={9}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col
                        xs={12}
                        md={4}
                        sm={6}
                        className="d-flex align-items-center my-3"
                    >
                        <SelectComp
                            selectText="All trades"
                            arrayComp={['Fiat', 'Crypto']}
                            onChange={(e: any) => setCategory(e.target.value)}
                        />
                        <TotalResultText>{`${
                            allTrade?.data.length || 0
                        } result`}</TotalResultText>
                    </Col>
                    <Col
                        xs={12}
                        md={4}
                        sm={3}
                        className="d-flex align-items-center my-3"
                    >
                        <InputWrap>
                            <img src={SearchIcon} alt="" />
                            <FormControl
                                className="border-start-0 border-bottom-0 border-top-0"
                                onChange={(e) => setDateSearch(e.target.value)}
                                type="date"
                            />
                        </InputWrap>
                    </Col>
                    <Col
                        xs={12}
                        md={4}
                        sm={4}
                        className="d-flex align-items-center my-3"
                    >
                        <InputWrap>
                            <img src={SearchIcon} alt="" />
                            <FormControl
                                aria-label="Amount (to the nearest dollar)"
                                placeholder="Search By Name"
                                className="border-start-0 border-bottom-0 border-top-0"
                                onChange={(e) =>
                                    setSearchByName(e.target.value)
                                }
                            />
                        </InputWrap>
                    </Col>
                    <Col
                        xs={12}
                        md={3}
                        sm={4}
                        className="d-flex align-items-center my-3"
                    >
                        <InputWrap>
                            <img src={SearchIcon} alt="" />
                            <FormControl
                                aria-label="Amount (to the nearest dollar)"
                                placeholder="Search By Currency"
                                className="border-start-0 border-bottom-0 border-top-0"
                                onChange={(e) =>
                                    setSearchByCurrency(e.target.value)
                                }
                            />
                        </InputWrap>
                    </Col>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'TRADE RECORD'}
                            {...{
                                columns: headersPdf,
                                body: cleanUpTradeDataForPdf(allTrade?.data),
                            }}
                        />

                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={allTrade?.data}
                        />
                    </ButtonContainer>
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={TradeTableHeader(Items)}
                    data={allTrade?.data as ITrade[]}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>
        </Container>
    );
};
