import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { IConfig, IConfigStats } from './interface';
import { paginatedResponse } from '../paginatedInterface';

export const configurationApi = createApi({
    reducerPath: 'configurationApi',
    baseQuery: baseQuery,
    tagTypes: ['Config'],
    endpoints: (builder) => ({
        addConfig: builder.mutation<
            Response<IConfig[]>,
            {
                partnerChargePerTrade: number;
                serviceChargeOnPartnerCharge: number;
                supportNumber: string;
                socialLinks: {
                    facebook: string;
                    twitter: string;
                    youtube: string;
                    tiktok: string;
                    instagram: string;
                    linkedin: string;
                };
                transactionLimits: {
                    new_user: {
                        min: number;
                        max: number;
                    };
                    beginner: {
                        min: number;
                        max: number;
                    };
                    intermediate: {
                        min: number;
                        max: number;
                    };
                    advanced: {
                        min: number;
                        max: number;
                    };
                };
            }
        >({
            query: (body) => ({
                url: `/marketPlace/api/v1/setup`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Config'],
        }),
        editConfig: builder.mutation<
            Response<IConfig>,
            {
                partnerChargePerTrade: number;
                serviceChargeOnPartnerCharge: number;
                supportNumber: string;
                socialLinks: {
                    facebook: string;
                    twitter: string;
                    youtube: string;
                    tiktok: string;
                    instagram: string;
                    linkedin: string;
                };
                transactionLimits: {
                    new_user: {
                        min: number;
                        max: number;
                    };
                    beginner: {
                        min: number;
                        max: number;
                    };
                    intermediate: {
                        min: number;
                        max: number;
                    };
                    advanced: {
                        min: number;
                        max: number;
                    };
                };
                setupId: string;
                isActive: boolean;
            }
        >({
            query: (body) => ({
                url: `/marketPlace/api/v1/setup`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['Config'],
        }),
        getAllConfig: builder.query<
            paginatedResponse<IConfig[]>,
            { perPage: number; currentPage: number }
        >({
            query: ({ perPage, currentPage }) => ({
                url: `/marketPlace/api/v1/setup?perPage=${perPage}&currentPage=${currentPage}`,
                method: 'GET',
            }),
            providesTags: ['Config'],
        }),
        getSingleConfig: builder.query({
            query: (query) => ({
                url: `/marketPlace/api/v1/setup/${query}`,
                method: 'GET',
            }),
        }),
        deleteConfig: builder.mutation<Response<IConfig>, string>({
            query: (query) => ({
                url: `/marketPlace/api/v1/setup/${query}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Config'],
        }),
        configStats: builder.query<Response<IConfigStats>, void>({
            query: () => ({
                url: `/marketPlace/api/v1/setup/a/stats`,
                method: 'GET',
            }),
            providesTags: ['Config'],
        }),
    }),
});

export const {
    useAddConfigMutation,
    useEditConfigMutation,
    useGetAllConfigQuery,
    useGetSingleConfigQuery,
    useDeleteConfigMutation,
    useConfigStatsQuery,
} = configurationApi;
