import styled from 'styled-components';
import { useState } from 'react';
import { ReactComponent as dashboard } from '../../../images/dashboard_icon.svg';
import IbxLogo from '../../../images/ibx_logo1.svg';
import { ReactComponent as UserIcon } from '../../../images/user_icon.svg';
import { ReactComponent as TransactionIcon } from '../../../images/transaction_icon.svg';
import { ReactComponent as ContactIcon } from '../../../images/support_icon.svg';
import { ReactComponent as CurrencyIcon } from '../../../images/currency_icon.svg';
import { ReactComponent as CurrencyPairIcon } from '../../../images/currencypair_icon.svg';
import { ReactComponent as ConfigurationIcon } from '../../../images/configuration_icon.svg';
import { ReactComponent as SettingIcon } from '../../../images/setting_icon.svg';
import { ReactComponent as AppversionIcon } from '../../../images/appversion_icon.svg';
import { ReactComponent as LogoutIcon } from '../../../images/logout_icon.svg';
import { ReactComponent as AdvertIcon } from '../../../images/advert_icon.svg';
import { ReactComponent as TradeIcon } from '../../../images/trade_icon.svg';
import { ReactComponent as CareerIcon } from '../../../images/career_icon.svg';
import { ReactComponent as FaqIcon } from '../../../images/faq_icon.svg';
import { ReactComponent as KycIcon } from '../../../images/kyc_icon.svg';
import { ReactComponent as BankIcon } from '../../../images/bank_icon.svg';
import { ReactComponent as WikiIcon } from '../../../images/icons8-wiki.svg';
import { BiLogoWikipedia } from 'react-icons/bi';
import { FaExchangeAlt } from 'react-icons/fa';
import { ReactComponent as NewsLetterIcon } from '../../../images/news_letter.svg';
import { ReactComponent as ProfileIcon } from '../../../images/profile.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { colors } from '../../colors';
import { pxToRem } from '../../../utils/pxToRem';
import { setUser } from '../../../../store/auth';
import { setAuthToken } from '../../../utils/helpers';
import { useAppDispatch } from '../../../../store/hooks';
import { useLogoutMutation } from '../../../../store/auth/authApi';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import { isTemplateHead, isTemplateSpan } from 'typescript';
import { IconWrap } from '../../../../pages/users/style';
import { useLocation } from 'react-router-dom';

export const SideBar = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [logout] = useLogoutMutation();
    const [openMenus, setOpenMenus] = useState<{
        id: number;
        state: boolean;
    }>();

    const handleMenuClick = (menuId: number) => {
        setOpenMenus((prevState) => {
            return {
                ...prevState,
                id: menuId,
                state: !prevState?.state,
            };
        });
    };

    const location = useLocation();
    console.log(location.pathname);

    const sidenavs = [
        {
            id: 1,
            icon: dashboard,
            name: 'Dashboard',
            link: '/dashboard/admin',
        },
        {
            id: 2,
            icon: ConfigurationIcon,
            name: 'Configuration',
            link: '/dashboard/configuration',
        },
        {
            id: 3,
            icon: AppversionIcon,
            name: 'App Versions',
            link: '/dashboard/appversion',
        },
        {
            id: 4,
            icon: CurrencyIcon,
            name: 'Currency',
            link: '/dashboard/currency',
        },

        {
            id: 5,
            icon: CurrencyPairIcon,
            name: 'Currency Pair',
            link: '/dashboard/currencypair',
        },
        {
            id: 6,
            icon: AdvertIcon,
            name: 'Trade Adverts',
            link: '/dashboard/trade-advert',
        },
        {
            id: 7,
            icon: TradeIcon,
            name: 'Trade',
            link: '/dashboard/trade',
        },
        {
            id: 8,
            icon: TransactionIcon,
            name: 'Transaction',
            link: '/dashboard/transaction',
        },

        {
            id: 9,
            icon: UserIcon,
            name: 'Users',
            link: '/dashboard/users',
        },
        {
            id: 10,
            icon: UserIcon,
            name: 'Partner Request',
            link: '/dashboard/partner-request',
        },
        {
            id: 11,
            icon: UserIcon,
            name: 'Merchant Request',
            link: '/dashboard/merchant-request',
        },
        {
            id: 12,
            icon: UserIcon,
            name: 'Withdrawal Request',
            link: '/dashboard/withdrawal-request',
        },
        {
            id: 13,
            icon: UserIcon,
            name: 'Super Merchant',
            link: '/dashboard/super-merchant',
        },
        {
            id: 14,
            icon: CareerIcon,
            name: 'Career',
            link: '/dashboard/career',
        },
        {
            id: 15,
            icon: WikiIcon,
            name: 'Wiki',
            link: 'javascript:void(0)',
            children: [
                {
                    item: 'Topic',
                    icon: TradeIcon,
                    link: '/dashboard/topic',
                },
                {
                    item: 'SubTopic',
                    icon: TradeIcon,
                    link: '/dashboard/subtopic',
                },
                {
                    item: 'Content',
                    icon: TradeIcon,
                    link: '/dashboard/content',
                },
            ],
        },
        {
            id: 16,
            icon: ContactIcon,
            name: 'Contact Us',
            link: '/dashboard/contact-us',
        },
        {
            id: 17,
            icon: NewsLetterIcon,
            name: 'Newsletter',
            link: '/dashboard/newsletter',
        },
        {
            id: 18,
            icon: FaExchangeAlt,
            name: 'Exchange Rates',
            link: '/dashboard/exchange-rates',
        },
        {
            id: 19,
            icon: BankIcon,
            name: 'Bank',
            link: '/dashboard/bank',
        },
        {
            id: 20,
            icon: FaqIcon,
            name: 'FAQ',
            link: '/dashboard/faq',
        },
        {
            id: 21,
            icon: KycIcon,
            name: 'KYC',
            link: '/dashboard/kyc',
        },
        {
            id: 22,
            icon: AppversionIcon,
            name: 'Referral Campaign',
            link: '/dashboard/referral-campaign',
        },
    ];

    const onLogout = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        logout();
        dispatch(setUser(null));
        setAuthToken('');
        navigate('/login');
    };

    return (
        <Container>
            <ContentContainer>
                <Logo>
                    <img src={IbxLogo} alt="" />
                </Logo>
                <LinkContainer>
                    <Version>V.2.01</Version>
                    {sidenavs.map((item) => {
                        const Icon = item.icon;
                        return (
                            <div>
                                <Link
                                    to={item.link}
                                    key={item.name}
                                    onClick={() => handleMenuClick(item.id)}
                                >
                                    <div>
                                        <Icon />
                                    </div>
                                    <LinkParagraph>{item.name}</LinkParagraph>

                                    {item.children && (
                                        <ArrowIcon>
                                            {openMenus?.id === item.id ? (
                                                <IoIosArrowDown />
                                            ) : (
                                                <IoIosArrowForward />
                                            )}
                                        </ArrowIcon>
                                    )}
                                </Link>

                                {item.children && openMenus?.id === item.id && (
                                    <div>
                                        {item.children?.map(
                                            (child: any, index: any) => {
                                                const Icon = item.icon;
                                                return (
                                                    <ChildLink key={index}>
                                                        <Link to={child?.link}>
                                                            <div>
                                                                <Icon />
                                                            </div>
                                                            <div>
                                                                {child?.item}
                                                            </div>
                                                        </Link>
                                                    </ChildLink>
                                                );
                                            },
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    <ButtomTab>
                        <Link
                            to={'/dashboard/profile-setting'}
                            // as="div"
                            onClick={() =>
                                navigate('/dashboard/profile-setting')
                            }
                        >
                            <div>
                                <ProfileIcon />
                            </div>
                            <LinkParagraph>Profile</LinkParagraph>
                        </Link>
                        <ButtomTabLink as="div" onClick={onLogout}>
                            <div>
                                <LogoutIcon />
                            </div>
                            <LinkParagraph>Log out</LinkParagraph>
                        </ButtomTabLink>
                    </ButtomTab>
                </LinkContainer>
            </ContentContainer>
        </Container>
    );
};

const Container = styled.div`
    width: 200px;
    height: 100vh;
    background: ${colors.primary12};
    position: relative;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    @media (max-width: 600px) {
        display: none;
    }
`;
const ContentContainer = styled.div``;
const Logo = styled.div`
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 18px;
    border-bottom: 1px solid rgba(238, 240, 243, 0.3);
    img {
        width: 50px;
        height: 40px;
        background: ${colors.white};
        border-radius: 8px;
    }
`;
const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 10px 0px 10px 0px;
    max-height: calc(100vh - 150px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 0px;
        background: ${colors.Primary1};
        background: ${colors.gray2};
    }
`;
const Link = styled(NavLink)`
    padding: 8px 10px;
    margin: 0px 8px;
    display: flex;
    column-gap: 8px;
    align-items: center;
    text-decoration: none;
    border-radius: 4px;
    color: ${colors.gray2};
    @media (max-width: 600px) {
        margin: 10px 5px;
    }
    svg {
        fill: ${colors.gray2};
        stroke: ${colors.gray2};
        width: 14px;
        height: 14px;
    }
    &.active {
        background: ${colors.white};
        color: ${colors.Primary1};
        svg {
            fill: ${colors.Primary1};
            stroke: ${colors.Primary1};
        }
    }
`;

const ButtomTab = styled.div`
    position: absolute;
    bottom: 0px;
    width: 200px;
    border-radius: 0px;
    padding: 5px 0px;
    cursor: pointer;
    border-top: 1px solid rgba(238, 240, 243, 0.3);
`;
const ButtomTabLink = styled(Link)``;
const Version = styled.span`
    position: absolute;
    bottom: 42px;
    left: 18px;
    font-size: 14px;
    line-height: 17px;
    margin: 0px;
    color: ${colors.white};
`;
const LinkParagraph = styled.h3`
    font-weight: 500;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(17)};
`;

const ChildLink = styled.li`
    list-style-type: none;
    margin-bottom: 0.5rem;
    margin-left: 2.5rem;
    font-weight: 500;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(17)};
`;

const ArrowIcon = styled.span`
    margin-left: 0.6rem;
    margin-bottom: 0.2rem;
`;
