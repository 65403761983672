import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { Navbar } from "./navbar";
import { SideBar } from "./sidebar";

export const IbxShell = () => {
	return (
		<Container>
			<SideBar />
			<RightContainer>
				<Navbar />
				<OutletContainer>
					<Outlet />
				</OutletContainer>
			</RightContainer>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
`;
const RightContainer = styled.div`
	width: calc(100vw - 200px);
`;
const OutletContainer = styled.div`
	position: relative;
	margin: 8vh 0px 0px 0px;
	padding: 0px 20px;
	height: calc(100vh - 8vh);
	overflow: hidden;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		width: 0px;
	}
	@media (max-width: 600px) {
		width: calc(100vw);
		margin-top: 0vh;
		padding: 8vh 15px 5px 15px;
		height: calc(100vh - 0vh);
	}
`;
