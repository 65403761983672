import {
    BarLine,
    ButtonContainer,
    Container,
    InputWrap,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    Loader,
    PdfDownloader,
    DeleteModal,
    SelectComp,
    ModalComp,
    CustomButton,
} from '../../common/component';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { ContactUsTableHeader } from '../../common/utils/dataUtils';
import { BsEye } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdOutlinePersonAddDisabled } from 'react-icons/md';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import SearchIcon from '../../common/images/search_icon.svg';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as SupportIcon } from '../../common/images/support_icon.svg';
import {
    useContactUsStatsQuery,
    useGetAllContactUsQuery,
    useDeleteContactUsMutation,
    useGetSingleContactQuery,
    useUpdateContactUsMutation,
} from '../../store/contactUsApi';
import { useEffect, useState } from 'react';
import { IContactUs } from '../../store/contactUsApi/interface';
import { showErrorToast, showSuccessToast } from '../../common/utils/toast';

export const Support = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropDownTerm, setDropDownTerm] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [contactUsId, setContactUsId] = useState('');
    const [contactUsModal, setContactUsModal] = useState(false);

    const [newArray, setNewArray] = useState<IContactUs[] | any>();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const [requestStatusModal, setRequestStatusModal] = useState(false);

    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: allContants,
        isLoading,
        isFetching,
    } = useGetAllContactUsQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });
    const { data: contactStats } = useContactUsStatsQuery();
    const [deleteContactUs, { isLoading: isDeleting }] =
        useDeleteContactUsMutation();
    const { data: singleContactUs } = useGetSingleContactQuery(contactUsId);
    const [updateContactUs, { isLoading: isProcessingContactRequest }] =
        useUpdateContactUsMutation();

    useEffect(() => {
        setTotalRows(allContants?.pagination?.totalDocumentCount as number);
    }, [allContants?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allContants?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    //handle partner request status
    const updateUserStatus = useForm({
        defaultValues: {
            newData: { requestStatus: '' },
        },
    });
    const { handleSubmit, control, setValue } = updateUserStatus;

    const Items = [
        {
            name: ' View more',
            icon: <BsEye size={15} />,
            OnClick: () => {},
            link: 'view',
            id: '_id',
        },
        {
            name: 'Delete item',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: any) => {
                setDeleteId(data?._id);
                setShowDeleteModal(true);
            },
        },
        {
            name: 'Manage request',
            icon: <MdOutlinePersonAddDisabled size={15} />,
            OnClick: (row: IUser) => {
                setContactUsId(row._id);
                setRequestStatusModal(true);
            },
        },
    ];

    // To implement search and dropdown input
    useEffect(() => {
        if (searchTerm === '' && dropDownTerm === '') {
            setNewArray(allContants?.data);
        }
        if (dropDownTerm !== '') {
            let filteredItems = allContants?.data?.filter(
                (item: IContactUs) => {
                    return item?.status
                        .toLowerCase()
                        .includes(dropDownTerm.toLowerCase());
                },
            );
            setNewArray(filteredItems);
        }
        if (searchTerm !== '') {
            let searchItems = allContants?.data?.filter((item: IContactUs) => {
                return (
                    item?.firstName
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase()) ||
                    item?.lastName
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase())
                );
            });
            setNewArray(searchItems);
        }
    }, [allContants, searchTerm, dropDownTerm]);

    const confirmDelete = () => {
        deleteContactUs(deleteId)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowDeleteModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    const handleContactUsStatus = (value: {
        newData: { requestStatus: string };
    }) => {
        let payload = {
            contactUsId: contactUsId,
            status: value?.newData?.requestStatus,
        };

        updateContactUs(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setRequestStatusModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'First Name', key: 'firstName' },
        {
            label: 'Last Name',
            key: 'lastName',
        },
        {
            label: 'Email',
            key: 'email',
        },
        {
            label: 'Phone Number',
            key: 'phone',
        },
        {
            label: 'Message',
            key: 'message',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { header: 'First Name', dataKey: 'firstName' },
        {
            header: 'Last Name',
            dataKey: 'lastName',
        },
        {
            header: 'Email',
            dataKey: 'email',
        },
        {
            header: 'Phone Number',
            dataKey: 'phone',
        },
        {
            header: 'Message',
            dataKey: 'message',
        },
    ];

    return (
        <Container>
            {isLoading || isDeleting ? <Loader /> : null}
            <Header>Contact Us</Header>
            <Grid>
                <Counter
                    counter_title="Total contacts"
                    count={contactStats?.data?.totalContactUs || 0}
                    iconFill="blue"
                    iconBG="blue"
                >
                    <SupportIcon />
                </Counter>

                <Counter
                    counter_title="Total resolved"
                    count={contactStats?.data?.totalResolvedContactUs || 0}
                    iconFill="green"
                    iconBG="green"
                >
                    <SupportIcon />
                </Counter>
                <Counter
                    counter_title="Total opened"
                    count={contactStats?.data?.totalOpenContactUs || 0}
                >
                    <SupportIcon />
                </Counter>
                <Counter
                    counter_title="Total blocked"
                    count={contactStats?.data?.totalBlockedContactUs || 0}
                    iconFill="red"
                    iconBG="red"
                >
                    <SupportIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={9}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col
                        xs={10}
                        sm={6}
                        className="d-flex align-items-center my-3"
                    >
                        <SelectComp
                            selectText="All contact us"
                            arrayComp={[
                                'pending',
                                'blocked',
                                'open',
                                'resolved',
                            ]}
                            onChange={(e: any) =>
                                setDropDownTerm(e.target.value)
                            }
                        />
                        <TotalResultText>{`${
                            allContants?.data?.length || 0
                        } result`}</TotalResultText>
                    </Col>
                    <InputWrap>
                        <img src={SearchIcon} alt="" />
                        <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Search"
                            className="border-start-0 border-bottom-0 border-top-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputWrap>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'CONTACT US RECORD'}
                            {...{
                                columns: headersPdf,
                                body: allContants?.data,
                            }}
                        />
                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={allContants?.data}
                        />
                    </ButtonContainer>
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={ContactUsTableHeader(Items)}
                    data={newArray}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>
            <ModalComp
                show={contactUsModal}
                handleClose={() => setContactUsModal(false)}
                centered
                close={true}
                modalTitle="Contact Us status"
                paragraph="Fill in the forms to update contact-us status"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleSubmit(handleContactUsStatus)}>
                            <Controller
                                name="newData.requestStatus"
                                control={control}
                                rules={{
                                    required: 'account status is required',
                                }}
                                render={({
                                    field: { value, onChange },
                                    formState: { errors },
                                }) => {
                                    const errorMessage =
                                        errors?.newData?.requestStatus?.message;
                                    return (
                                        <SelectComp
                                            labelText="Status"
                                            arrayComp={[
                                                'pending',
                                                'blocked',
                                                'open',
                                                'resolved',
                                            ]}
                                            name="serviceChargeOnPartnerCharge"
                                            defaultValue={
                                                singleContactUs?.data?.status
                                            }
                                            {...{
                                                value,
                                                onChange,
                                                errors: [errorMessage],
                                            }}
                                        />
                                    );
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isProcessingContactRequest
                                            ? 'Save changes'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />
            <DeleteModal
                deleteItem={'Contact Us'}
                showDeleteModal={showDeleteModal}
                cancelDelete={() => setShowDeleteModal(false)}
                confirmDelete={confirmDelete}
                isDeleting={isDeleting}
            />
            {/* handle contact us request */}
            <ModalComp
                show={requestStatusModal}
                handleClose={() => setRequestStatusModal(false)}
                centered
                close={true}
                modalTitle="Contact Us status"
                paragraph="Fill in the  the forms to update contact us status"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleSubmit(handleContactUsStatus)}>
                            <Controller
                                name="newData.requestStatus"
                                control={control}
                                rules={{
                                    required: 'contact us status is required',
                                }}
                                render={({
                                    field: { value, onChange },
                                    formState: { errors },
                                }) => {
                                    const errorMessage =
                                        errors?.newData?.requestStatus?.message;
                                    return (
                                        <SelectComp
                                            labelText="Status"
                                            arrayComp={['resolved', 'blocked']}
                                            name="contactUs"
                                            defaultValue={
                                                singleContactUs?.data?.status
                                            }
                                            {...{
                                                value,
                                                onChange,
                                                errors: [errorMessage],
                                            }}
                                        />
                                    );
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isProcessingContactRequest
                                            ? 'Save changes'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />
        </Container>
    );
};
