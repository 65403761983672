import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../api";
import { Response } from "../genericInterface";
import { IAdvert, IAdvertStats } from "./interface";
import { paginatedResponse } from "../paginatedInterface";

export const advertApi = createApi({
	reducerPath: "advertApi",
	baseQuery: baseQuery,
	tagTypes: ["advert"],
	endpoints: (builder) => ({
		getAllAdvert: builder.query<
			paginatedResponse<IAdvert[]>,
			{ perPage: number; currentPage: number }
		>({
			query: ({ perPage, currentPage }) => ({
				url: `/marketPlace/api/v1/advert?perPage=${perPage}&currentPage=${currentPage}`,
				method: "GET",
			}),
			providesTags: ["advert"],
		}),
		getSingleAdvert: builder.query<Response<IAdvert>, string>({
			query: (query) => ({
				url: `/marketPlace/api/v1/advert/${query}`,
				method: "GET",
			}),
			providesTags: ["advert"],
		}),
		suspendAdvert: builder.mutation<
			Response<IAdvert>,
			{ advertStatus: string; advertId: string }
		>({
			query: (body) => ({
				url: `/marketPlace/api/v1/advert/updateAdvertStatus`,
				method: "PATCH",
				body,
			}),
			invalidatesTags: ["advert"],
		}),

		advertStats: builder.query<Response<IAdvertStats>, void>({
			query: () => ({
				url: `/marketPlace/api/v1/advert/a/stats`,
				method: "GET",
			}),
			providesTags: ["advert"],
		}),
	}),
});

export const {
	useGetAllAdvertQuery,
	useGetSingleAdvertQuery,
	useSuspendAdvertMutation,
	useAdvertStatsQuery,
} = advertApi;
