import {
    BarLine,
    Grid,
    ImageContainer,
    StatusContainer,
    SubHeading,
    Card,
    Text,
    UserWrap,
} from '../partnerRequest/style';
import { BackButton, TopText, UserImage, UserName } from '../users/style';
import { useNavigate, useParams } from 'react-router-dom';
import back_arrow from '../../common/images/back_arrow.svg';
import { Loader, ModalComp } from '../../common/component';
import { getDateString } from '../../common/utils/date';
import { useState } from 'react';
import { useGetSingleSuperMerchantQuery } from '../../store/superMerchantApi';

export const ViewSuperMerchant = () => {
    const params = useParams();
    const navigate = useNavigate();
    const merchantRequestId = params?.id as string;
    const { data: merchantData, isLoading } =
        useGetSingleSuperMerchantQuery(merchantRequestId);

    return (
        <div>
            <div className="d-flex align-items-center my-2">
                <BackButton onClick={() => navigate(-1)}>
                    <img src={back_arrow} alt="" /> Back
                </BackButton>
                <TopText>
                    {' '}
                    <span>/ Super Merchant</span> / View Request
                </TopText>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="d-flex gap-3 mt-4">
                        <BarLine />
                        <div className="d-flex flex-column gap-4 w-100">
                            <Grid className="d-flex gap-4 gap-sm-5">
                                <Card>
                                    <div className="pb-1">
                                        <UserWrap>Applied by</UserWrap>
                                    </div>
                                    <div className="d-flex align-items-center gap-3 gap-lg-2">
                                        <UserImage>
                                            <img
                                                src={
                                                    merchantData?.data?.user
                                                        ?.passportPhoto
                                                }
                                                alt=""
                                            />
                                        </UserImage>
                                        <UserName>{`${merchantData?.data?.user?.firstName} ${merchantData?.data?.user?.lastName}`}</UserName>
                                    </div>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Applied on</SubHeading>
                                    <Text>
                                        {getDateString(
                                            merchantData?.data
                                                ?.createdAt as string,
                                        )}
                                    </Text>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Address</SubHeading>
                                    <Text>{merchantData?.data?.address}</Text>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>About</SubHeading>
                                    <Text>{merchantData?.data?.about}</Text>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>
                                        Additional Information
                                    </SubHeading>
                                    <Text>
                                        {
                                            merchantData?.data
                                                ?.additionalInformation
                                        }
                                    </Text>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Status</SubHeading>
                                    <StatusContainer
                                        status={
                                            merchantData?.data?.status as string
                                        }
                                    >
                                        {merchantData?.data?.status}
                                    </StatusContainer>
                                </Card>
                            </Grid>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
