import { FC } from "react";
import { Link } from "react-router-dom";
import { ITableItems } from "../../types";

//Item takes all i.e View Details,
//row takes the object
//
export const TableDropDown: FC<ITableItems> = ({ Items = [], row }) => {
	return (
		<div className="px-2 flex " style={{ width: '180px'}}>
			{Items?.map((item, index) => (
				<div className="mb-2 " key={index}>
					{item?.link ? (
						<Link
							to={`${item?.link}/${item?.id ? row?.[item?.id] : ""}`}
							className="mb-0 h50 text-1 d-flex"
						>
							<span className="me-2">{item?.icon}</span>
							{item?.name}
						</Link>
					) : (
						<div
							className="h50 text-muted pointer d-flex"
							onClick={() => {
								item?.OnClick(row);
							}}
							style={{ cursor: "pointer" }}
						>
							<span className="me-2">{item?.icon}</span>
							{item?.name}
						</div>
					)}
				</div>
			))}
		</div>
	);
};
