import {
    BarLine,
    Grid,
    ImageContainer,
    StatusContainer,
    SubHeading,
    Card,
    Text,
    UserWrap,
} from '../partnerRequest/style';
import { BackButton, TopText, UserImage, UserName } from '../users/style';
import { useNavigate, useParams } from 'react-router-dom';
import back_arrow from '../../common/images/back_arrow.svg';
import { Loader, ModalComp } from '../../common/component';
import { getDateString } from '../../common/utils/date';
import { useState } from 'react';
import { useGetSingleMerchantRequestQuery } from '../../store/merchantRequestApi';

export const ViewMerchant = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const merchantRequestId = params?.id as string;
    const { data: merchantData, isLoading } =
        useGetSingleMerchantRequestQuery(merchantRequestId);

    return (
        <div>
            <div className="d-flex align-items-center my-2">
                <BackButton onClick={() => navigate(-1)}>
                    <img src={back_arrow} alt="" /> Back
                </BackButton>
                <TopText>
                    {' '}
                    <span>/ Merchant Request</span> / View Request
                </TopText>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="d-flex gap-3 mt-4">
                        <BarLine />
                        <div className="d-flex flex-column gap-4 w-100">
                            <Grid className="d-flex gap-4 gap-sm-5">
                                <Card>
                                    <div className="pb-1">
                                        <UserWrap>Applied by</UserWrap>
                                    </div>
                                    <div className="d-flex align-items-center gap-3 gap-lg-2">
                                        <UserImage>
                                            <img
                                                src={
                                                    merchantData?.data?.user
                                                        ?.passportPhoto
                                                }
                                                alt=""
                                            />
                                        </UserImage>
                                        <UserName>{`${merchantData?.data?.user?.firstName} ${merchantData?.data?.user?.lastName}`}</UserName>
                                    </div>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Applied on</SubHeading>
                                    <Text>
                                        {getDateString(
                                            merchantData?.data
                                                ?.createdAt as string,
                                        )}
                                    </Text>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>
                                        Means of identification
                                    </SubHeading>
                                    <div className="d-flex gap-1">
                                        <Text>{`${merchantData?.data?.meansOfIdentification[0]?.idCardType}/`}</Text>
                                        <SubHeading
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => setShowModal(true)}
                                        >{`View image`}</SubHeading>
                                    </div>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Address</SubHeading>
                                    <Text>{merchantData?.data?.address}</Text>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Status</SubHeading>
                                    <StatusContainer
                                        status={
                                            merchantData?.data?.status as string
                                        }
                                    >
                                        {merchantData?.data?.status}
                                    </StatusContainer>
                                </Card>
                            </Grid>
                        </div>
                    </div>
                </>
            )}

            <ModalComp
                show={showModal}
                handleClose={() => setShowModal(false)}
                centered
                close={true}
                modalTitle={`${merchantData?.data?.meansOfIdentification[0]?.idCardType} image`}
                modalBody={
                    <div className="d-flex flex-column gap-4 py-2 px-lg-4">
                        {merchantData?.data?.meansOfIdentification[0]
                            ?.idCardFrontURL && (
                            <ImageContainer>
                                <img
                                    src={
                                        merchantData?.data
                                            ?.meansOfIdentification[0]
                                            ?.idCardFrontURL
                                    }
                                    alt=""
                                />
                            </ImageContainer>
                        )}
                        {merchantData?.data?.meansOfIdentification[0]
                            ?.idCardBackURL && (
                            <ImageContainer>
                                <img
                                    src={
                                        merchantData?.data
                                            ?.meansOfIdentification[0]
                                            ?.idCardBackURL
                                    }
                                    alt=""
                                />
                            </ImageContainer>
                        )}
                    </div>
                }
            />
        </div>
    );
};
