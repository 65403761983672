import { useParams } from "react-router-dom";
import { Counter, Loader } from "../../common/component";
import { TableCompData } from "../../common/component/table";
import { ContainerComp } from "../../common/component/Ui/containerComp";
import { ReactComponent as CurrencyIcon } from "../../common/images/currency_icon.svg";
import { ViewUserTradeTableHeader } from "../../common/utils/dataUtils";
import {
	useGetUserTradeQuery,
	useGetUserTradeStatsQuery,
} from "../../store/tradeApi";
import { ITrade } from "../../store/tradeApi/interface";
import { Grid } from "../dashboard/styles";
import { SubHeading } from "./style";
import { useState, useEffect } from "react";

export const TradeHistory = () => {
	const params = useParams();
	const userId = params?.id as string;
	const { data: tradeStats } = useGetUserTradeStatsQuery(userId);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRows, setTotalRows] = useState(100);
	const paginate = {
		userId: userId,
		perPage: rowsPerPage,
		currentPage: currentPage,
	};
	const {
		data: userTrade,
		isLoading,
		isFetching,
	} = useGetUserTradeQuery(paginate, {
		refetchOnMountOrArgChange: true,
	});

	useEffect(() => {
		setTotalRows(userTrade?.pagination?.totalDocumentCount as number);
	}, [userTrade?.pagination?.totalDocumentCount]);
	const handlePageChange = (page: any) => setCurrentPage(page);
	const handleRowsPerPageChange = (newRowsPerPage: any) => {
		if (!userTrade?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
		setRowsPerPage(newRowsPerPage);
		setCurrentPage(1);
	};

	return (
		<div>
			{isLoading && <Loader />}
			<Grid className="mb-4 ">
				<Counter
					counter_title="Total trades"
					count={tradeStats?.data?.totalTrades || 0}
					iconFill="primaryBlue"
					iconBG="blue"
				>
					<CurrencyIcon />
				</Counter>
				<Counter
					counter_title="Open trades"
					count={tradeStats?.data?.openTrades || 0}
					iconFill="primaryBlue"
					iconBG="blue"
				>
					<CurrencyIcon />
				</Counter>
				<Counter
					counter_title="Completed trades"
					count={tradeStats?.data?.completedTrades || 0}
				>
					<CurrencyIcon />
				</Counter>
				<Counter
					counter_title="Cancelled trades"
					count={tradeStats?.data?.cancelledTrades || 0}
					iconFill="red"
					iconBG="red"
				>
					<CurrencyIcon />
				</Counter>
			</Grid>
			<SubHeading className="my-2">Trade history</SubHeading>
			<ContainerComp>
				<TableCompData
					columns={ViewUserTradeTableHeader()}
					data={userTrade?.data as ITrade[]}
					progressPending={isFetching}
					totalRows={totalRows}
					rowsPerPage={rowsPerPage}
					currentPage={currentPage}
					handlePageChange={handlePageChange}
					handleRowsPerPageChange={handleRowsPerPageChange}
				/>
			</ContainerComp>
		</div>
	);
};
