import {
    BarLine,
    ButtonContainer,
    Container,
    InputWrap,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    CustomButton,
    DeleteModal,
    Loader,
    PdfDownloader,
} from '../../common/component';
import { useNavigate } from 'react-router-dom';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { ConfigurationTableHeader } from '../../common/utils/dataUtils';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { GrOverview } from 'react-icons/gr';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as ConfigurationIcon } from '../../common/images/configuration_icon.svg';
import { showErrorToast, showSuccessToast } from '../../common/utils/toast';
import {
    useDeleteConfigMutation,
    useGetAllConfigQuery,
    useConfigStatsQuery,
} from '../../store/configApi';
import { IConfig } from '../../store/configApi/interface';

export const Configuration = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [newArray, setNewArray] = useState<IConfig[] | any>();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const [configData, setConfigData] = useState<string>('');
    const [deleteConfigItem, setDeleteConfigItem] = useState<boolean>(false);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: allConfig,
        isLoading,
        isFetching,
    } = useGetAllConfigQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });

    const [deleteConfig, { isLoading: isDeleting }] = useDeleteConfigMutation();
    const { data: configStats } = useConfigStatsQuery();

    const navigate = useNavigate();

    useEffect(() => {
        setTotalRows(allConfig?.pagination?.totalDocumentCount as number);
    }, [allConfig?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allConfig?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const handleConfirmDelete = () => {
        onHandleDelete(configData);
        setDeleteConfigItem(false);
    };

    const Items = [
        {
            name: 'Edit item',
            icon: <BiEditAlt size={15} />,
            OnClick: (data: any) => {
                navigate(`/dashboard/configuration/edit?id=${data?._id}`);
            },
        },
        {
            name: 'Delete item',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: any) => {
                setDeleteConfigItem((prev) => !prev);
                setConfigData(data?._id);
            },
        },
        {
            name: 'View more',
            icon: <GrOverview size={15} />,
            OnClick: (data: any) => {
                navigate(`/dashboard/configuration/view?id=${data?._id}`);
            },
        },
    ];

    // To implement search
    useEffect(() => {
        if (searchTerm === '') {
            setNewArray(allConfig?.data);
        }
        if (searchTerm !== '') {
            // let searchItems = allConfig?.data?.filter((item: IConfig) => {
            // 	return item?.isActive?.includes(searchTerm?.toLowerCase());
            // });
            // setNewArray(searchItems);
        }
    }, [allConfig, searchTerm]);

    const onHandleDelete = (id: string) => {
        deleteConfig(id)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'Partner Charge Per Trade', key: 'partnerChargePerTrade' },
        {
            label: 'ServiceCharge On Partner Charge',
            key: 'serviceChargeOnPartnerCharge',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        {
            header: 'Partner Charge Per Trade',
            dataKey: 'partnerChargePerTrade',
        },
        {
            header: 'ServiceCharge On Partner Charge',
            dataKey: 'serviceChargeOnPartnerCharge',
        },
    ];

    return (
        <Container>
            {isLoading || isDeleting ? <Loader /> : null}
            <Header>Configurations</Header>
            <Grid>
                <Counter
                    counter_title="Total Setup"
                    count={configStats?.data?.totalSetups || '0'}
                    iconFill="black"
                    iconBG="blue"
                >
                    <ConfigurationIcon />
                </Counter>
                <Counter
                    counter_title="Active Setup"
                    count={configStats?.data?.activeSetups || '0'}
                    iconFill="green"
                    iconBG="green"
                >
                    <ConfigurationIcon />
                </Counter>
                <Counter
                    counter_title="Inactive Setup"
                    count={configStats?.data?.inactiveSetups || '0'}
                    iconFill="red"
                    iconBG="red"
                >
                    <ConfigurationIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={7}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col xl={4} className="d-flex align-items-center my-3">
                        <TotalResultText>{`${
                            allConfig?.data?.length || 0
                        } configurations`}</TotalResultText>
                    </Col>
                    <InputWrap>
                        <img src={SearchIcon} alt="" />
                        <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Search"
                            className="border-start-0 border-bottom-0 border-top-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputWrap>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'CONFIGURATION RECORD'}
                            {...{ columns: headersPdf, body: allConfig?.data }}
                        />
                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={allConfig?.data}
                        />
                    </ButtonContainer>

                    <CustomButton
                        text="+ New configuration"
                        onClick={() => navigate('/dashboard/configuration/new')}
                    />
                </div>
            </div>
            <ContainerComp>
                <TableCompData
                    columns={ConfigurationTableHeader(Items)}
                    data={newArray}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>
            <DeleteModal
                showDeleteModal={deleteConfigItem}
                cancelDelete={() => setDeleteConfigItem(false)}
                confirmDelete={handleConfirmDelete}
                deleteItem="Configuration"
                isDeleting={isDeleting}
            />
        </Container>
    );
};
