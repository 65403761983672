import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../common/component";
import { ReactComponent as IbxLogo } from "../../common/images/ibx_main_logo.svg";

export const LandingPage = () => {
	const navigate = useNavigate();
	const [redirect, setRedirect] = useState(false);
	useEffect(() => {
		setInterval(() => {
			setRedirect(true);
		}, 2000);
	});

	return (
		<>
			{redirect && navigate("/login", { replace: true })}
			<Container>
				<IbxLogo />
			</Container>
		</>
	);
};

const Container = styled.div`
	height: 100vh;
	background: ${colors.Primary1};
	display: flex;
	justify-content: center;
	align-items: center;
	svg {
		animation-name: fadeIn;
		animation-duration: 3s;

		@keyframes fadeIn {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}
	}
`;
