import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { paginatedResponse } from '../paginatedInterface';
import { IMerchantRequest, IMerchantRequestStats } from './interface';

export const merchantRequestApi = createApi({
    reducerPath: 'merchantRequestApi',
    baseQuery: baseQuery,
    tagTypes: ['merchantRequest'],
    endpoints: (builder) => ({
        getAllMerchantRequest: builder.query<
            paginatedResponse<IMerchantRequest[]>,
            { currentPage: number; perPage: number }
        >({
            query: ({ currentPage, perPage }) => ({
                url: `/user/api/v1/merchant-request?perPage=${perPage}&currentPage=${currentPage}`,
                method: 'GET',
            }),
            providesTags: ['merchantRequest'],
        }),
        getMerchantRequestByStatus: builder.query<
            paginatedResponse<IMerchantRequest[]>,
            { currentPage: number; status: string; perPage: number }
        >({
            query: ({ currentPage, status, perPage }) => ({
                url: `/user/api/v1/merchant-request`,
                method: 'GET',
                params: { currentPage, status, perPage },
            }),
            providesTags: ['merchantRequest'],
        }),
        updateMerchantRequest: builder.mutation<
            Response<IMerchantRequest>,
            {
                merchantRequestId: string;
                status: string;
                approvedBy: string;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/merchant-request/approveMerchantRequest`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['merchantRequest'],
        }),
        getSingleMerchantRequest: builder.query<
            Response<IMerchantRequest>,
            string
        >({
            query: (merchantRequestId) => ({
                url: `/user/api/v1/merchant-request/${merchantRequestId}`,
                method: 'GET',
            }),
            providesTags: ['merchantRequest'],
        }),
        deleteMerchantRequest: builder.mutation<
            Response<IMerchantRequest>,
            string
        >({
            query: (merchantRequestId) => ({
                url: `/user/api/v1/merchant-request/${merchantRequestId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['merchantRequest'],
        }),
        merchantRequestStats: builder.query<
            Response<IMerchantRequestStats>,
            void
        >({
            query: () => ({
                url: 'user/api/v1/merchant-request/a/stats',
                method: 'GET',
            }),
            providesTags: ['merchantRequest'],
        }),
    }),
});

export const {
    useGetAllMerchantRequestQuery,
    useGetMerchantRequestByStatusQuery,
    useUpdateMerchantRequestMutation,
    useDeleteMerchantRequestMutation,
    useGetSingleMerchantRequestQuery,
    useMerchantRequestStatsQuery,
} = merchantRequestApi;
