import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../api";
import { Response } from "../genericInterface";
import { IAppVersion, IAppVersionStats } from "./interface";
import { paginatedResponse } from "../paginatedInterface";

export const appVersionApi = createApi({
	reducerPath: "appVersionApi",
	baseQuery: baseQuery,
	tagTypes: ["appVersion"],
	endpoints: (builder) => ({
		addAppVersion: builder.mutation<
			Response<IAppVersion>,
			{
				versionName: string;
				versionNumber: number;
				buildNumber: number;
				storeURL: string;
				appType: string;
				updateType: string;
			}
		>({
			query: (body) => ({
				url: `/user/api/v1/app-version`,
				method: "POST",
				body,
			}),
			invalidatesTags: ["appVersion"],
		}),
		editAppVersion: builder.mutation<
			Response<IAppVersion>,
			{
				versionName: string;
				versionNumber: number;
				buildNumber: number;
				storeURL: string;
				appType: string;
				updateType: string;
				status: boolean;
			}
		>({
			query: (body) => ({
				url: `/user/api/v1/app-version`,
				method: "PATCH",
				body,
			}),
			invalidatesTags: ["appVersion"],
		}),
		getAllAppVersion: builder.query<
			paginatedResponse<IAppVersion[]>,
			{ perPage: number; currentPage: number }
		>({
			query: ({ perPage, currentPage }) => ({
				url: `/user/api/v1/app-version?perPage=${perPage}&currentPage=${currentPage}`,
				method: "GET",
			}),
			providesTags: ["appVersion"],
		}),
		getSingleAppVersion: builder.query<Response<IAppVersion>, string>({
			query: (query) => ({
				url: `/user/api/v1/app-version/${query}`,
				method: "GET",
			}),
		}),
		deleteAppVersion: builder.mutation<Response<IAppVersion>, string>({
			query: (query) => ({
				url: `/user/api/v1/app-version/${query}`,
				method: "DELETE",
			}),
			invalidatesTags: ["appVersion"],
		}),
		appVersionStats: builder.query<Response<IAppVersionStats>, void>({
			query: () => ({
				url: `/user/api/v1/app-version/a/stats`,
				method: "GET",
			}),
			providesTags: ["appVersion"],
		}),
	}),
});

export const {
	useAddAppVersionMutation,
	useAppVersionStatsQuery,
	useGetSingleAppVersionQuery,
	useGetAllAppVersionQuery,
	useEditAppVersionMutation,
	useDeleteAppVersionMutation,
} = appVersionApi;
