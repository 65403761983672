import {
    BarLine,
    ButtonContainer,
    Container,
    InputWrap,
    TotalResultText,
} from '../users/style';
import {
    Counter,
    CsvDownloader,
    Loader,
    PdfDownloader,
    SelectComp,
    TextInputComp,
} from '../../common/component';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import {
    CurrencyPairTableHeader,
    cleanUpCurrencyDataForPdf,
} from '../../common/utils/dataUtils';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { CustomButton } from '../../common/component/button';
import { ModalComp } from '../../common/component/Ui/modalComp';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as CurrencyPairIcon } from '../../common/images/currencypair_icon.svg';
import {
    useAddCurrencyPairMutation,
    useCurrencyPairStatsQuery,
    useDeleteCurrencyPairMutation,
    useEditCurrencyPairMutation,
    useGetAllCurrencyPairQuery,
    useGetSingleCurrencyPairQuery,
} from '../../store/currencyPairApi';
import { showErrorToast, showSuccessToast } from '../../common/utils/toast';
import { useForm, Controller } from 'react-hook-form';
import { ICurrencyPair } from '../../store/currencyPairApi/interface';
import { useAllCurrencyQuery } from '../../store/currencyApi';
import { Flexbox2 } from './style';

export const CurrencyPair = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropDownTerm, setDropDownTerm] = useState('');
    const [filteredCurrencyPair, setfilteredCurrencyPair] = useState<
        ICurrencyPair[] | any
    >();

    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeletModal] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);

    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: allCurrencyPair,
        isLoading,
        isFetching,
    } = useGetAllCurrencyPairQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });
    const [addCurrencyPair, { isLoading: isAdding }] =
        useAddCurrencyPairMutation();
    const [editCurrencyPair, { isLoading: isEditing }] =
        useEditCurrencyPairMutation();
    const [deleteCurrencyPair, { isLoading: isDeleting }] =
        useDeleteCurrencyPairMutation();
    const [currencyPairId, setCurrencyPairId] = useState('');
    const { data: singleCurrencyPairData } =
        useGetSingleCurrencyPairQuery(currencyPairId);
    const { data: currencyPairStats } = useCurrencyPairStatsQuery();
    const { data: allCurrency } = useAllCurrencyQuery(paginate);

    useEffect(() => {
        setTotalRows(allCurrencyPair?.pagination?.totalDocumentCount as number);
    }, [allCurrencyPair?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allCurrencyPair?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const Items = [
        {
            name: 'Edit item',
            icon: <BiEditAlt size={15} />,
            OnClick: (data: any) => {
                setCurrencyPairId(data?._id);
                setShowEditModal(true);
            },
        },
        {
            name: 'Delete item',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: any) => {
                setCurrencyPairId(data?._id);
                setShowDeletModal(true);
            },
        },
    ];

    // To implement search and dropdown input
    useEffect(() => {
        if (searchTerm === '' && dropDownTerm === '') {
            setfilteredCurrencyPair(allCurrencyPair?.data);
        }
        if (dropDownTerm !== '') {
            let filteredItems = allCurrencyPair?.data?.filter(
                (item: ICurrencyPair) => {
                    return item?.baseCurrency?.currencyType
                        ?.toLowerCase()
                        .includes(dropDownTerm.toLowerCase());
                },
            );
            setfilteredCurrencyPair(filteredItems);
        }
        if (searchTerm !== '') {
            let searchItems = allCurrencyPair?.data?.filter(
                (item: ICurrencyPair) => {
                    return (
                        item?.baseCurrency?.name
                            ?.toLowerCase()
                            .includes(searchTerm?.toLowerCase()) ||
                        item?.tradingCurrency?.name
                            ?.toLowerCase()
                            .includes(searchTerm?.toLowerCase())
                    );
                },
            );
            setfilteredCurrencyPair(searchItems);
        }
    }, [allCurrencyPair, searchTerm, dropDownTerm]);

    const allCurrencyType = allCurrency?.data?.map((item: any) => {
        return item?.name;
    });
    // add currency
    const currencyPair = useForm({
        defaultValues: {
            baseCurrency: '',
            tradingCurrency: '',
        },
    });
    const { handleSubmit, control, reset } = currencyPair;

    // edit currency
    const editCurrencyPairForm = useForm({
        defaultValues: {
            newData: {
                baseCurrency: '',
                tradingCurrency: '',
                status: '',
            },
        },
    });
    const {
        handleSubmit: handleEditSubmit,
        control: editControl,
        setValue,
    } = editCurrencyPairForm;

    useEffect(() => {
        if (singleCurrencyPairData?.data?._id) {
            setValue(
                'newData',
                {
                    baseCurrency:
                        singleCurrencyPairData?.data?.baseCurrency?.name,
                    tradingCurrency:
                        singleCurrencyPairData?.data?.tradingCurrency?.name,
                    status: singleCurrencyPairData?.data?.status
                        ? 'Active'
                        : 'Inactive',
                },
                { shouldValidate: true },
            );
        }
    }, [singleCurrencyPairData, setValue]);

    const onHandleSubmit = (value: {
        baseCurrency: string;
        tradingCurrency: string;
    }) => {
        addCurrencyPair(value)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowAddModal(false);
                reset();
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    const onHandleEdit = (value: {
        newData: {
            baseCurrency: string;
            tradingCurrency: string;
            status: string;
        };
    }) => {
        const { baseCurrency, tradingCurrency, status } = value?.newData;
        let payload = {
            baseCurrency: baseCurrency,
            tradingCurrency: tradingCurrency,
            tradingPairId: singleCurrencyPairData?.data?._id as string,
            status: status === 'Active' ? true : false,
        };
        editCurrencyPair(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowEditModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    const onHandleDelete = (id: string) => {
        deleteCurrencyPair(id)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowDeletModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'Base Currency', key: 'baseCurrency.name' },
        {
            label: 'Trading Currency',
            key: 'tradingCurrency.name',
        },
        {
            label: 'Category',
            key: 'baseCurrency.currencyType',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { header: 'Base Currency', dataKey: 'baseCurrency' },
        {
            header: 'Trading Currency',
            dataKey: 'tradingCurrency',
        },
        {
            header: 'Category',
            dataKey: 'category',
        },
    ];

    return (
        <Container>
            <Header>Currency Pair</Header>
            {isLoading || isDeleting ? <Loader /> : null}
            <Grid>
                <Counter
                    counter_title="Total currency pair"
                    count={currencyPairStats?.data?.totalCurrencyPairs || '0'}
                    iconFill="black"
                    iconBG="blue"
                >
                    <CurrencyPairIcon />
                </Counter>
                <Counter
                    counter_title="Active currency pair"
                    count={currencyPairStats?.data?.activeCurrencyPairs || '0'}
                    iconFill="green"
                    iconBG="green"
                >
                    <CurrencyPairIcon />
                </Counter>
                <Counter
                    counter_title="Inactive currency pair"
                    count={
                        currencyPairStats?.data?.inactiveCurrencyPairs || '0'
                    }
                    iconFill="red"
                    iconBG="red"
                >
                    <CurrencyPairIcon />
                </Counter>
            </Grid>

            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={7}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col
                        xs={10}
                        sm={6}
                        className="d-flex align-items-center my-3"
                    >
                        <SelectComp
                            selectText="All currency pair"
                            arrayComp={['Crypto', 'Fiat']}
                            onChange={(e: any) =>
                                setDropDownTerm(e.target.value)
                            }
                        />
                        <TotalResultText>{`${
                            filteredCurrencyPair?.length || 0
                        } result`}</TotalResultText>
                    </Col>
                    <InputWrap>
                        <img src={SearchIcon} alt="" />
                        <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Search"
                            className="border-start-0 border-bottom-0 border-top-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputWrap>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={'CURRENCY PAIR RECORD'}
                            {...{
                                columns: headersPdf,
                                body: cleanUpCurrencyDataForPdf(
                                    allCurrencyPair?.data,
                                ),
                            }}
                        />
                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={allCurrencyPair?.data}
                        />
                    </ButtonContainer>
                    <CustomButton
                        text="Create currency pair"
                        onClick={() => setShowAddModal(true)}
                    />
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={CurrencyPairTableHeader(Items)}
                    data={filteredCurrencyPair}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>

            {/* add currency pair modal */}
            <ModalComp
                show={showAddModal}
                handleClose={() => setShowAddModal(false)}
                centered
                close={true}
                modalTitle="Add Currency pair"
                paragraph="Fill in the  the forms to add a currency pair"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleSubmit(onHandleSubmit)}>
                            <div className="my-3">
                                <Controller
                                    name="baseCurrency"
                                    control={control}
                                    rules={{
                                        required: 'Base currency is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.baseCurrency?.message;
                                        return (
                                            <SelectComp
                                                labelText="Base Currency"
                                                arrayComp={allCurrencyType}
                                                name="baseCurrency"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="tradingCurrency"
                                    control={control}
                                    rules={{
                                        required:
                                            'Trading currency is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.tradingCurrency?.message;
                                        return (
                                            <SelectComp
                                                labelText="Trading Currency"
                                                arrayComp={allCurrencyType}
                                                name="tradingCurrency"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isAdding
                                            ? 'Create Currency Pair'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />

            {/* edit modal */}
            <ModalComp
                show={showEditModal}
                handleClose={() => setShowEditModal(false)}
                centered
                close={true}
                modalTitle="Edit Currency pair"
                paragraph="Fill in the  the forms to edit a currency pair"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form onSubmit={handleEditSubmit(onHandleEdit)}>
                            <div className="my-3">
                                <Controller
                                    name="newData.baseCurrency"
                                    control={editControl}
                                    rules={{
                                        required: 'Base currency is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.baseCurrency
                                                ?.message;
                                        return (
                                            <SelectComp
                                                labelText="Base Currency"
                                                arrayComp={allCurrencyType}
                                                name="baseCurrency"
                                                defaultValue={
                                                    singleCurrencyPairData?.data
                                                        ?.baseCurrency?.name
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Controller
                                    name="newData.tradingCurrency"
                                    control={editControl}
                                    rules={{
                                        required:
                                            'Trading currency is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.tradingCurrency
                                                ?.message;
                                        return (
                                            <SelectComp
                                                labelText="Trading Currency"
                                                arrayComp={allCurrencyType}
                                                name="tradingCurrency"
                                                defaultValue={
                                                    singleCurrencyPairData?.data
                                                        ?.tradingCurrency?.name
                                                }
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <Controller
                                name="newData.status"
                                control={editControl}
                                rules={{
                                    required: 'status is required',
                                }}
                                render={({
                                    field: { value, onChange },
                                    formState: { errors },
                                }) => {
                                    const errorMessage =
                                        errors?.newData?.status?.message;
                                    return (
                                        <SelectComp
                                            labelText="Status"
                                            inputClassName={'py-2 '}
                                            arrayComp={['Active', 'Inactive']}
                                            name="serviceChargeOnPartnerCharge"
                                            defaultValue={
                                                singleCurrencyPairData?.data
                                                    ?.status
                                                    ? 'Active'
                                                    : 'Inactive'
                                            }
                                            {...{
                                                value,
                                                onChange,
                                                errors: [errorMessage],
                                            }}
                                        />
                                    );
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isEditing
                                            ? 'Edit Currency'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />
            {/* DELETE MODAL */}

            <ModalComp
                show={showDeleteModal}
                handleClose={() => setShowDeletModal(false)}
                centered
                close={true}
                modalTitle="Delete Currency Pair"
                // paragraph="Fill in the  the forms to edit a currency pair"
                modalBody={
                    <Flexbox2>
                        <CustomButton
                            className="py-3 mt-4"
                            text={'Cancel'}
                            onClick={() => setShowDeletModal(false)}
                        />
                        <CustomButton
                            className="py-3 mt-4"
                            text={!isDeleting ? 'Delete' : 'Loading...'}
                            onClick={() => onHandleDelete(currencyPairId)}
                        />
                    </Flexbox2>
                }
            />
        </Container>
    );
};
