import { useSearchParams } from 'react-router-dom';

import { Container } from '../users/style';
import { Loader, colors } from '../../common/component';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Header } from '../dashboard/styles';

import { useGetSingleConfigQuery } from '../../store/configApi';
import { ConfigStatus } from '../../common/utils/dataUtils';

export const ViewConfiguration = () => {
    const [searchParams] = useSearchParams();

    const configId = searchParams.get('id');

    const { data: singleConfig, isLoading: isFetchingConfig } =
        useGetSingleConfigQuery(configId);

    console.log('singleConfig: ', singleConfig);

    return (
        <Container>
            {isFetchingConfig ? <Loader /> : null}
            <Header>View Configuration</Header>
            <TextParagraphOne></TextParagraphOne>
            <div className="py-4 px-lg-4 ">
                <div>
                    <h5>Configurations</h5>
                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <p>Partner charge</p>
                                <ConfigValue>
                                    {singleConfig?.data?.partnerChargePerTrade}
                                </ConfigValue>
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <p>Service Charge</p>
                                <ConfigValue>
                                    {
                                        singleConfig?.data
                                            ?.serviceChargeOnPartnerCharge
                                    }
                                </ConfigValue>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <p>Support Number</p>
                                <ConfigValue>
                                    {singleConfig?.data?.supportNumber}
                                </ConfigValue>
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <p>Status</p>
                                <ConfigStatus
                                    status={singleConfig?.data?.isActive}
                                >
                                    {singleConfig?.data?.isActive
                                        ? 'Active'
                                        : 'Inactive'}
                                </ConfigStatus>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="mt-3">
                    <h5>Social Links</h5>
                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <p>Facebook</p>
                                <ConfigValue>
                                    {singleConfig?.data?.socialLinks?.facebook}
                                </ConfigValue>
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <p>Twitter</p>
                                <ConfigValue>
                                    {singleConfig?.data?.socialLinks?.twitter}
                                </ConfigValue>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <p>Youtube</p>
                                <ConfigValue>
                                    {singleConfig?.data?.socialLinks?.youtube}
                                </ConfigValue>
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <p>Instagram</p>
                                <ConfigValue>
                                    {singleConfig?.data?.socialLinks?.instagram}
                                </ConfigValue>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="my-3">
                                <p>Tiktok</p>
                                <ConfigValue>
                                    {singleConfig?.data?.socialLinks?.tiktok}
                                </ConfigValue>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="mt-3">
                    <h5 className="mb-3">Transaction Limits</h5>
                    <table
                        style={{
                            width: '36rem',
                            border: '0.5px solid #D2D2D2',
                            borderSpacing: 0,
                            borderCollapse: 'separate',
                            borderRadius: '10px',
                        }}
                    >
                        <tr
                            style={{
                                backgroundColor: '#FAFAFC',
                            }}
                        >
                            <td
                                style={{
                                    padding: '1rem',
                                    color: '#777',
                                    fontWeight: 600,
                                }}
                            >
                                Users
                            </td>
                            <td
                                style={{
                                    padding: '1rem',
                                    color: '#777',
                                    fontWeight: 600,
                                    textAlign: 'center',
                                }}
                            >
                                Min Amount
                            </td>
                            <td
                                style={{
                                    padding: '1rem',
                                    color: '#777',
                                    fontWeight: 600,
                                    textAlign: 'center',
                                }}
                            >
                                Max Amount
                            </td>
                        </tr>

                        <tr>
                            <td
                                style={{
                                    backgroundColor: '#FAFAFC',
                                    padding: '1rem',
                                    fontSize: '0.875rem',
                                }}
                            >
                                New
                            </td>
                            <td
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 600,
                                }}
                            >
                                {
                                    singleConfig?.data?.transactionLimits
                                        ?.new_user?.min
                                }
                            </td>
                            <td
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 600,
                                }}
                            >
                                {
                                    singleConfig?.data?.transactionLimits
                                        ?.new_user?.max
                                }
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    backgroundColor: '#FAFAFC',
                                    padding: '1rem',
                                    fontSize: '0.875rem',
                                }}
                            >
                                Beginner
                            </td>
                            <td
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 600,
                                }}
                            >
                                {
                                    singleConfig?.data?.transactionLimits
                                        ?.beginner?.min
                                }
                            </td>
                            <td
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 600,
                                }}
                            >
                                {
                                    singleConfig?.data?.transactionLimits
                                        ?.beginner?.max
                                }
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    backgroundColor: '#FAFAFC',
                                    padding: '1rem',
                                    fontSize: '0.875rem',
                                }}
                            >
                                Intermediate
                            </td>
                            <td
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 600,
                                }}
                            >
                                {
                                    singleConfig?.data?.transactionLimits
                                        ?.intermediate?.min
                                }
                            </td>
                            <td
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 600,
                                }}
                            >
                                {
                                    singleConfig?.data?.transactionLimits
                                        ?.intermediate?.max
                                }
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    backgroundColor: '#FAFAFC',
                                    padding: '1rem',
                                    fontSize: '0.875rem',
                                }}
                            >
                                Advance
                            </td>
                            <td
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 600,
                                }}
                            >
                                {
                                    singleConfig?.data?.transactionLimits
                                        ?.advanced?.min
                                }
                            </td>
                            <td
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 600,
                                }}
                            >
                                {
                                    singleConfig?.data?.transactionLimits
                                        ?.advanced?.max
                                }
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </Container>
    );
};

const TextParagraphOne = styled.p`
    margin-top: -1rem;
    color: ${colors?.gray31};
`;

const ConfigValue = styled.p`
    font-weight: 500;
    word-wrap: break-word;
`;

export const SubHeader = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${colors.blue10};
`;
