import { ReactComponent as Message } from '../../common/images/message_icon.svg';
import back_arrow from '../../common/images/back_arrow.svg';
import {
    BackButton,
    TopText,
    UserContact,
    UserImage,
    UserName,
} from '../users/style';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { colors, Loader } from '../../common/component';
import {
    useGetSingleAdvertQuery,
    useSuspendAdvertMutation,
} from '../../store/advertApi';
import { getDateString } from '../../common/utils/date';
import { showErrorToast, showSuccessToast } from '../../common/utils/toast';
import { useState } from 'react';
import { currencyFormater } from '../../common/utils/helpers';

export const ViewAdvert = () => {
    const params = useParams();
    const Id = params?.id as string;
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(true);
    const { data: singleAdvert, isLoading } = useGetSingleAdvertQuery(Id);
    const [suspendAdvert, { isLoading: isSuspending }] =
        useSuspendAdvertMutation();

    const onSuspendAdvert = (value: string) => {
        let payload = {
            advertStatus: value,
            advertId: Id,
        };

        suspendAdvert(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    return (
        <div className="pb-4">
            <div className="d-flex align-items-center my-2">
                <BackButton onClick={() => navigate(-1)}>
                    <img src={back_arrow} alt="" /> Back
                </BackButton>
                <TopText>
                    {' '}
                    <span>/ Advert</span> / View Advert
                </TopText>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div
                        className="d-flex align-items-center justify-content-between p-3"
                        style={{
                            background: 'rgba(250, 202, 21, 0.1)',
                            borderRadius: '8',
                        }}
                    >
                        <div>
                            <SubHeading className="mb-2">CREATED BY</SubHeading>
                            <div className="d-flex align-items-center gap-3">
                                <UserImage>
                                    <img
                                        src={
                                            singleAdvert?.data?.user
                                                ?.passportPhoto
                                        }
                                        alt=""
                                    />
                                </UserImage>
                                <div>
                                    <UserName>{`${singleAdvert?.data?.user?.firstName} ${singleAdvert?.data?.user?.lastName}`}</UserName>
                                    <div className="d-lg-flex align-items-center mt-1">
                                        <UserContact>
                                            <Message />
                                            {singleAdvert?.data?.user?.email}
                                        </UserContact>{' '}
                                        <div className="px-3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                            <p style={{ fontWeight: 600 }}>
                                {singleAdvert?.data?.user?.userType.toUpperCase()}
                            </p>
                        </div>
                    </div>
                    <Grid className="my-5">
                        <Card className="d-flex gap-1">
                            <BarLine />
                            <div className="d-flex flex-column gap-2">
                                <SubHeading>Creation date</SubHeading>
                                <Text>
                                    {getDateString(
                                        singleAdvert?.data?.createdAt as string,
                                    )}
                                </Text>
                            </div>
                        </Card>
                        <Card className="d-flex gap-1">
                            <BarLine />
                            <div className="d-flex flex-column gap-2">
                                <SubHeading>Currency pair</SubHeading>
                                <Text>{`${singleAdvert?.data?.currencyPair?.baseCurrency?.name.toUpperCase()}/${singleAdvert?.data?.currencyPair?.tradingCurrency?.name.toUpperCase()}`}</Text>
                            </div>
                        </Card>
                        <Card className="d-flex gap-1">
                            <BarLine />
                            <div className="d-flex flex-column gap-2">
                                <SubHeading>Advert type</SubHeading>
                                <Text>
                                    {singleAdvert?.data?.advertType?.toUpperCase()}
                                </Text>
                            </div>
                        </Card>
                        <Card className="d-flex gap-1">
                            <BarLine />
                            <div className="d-flex flex-column gap-2">
                                <SubHeading>{`Price per ${singleAdvert?.data?.currencyPair?.tradingCurrency?.name.toUpperCase()}`}</SubHeading>
                                <Text>{`${singleAdvert?.data?.currencyPair?.baseCurrency?.currencySymbol?.toUpperCase()}${currencyFormater(
                                    singleAdvert?.data?.price,
                                )}`}</Text>
                            </div>
                        </Card>
                        <Card className="d-flex gap-1">
                            <BarLine />
                            <div className="d-flex flex-column gap-2">
                                <SubHeading>Available asset</SubHeading>
                                <Text>{`${singleAdvert?.data?.currencyPair?.tradingCurrency?.currencySymbol?.toUpperCase()}${currencyFormater(
                                    singleAdvert?.data?.assetAvailable,
                                )}`}</Text>
                            </div>
                        </Card>
                        <Card className="d-flex gap-1">
                            <BarLine />
                            <div className="d-flex flex-column gap-2">
                                <SubHeading>Minimun trade volume</SubHeading>
                                <Text>
                                    {singleAdvert?.data?.minimumTradingVolume}
                                </Text>
                            </div>
                        </Card>
                        <Card className="d-flex gap-1">
                            <BarLine />
                            <div className="d-flex flex-column gap-2">
                                <SubHeading>Maximum trade volume</SubHeading>
                                <Text>
                                    {singleAdvert?.data?.maximumTradingVolume}
                                </Text>
                            </div>
                        </Card>
                        <Card className="d-flex gap-1">
                            <BarLine />
                            <div className="d-flex flex-column gap-2">
                                <SubHeading>Category</SubHeading>
                                <TradeType>
                                    {singleAdvert?.data?.currencyPair?.baseCurrency?.currencyType.toUpperCase()}
                                </TradeType>
                            </div>
                        </Card>
                        <Card className="d-flex gap-1">
                            <BarLine />
                            <div className="d-flex flex-column gap-2">
                                <SubHeading>Advert status</SubHeading>
                                <Status
                                    status={
                                        singleAdvert?.data
                                            ?.advertStatus as string
                                    }
                                >
                                    {singleAdvert?.data?.advertStatus}
                                </Status>
                            </div>
                        </Card>
                        {singleAdvert?.data?.tags ? (
                            <Card className="d-flex gap-1">
                                <BarLine />
                                <div className="d-flex flex-column gap-2">
                                    <SubHeading>Tags</SubHeading>
                                    {singleAdvert?.data?.tags?.map(
                                        (tag, index) => (
                                            <Text key={index}>{tag}</Text>
                                        ),
                                    )}
                                    <Text>{`${singleAdvert?.data
                                        ?.merchantBankDetails
                                        ?.accountNumber}/${singleAdvert?.data?.merchantBankDetails?.accountType?.toUpperCase()}`}</Text>
                                </div>
                            </Card>
                        ) : null}
                        {singleAdvert?.data?.descriptions ? (
                            <Card className="d-flex gap-1">
                                <BarLine />
                                <div className="d-flex flex-column gap-2">
                                    <SubHeading>Description</SubHeading>
                                    <Text>
                                        {' '}
                                        {singleAdvert?.data?.descriptions}
                                    </Text>
                                </div>
                            </Card>
                        ) : null}
                        {singleAdvert?.data?.tradeNotes ? (
                            <Card className="d-flex gap-1">
                                <BarLine />
                                <div className="d-flex flex-column gap-2">
                                    <SubHeading>Trade note</SubHeading>
                                    <Text>
                                        {' '}
                                        {singleAdvert?.data?.tradeNotes}
                                    </Text>
                                </div>
                            </Card>
                        ) : null}
                    </Grid>

                    <SuppendBtn>
                        <Button
                            disabled={
                                singleAdvert?.data?.advertStatus === 'suspended'
                                    ? true
                                    : false
                            }
                            onClick={() => onSuspendAdvert('suspended')}
                        >
                            {isSuspending && disabled
                                ? 'Loading...'
                                : 'Suspend advert'}
                        </Button>
                        <Button
                            disabled={
                                singleAdvert?.data?.advertStatus === 'active' ||
                                singleAdvert?.data?.advertStatus === 'paused'
                                    ? true
                                    : false
                            }
                            onClick={() => {
                                onSuspendAdvert('active');
                                setDisabled(false);
                            }}
                        >
                            {isSuspending && !disabled
                                ? 'Loading...'
                                : 'Unsuspend advert'}
                        </Button>
                    </SuppendBtn>
                </>
            )}
        </div>
    );
};

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 50px;
    row-gap: 40px;
`;
const SuppendBtn = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
`;
const Card = styled.div`
    width: calc(20% - 40px);
    @media (max-width: 10248px) {
        width: calc(33.33% - 35px);
    }
    @media (max-width: 768px) {
        width: calc(50% - 25px);
    }
    @media (max-width: 376px) {
        width: calc(100%);
    }
`;
const BarLine = styled.div`
    border: 1px solid #bc8019;
    background: #bc8019;
`;
export const SubHeading = styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.gray30};
`;
export const Text = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #10192d;
`;
export const Status = styled(Text)<{ status: string }>`
    color: ${(props) =>
        props.status === 'active'
            ? colors.Green80
            : props.status === 'suspended'
            ? colors.Primary1
            : colors.red100};
`;
const TradeType = styled(Text)`
    background: ${colors.blue30};
    text-align: center;
    padding: 5px 10px;
    border-radius: 4px;
`;
const Button = styled.button<{ disabled?: boolean }>`
    background: ${(prop) => (prop.disabled ? colors.gray10 : colors.Primary1)};
    color: ${(prop) => (prop.disabled ? colors.gray30 : colors.white)};
    border: 1px solid ${colors.black10};
    border-radius: 6px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    padding: 10px 30px;
`;
