import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
    colors,
    CustomButton,
    ModalComp,
    SelectComp,
    TextEditor,
    TextInputComp,
} from '../../common/component';
import { showSuccessToast, showErrorToast } from '../../common/utils/toast';
import {
    useGetSingleJobsQuery,
    useUpdateJobMutation,
} from '../../store/jobAPi';
const parse = require('html-react-parser');

export const JobDetails = () => {
    const param = useParams();
    const jobId = param?.id as string;
    const [showModal, setShowModal] = useState(false);
    const { data: jobData } = useGetSingleJobsQuery(jobId);
    const [updateJob, { isLoading: isUpdating }] = useUpdateJobMutation();

    const { handleSubmit, setValue, control } = useForm({
        defaultValues: {
            newData: {
                role: '',
                title: '',
                description: '',
                location: '',
                category: '',
                schedule: '',
            },
        },
    });

    useEffect(() => {
        if (jobData?.data?._id) {
            setValue('newData', {
                role: jobData?.data?.role,
                title: jobData?.data?.title,
                description: jobData?.data?.description,
                location: jobData?.data?.location,
                schedule: jobData?.data?.schedule,
                category: jobData.data.category,
            });
        }
    });

    const onHandleEdit = (value: {
        newData: {
            role: string;
            title: string;
            description: string;
            location: string;
            schedule: string;
            category: string;
        };
    }) => {
        const { role, title, description, location, schedule, category } =
            value?.newData;
        let payload = {
            role: role,
            title: title,
            description: description,
            location: location,
            schedule: schedule,
            category: category,
            jobsId: jobId,
        };
        console.log(value?.newData, 'value?.newData');
        updateJob(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setShowModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    return (
        <div className="d-flex flex-column gap-4 mx-lg-5 mb-4">
            <>
                <div className="d-flex justify-content-between">
                    <Wrap>
                        <Title>Job title</Title>
                        <Paragraph>{jobData?.data?.role}</Paragraph>
                    </Wrap>
                    <Wrap>
                        <Title>Role</Title>
                        <Paragraph>{jobData?.data?.title}</Paragraph>
                    </Wrap>
                </div>
                <div className="d-flex justify-content-between ">
                    <Wrap>
                        <Title>Mode</Title>
                        <Paragraph>{jobData?.data?.location}</Paragraph>
                    </Wrap>
                    <Wrap>
                        <Title>Type</Title>
                        <Paragraph>{jobData?.data?.schedule}</Paragraph>
                    </Wrap>
                </div>
                <Wrap>
                    <Title>Job category</Title>
                    <Paragraph>{parse(jobData?.data?.category)}</Paragraph>
                </Wrap>
                <Wrap>
                    <Title>Job description</Title>
                    <TextArea>{parse(jobData?.data?.description)}</TextArea>
                </Wrap>

                <div className="d-flex justify-content-center">
                    <CustomButton
                        className="py-3 mt-4"
                        text="Edit details"
                        onClick={() => {
                            setShowModal(true);
                        }}
                    />
                </div>
            </>

            <ModalComp
                show={showModal}
                handleClose={() => setShowModal(false)}
                centered
                close={true}
                size="lg"
                modalTitle="Edit job"
                paragraph="Fill in the forms to edit a job"
                modalBody={
                    <form onSubmit={handleSubmit(onHandleEdit)}>
                        <div className="py-1 px-lg-4 ">
                            <div className="d-sm-flex gap-2">
                                <div className="my-3 w-100">
                                    <Controller
                                        name="newData.role"
                                        control={control}
                                        rules={{
                                            required: 'Job role is required',
                                        }}
                                        render={({
                                            field: { value, onChange },
                                            formState: { errors },
                                        }) => {
                                            const errorMessage =
                                                errors?.newData?.role?.message;
                                            return (
                                                <TextInputComp
                                                    labelText="Job role"
                                                    placeholder="eg. UI/UX Designer"
                                                    inputClassName={'py-2 '}
                                                    name="role"
                                                    defaultValue={
                                                        jobData?.data?.role
                                                    }
                                                    {...{
                                                        value,
                                                        onChange,
                                                        errors: [errorMessage],
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                                <div className="my-3 w-100">
                                    <Controller
                                        name="newData.title"
                                        control={control}
                                        rules={{
                                            required: 'Job title is required',
                                        }}
                                        render={({
                                            field: { value, onChange },
                                            formState: { errors },
                                        }) => {
                                            const errorMessage =
                                                errors?.newData?.title?.message;
                                            return (
                                                <TextInputComp
                                                    labelText="Job title"
                                                    placeholder="eg. UI/UX Designer needed"
                                                    inputClassName={'py-2 '}
                                                    name="title"
                                                    defaultValue={
                                                        jobData?.data?.title
                                                    }
                                                    {...{
                                                        value,
                                                        onChange,
                                                        errors: [errorMessage],
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="d-lg-flex gap-2">
                                <div className="my-3 w-100">
                                    <Controller
                                        name="newData.location"
                                        control={control}
                                        rules={{
                                            required:
                                                'Job location is required',
                                        }}
                                        render={({
                                            field: { value, onChange },
                                            formState: { errors },
                                        }) => {
                                            const errorMessage =
                                                errors?.newData?.location
                                                    ?.message;
                                            return (
                                                <SelectComp
                                                    labelText="Job location"
                                                    arrayComp={[
                                                        'Remote',
                                                        'On-site',
                                                    ]}
                                                    name="location"
                                                    defaultValue={
                                                        jobData?.data?.location
                                                    }
                                                    {...{
                                                        value,
                                                        onChange,
                                                        errors: [errorMessage],
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                                <div className="my-3 w-100">
                                    <Controller
                                        name="newData.schedule"
                                        control={control}
                                        rules={{
                                            required: 'Job type is required',
                                        }}
                                        render={({
                                            field: { value, onChange },
                                            formState: { errors },
                                        }) => {
                                            const errorMessage =
                                                errors?.newData?.schedule
                                                    ?.message;
                                            return (
                                                <SelectComp
                                                    labelText="Job type"
                                                    arrayComp={[
                                                        'full-time',
                                                        'part-time',
                                                        'contract',
                                                    ]}
                                                    name="schedule"
                                                    {...{
                                                        value,
                                                        onChange,
                                                        errors: [errorMessage],
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="my-3 w-50">
                                <Controller
                                    name="newData.category"
                                    control={control}
                                    rules={{
                                        required: 'Job type is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.newData?.category?.message;
                                        return (
                                            <SelectComp
                                                labelText="Job type"
                                                arrayComp={[
                                                    'engineering',
                                                    'marketing',
                                                    'design',
                                                    'management',
                                                    'operations',
                                                    'finance',
                                                    'customer-service',
                                                ]}
                                                name="category"
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-4">
                                <Controller
                                    name="newData.description"
                                    control={control}
                                    rules={{
                                        required: 'Job type is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                    }) => {
                                        return (
                                            <TextEditor
                                                {...{ value, onChange }}
                                                labelText="Job description"
                                                defaultValue={
                                                    jobData?.data?.description
                                                }
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        isUpdating ? 'Loading...' : 'Edit job'
                                    }
                                />
                            </div>
                        </div>
                    </form>
                }
            />
        </div>
    );
};
const Wrap = styled.div`
    width: 100%;
`;
const Title = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${colors.black40};
    margin: 8px 0px;
`;
const Paragraph = styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    color: ${colors.black20};
`;
const TextArea = styled.div`
    background: #f7f9fa;
    border-radius: 6px;
    padding: 15px;
    font-size: 18px;
`;
