import { useNavigate } from "react-router-dom";
import IbxLogo from "../../../common/images/ibx_main_logo.svg";

import {
	Button,
	ContentContainer,
	Header,
	AuthContainer,
	Paragraph,
	PrivacyText,
	IBXLogo,
} from "../login/style";
import { Container } from "react-bootstrap";
import { useInitializeResetPasswordMutation } from "../../../store/auth/authApi";
import { Controller, useForm } from "react-hook-form";
import { TextInputComp } from "../../../common/component";
import { showSuccessToast, showErrorToast } from "../../../common/utils/toast";

export const ForgetPassword = () => {
	const navigate = useNavigate();

	const [initializeResetPassword, { isLoading }] =
		useInitializeResetPasswordMutation();

	const login = useForm({
		defaultValues: {
			email: "",
		},
	});
	const { handleSubmit, control } = login;

	const onHandleSubmit = (value: { email: string }) => {
		initializeResetPassword(value)
			.unwrap()
			.then((result) => {
				showSuccessToast(result?.message);
				localStorage.setItem("userEmail", value.email);
				navigate("/password/set-otp");
			})
			.catch((error) => {
				showErrorToast(error?.data?.message);
			});
	};
	return (
		<Container>
			<AuthContainer>
				<ContentContainer>
					<IBXLogo className="d-flex justify-content-center mb-5 pt-2">
						<img src={IbxLogo} alt="" />
					</IBXLogo>
					<div className="pb-2">
						<Header>Forget Password</Header>
					</div>
					<form onSubmit={handleSubmit(onHandleSubmit)}>
						<Paragraph>
							Enter your email address to get a verification code
						</Paragraph>
						<div className="mb-5 mt-4">
							<Controller
								name="email"
								control={control}
								rules={{
									required: "Email is required",
								}}
								render={({
									field: { value, onChange },
									formState: { errors },
								}) => {
									const errorMessage = errors?.email?.message;
									return (
										<TextInputComp
											labelText="Email Address"
											placeholder="Enter email address"
											inputClassName={"py-2"}
											name="email"
											{...{ value, onChange, errors: [errorMessage] }}
										/>
									);
								}}
							/>
						</div>
						<div className="d-flex justify-content-center my-3">
							<Button>{isLoading ? "Loading..." : "Submit"}</Button>
						</div>
					</form>
				</ContentContainer>
			</AuthContainer>
			<PrivacyText>
				Protected by reCAPTCHA and subject to the Google{" "}
				<span>Privacy Policy</span> and <span>Terms of Service.</span>
			</PrivacyText>
		</Container>
	);
};
