import styled from "styled-components";
import { colors } from "../../../common/component";

export const InputGrid = styled.div`
	display: flex;
	column-gap: 20px;
    margin: 20px 0px 10px 0px;
`;

export const ColoredText = styled.p`
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	text-align: right;
	color: ${colors.Primary1};
    cursor: pointer;
`;
