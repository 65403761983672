import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { IContentStat, ISubTopicStat, ITopic, ITopicStat } from './interface';
import { paginatedResponse } from '../paginatedInterface';

export const topicApi = createApi({
    reducerPath: 'topicApi',
    baseQuery: baseQuery,
    tagTypes: ['topic'],
    endpoints: (builder) => ({
        createTopic: builder.mutation<
            Response<ITopic>,
            {
                title: string;
                description: string;
                priority: number;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/topics`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['topic'],
        }),
        getAllTopics: builder.query<
            paginatedResponse<ITopic[]>,
            { perPage: number; currentPage: number }
        >({
            query: ({ perPage, currentPage }) => ({
                url: `/user/api/v1/topics?perPage=${perPage}&currentPage=${currentPage}`,
                method: 'GET',
            }),
            providesTags: ['topic'],
        }),
        getSingleTopic: builder.query<Response<ITopic>, string>({
            query: (query) => ({
                url: `/user/api/v1/topics/${query}`,
                method: 'GET',
            }),
            providesTags: ['topic'],
        }),
        updateTopic: builder.mutation<
            Response<ITopic>,
            {
                topicId: string;
                title: string;
                description: string;
                priority: number;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/topics`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['topic'],
        }),
        deleteTopic: builder.mutation<Response<ITopic>, string>({
            query: (query) => ({
                url: `/user/api/v1/topics/${query}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['topic'],
        }),
        topicStats: builder.query<Response<ITopicStat>, void>({
            query: () => ({
                url: `/user/api/v1/topics/a/stats`,
                method: 'GET',
            }),
            providesTags: ['topic'],
        }),
        createSubTopic: builder.mutation<
            Response<ITopic>,
            {
                title: string;
                description: string;
                topic: string;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/subtopic`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['topic'],
        }),
        getAllSubTopic: builder.query<
            paginatedResponse<ITopic[]>,
            { perPage: number; currentPage: number }
        >({
            query: ({ perPage, currentPage }) => ({
                url: `/user/api/v1/subtopic?perPage=${perPage}&currentPage=${currentPage}`,
                method: 'GET',
            }),
            providesTags: ['topic'],
        }),
        getSingleSubTopic: builder.query<Response<ITopic>, string>({
            query: (query) => ({
                url: `/user/api/v1/subtopic/${query}`,
                method: 'GET',
            }),
            providesTags: ['topic'],
        }),
        updateSubTopic: builder.mutation<
            Response<ITopic>,
            {
                topic: string;
                title: string;
                description: string;
                subTopicId: string;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/subtopic`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['topic'],
        }),
        deleteSubTopic: builder.mutation<Response<ITopic>, string>({
            query: (query) => ({
                url: `/user/api/v1/subtopic/${query}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['topic'],
        }),
        subTopicStats: builder.query<Response<ISubTopicStat>, void>({
            query: () => ({
                url: `/user/api/v1/subtopic/a/stats`,
                method: 'GET',
            }),
            providesTags: ['topic'],
        }),
        createContent: builder.mutation<
            Response<ITopic>,
            {
                topic: string;
                subTopic: string;
                content: string;
                description: string;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/content`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['topic'],
        }),
        getAllContent: builder.query<
            paginatedResponse<ITopic[]>,
            { perPage: number; currentPage: number }
        >({
            query: ({ perPage, currentPage }) => ({
                url: `/user/api/v1/content?perPage=${perPage}&currentPage=${currentPage}`,
                method: 'GET',
            }),
            providesTags: ['topic'],
        }),
        getSingleContent: builder.query<Response<ITopic>, string>({
            query: (query) => ({
                url: `/user/api/v1/content/${query}`,
                method: 'GET',
            }),
            providesTags: ['topic'],
        }),
        updateContent: builder.mutation<
            Response<ITopic>,
            {
                topic: string;
                subTopic: string;
                content: string;
                description: string;
                contentId: string;
                status: boolean;
            }
        >({
            query: (body) => ({
                url: `/user/api/v1/content`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['topic'],
        }),
        deleteContent: builder.mutation<Response<ITopic>, string>({
            query: (query) => ({
                url: `/user/api/v1/content/${query}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['topic'],
        }),
        contentStats: builder.query<Response<IContentStat>, void>({
            query: () => ({
                url: `/user/api/v1/content/a/stats`,
                method: 'GET',
            }),
            providesTags: ['topic'],
        }),
    }),
});

export const {
    useCreateTopicMutation,
    useGetAllTopicsQuery,
    useGetSingleTopicQuery,
    useUpdateTopicMutation,
    useDeleteTopicMutation,
    useTopicStatsQuery,
    useCreateSubTopicMutation,
    useGetAllSubTopicQuery,
    useGetSingleSubTopicQuery,
    useUpdateSubTopicMutation,
    useDeleteSubTopicMutation,
    useSubTopicStatsQuery,
    useCreateContentMutation,
    useGetAllContentQuery,
    useGetSingleContentQuery,
    useUpdateContentMutation,
    useDeleteContentMutation,
    useContentStatsQuery,
} = topicApi;
