import {
    ContentState,
    convertFromHTML,
    convertToRaw,
    EditorState,
} from 'draft-js';
import React, { useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import styled from 'styled-components';

export const TextEditor = (props: any) => {
    const [editorState, setEditorState] = React.useState(
        EditorState.createEmpty(),
    );
    useEffect(() => {
        setEditorState(
            EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(props?.defaultValue || '') as any,
                ),
            ),
        );
    }, [props?.defaultValue]);

    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState);
        return props.onChange(
            draftToHtml(convertToRaw(editorState.getCurrentContent())),
        );
    };
    return (
        <div>
            <Label className="pb-3">{props.labelText}</Label>
            <div
                className="px-1 py-0"
                style={{
                    minHeight: '250px',
                    border: '1px solid #F1F1F1',
                    borderRadius: '8px',
                }}
            >
                <Editor
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                        options: [
                            'inline',
                            'blockType',
                            'link',
                            'list',
                            'textAlign',
                        ],
                        inline: {
                            options: ['bold', 'italic', 'underline'],
                        },
                        blockType: {
                            inDropdown: false,
                            options: [
                                'Normal',
                                'H1',
                                'H2',
                                'H3',
                                'H4',
                                'H5',
                                'H6',
                            ],
                        },
                        list: { options: ['unordered', 'ordered'] },
                        textAlign: {
                            options: ['left', 'center', 'right', 'justify'],
                        },
                        link: {
                            inDropdown: false,
                        },
                    }}
                />
            </div>
        </div>
    );
};

const Label = styled.label`
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
`;
