import { Container, InputWrap } from '../users/style';
import {
    Counter,
    Loader,
    TextEditor,
    TextInputComp,
} from '../../common/component';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { TopicHeader } from '../../common/utils/dataUtils';
import { BsEye } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { CustomButton } from '../../common/component/button';
import { ModalComp } from '../../common/component/Ui/modalComp';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as CareerIcon } from '../../common/images/career_icon.svg';
import { showSuccessToast, showErrorToast } from '../../common/utils/toast';
import { Controller, useForm } from 'react-hook-form';
import { Flexbox2 } from '../currencyPair/style';
import {
    useCreateTopicMutation,
    useDeleteTopicMutation,
    useGetAllTopicsQuery,
    useTopicStatsQuery,
} from '../../store/topicApi';
import { ITopic } from '../../store/topicApi/interface';
const parse = require('html-react-parser');

export const Topic = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropDownTerm, setDropDownTerm] = useState('');
    const [newArray, setNewArray] = useState<ITopic[] | any>();
    const [showModal, setShowModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [topicId, setTopicId] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };

    const {
        data: allTopics,
        isLoading,
        isFetching,
    } = useGetAllTopicsQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });

    const [createTopic, { isLoading: isAddTopic }] = useCreateTopicMutation();
    const [deleteTopic, { isLoading: isDeleting }] = useDeleteTopicMutation();
    const { data: topicStats } = useTopicStatsQuery();
    useEffect(() => {
        setTotalRows(allTopics?.pagination?.totalDocumentCount as number);
    }, [allTopics?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allTopics?.data?.length) return;
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const Items = [
        {
            name: ' View topic',
            icon: <BsEye size={15} />,
            link: 'view',
            id: '_id',
        },
        {
            name: ' Delete topic',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: any) => {
                setTopicId(data?._id);
                setDeleteModal(true);
            },
        },
    ];

    console.log(newArray);
    // To implement search and dropdown input
    useEffect(() => {
        if (searchTerm === '' && dropDownTerm === '') {
            setNewArray(allTopics?.data);
        }
        if (dropDownTerm !== '') {
            let filteredItems = allTopics?.data?.filter((item) => {
                return item?.schedule
                    .toLowerCase()
                    .includes(dropDownTerm.toLowerCase());
            });
            setNewArray(filteredItems);
        }
        if (searchTerm !== '') {
            let searchItems = allTopics?.data?.filter((item) => {
                return item?.title
                    ?.toLowerCase()
                    .includes(searchTerm?.toLowerCase());
            });
            setNewArray(searchItems);
        }
    }, [allTopics, searchTerm, dropDownTerm]);

    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
            title: '',
            description: '',
            priority: 0,
        },
    });

    const onHandleSubmit = (value: {
        title: string;
        description: string;
        priority: number;
    }) => {
        createTopic(value)
            .unwrap()
            .then((result) => {
                console.log('Topic added successfully:', result);
                showSuccessToast(result?.message);
                setShowModal(false);
                reset();
            })
            .catch((error) => {
                console.error('Error adding job:', error);
                showErrorToast(error?.data?.message);
            });
    };

    const onDeleteJob = (id: string) => {
        deleteTopic(id)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setDeleteModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    return (
        <Container>
            {isLoading || isDeleting ? <Loader /> : null}
            <Header>Topics</Header>
            <Grid>
                <Counter
                    counter_title="Total Topics"
                    count={topicStats?.data?.totalTopics || 0}
                    iconFill="blue"
                    iconBG="blue"
                >
                    <CareerIcon />
                </Counter>
                <Counter
                    counter_title="Inactive Topics"
                    count={topicStats?.data?.InActiveTopics || 0}
                >
                    <CareerIcon />
                </Counter>
                <Counter
                    counter_title="Active Topics"
                    count={topicStats?.data?.activeTopics || 0}
                    iconFill="green"
                    iconBG="green"
                >
                    <CareerIcon />
                </Counter>
                <Counter
                    counter_title="Deleted Topics"
                    count={topicStats?.data?.deletedTopics || 0}
                    iconFill="red"
                    iconBG="red"
                >
                    <CareerIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={8}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <InputWrap>
                        <img src={SearchIcon} alt="" />
                        <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Search"
                            className="border-start-0 border-bottom-0 border-top-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputWrap>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <CustomButton
                        text="Create topic "
                        onClick={() => setShowModal(true)}
                    />
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={TopicHeader(Items)}
                    data={newArray}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>
            <ModalComp
                show={showModal}
                handleClose={() => setShowModal(false)}
                centered
                close={true}
                size="lg"
                modalTitle="Create topic"
                paragraph="Fill in the forms to create a topic"
                modalBody={
                    <form onSubmit={handleSubmit(onHandleSubmit)}>
                        <div className="py-1 px-lg-4 ">
                            <div className="my-3">
                                <Controller
                                    name="priority"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                    }) => {
                                        return (
                                            <TextInputComp
                                                labelText="Priority"
                                                inputClassName={'py-2'}
                                                name="priority"
                                                type="number"
                                                defaultValue={1}
                                                {...{ value, onChange }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-4">
                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{
                                        required: 'Topic title is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.title?.message;
                                        return (
                                            <TextEditor
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                                labelText="Topic title"
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-4">
                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{
                                        required:
                                            'Topic description is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.description?.message;
                                        return (
                                            <TextEditor
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                                labelText="Topic description"
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        isAddTopic
                                            ? 'Loading...'
                                            : 'Create Topic'
                                    }
                                />
                            </div>
                        </div>
                    </form>
                }
            />
            <ModalComp
                show={deleteModal}
                handleClose={() => setDeleteModal(false)}
                centered
                close={true}
                modalTitle="Delete Job"
                modalBody={
                    <Flexbox2>
                        <CustomButton
                            className="py-3 mt-4"
                            text={'Cancel'}
                            onClick={() => setDeleteModal(false)}
                        />
                        <CustomButton
                            className="py-3 mt-4"
                            text={!isDeleting ? 'Delete' : 'Loading...'}
                            onClick={() => onDeleteJob(topicId)}
                        />
                    </Flexbox2>
                }
            />
        </Container>
    );
};
