import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { ITrade, ITradeStats } from './interface';
import { paginatedResponse } from '../paginatedInterface';

export const tradeApi = createApi({
    reducerPath: 'tradeApi',
    baseQuery: baseQuery,
    tagTypes: ['trade'],
    endpoints: (builder) => ({
        getAllTrade: builder.query<
            paginatedResponse<ITrade[]>,
            {
                perPage: number;
                currentPage: number;
                category?: string;
                name?: string;
                currency?: string;
                date?: string;
            }
        >({
            query: ({
                perPage,
                currentPage,
                category,
                name,
                currency,
                date,
            }) => {
                let query = `/marketPlace/api/v1/trade?perPage=${perPage}&currentPage=${currentPage}`;
                if (name) {
                    query = `${query}&name=${name}`;
                }

                if (name && currency) {
                    query = `${query}&name=${name}&currency=${currency}`;
                }

                if (name && currency && date) {
                    query = `${query}&name=${name}&currency=${currency}&date=${date}`;
                }

                if (name && currency && date && category) {
                    query = `${query}&name=${name}&currency=${currency}&date=${date}&category=${category}`;
                }

                if (currency) {
                    query = `${query}&currency=${currency}`;
                }

                if (date) {
                    query = `${query}&date=${date}`;
                }

                if (category) {
                    query = `${query}&category=${category.toLowerCase()}`;
                }

                return {
                    url: query,
                    method: 'GET',
                };
            },
            providesTags: ['trade'],
        }),
        getSingleTrade: builder.query<Response<ITrade>, string>({
            query: (query) => ({
                url: `/marketPlace/api/v1/trade/${query}`,
                method: 'GET',
            }),
        }),
        tradeStats: builder.query<Response<ITradeStats>, void>({
            query: () => ({
                url: `/marketPlace/api/v1/trade/a/stats`,
                method: 'GET',
            }),
        }),
        getUserTrade: builder.query<
            paginatedResponse<ITrade[]>,
            { userId: string; perPage: number; currentPage: number }
        >({
            query: ({ perPage, userId, currentPage }) => ({
                url: `/marketPlace/api/v1/trade/getUserTrades/${userId}?perPage=${perPage}&currentPage=${currentPage}`,
                method: 'GET',
            }),
        }),
        getUserTradeStats: builder.query<Response<ITradeStats>, string>({
            query: (query) => ({
                url: `/marketPlace/api/v1/trade/getUserTradeCounts/${query}`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetAllTradeQuery,
    useGetSingleTradeQuery,
    useTradeStatsQuery,
    useGetUserTradeQuery,
    useGetUserTradeStatsQuery,
} = tradeApi;
