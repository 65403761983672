import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { ISubscribe, ISubscribeStats } from './interface';
import { paginatedResponse } from '../paginatedInterface';

export const subscribeApi = createApi({
    reducerPath: 'subscribeApi',
    baseQuery: baseQuery,
    tagTypes: ['subscribe'],
    endpoints: (builder) => ({
        getAllSubscribe: builder.query<
            paginatedResponse<ISubscribe[]>,
            { perPage: number; currentPage: number; email?: string }
        >({
            query: ({ perPage, currentPage, email }) => {
                let query = `/notification/api/v1/newsletter?perPage=${perPage}&currentPage=${currentPage}`;
                if (email) {
                    query = `${query}&email=${email}`;
                }
                return {
                    url: query,
                    method: 'GET',
                };
            },
            providesTags: ['subscribe'],
        }),
        deleteSubscribe: builder.mutation<Response<ISubscribe>, string>({
            query: (query) => ({
                url: `/notification/api/v1/newsletter/${query}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['subscribe'],
        }),
        subscribeStats: builder.query<Response<ISubscribeStats>, void>({
            query: () => ({
                url: `/notification/api/v1/newsletter/a/stats`,
                method: 'GET',
            }),
            providesTags: ['subscribe'],
        }),
    }),
});

export const {
    useGetAllSubscribeQuery,
    useDeleteSubscribeMutation,
    useSubscribeStatsQuery,
} = subscribeApi;
