import styled from 'styled-components';
import { colors } from '../../common/component';

export const Container = styled.div`
    padding-bottom: 16px;
`;
export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 4px;
    margin: 0px 15px;

    button {
        background: none;
        border: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        padding: 0px 5px;
        color: ${colors.purple100};
        cursor: pointer;

        &:hover {
            font-weight: 600;
        }
        img {
            width: 10px;
        }
    }
`;
export const BarLine = styled.div`
    height: 20px;
    width: 1px;
    border: none;
    background: ${colors.gray20};
`;
export const TotalResultText = styled.p`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    min-width: 40%;
    color: ${colors.black100};
    margin-left: 10px;
`;
export const InputWrap = styled.span`
    border: 1px solid ${colors.gray10};
    border-radius: 6px;
    width: calc(65% - 0px);
    @media (max-width: 768px) {
        width: 50%;
    }
    @media (max-width: 600px) {
        width: 100%;
    }
    position: relative;
    img {
        position: absolute;
        top: 6px;
        left: 8px;
    }
    input {
        background: ${colors.white};
        border-radius: 5px;
        width: calc(100% - 44px);
        padding: 6px 10px 6px 32px;
        font-size: 16px;
        border: none;
        width: 100%;
    }
`;

//view users
export const BackButton = styled.p`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${colors.purple100};
    margin-right: 5px;
    cursor: pointer;
`;
export const TopText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    span {
        color: ${colors.black50};
    }
`;
export const DownText = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    .date {
        display: flex;
        align-items: center;
        gap: 4px;

        .purple {
            height: 6px;
            width: 6px;
            background-color: ${colors.purple3};
            border-radius: 50%;
        }
    }
    .DownStatus {
        height: 27px;
        width: 86px;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color: ${colors.white};
        background-color: ${colors.secondary10};
        padding: 3px 12px;
    }
`;
export const UserImage = styled.div`
    min-width: 45px;
    min-height: 45px;
    max-width: 45px;
    max-height: 45px;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    border: 1px solid ${colors.black10};

    @media (max-width: 600px) {
        width: 30px;
        height: 30px;
    }
    img {
        width: 100%;
        object-fit: cover;
    }
`;
export const UserName = styled.p`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${colors.black90};
`;
export const PaymentStatus = styled.p<{ status?: boolean }>`
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${(prop) => (prop.status ? colors.Primar : colors.red100)};
`;
export const UserContact = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: ${colors.black80};
    svg {
        stroke: ${colors.black80};
    }
`;
export const BorderDiv = styled.div`
    border-bottom: 1px solid ${colors.black10};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow-x: scroll;
    @media (max-width: 600px) {
        flex-direction: column-reverse;
        align-items: unset;
    }
    &::-webkit-scrollbar {
        width: 0px;
        display: none;
    }
`;
export const ActiveButton = styled.div<{ active?: boolean }>`
    border-bottom: ${(props) =>
        props.active ? `2px solid ${colors.secondary80}` : 'none'};
    color: ${(props) => (props.active ? colors.secondary80 : colors.black50)};
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
    padding: 8px 0px;
    cursor: pointer;
`;
export const SubHeading = styled.p`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${colors.blue10};
`;
export const TextGrid = styled.div`
    display: flex;
    column-gap: 20px;
    width: 120%;
    @media (max-width: 600px) {
        width: 110%;
    }
    @media (max-width: 376px) {
        width: 120%;
    }
    @media (max-width: 320px) {
        width: 150%;
    }
`;
export const UserType = styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    color: ${colors.purple100};
    padding: 8px 0px;
    margin: 0px 0px;
    border-radius: 4px;
`;
export const Wrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
    input {
        border: 1px solid ${colors.blue20};
        border-radius: 6px;
        padding: 6px;
        width: 50%;
    }

    svg {
        fill: ${colors.black100};
        stroke: ${colors.white};
        cursor: pointer;
    }
`;
export const IconWrap = styled.div<{ white?: string }>`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => (props.white ? colors.white : colors.Primary1)};
    border: 1px solid #bbc6cc;
    border-radius: 6px;
`;
