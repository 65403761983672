import {
    BarLine,
    Grid,
    ImageContainer,
    StatusContainer,
    SubHeading,
    Card,
    Text,
    UserWrap,
} from '../partnerRequest/style';
import { BackButton, TopText, UserImage, UserName } from '../users/style';
import { useNavigate, useParams } from 'react-router-dom';
import back_arrow from '../../common/images/back_arrow.svg';
import { Loader, ModalComp } from '../../common/component';
import { getDateString } from '../../common/utils/date';
import { useState } from 'react';
import { useGetSingleContactQuery } from '../../store/contactUsApi';

export const ViewContact = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const contactUsId = params?.id as string;
    const { data: contactData, isLoading } =
        useGetSingleContactQuery(contactUsId);
    console.log('my data:', contactData);
    return (
        <div>
            <div className="d-flex align-items-center my-2">
                <BackButton onClick={() => navigate(-1)}>
                    <img src={back_arrow} alt="" /> Back
                </BackButton>
                <TopText>
                    {' '}
                    <span>/ Contact Details</span> / View User
                </TopText>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="d-flex gap-3 mt-4">
                        <BarLine />
                        <div className="d-flex flex-column gap-4 w-100">
                            <Grid className="d-flex gap-4 gap-sm-5">
                                <Card>
                                    <div className="pb-1">
                                        <UserWrap>User Contact</UserWrap>
                                    </div>
                                    <div className="d-flex align-items-center gap-3 gap-lg-2">
                                        <UserName>{`${contactData?.data?.firstName} ${contactData?.data?.lastName}`}</UserName>
                                    </div>
                                </Card>

                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Date Created</SubHeading>
                                    <Text>
                                        {getDateString(
                                            contactData?.data
                                                ?.createdAt as string,
                                        )}
                                    </Text>
                                </Card>

                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Message</SubHeading>
                                    <Text>{contactData?.data?.message}</Text>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Address</SubHeading>
                                    <Text>{contactData?.data?.phone}</Text>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Email</SubHeading>
                                    <Text>{contactData?.data?.email}</Text>
                                </Card>
                                <Card className="d-flex flex-column gap-2">
                                    <SubHeading>Status</SubHeading>
                                    <StatusContainer
                                        status={
                                            contactData?.data?.status as string
                                        }
                                    >
                                        {contactData?.data?.status}
                                    </StatusContainer>
                                </Card>
                            </Grid>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
