import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../api';
import { Response } from '../genericInterface';
import { ICurrency, ICurrencyStats } from './interface';
import { paginatedResponse } from '../paginatedInterface';

export const currencyApi = createApi({
    reducerPath: 'currencyApi',
    baseQuery: baseQuery,
    tagTypes: ['currency'],
    endpoints: (builder) => ({
        addCurrency: builder.mutation<
            Response<ICurrency[]>,
            {
                name: string;
                currencySymbol: string;
                currencyType: string;
                imageUrl: string;
            }
        >({
            query: (body) => ({
                url: `/marketPlace/api/v1/currency`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['currency'],
        }),
        editCurrency: builder.mutation<
            Response<ICurrency[]>,
            {
                name: string;
                currencySymbol: string;
                currencyType: string;
                imageUrl: string;
                currencyId: string;
            }
        >({
            query: (body) => ({
                url: `/marketPlace/api/v1/currency`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['currency'],
        }),
        allCurrency: builder.query<
            paginatedResponse<ICurrency[]>,
            {
                perPage: number;
                currentPage: number;
                currencyType?: string;
                name?: string;
                createdAt?: string;
            }
        >({
            query: ({
                perPage,
                currentPage,
                currencyType,
                name: currenyName,
                createdAt,
            }) => {
                let query = `/marketPlace/api/v1/currency?perPage=${perPage}&currentPage=${currentPage}`;
                if (currencyType) {
                    query = `${query}&currencyType=${currencyType}`;
                }
                if (currenyName) {
                    query = `${query}&name=${currenyName}`;
                }
                if (createdAt) {
                    query = `${query}&createdAt=${createdAt}`;
                }
                return {
                    url: query,
                    method: 'GET',
                };
            },
            providesTags: ['currency'],
        }),
        getSingleCurrency: builder.query<Response<ICurrency>, string>({
            query: (query) => ({
                url: `/marketPlace/api/v1/currency/${query}`,
                method: 'GET',
            }),
        }),
        deleteCurrency: builder.mutation<Response<ICurrency>, string>({
            query: (query) => ({
                url: `/marketPlace/api/v1/currency/${query}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['currency'],
        }),
        currencyStats: builder.query<Response<ICurrencyStats>, void>({
            query: () => ({
                url: `/marketPlace/api/v1/currency/a/stats`,
                method: 'GET',
            }),
            providesTags: ['currency'],
        }),
    }),
});

export const {
    useAddCurrencyMutation,
    useCurrencyStatsQuery,
    useDeleteCurrencyMutation,
    useEditCurrencyMutation,
    useAllCurrencyQuery,
    useGetSingleCurrencyQuery,
} = currencyApi;
